import ProgressBar from "@/components/ProgressBar";
import { useUser } from "@/context/user-context";
import { Report } from "@/types/evaluate";
import { evaluationScoreText } from "@/utils/evaluate";
import moment from "moment";
import { Link } from "react-router-dom";

export const getProgressPercentage = (report: Report) => {
  const reportStatus = report.status;
  if (!reportStatus) {
    return 0;
  }
  const total =
    reportStatus.COMPLETED +
    reportStatus.FAILED +
    reportStatus.RUNNING +
    reportStatus.QUEUED;

  if (total === 0 || reportStatus.COMPLETED + reportStatus.FAILED === 0) {
    return 0;
  }

  // Calculate the percentage using the original logic
  let percentage = Math.round(
    ((reportStatus.QUEUED + reportStatus.RUNNING
      ? reportStatus.COMPLETED + reportStatus.FAILED
      : reportStatus.COMPLETED) /
      total) *
      100,
  );

  // If there are still items queued or running, cap at 99%
  if (reportStatus.QUEUED + reportStatus.RUNNING > 0 && percentage === 100) {
    percentage = 99;
  }

  return percentage;
};

export const ReportCard = ({ report }: { report: Report }) => {
  const momentsAgo = moment(report.created_at).fromNow();
  const userContext = useUser();
  const { scoreText, scoreColor, scoreIcon } = evaluationScoreText(
    report.score,
    "mr-1 inline h-3 w-auto",
  );
  const renderScore = scoreText ? (
    <div className={`flex items-center justify-between text-sm ${scoreColor}`}>
      {scoreIcon}
      {scoreText}
    </div>
  ) : null;
  const total = report.status
    ? report.status.COMPLETED +
      report.status.FAILED +
      report.status.RUNNING +
      report.status.QUEUED
    : 0;
  const progress = report.status
    ? report.status.COMPLETED + report.status.FAILED
    : 0;
  const stillRunning = total === 0 || progress !== total;

  return (
    <Link
      to={`/workspace/${userContext?.activeWorkspaceId}/evaluate/reports/${report.id}`}
    >
      <div className="flex items-center border-b border-gray-100 p-2 hover:bg-gray-100">
        <h3 className="max-w-lg flex-1 truncate break-all text-base font-medium text-gray-900">
          {report.name}
        </h3>
        {renderScore ? (
          <div className="ml-auto flex pr-3 text-right">
            <div>{renderScore}</div>
          </div>
        ) : getProgressPercentage(report) === 100 ? (
          <div className="ml-auto flex pr-3 text-right text-sm">
            <div className="font-normal text-gray-500">Done.</div>
          </div>
        ) : (
          <ProgressBar
            isLoading={stillRunning}
            progress={getProgressPercentage(report)}
          />
        )}
        <p className="w-32 text-right text-sm text-gray-500">{momentsAgo}</p>
      </div>
    </Link>
  );
};

export const BlueprintCard = ({ report }: { report: Report }) => {
  const momentsAgo = moment(report.updated_at).fromNow();
  const userContext = useUser();
  return (
    <Link
      to={`/workspace/${userContext?.activeWorkspaceId}/evaluate/blueprints/${report.id}`}
    >
      <div className="col-span-1 h-full rounded-lg border border-gray-200 p-4 hover:bg-gray-100">
        <h3 className="break-all text-lg font-medium text-gray-900">
          {report.name}
        </h3>
        <p className="mt-1 text-sm text-gray-500">Last Updated: {momentsAgo}</p>
      </div>
    </Link>
  );
};
