import { WEBSOCKET_MESSAGE_NAME } from "@/constants";
import { useUser } from "@/context/user-context";
import { useChannel } from "ably/react";
import { usePlayground } from "../../playground-store";

const useMessageUpdateListener = () => {
  const { user } = useUser();
  const playground = usePlayground();

  const handleMessageUpdate = (payload: any) => {
    const {
      payload: { message },
      individual_run_request_id,
    } = JSON.parse(payload.data);

    if (!playground.isRequestValid(individual_run_request_id)) return;

    const operation =
      playground.operationStore.getAssociatedOperationByRequestId(
        individual_run_request_id,
      );

    if (!operation) return;

    const messageId = playground.operationStore.getAssociatedMessageId(
      operation.id,
      individual_run_request_id,
    );

    if (messageId)
      playground
        .getOutput(operation!.output_instance_id!)!
        .updateMessage(messageId, message);
  };

  useChannel(
    `user:${user.id}`,
    WEBSOCKET_MESSAGE_NAME.UPDATE_LAST_MESSAGE,
    handleMessageUpdate,
  );
};

export default useMessageUpdateListener;
