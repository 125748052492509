import { useQueryClient } from "@tanstack/react-query";
import { Conditional } from "@/types/conditionals";
import { jsonAuthHeaders } from "@/utils/headers";
import { ENDPOINTS } from "@/api/application-api";
import { useMutation } from "@tanstack/react-query";
import { handleResponse } from "@/utils/errorResponseHandlers";
import { ResponseWithStatus } from "../types";

interface Message {
  loc: string[];
  msg: string;
  type: string;
}

export const useSetWorkflowReleaseLabelGroup = (
  userToken: string,
  workspace_id: number,
  workflowReleaseLabelGroupId: string,
) => {
  const queryClient = useQueryClient();

  return useMutation<ResponseWithStatus<Message[]>, Error, Conditional[]>(
    (conditionals) =>
      fetch(
        `${ENDPOINTS.workflow_release_label_groups}/${workflowReleaseLabelGroupId}`,
        {
          method: "PUT",
          headers: jsonAuthHeaders(userToken),
          body: JSON.stringify({
            workspace_id,
            conditionals,
          }),
        },
      ).then(handleResponse),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([
          ENDPOINTS.workflow_release_label_groups,
        ]);
      },
    },
  );
};
