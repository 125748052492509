import { BasicTooltip } from "@/components/ui/Tooltip";
import { Button } from "@/components/ui/button";
import { PlusIcon } from "@heroicons/react/outline";
import { AnimatePresence, motion } from "framer-motion";
import { observer } from "mobx-react-lite";
import { usePlayground } from "../../playground-store";
import SidebarWidget from "../SidebarWidget";
import {
  EmptyVariableSetPlaceholder,
  InputVariableSet,
} from "./InputVariableSet";
import VariableSetDialog from "./InputVariableSet/VariableSetDialog";
import useScrollToNewElement from "./hooks/useScrollToNewElement";
import useVariableSetEditing from "./hooks/useVariableSetEditing";

const AddVariableSetButton = ({
  onAdd,
}: {
  onAdd: (setId: string) => void;
}) => {
  const playground = usePlayground();

  return (
    <motion.div
      key="add-button"
      layout
      initial={{ opacity: 0, scale: 0.8 }}
      animate={{ opacity: 1, scale: 1 }}
      exit={{ opacity: 0, scale: 0.8 }}
      transition={{
        duration: 0.2,
        ease: "easeInOut",
      }}
      className="sticky right-0 z-10 ml-2 flex items-center"
    >
      <BasicTooltip content="Add new input variable set">
        <Button
          variant={"default"}
          size={"sm"}
          className="pointer-events-auto h-16 w-16 rounded-full border shadow-sm transition-all duration-200 hover:border-gray-200 hover:shadow-md"
          onClick={() => {
            const newSetId = playground.addEmptyInputVariableSet();
            onAdd(newSetId);
          }}
        >
          <PlusIcon className="h-5 w-5" />
        </Button>
      </BasicTooltip>
    </motion.div>
  );
};

const VariableSetList = observer(
  ({
    inputVariableSets,
    containerRef,
    onSetClick,
  }: {
    inputVariableSets: any[];
    containerRef: React.RefObject<HTMLDivElement>;
    onSetClick: (setId: string, scroll: boolean) => void;
  }) => {
    const playground = usePlayground();

    return (
      <div className="pointer-events-auto relative w-full">
        <div
          ref={containerRef}
          className="scrollbar-thin scrollbar-thumb-gray-200 scrollbar-track-transparent flex overflow-x-auto pb-2"
        >
          <div className="flex items-center gap-x-2 px-0.5">
            <AnimatePresence mode="popLayout" initial={false}>
              {inputVariableSets.map((set, index) => (
                <motion.div
                  layout
                  key={set.id}
                  initial={{ opacity: 0, scale: 0.8 }}
                  animate={{ opacity: 1, scale: 1 }}
                  exit={{ opacity: 0, scale: 0.8 }}
                  transition={{
                    duration: 0.2,
                    ease: "easeInOut",
                  }}
                >
                  <InputVariableSet
                    set={set}
                    number={index + 1}
                    onDuplicate={(newSetId) => onSetClick(newSetId, true)}
                    onClick={() => onSetClick(set.id, false)}
                  />
                </motion.div>
              ))}
              {playground.canAddInputVariableSet && (
                <AddVariableSetButton
                  onAdd={(setId) => onSetClick(setId, true)}
                />
              )}
            </AnimatePresence>
          </div>
        </div>
      </div>
    );
  },
);

const InputVariableWidget = () => {
  const playground = usePlayground();

  const { containerRef, scrollToEnd } = useScrollToNewElement();
  const { editingSetId, isEditingSet, startEditing, stopEditing } =
    useVariableSetEditing();

  const handleNewSetCreated = (setId: string, scroll = true) => {
    startEditing(setId);
    scroll && setTimeout(scrollToEnd, 100);
  };

  return (
    <SidebarWidget
      name="Input Variable Sets"
      count={playground.input_variable_sets.length}
    >
      <div key="content" className="flex flex-col gap-y-2">
        {playground.input_variable_sets.length === 0 ? (
          <EmptyVariableSetPlaceholder onAdd={handleNewSetCreated} />
        ) : (
          <VariableSetList
            inputVariableSets={playground.input_variable_sets}
            containerRef={containerRef}
            onSetClick={handleNewSetCreated}
          />
        )}
      </div>
      <VariableSetDialog
        set={
          playground.input_variable_sets.find((set) => set.id === editingSetId)!
        }
        isOpen={isEditingSet}
        onClose={stopEditing}
      />
    </SidebarWidget>
  );
};

export default observer(InputVariableWidget);
