import InputVariableConfigurator from "@/components/InputVariableConfigurator";
import { usePlayground } from "@/components/Playground2/playground-store";
import { VariableSet } from "@/components/Playground2/types";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";

import { observer } from "mobx-react-lite";

interface VariableSetDialogProps {
  set?: VariableSet;
  isOpen: boolean;
  onClose: () => void;
}

const VariableSetDialog = observer(
  ({ set, isOpen, onClose }: VariableSetDialogProps) => {
    const playground = usePlayground();
    return (
      <Dialog open={isOpen} onOpenChange={onClose}>
        <DialogContent className="max-h-[580px] max-w-3xl">
          <DialogHeader>
            <DialogTitle>Edit Input Variable Set</DialogTitle>
            <DialogDescription>
              Input variables allow you to create reusable values that can be
              referenced in your messages. Use the syntax{" "}
              <code>{"{variable}"}</code> to include variables in your text.
            </DialogDescription>
          </DialogHeader>
          {set && (
            <InputVariableConfigurator
              onUpdateInputVariable={playground.updateInputVariable(set.id)}
              onRemoveInputVariable={playground.removeInputVariable(set.id)}
              onClearInputVariables={() =>
                playground.clearInputVariableSet(set.id)
              }
              inputVariables={set?.variables || []}
              onClose={onClose}
            />
          )}
        </DialogContent>
      </Dialog>
    );
  },
);

export default VariableSetDialog;
