import { useIsCurrentPageNumber } from "@/components/SidebarSearchLayout/hooks/useIsCurrentPageNumber";
import { useUser } from "@/context/user-context";
import { CogIcon } from "lucide-react";
import { Link } from "react-router-dom";

const Settings = () => {
  const userContext = useUser();
  const isCurrentPageName = useIsCurrentPageNumber();
  return (
    <Link
      className={`flex ${
        (isCurrentPageName({
          name: "settings",
          href: "settings",
        }) &&
          "bg-gray-200") ||
        "hover:bg-gray-50"
      } box-border h-6 items-center justify-center rounded-full p-4`}
      to={`/workspace/${userContext?.activeWorkspaceId}/settings`}
    >
      <CogIcon className="h-6 w-auto cursor-pointer rounded-full hover:text-gray-700" />
    </Link>
  );
};

export default Settings;
