import { ParameterControlContent } from "./ParameterControlContent";
import { ParameterLabel } from "./ParameterLabel";
import { observer } from "mobx-react-lite";
import { ParameterStore } from "@/components/ModelParameters/store/parameter-store";

interface BasicParameterProps {
  parameter: ParameterStore;
  labelClasses: string;
  shouldShowToggle?: boolean;
  disabledClass: string;
  isDisabled?: boolean;
  value?: any;
  readOnly?: boolean;
}

export const BasicParameter = observer(
  ({
    parameter,
    labelClasses,
    disabledClass,
    isDisabled,
    value,
    shouldShowToggle,
    readOnly,
  }: BasicParameterProps) => {
    return (
      <div
        className={`flex ${
          readOnly
            ? "w-full flex-row items-center justify-between"
            : "flex-col gap-2"
        }`}
      >
        <ParameterLabel
          id={parameter.paramId ?? ""}
          name={parameter.name ?? ""}
          labelClasses={labelClasses}
          disabledClass={disabledClass}
          isDisabled={isDisabled}
          readOnly={readOnly}
          shouldShowToggle={shouldShowToggle}
          onToggle={(isEnabled) => parameter.updateDisabled(isEnabled)}
        />
        <div
          className={`w-full ${readOnly ? "max-w-[229px]" : ""} ${
            isDisabled ? disabledClass : ""
          }`}
        >
          <ParameterControlContent
            onChange={(value) => parameter.updateValue(value)}
            value={value ?? parameter.value ?? parameter.default}
            parameter={parameter}
            isDisabled={isDisabled}
            readOnly={readOnly}
          />
        </div>
      </div>
    );
  },
);
