import { useAuth } from "@/context/auth-context";
import { useUpdatePlaygroundSession } from "@/queries";
import { reaction } from "mobx";
import { useEffect, useRef } from "react";
import { usePlayground } from "../../playground-store";

const useAutoSaveSession = () => {
  const playground = usePlayground();
  const userToken = useAuth()?.userToken || "";
  const updatePlaygroundSession = useUpdatePlaygroundSession(userToken);
  const initialMountRef = useRef(true);

  useEffect(() => {
    const disposeUndebouncedReaction = reaction(
      () => [
        playground.payload,
        playground.messagesContent,
        playground.inputVariableSetsContent,
      ],
      () => {
        if (playground.isPendingSessionInitialization) return;
        if (initialMountRef.current) {
          initialMountRef.current = false;
          return;
        }
        if (!playground.isSavingSession) playground.setIsSavingSession(true);
      },
    );

    const disposeDebouncedReaction = reaction(
      () => [
        playground.payload,
        playground.messagesContent,
        playground.inputVariableSetsContent,
        playground.functionToolCallContent,
      ],
      () => {
        if (playground.isPendingSessionInitialization) return;
        if (initialMountRef.current) {
          initialMountRef.current = false;
          return;
        }

        updatePlaygroundSession.reset();
        updatePlaygroundSession.mutate(
          {
            workspaceId: playground.workspace_id,
            session: {
              id: playground.instanceId,
              name: "Not implemented",
              prompt_blueprint: playground.blueprint,
              input_variables: playground.input_variable_sets.map(
                (set) => set.variables,
              ),
            },
          },
          {
            onSuccess: () => {
              playground.setIsSavingSession(false);
            },
            onError: () => {
              playground.setIsSavingSession(false);
            },
          },
        );
      },
      { delay: 1000 },
    );

    return () => {
      disposeUndebouncedReaction();
      disposeDebouncedReaction();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [playground]);
};

export default useAutoSaveSession;
