import { DialogHeader } from "@/components/ui/dialog";
import { BasicTooltip } from "@/components/ui/Tooltip";
import {
  Dialog,
  DialogTrigger,
  DialogContent,
  DialogTitle,
  DialogDescription,
} from "@/components/ui/dialog";
import { CogIcon } from "lucide-react";

import FormatSelector from "./FormatSelector";
import ModeSwitch from "./ModeSwitch";
import { Button } from "@/components/ui/button";
import { usePlayground } from "../../playground-store";

const MessagesSettings = () => {
  const { readonly } = usePlayground();
  return (
    <Dialog>
      <BasicTooltip content="Prompt Settings">
        <DialogTrigger asChild>
          <Button
            variant="ghost"
            size="icon"
            className="rounded-full transition-all duration-200 hover:bg-gray-100 active:scale-95"
          >
            <CogIcon className="h-5 w-5 text-gray-500 transition-colors hover:text-gray-700" />
          </Button>
        </DialogTrigger>
      </BasicTooltip>
      <DialogContent className="sm:max-w-[400px]">
        <DialogHeader>
          <DialogTitle className="text-xl font-semibold">Settings</DialogTitle>
          <DialogDescription className="text-sm text-gray-500">
            Customize your message display preferences
          </DialogDescription>
        </DialogHeader>

        <div className="space-y-8">
          <div className="space-y-1.5">
            <h3 className="text-sm font-medium text-gray-700">
              Message Format
            </h3>
            <FormatSelector readonly={readonly} />
          </div>
          <div className="space-y-1.5">
            <h3 className="text-sm font-medium text-gray-700">Display Mode</h3>
            <div className="flex justify-center">
              <ModeSwitch readonly={readonly} />
            </div>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default MessagesSettings;
