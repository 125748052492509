import { ExclamationIcon } from "@heroicons/react/outline";
import { useState } from "react";

import { ModalStep } from "@/components/Evaluate/TableComponents/NewColumnModal/ModalRouter";
import {
  getLabelsForSelectedTemplate,
  getSelectedLabel,
  getSelectedTemplateName,
  getSelectedVersionNumber,
} from "@/components/Evaluate/TableComponents/NewColumnModal/PromptTemplateType/helpers";
import TemplateSelectDropdown from "@/components/TemplateSelectDropdown";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import ToggleSwitch from "@/components/ui/toggle-switch";
import { useAuth } from "@/context/auth-context";
import { useUser } from "@/context/user-context";
import { usePromptRegistryObjects, usePromptVersions } from "@/queries";
import { PromptTemplateConfiguration } from "@/types/evaluate";

export function ChooseTemplateModal({
  navigateAway,
  navigateBack,
  configuration,
  setConfiguration,
  editable,
  submitText = "Save",
  cancelText,
  showSnippetWarning = false,
  sourceTemplateId,
  isSnippet = false,
}: {
  navigateAway: () => void;
  navigateBack?: () => void;
  configuration: Partial<PromptTemplateConfiguration>;
  setConfiguration: (
    data:
      | Partial<PromptTemplateConfiguration>
      | ((
          prevState: Partial<PromptTemplateConfiguration>,
        ) => Partial<PromptTemplateConfiguration>),
  ) => void;
  editable: boolean;
  submitText?: string;
  cancelText?: string;
  showSnippetWarning?: boolean;
  sourceTemplateId?: number | null;
  isSnippet?: boolean;
}) {
  const userContext = useUser();
  const auth = useAuth();
  const userToken = auth?.userToken!;
  const workspaceId = userContext.activeWorkspaceId!;

  const templateSet = usePromptRegistryObjects(userToken, {
    workspaceId,
    perPage: Number.MAX_SAFE_INTEGER,
    templateType: isSnippet ? "completion" : undefined,
  });

  const availablePromptTemplates =
    templateSet.data?.pages
      .flatMap((page) => page.items)
      .filter((item) => item.id !== sourceTemplateId) || [];

  const [usingLabel, setUsingLabel] = useState<boolean>(
    !!getSelectedLabel(configuration),
  );

  const selectedPromptTemplate = availablePromptTemplates.find(
    (template) =>
      template.prompt_name === getSelectedTemplateName(configuration),
  );

  const promptVersionsQuery = usePromptVersions(userToken, {
    workspaceId,
    promptRegistryId: selectedPromptTemplate?.id,
    perPage: Number.MAX_SAFE_INTEGER,
  });

  const promptVersions =
    promptVersionsQuery.data?.pages.flatMap((page) => page.items) || [];

  const labelsForSelectedTemplate = promptVersions
    .map((version) => version?.labels)
    .flat()
    .map((label) => label?.name);

  const handleTemplateNameSelection = (newTemplateName: string) => {
    setConfiguration(
      (prevConfiguration: Partial<PromptTemplateConfiguration>) => {
        return {
          ...prevConfiguration,
          template: {
            name: newTemplateName,
            version_number: null, // latest
          },
          prompt_template_variable_mappings: {},
        };
      },
    );
  };

  const handleVersionSelection = (newVersion: string | number) => {
    setConfiguration(
      (prevConfiguration: Partial<PromptTemplateConfiguration>) => {
        let newVersionNumber: number | null | undefined = undefined;
        if (newVersion === "latest") {
          newVersionNumber = null;
        } else {
          newVersionNumber = Number(newVersion);
        }
        return {
          ...prevConfiguration,
          template: {
            name: prevConfiguration.template?.name || "",
            version_number: newVersionNumber,
          },
          prompt_template_variable_mappings: {},
        };
      },
    );
  };

  const handleLabelSelection = (newLabel: string) => {
    setConfiguration(
      (prevConfiguration: Partial<PromptTemplateConfiguration>) => {
        return {
          ...prevConfiguration,
          template: {
            name: prevConfiguration.template?.name || "",
            label: newLabel === "" ? undefined : newLabel,
          },
          prompt_template_variable_mappings: {},
        };
      },
    );
  };

  const handleVersionLabelToggle = (value: string) => {
    const newUsingLabel = value === "Label";
    setUsingLabel(newUsingLabel);
    setConfiguration(
      (prevConfiguration: Partial<PromptTemplateConfiguration>) => {
        if (
          newUsingLabel &&
          getLabelsForSelectedTemplate(
            prevConfiguration,
            availablePromptTemplates,
          ).length > 0
        ) {
          return {
            ...prevConfiguration,
            template: {
              name: prevConfiguration.template?.name || "",
              label:
                prevConfiguration.template?.label ||
                getLabelsForSelectedTemplate(
                  prevConfiguration,
                  availablePromptTemplates,
                )[0],
            },
            prompt_template_variable_mappings: {},
          };
        } else {
          return {
            ...prevConfiguration,
            template: {
              name: prevConfiguration.template?.name || "",
              version_number:
                prevConfiguration.template?.version_number || null,
            },
            prompt_template_variable_mappings: {},
          };
        }
      },
    );
  };

  return (
    <ModalStep
      navigateNext={editable ? navigateAway : undefined}
      nextButtonText={editable ? submitText : undefined}
      previousButtonText={editable && cancelText ? cancelText : undefined}
      navigatePrevious={
        editable ? (cancelText ? navigateBack : undefined) : navigateAway
      }
    >
      <div className={`rounded-md border border-gray-200 bg-gray-50 p-3`}>
        <div className="flex flex-col">
          <div className={`mb-4 md:mb-0`}>
            <div className="mb-4">
              <label
                htmlFor="prompt-select"
                className="mb-2 block text-sm font-medium text-gray-700"
              >
                Prompt Template
              </label>
              <div className="relative">
                <TemplateSelectDropdown
                  selectedTemplateName={getSelectedTemplateName(configuration)}
                  handleTemplateSelection={({ name }) =>
                    handleTemplateNameSelection(name)
                  }
                  sourceTemplateId={sourceTemplateId}
                  isSnippet={isSnippet}
                  disabled={!editable}
                />
              </div>
            </div>
          </div>
          {getSelectedTemplateName(configuration) && (
            <div>
              <div className="flex justify-center">
                <ToggleSwitch
                  left="Version"
                  right="Label"
                  onChange={handleVersionLabelToggle}
                  value={usingLabel ? "Label" : "Version"}
                  disabled={!editable}
                />
              </div>
              {!usingLabel && (
                <div className={`mb-4 md:mb-0`}>
                  <div className="mb-4">
                    <label
                      htmlFor="version-select"
                      className="mb-2 block text-sm font-medium text-gray-700"
                    >
                      Version
                    </label>
                    <div className="relative">
                      <DropdownMenu>
                        <DropdownMenuTrigger
                          disabled={!editable}
                          className="w-full"
                        >
                          {getSelectedVersionNumber(configuration) || "latest"}
                        </DropdownMenuTrigger>
                        <DropdownMenuContent>
                          <DropdownMenuItem
                            key={"latest"}
                            onSelect={() => handleVersionSelection("latest")}
                          >
                            latest
                          </DropdownMenuItem>
                          {promptVersions.map((version) => (
                            <DropdownMenuItem
                              key={version.number}
                              onSelect={() =>
                                handleVersionSelection(version.number)
                              }
                            >
                              {version.number}
                            </DropdownMenuItem>
                          ))}
                        </DropdownMenuContent>
                      </DropdownMenu>
                    </div>
                  </div>
                </div>
              )}
              {usingLabel && labelsForSelectedTemplate.length > 0 && (
                <div className={`mb-4 md:mb-0`}>
                  <div className="mb-4">
                    <label
                      htmlFor="label-select"
                      className="mb-2 block text-sm font-medium text-gray-700"
                    >
                      Label
                    </label>
                    <div className="relative">
                      <DropdownMenu>
                        <DropdownMenuTrigger
                          disabled={!editable}
                          className="w-full"
                        >
                          {getSelectedLabel(configuration) || (
                            <span className="font-normal text-gray-500">
                              Select a label...
                            </span>
                          )}
                        </DropdownMenuTrigger>
                        <DropdownMenuContent>
                          {labelsForSelectedTemplate.map((label: string) => (
                            <DropdownMenuItem
                              key={label}
                              onSelect={() => handleLabelSelection(label)}
                            >
                              {label}
                            </DropdownMenuItem>
                          ))}
                        </DropdownMenuContent>
                      </DropdownMenu>
                    </div>
                  </div>
                </div>
              )}
              {usingLabel && labelsForSelectedTemplate.length === 0 && (
                <div className={`mb-4 md:mb-0`}>
                  <div className="mb-4">
                    <label
                      htmlFor="label-select"
                      className="mb-2 block text-sm font-medium text-gray-700"
                    >
                      Label
                    </label>
                    <div className="relative">
                      <div className="text-sm text-gray-700">
                        No labels available. Please select a version instead.
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
      {showSnippetWarning && (
        <div className="mt-4 px-2 text-sm text-gray-500">
          <ExclamationIcon className="mr-1 inline h-4 w-4" />
          <span>
            Don't see your snippet? Make sure it's a completion-type template,
            not chat.
          </span>
        </div>
      )}
    </ModalStep>
  );
}
