import { ENDPOINTS } from "@/api/application-api";
import { WEBSOCKET_MESSAGE_NAME } from "@/constants";
import { useAuth } from "@/context/auth-context";
import { useUser } from "@/context/user-context";
import { authHeader } from "@/utils/headers";
import { displayErrorToast } from "@/utils/toast";
import { useChannel } from "ably/react";
import { runInAction, toJS } from "mobx";
import { usePlayground } from "../../playground-store";
import { v4 } from "uuid";

const useRunCompleteListener = () => {
  const { user } = useUser();
  const auth = useAuth();
  const playground = usePlayground();

  const handleRunComplete = async (payload: any) => {
    const { individual_run_request_id } = JSON.parse(payload.data);

    if (
      !runInAction(() => playground.isRequestValid(individual_run_request_id))
    )
      return;

    const operation = runInAction(
      () =>
        playground.operationStore.getAssociatedOperationByRequestId(
          individual_run_request_id,
        )!,
    );

    let output = runInAction(() =>
      playground.getOutput(operation.output_instance_id!),
    );

    runInAction(() => {
      if (!operation.output_instance_id) {
        playground.operationStore.assignOutputId(operation.id, v4());
      }

      if (!output) {
        playground.addOutput(playground.clone(operation));
        // Re-fetch output after adding it
        output = playground.getOutput(operation.output_instance_id!);
      }
    });

    try {
      const response = await fetch(
        new URL(
          `${ENDPOINTS.individual_run_requests}/${toJS(
            individual_run_request_id,
          )}`,
        ).toString(),
        { headers: authHeader(auth?.userToken!) },
      );

      const {
        individual_run,
        success,
      }: { individual_run: any; success: boolean } = await response.json();

      runInAction(() => {
        output?.setOperationRun(individual_run);

        if (!success) {
          displayErrorToast("Run failed");
          return;
        }

        if (individual_run?.status === "failed") {
          playground.operationStore.assignError(
            operation.id,
            individual_run_request_id,
            individual_run.status_message,
          );
          output?.setCurrentOperation(operation);
        }
      });
    } catch (error) {
      console.error(error);
      displayErrorToast("Failed to fetch run details");
    }

    // Wrap final state updates in runInAction
    runInAction(() => {
      output?.setOperationMessageCompleted(individual_run_request_id);

      if (!output || output.isOutputCompleted) {
        playground.operationStore.deleteOperation(operation.id);
      }
    });
  };

  useChannel(
    `user:${user.id}`,
    WEBSOCKET_MESSAGE_NAME.INDIVIDUAL_RUN_COMPLETE,
    handleRunComplete,
  );
};

export default useRunCompleteListener;
