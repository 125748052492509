import ImageVariableModal from "@/components/ImageVariableModal";
import { useState } from "react";
import VariableInput from "./VariableInput";

const SetMediaVariableButton = ({
  mediaVariable,
  onSave,
}: {
  mediaVariable: string;
  onSave: (value: string) => void;
}) => {
  const [value, setValue] = useState(mediaVariable);

  return (
    <ImageVariableModal imageVariable={mediaVariable}>
      <VariableInput
        value={value}
        onChange={setValue}
        onSave={() => onSave(value)}
        onKeyDown={() => {}}
      />
    </ImageVariableModal>
  );
};

export default SetMediaVariableButton;
