import { observer } from "mobx-react-lite";

import { useParameters } from "@/components/ModelParameters/store";
import { ParameterStore } from "@/components/ModelParameters/store/parameter-store";

import { CustomParameters } from "./ParameterControl/CustomParameters";
import { Parameter } from "./ParameterControl";

interface GroupedParameters {
  defaultParameters: ParameterStore[];
  customParameters: ParameterStore[];
}

export const AdvancedControls = observer(() => {
  const store = useParameters();

  const params = store.parameters.reduce(
    (acc: GroupedParameters, parameter: ParameterStore) => {
      if (!parameter.isCustom) {
        acc.defaultParameters.push(parameter);
      } else {
        acc.customParameters.push(parameter);
      }
      return acc;
    },
    { defaultParameters: [], customParameters: [] },
  );

  return (
    <div className="mb-6 grid max-h-[55vh] grid-cols-[repeat(2,minmax(0,234px))] gap-6 overflow-y-auto border-t border-gray-200 p-3 pt-6 text-gray-500">
      {params.defaultParameters.map((parameter: ParameterStore) => (
        <div className="w-full" key={parameter.paramId}>
          <Parameter parameter={parameter} />
        </div>
      ))}
      <CustomParameters customParams={params.customParameters} />
    </div>
  );
});

export * from "./types";
export * from "./constants";
