import { Input } from "@/components/ui/input";
import { ParameterInputProps } from "./types";

export const ParameterInput = ({
  onChange,
  value,
  defaultValue,
  type,
  isDisabled,
  placeholder,
  readOnly,
}: ParameterInputProps) => {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (type === "text") {
      onChange(e.target.value);
      return;
    }
    const value = e.target.value.replace(/[^0-9.]/g, "");

    onChange(value);
  };

  const inputValue =
    value !== undefined
      ? value
      : defaultValue !== undefined
      ? defaultValue?.toString()
      : "";

  return (
    <Input
      disabled={isDisabled}
      readOnly={readOnly}
      value={inputValue}
      placeholder={placeholder}
      onChange={handleChange}
    />
  );
};
