import { useMutation, useQueryClient } from "@tanstack/react-query";

import { jsonAuthHeaders } from "@/utils/headers";

import { ENDPOINTS } from "@/api/application-api";
import { handleResponse } from "@/utils/errorResponseHandlers";
import { displayErrorToast, displayToast } from "@/utils/toast";
import { ToastType } from "@/enums";

export const useEditDatasetGroup = (userToken: string) => {
  const queryClient = useQueryClient();

  return useMutation(
    ({ datasetGroupId, name }: { datasetGroupId: number; name: string }) =>
      fetch(`${ENDPOINTS.dataset_groups}/${datasetGroupId}`, {
        method: "PATCH",
        headers: jsonAuthHeaders(userToken),
        body: JSON.stringify({
          name,
        }),
      }).then(handleResponse),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([ENDPOINTS.dataset_groups]);
        displayToast("Dataset group name updated", ToastType.success);
      },
      onError: () => {
        displayErrorToast("Failed to update dataset group name");
      },
    },
  );
};
