import AnimatedElement from "@/components/Playground2/Messages/AnimatedElement";
import { usePlayground } from "@/components/Playground2/playground-store";
import { VariableSet } from "@/components/Playground2/types";
import { BasicTooltip } from "@/components/ui/Tooltip";
import { Button } from "@/components/ui/button";
import {
  DocumentDuplicateIcon,
  DocumentTextIcon,
  XIcon,
} from "@heroicons/react/outline";
import { AnimatePresence } from "framer-motion";

interface InputVariableSetProps {
  set: VariableSet;
  number: number;
  onClick: () => void;
  onDuplicate: (newSetId: string) => void;
}

interface EmptyVariableSetPlaceholderProps {
  onAdd: (setId: string) => void;
}

const EmptyVariableSetPlaceholder = ({
  onAdd,
}: EmptyVariableSetPlaceholderProps) => {
  const playground = usePlayground();

  const handleClick = () => {
    const newSetId = playground.addEmptyInputVariableSet();
    onAdd(newSetId);
  };

  return (
    <div
      className="group relative h-24 w-48 cursor-pointer overflow-hidden rounded-lg border-2 border-dashed border-gray-200 p-3 transition-all duration-200 ease-in-out hover:border-gray-300 hover:bg-gray-50 hover:shadow-md"
      onClick={handleClick}
    >
      <div className="flex h-full flex-col items-center justify-center gap-2 text-center">
        <div className="text-sm text-gray-400 transition-colors duration-200 group-hover:text-gray-500">
          Create new variable set
        </div>
        <div className="text-xs text-gray-300 transition-colors duration-200 group-hover:text-gray-400">
          Add multiple inputs to get multiple outputs
        </div>
      </div>
    </div>
  );
};

const InputVariableSet = ({
  set,
  onClick,
  onDuplicate,
  number,
}: InputVariableSetProps) => {
  const playground = usePlayground();

  const deleteSet = (e: React.MouseEvent) => {
    e.stopPropagation();
    playground.removeInputVariableSet(set.id);
  };

  const duplicateSet = (e: React.MouseEvent) => {
    e.stopPropagation();
    const newSetId = playground.duplicateInputVariableSet(set);
    onDuplicate(newSetId);
  };

  return (
    <div className="relative pr-2 pt-2">
      <div className="absolute right-0 top-0 z-10 flex gap-1 transition-opacity duration-200">
        <BasicTooltip content="Duplicate variable set">
          <Button
            onClick={duplicateSet}
            variant={"ghost"}
            className="h-7 w-7 rounded-full border bg-white p-0 shadow-sm hover:bg-gray-50 hover:shadow-md"
          >
            <DocumentDuplicateIcon className="m-auto h-4 w-4 text-gray-400 transition-colors duration-200 hover:text-gray-600" />
          </Button>
        </BasicTooltip>
        <BasicTooltip content="Delete variable set">
          <Button
            onClick={deleteSet}
            variant={"ghost"}
            className="h-7 w-7 rounded-full border bg-white p-0 shadow-sm hover:bg-gray-50 hover:shadow-md"
          >
            <XIcon className="m-auto h-4 w-4 text-gray-400 transition-colors duration-200 hover:text-gray-600" />
          </Button>
        </BasicTooltip>
      </div>

      <div
        className="group relative h-32 w-48 cursor-pointer rounded-lg border border-gray-200 bg-white transition-all duration-200 ease-in-out hover:border-gray-300 hover:bg-gray-50 hover:shadow-sm"
        onClick={onClick}
      >
        <div className="overflow-hidden rounded-t-lg border-b border-gray-100 bg-gray-50 px-3 py-1 text-[10px] text-gray-400 transition-colors duration-200 group-hover:bg-gray-100 group-hover:text-gray-500">
          Set {number}
        </div>
        <div className="flex h-[calc(100%-24px)] flex-col gap-2 overflow-auto">
          {set.variables.length === 0 ? (
            <div className="mb-1 flex h-full flex-col items-center justify-center gap-2 text-center">
              <div className="rounded-full bg-gray-50 p-2 transition-all duration-200 group-hover:bg-gray-100">
                <DocumentTextIcon className="h-6 w-6 text-gray-400 transition-all duration-200 group-hover:text-gray-500" />
              </div>
              <div>
                <div className="text-xs font-medium text-gray-600 transition-colors duration-200 group-hover:text-gray-700">
                  No variables defined
                </div>
                <div className="text-xs text-gray-400 transition-colors duration-200 group-hover:text-gray-500">
                  Click to add variables
                </div>
              </div>
            </div>
          ) : (
            <div className="my-2 flex h-full flex-col gap-2 px-3 pt-2">
              <AnimatePresence initial={true}>
                {set.variables.map((variable, index) => (
                  <AnimatedElement key={index}>
                    <div className="flex min-w-0 items-center gap-2 text-sm">
                      <div className="min-w-[30%] max-w-[40%] flex-shrink-0">
                        <span className="block truncate font-mono font-medium text-gray-700">
                          {variable.key || "(empty)"}
                        </span>
                      </div>
                      <span className="flex-shrink-0 text-gray-400">→</span>
                      <div className="min-w-0 flex-1">
                        <span className="block truncate font-mono text-gray-600">
                          {typeof variable.value === "string"
                            ? variable.value || "(empty)"
                            : JSON.stringify(variable.value, null, 2) ||
                              "(empty)"}
                        </span>
                      </div>
                    </div>
                  </AnimatedElement>
                ))}
              </AnimatePresence>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export { EmptyVariableSetPlaceholder, InputVariableSet };
