import EmptyPlaceholder from "@/components/ui/EmptyPlaceholder";

import ImportVariablesPopover from "@/components/ui/ImportVariablesPopover";
import { AnimatePresence, motion } from "framer-motion";
import { observer } from "mobx-react-lite";
import useAutoScroll from "./hooks/useAutoScroll";
import useVariableManagement from "./hooks/useVariableManagement";
import { Variable } from "./types";
import VariableControls from "./VariableControls";
import VariableList from "./VariableList";
import { useRef } from "react";

interface InputVariableConfiguratorProps {
  inputVariables: Variable[];
  onUpdateInputVariable: (
    id: string,
    key: string,
    value: string | object,
  ) => void;
  onRemoveInputVariable: (id: string) => void;
  onClearInputVariables: () => void;
  isLoading?: boolean;
  onClose: () => void;
  useSaveButton?: boolean;
}

const InputVariableConfigurator = ({
  inputVariables,
  onClearInputVariables,
  onClose,
  onRemoveInputVariable,
  onUpdateInputVariable,
  isLoading,
  useSaveButton = false,
}: InputVariableConfiguratorProps) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const { lastItemRef } = useAutoScroll(inputVariables, containerRef);
  const {
    addInputVariable,
    removeInputVariable,
    clearAll,
    updateInputVariable,
  } = useVariableManagement(
    onUpdateInputVariable,
    onRemoveInputVariable,
    onClearInputVariables,
    inputVariables,
  );

  return (
    <>
      <div className="border-b border-gray-200 px-4 py-2">
        <div className="flex items-center justify-between">
          <h3 className="text-sm font-medium">
            Input Variables ({inputVariables.length})
          </h3>
          <ImportVariablesPopover
            onVariablesFetched={(newVariables) => {
              const existingKeys = new Set(inputVariables.map((v) => v.key));
              Object.entries(newVariables).forEach(([key, value]) => {
                if (existingKeys.has(key)) {
                  const existingVariable = inputVariables.find(
                    (v) => v.key === key,
                  );
                  if (existingVariable)
                    onUpdateInputVariable(
                      existingVariable.id,
                      key,
                      value ?? "",
                    );
                } else {
                  addInputVariable(key, value);
                }
              });
            }}
          />
        </div>
      </div>
      <AnimatePresence mode="wait" initial={false}>
        <motion.div
          className="relative mb-1 flex max-h-[300px] w-full flex-col gap-y-2 overflow-y-auto px-4 pb-2"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          ref={containerRef}
          exit={{ opacity: 0, y: -20 }}
          transition={{
            duration: 0.15,
            ease: "easeOut",
          }}
        >
          <AnimatePresence mode="wait" initial={false}>
            {inputVariables.length === 0 ? (
              <motion.div
                key="empty"
                initial={{ opacity: 0, scale: 0.95 }}
                animate={{ opacity: 1, scale: 1 }}
                exit={{ opacity: 0, scale: 0.95 }}
                transition={{ duration: 0.15, ease: "easeOut" }}
              >
                <EmptyPlaceholder message="No input variables defined" icon />
              </motion.div>
            ) : (
              <VariableList
                variables={inputVariables}
                updateInputVariable={updateInputVariable}
                removeInputVariable={removeInputVariable}
                lastItemRef={lastItemRef}
              />
            )}
          </AnimatePresence>
        </motion.div>
      </AnimatePresence>
      <VariableControls
        useClearAll={inputVariables.length > 0}
        useSaveButton={useSaveButton}
        clearAll={clearAll}
        addInputVariable={addInputVariable}
        onClose={onClose}
        isLoading={isLoading}
      />
    </>
  );
};

export default observer(InputVariableConfigurator);
