import { Button } from "@/components/ui/button";
import { Dialog, DialogContent } from "@/components/ui/dialog";
import { cn } from "@/lib/utils";
import { ArrowsExpandIcon } from "@heroicons/react/outline";
import Editor from "@monaco-editor/react";
import { FC, useState, useRef } from "react";

import RuntimeDetails from "./RuntimeDetails";
import { LanguageSelector } from "./LanguageSelector";
import { LanguageType } from "./types";
import { CopyButton } from "../ui/copy-button";

interface CodeEditorProps {
  code: string;
  selectedLanguage: LanguageType;
  editorOptions?: any;
  dataIsValid?: { code: boolean };
  className?: string;
  showRuntimeDetails?: boolean;
  showFullScreenButton?: boolean;
  height?: string;
  width?: string;
  controlsType?: "tabs" | "dropdown";
  action?: React.ReactNode;
  languages: LanguageType[];
  onCopyCode?: (code: string) => void;
  handleCodeChange?: (code: string) => void;
  handleLanguageChange?: (language: LanguageType) => void;
  languageSelectorLabel?: string;
  docsLink?: string;
}
const EDITOR_OPTIONS = {
  minimap: { enabled: false },
  scrollBeyondLastLine: false,
  fontSize: 13,
  lineHeight: 20,
  padding: { top: 12, bottom: 12 },
  renderLineHighlight: "none",
  fontFamily: "menlo, monaco, monospace",
  automaticLayout: true,
} as const;

const CodeEditor: FC<CodeEditorProps> = ({
  editorOptions,
  dataIsValid,
  className,
  showRuntimeDetails = true,
  height = "300px",
  showFullScreenButton = true,
  languageSelectorLabel,
  selectedLanguage,
  controlsType = "tabs",
  action,
  code,
  handleCodeChange,
  handleLanguageChange,
  languages,
  docsLink,
  onCopyCode,
}) => {
  const [isFullscreen, setIsFullscreen] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);
  const modalContainerRef = useRef<HTMLDivElement>(null);

  return (
    <div
      className={cn("flex flex-col", controlsType === "tabs" ? "" : "gap-2")}
    >
      <LanguageSelector
        type={controlsType}
        action={action}
        docsLink={docsLink}
        label={languageSelectorLabel}
        isReadOnly={editorOptions?.readOnly}
        languages={languages}
        selectedLanguage={selectedLanguage}
        setSelectedLanguage={(language) => handleLanguageChange?.(language)}
      />
      <div
        ref={containerRef}
        className={cn(
          "relative col-span-3 w-full overflow-hidden rounded-md border border-gray-200 pt-2",
          className,
          controlsType === "tabs"
            ? "rounded-tl-none rounded-tr-none border-t-0 pt-0"
            : "",
        )}
      >
        <div className="absolute right-4 top-1 z-10 flex items-center">
          {editorOptions?.readOnly && (
            <CopyButton
              text={code?.trim() || ""}
              onCopy={() => onCopyCode?.(code)}
            />
          )}
          {showFullScreenButton && (
            <Button
              variant="ghost"
              size="sm"
              onClick={() => setIsFullscreen(true)}
            >
              <ArrowsExpandIcon className="h-4 w-4" />
            </Button>
          )}
        </div>
        <Editor
          height={height}
          width="100%"
          defaultLanguage={selectedLanguage?.toLowerCase()}
          language={selectedLanguage?.toLowerCase()}
          value={code?.trim()}
          onChange={(value) => handleCodeChange?.(value?.trim() || "")}
          options={{ ...editorOptions, ...EDITOR_OPTIONS }}
          className="w-full"
        />
      </div>
      {dataIsValid && !dataIsValid.code && (
        <div className="col-span-3 mt-2 text-left text-xs text-red-500">
          Code is required
        </div>
      )}
      {showRuntimeDetails && (
        <div className="col-span-3 text-sm text-gray-500">
          <RuntimeDetails language={selectedLanguage?.toUpperCase()} />
        </div>
      )}
      <Dialog open={isFullscreen} onOpenChange={setIsFullscreen}>
        <DialogContent className="max-w-[90vw]">
          <div ref={modalContainerRef} className="h-[80vh]">
            <div className="absolute right-12 top-10 z-10 flex items-center">
              {editorOptions?.readOnly && (
                <CopyButton
                  text={code?.trim() || ""}
                  onCopy={() => onCopyCode?.(code)}
                />
              )}
            </div>
            <Editor
              defaultLanguage={selectedLanguage?.toLowerCase()}
              language={selectedLanguage?.toLowerCase()}
              value={code?.trim()}
              onChange={(value) => handleCodeChange?.(value?.trim() || "")}
              options={{ ...editorOptions, ...EDITOR_OPTIONS }}
              className="mt-4 h-full w-full"
            />
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default CodeEditor;
