import { useState } from "react";
import {
  ChevronRightIcon,
  InformationCircleIcon,
} from "@heroicons/react/outline";
import { PopoverContent } from "./ui/popover";
import { CopyButton } from "./ui/copy-button";

const InputVariablesPopoverContent = ({
  variables,
}: {
  variables: Record<string, string>;
}) => {
  const [expandedRows, setExpandedRows] = useState<Record<string, boolean>>({});

  const getDisplayValue = (value: boolean | object | string): string => {
    if (typeof value === "boolean") return value ? "True" : "False";
    if (typeof value === "object") return JSON.stringify(value, null, 2);
    return value;
  };

  const toggleExpand = (key: string) => {
    setExpandedRows((prev) => ({ ...prev, [key]: !prev[key] }));
  };

  return (
    <PopoverContent
      onClick={(e) => e.stopPropagation()}
      className="flex max-h-[80vh] w-[600px] flex-col lg:max-h-[45vh]"
    >
      <div className="flex items-center space-x-2 px-4 py-3">
        <div>
          <InformationCircleIcon className="inline h-6 text-gray-600" />
        </div>
        <div className="flex-1">
          <div className="text-sm text-gray-800">Input Variables</div>
          <div className="text-xs text-gray-600">
            Variables injected into the prompt template for this request.
          </div>
        </div>
      </div>
      <div className="max-h-[80vh] flex-1 overflow-y-auto lg:max-h-[45vh]">
        <div className="h-full ">
          <table className="w-full table-fixed divide-y divide-gray-200">
            <thead className="sticky top-0 bg-white">
              <tr className="text-left text-xs font-medium uppercase tracking-wider text-gray-600">
                <th className="w-1/3 px-4 py-2">Name</th>
                <th className="w-2/3 px-4 py-2">Value</th>
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200">
              {Object.entries(variables).map(([key, value], index) => (
                <tr key={index} className="hover:bg-gray-100">
                  <td className="w-1/3 px-4 py-2 text-gray-600">
                    <div className="truncate font-mono text-xs" title={key}>
                      {key}
                    </div>
                  </td>
                  <td className="w-2/3 px-4 py-2 text-gray-600">
                    <div className="flex items-start justify-between overflow-y-auto">
                      <div className="flex items-start">
                        <CopyButton
                          text={
                            typeof value !== "string"
                              ? JSON.stringify(value)
                              : value
                          }
                          iconSize="h-4 w-4"
                        />
                        <button
                          onClick={() => toggleExpand(key)}
                          className="ml-2 focus:outline-none"
                        >
                          <ChevronRightIcon
                            className={`h-4 w-4 transform transition-transform ${
                              expandedRows[key] ? "rotate-90" : ""
                            }`}
                          />
                        </button>
                        <pre
                          className={`ml-2 whitespace-pre-wrap font-mono text-xs ${
                            expandedRows[key] ? "" : "line-clamp-3"
                          }`}
                        >
                          {getDisplayValue(value)}
                        </pre>
                      </div>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </PopoverContent>
  );
};

export { InputVariablesPopoverContent };
