import { VariantProps } from "class-variance-authority";
import { buttonVariants } from "../ui/button";

export enum ConfirmationModalVariant {
  default = "default",
  warning = "warning",
  destructive = "destructive",
}

export type ConfirmationModalProps = {
  title: string;
  isNavigationBlocked?: boolean;
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  leaveButtonText: string;
  saveButtonText: string;
  handleOnConfirm: (nextLocationPathname?: string) => void;
  handleLeave?: () => void;
  handleClose?: () => void;
  description?: string;
  MarkdownDescription?: JSX.Element;
  isLoading?: boolean;
  exitButtonVariant?: VariantProps<typeof buttonVariants>["variant"];
  saveButtonVariant?: VariantProps<typeof buttonVariants>["variant"];
  headerVariant?: ConfirmationModalVariant;
  children?: React.ReactNode;
  useBlocker?: boolean;
};

export interface Location {
  pathname: string;
}

export interface Transition {
  currentLocation: Location;
  nextLocation: Location;
}
