import RunButton from "@/components/ui/RunButton";

import { observer } from "mobx-react-lite";
import React from "react";
import { useWorkflow } from "../../workflow-context";

const RunWorkflow: React.FC = () => {
  const workflow = useWorkflow();
  const { isRunning, nodeCount, isStarted } = workflow;

  const disabled = nodeCount === 0;

  return (
    <RunButton
      run={() => {
        workflow.run();
      }}
      disabled={disabled}
      isRunning={isRunning || isStarted}
      tooltipSide="right"
      tooltipText={{
        idle: "Run Agent",
        running: "Agent is running",
      }}
    />
  );
};

export default observer(RunWorkflow);
