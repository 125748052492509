import { JSONSchema } from "@/types";
import { usePromptBlueprint } from "../../hooks";
import { useModels } from "@/queries/models";
import { useAuth } from "@/context/auth-context";
import { useParams } from "react-router-dom";

const useJSONSchema = () => {
  const form = usePromptBlueprint();
  const auth = useAuth();
  const { workspaceId } = useParams();
  const { data: providers } = useModels(auth?.userToken!, workspaceId ?? "");
  const metadata = form?.watch("metadata");
  const provider = metadata?.model?.provider;
  const model = metadata?.model?.name;

  if (!provider || !model) return false;
  const providerData = providers?.results.find(
    (p) => p.provider_name === provider,
  );
  const modelData = providerData?.model_configs.find(
    (m) => m.llm_model_name === model,
  );

  if (!modelData) return false;

  const hasJSONSchema = Boolean(
    modelData?.params
      .find((p) => p.param_id === "response_format")
      ?.options?.find((o) => o.value?.type === "json_schema"),
  );

  return (
    (metadata?.model?.parameters?.response_format?.json_schema ||
      hasJSONSchema) ??
    (false as JSONSchema | false)
  );
};

export default useJSONSchema;
