import { cn } from "@/lib/utils";
import { reaction } from "mobx";
import { observer } from "mobx-react-lite";
import {
  CSSProperties,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { TextareaProps } from "../ui/textarea";
import { WidgetTypes } from "../Widgets";
import useInitialWidgets from "../Widgets/useInitialWidgets";
import { useContentArea, useExternalUpdates } from "./content-area-context";
import ContextMenu from "./ContextMenu";
import { useContentAreaConfig } from "./helpers/content-area-config-context";
import { useCustomComponent } from "./hooks";
import useCursorPosition from "./hooks/handlers/useCursorPosition";
import useHandlers from "./hooks/useContentHandlers";
import MovingPlaceholder from "./MovingPlaceholder";
import { escapeXml } from "./utils";

interface ContentProps extends TextareaProps {
  readOnly?: boolean;
  isXrayMode?: boolean;
  placeholder?: string;
}

const Content = observer((props: ContentProps) => {
  const {
    features: { widgets },
  } = useContentAreaConfig();

  const {
    readOnly = false,
    isXrayMode = false,
    placeholder = `${
      (widgets && "Type / to use a widget...") || "Type here..."
    }`,
    ...textareaProps
  } = props;
  const content = useContentArea();
  const initialContent = content.html;

  const contentRef = useRef<HTMLDivElement>(null);
  const updateCursorPosition = useCursorPosition(contentRef);
  const { insertCustomComponent } = useCustomComponent(contentRef);
  const updates = useExternalUpdates();
  const prevUpdates = useRef(updates);
  const handles = useHandlers(contentRef, widgets);
  useInitialWidgets(contentRef, initialContent, readOnly, isXrayMode, updates);

  useEffect(() => {
    if (contentRef.current && prevUpdates.current !== updates) {
      const escaped = escapeXml(props.value as string);
      contentRef.current.innerHTML = escaped;
      content.setContent(escaped);
      prevUpdates.current = updates;
    }
  }, [content, initialContent, props.value, updates]);

  useEffect(() => {
    content.setContent(content.html);

    const disp = reaction(
      () => content.getContent(),
      (newContent) => {
        textareaProps?.onChange?.({
          target: { value: newContent },
        } as any);
      },
      { fireImmediately: false },
    );
    return disp;
  }, [content, textareaProps]);

  const [isFocused, setFocused] = useState(false);

  const handleFocus = useCallback(() => setFocused(true), []);
  const handleBlur = useCallback(() => setFocused(false), []);
  const handleScroll = useCallback(
    () => updateCursorPosition(),
    [updateCursorPosition],
  );
  const handleKeyDown = useCallback(
    (e: React.KeyboardEvent) => {
      if (!readOnly) {
        handles.onKeyDown(e);
        textareaProps.onKeyDown?.(e as any);
      }
    },
    [readOnly, handles, textareaProps],
  );

  const handleContextMenuSelect = useCallback(
    (item: string) => {
      insertCustomComponent(item as WidgetTypes);
      content.setShowContextMenu(false);
    },
    [insertCustomComponent, content],
  );

  const handleContextMenuClose = useCallback(() => {
    content.setShowContextMenu(false);
  }, [content]);

  const hidePlaceholder =
    (!isFocused && !!content.getContent().trim()) || readOnly;

  const contentStyle: CSSProperties = useMemo(
    () => ({
      minHeight: "60px",
      height: "100%",
      lineHeight: "1.5",
      whiteSpace: "pre-wrap",
      wordBreak: "break-word",
      display: "inline-block",
      width: "100%",
    }),
    [],
  );

  const contentClassName = useMemo(
    () =>
      cn(
        "relative z-10 w-full overflow-auto border border-gray-200 px-3 py-2 transition-colors duration-200",
        readOnly
          ? "cursor-default"
          : "hover:border-gray-300 focus:border-transparent  ",
        props.className,
        !props.className && "shadow-sm",
      ),
    [readOnly, props.className],
  );

  return (
    <div className="relative h-full w-full overflow-hidden">
      <div className="relative block h-full w-full overflow-hidden">
        {!hidePlaceholder &&
          content.isCurrentLineEmpty &&
          content.savedSelection?.collapsed && (
            <MovingPlaceholder
              position={content.absCaretPos}
              className={props.className}
            >
              {placeholder}
            </MovingPlaceholder>
          )}
        <div
          ref={contentRef}
          onFocus={handleFocus}
          onBlur={handleBlur}
          contentEditable={!readOnly}
          suppressContentEditableWarning
          onScroll={handleScroll}
          {...(readOnly ? {} : handles)}
          onKeyDown={handleKeyDown}
          style={contentStyle}
          role={readOnly ? "region" : "textbox"}
          aria-readonly={readOnly}
          aria-multiline={!readOnly}
          className={contentClassName}
        />
      </div>
      {!readOnly && (
        <ContextMenu
          isVisible={content.showContextMenu}
          position={{
            left: content.caretPosition.x,
            top: content.caretPosition.y,
          }}
          onSelect={handleContextMenuSelect}
          onClose={handleContextMenuClose}
          contentRef={contentRef}
        />
      )}
    </div>
  );
});

export default Content;
