import LoadingSpinner from "@/components/LoadingSpinner";
import { CopyButton } from "@/components/ui/copy-button";
import { observer } from "mobx-react-lite";
import { usePlayground } from "../../playground-store";
import SaveTemplateDialog from "./SaveTemplateDialog";
import MessagesSettings from "./MessagesSettings";

const MessagesHeader = () => {
  const { blueprint, isSavingSession, isEditor, selectedTemplateVersion } =
    usePlayground();
  return (
    <div className="relative flex h-[65px] w-full items-center justify-end border-b px-2">
      <div className="mr-auto flex items-center gap-x-2">
        <h1 className="text-xl font-semibold">Playground</h1>
        {isEditor && selectedTemplateVersion?.number !== undefined && (
          <span className="ml-2 rounded-md bg-blue-50 px-2 py-1 text-xs font-medium text-blue-700">
            Editing Version {selectedTemplateVersion.number}
          </span>
        )}
        {isSavingSession && <LoadingSpinner size={4} />}
      </div>

      <div className="flex items-center gap-x-2">
        {!isEditor && <SaveTemplateDialog disabled={isSavingSession} />}
        <CopyButton text={JSON.stringify(blueprint, null, 2)} />
        <MessagesSettings />
      </div>
    </div>
  );
};

export default observer(MessagesHeader);
