import {
  IMessageStore,
  MessageStore,
} from "@/components/Playground2/Messages/Message/message-store";
import {
  usePlayground,
  PlaygroundStore,
} from "@/components/Playground2/playground-store";
import {
  DEFAULT_OUTPUT_PAGE_SIZE,
  VariableSetOperation,
} from "@/components/Playground2/types";
import { useAuth } from "@/context/auth-context";
import { useGetRunGroups } from "@/queries/playground/useSessionRuns";
import { IndividualRun } from "@/types/metadata";
import { useMemo, useEffect } from "react";
import { v4 } from "uuid";

const useInitialOutputs = (page: number) => {
  const playground = usePlayground();
  const userToken = useAuth()?.userToken!;

  const { data: initialRunGroupData, isLoading } = useGetRunGroups(
    playground.workspace_id,
    playground.instanceId,
    { page, perPage: DEFAULT_OUTPUT_PAGE_SIZE },
    playground.localOutputs.length,
  );

  const hasNext = !!initialRunGroupData?.has_next;
  const hasPrev = !!initialRunGroupData?.has_prev;

  const total = initialRunGroupData?.total || 0;

  const initialOutputs = useMemo(
    () =>
      initialRunGroupData?.run_groups?.map((group) => {
        const { shared_prompt_blueprint, individual_run_requests } = group;
        let messages: IMessageStore[] = [];
        const sets = individual_run_requests.map((run) => {
          const message = run.message
            ? new MessageStore(run.message)
            : undefined;
          message && messages.push(message);

          return {
            id: run.id,
            variables: [],
            request_id: run.request_id,
            completed: true,
            error: run.status_message || undefined,
            position: run.run_group_position,
            run: {
              request: {
                price: run.price,
              },
              request_id: run.request_id,
            } as IndividualRun,
            message_id: message?.id || undefined,
          };
        });

        const input_variable_sets = group.individual_run_requests.map((v) => ({
          id: v4(),
          variables: Object.entries(v.input_variables).map(([key, value]) => ({
            id: v4(),
            key,
            value,
          })),
        }));

        const instance = new PlaygroundStore({
          workspace_id: playground.workspace_id,
          instance_id: playground.instanceId,
          input_variable_sets,
          userToken: userToken,
          prompt_blueprint: shared_prompt_blueprint,
        });

        messages.forEach(
          (message) => message && instance.addMessage(message.toMessage()),
        );

        instance.setCurrentOperation({
          id: group.id,
          sets: sets.reduce(
            (acc, set) => {
              acc[set.id] = set;
              return acc;
            },
            {} as Record<string, VariableSetOperation>,
          ),
        });
        return instance;
      }) || [],
    [
      initialRunGroupData?.run_groups,
      playground.instanceId,
      playground.workspace_id,
      userToken,
    ],
  );

  useEffect(() => {
    if (initialOutputs) playground.setInitialOutputs(initialOutputs);
  }, [initialOutputs, playground]);

  return {
    hasNext,
    hasPrev,
    total,
    isLoading,
  };
};

export default useInitialOutputs;
