import { Breadcrumbs } from "@/components/Breadcrumbs";
import { FolderModal } from "@/components/CreateFolderModel";
import LoadingSpinner from "@/components/LoadingSpinner";
import Folders from "@/components/Registry/components/Folders";
import Templates from "@/components/Registry/components/Templates";
import { useRegistryData } from "@/components/Registry/hooks/useRegistryData";
import { getNavigationUrl } from "@/components/Registry/utils";
import { Button } from "@/components/ui/button";
import { useUser } from "@/context/user-context";
import { getTruncatedFolderPath } from "@/utils/utils";
import { SearchIcon } from "@heroicons/react/outline";
import { useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import RegistryTabs from "./RegistryTabs";
import TagsDropdown from "./TagsDropdown";

const Registry = () => {
  const {
    q,
    selectedTags,
    folderId,
    folders,
    folderPath,
    folderPathWithIds,
    promptRegistryObjects,
    templates,
    allTemplateTags,
    workspaceId,
  } = useRegistryData();

  return (
    <>
      <div className="bg-white p-1">
        <Breadcrumbs
          items={
            [
              "Registry",
              "Prompts",
              ...(getTruncatedFolderPath(folderPath) ?? null),
            ].filter(Boolean) as string[]
          }
          navigateUrl={getNavigationUrl(
            folderPath,
            folderPathWithIds,
            workspaceId,
            folderId,
          )}
          tabsAboveTitle={<RegistryTabs />}
          pageTitle="Prompt Registry"
          pageSubtitle={<PageSubtitle />}
          rightTitleContent={<CreationButtons />}
        />
        <SearchBar
          selectedTags={selectedTags}
          allTemplateTags={allTemplateTags}
        />
      </div>
      {promptRegistryObjects.isLoading ? (
        <div className="pt-8 text-center">
          <LoadingSpinner size={5} />
        </div>
      ) : (
        <>
          <Folders
            searchQuery={q}
            templates={templates}
            folders={folders}
            folderPath={folderPath}
          />
          <Templates
            searchQuery={q}
            templates={templates}
            folderId={folderId}
            folders={folders}
            promptRegistryObjects={promptRegistryObjects}
          />
        </>
      )}
    </>
  );
};

const PageSubtitle = () => {
  return (
    <>
      Manage your prompt templates programmatically or through the web editor.
      See{" "}
      <a
        className="text-blue-500 hover:text-blue-400"
        href="https://docs.promptlayer.com/features/prompt-registry"
      >
        docs
      </a>{" "}
      for more info.
    </>
  );
};

const CreationButtons = () => {
  const [isFolderModalOpen, setFolderModalOpen] = useState(false);
  const { folderId } = useParams();
  const userContext = useUser();
  const navigate = useNavigate();
  return (
    <>
      <Button
        className="mr-2"
        onClick={() => setFolderModalOpen(true)}
        variant="secondary"
      >
        Create Folder
      </Button>
      <Button
        onClick={() =>
          navigate(
            `/workspace/${userContext?.activeWorkspaceId}/create-prompt/playground${
              folderId ? `?folder=${folderId}` : ""
            }`,
          )
        }
      >
        Create Prompt
      </Button>
      {isFolderModalOpen && <FolderModal setOpen={setFolderModalOpen} />}
    </>
  );
};

const SearchBar = ({
  selectedTags,
  allTemplateTags,
}: {
  selectedTags: string[];
  allTemplateTags: string[];
}) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const searchQuery = searchParams.get("registrySearch") || "";
  const handleTagClick = (tag: string) => {
    setSearchParams((prev) => {
      if (selectedTags.includes(tag)) {
        prev.delete("registryTags");
      } else {
        prev.append("registryTags", tag);
      }
      return prev;
    });
  };
  return (
    <div className="border-b py-2">
      {/* Search bar */}
      <div className="flex items-center">
        <div className="mt-3 flex flex-1 items-center rounded-sm px-1">
          <SearchIcon
            className="mr-2 h-5 w-auto text-gray-400"
            aria-hidden="true"
          />
          <input
            value={searchQuery}
            onChange={(e) => {
              setSearchParams((prev) => {
                if (e.target.value === "") {
                  prev.delete("registrySearch");
                  return prev;
                }
                prev.set("registrySearch", e.target.value);
                return prev;
              });
            }}
            type="text"
            name="search"
            id="search"
            className="block w-full rounded-sm border-0 border-transparent focus:border-transparent focus:ring-0 sm:text-sm"
            placeholder="Search templates..."
          />
        </div>
        <div className="px-1">
          <TagsDropdown
            allTags={allTemplateTags}
            selectedTags={selectedTags}
            toggleTag={handleTagClick}
            clearFilters={() => {
              setSearchParams();
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default Registry;
