import { useMutation } from "@tanstack/react-query";

import { ENDPOINTS } from "@/api/application-api";
import { useQueryClient } from "@tanstack/react-query";
import { authHeader } from "@/utils/headers";

export const useDeleteDataset = (userToken: string) => {
  const queryClient = useQueryClient();

  return useMutation(
    (datasetId: number) =>
      fetch(`${ENDPOINTS.datasets}/${datasetId}`, {
        method: "DELETE",
        headers: authHeader(userToken),
      }).then(() => {}),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([ENDPOINTS.datasets]);
        queryClient.invalidateQueries([ENDPOINTS.dataset_rows]);
        queryClient.invalidateQueries([ENDPOINTS.dataset_groups]);
      },
    },
  );
};
