import { observer } from "mobx-react-lite";

import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";

import { ParametersContent } from "./ParametersContent/ParametersContent";
import { ParameterProvider } from "./store";
import { ParametersTrigger } from "./ParametersTrigger";
import { ModelParametersProps } from "./types";
import Functions from "../PromptBlueprint/components/Functions";
import LegacyControlProvider from "../Playground2/Sidebar/ControlBar/LegacyControlProvider";

export const ModelParameters = observer(
  ({
    promptData,
    className,
    onUpdate,
    isPromptChat,
    displayAsPopover = true,
    shouldShowClearButton = true,
    showFunctions = true,
  }: ModelParametersProps) => (
    <ParameterProvider
      promptData={promptData}
      isPromptChat={isPromptChat}
      onUpdate={onUpdate}
    >
      <div className={`flex ${className} gap-2`}>
        {displayAsPopover ? (
          <Popover>
            <PopoverTrigger asChild>
              <ParametersTrigger />
            </PopoverTrigger>
            <PopoverContent className="w-full max-w-xl">
              <ParametersContent
                shouldShowClearButton={shouldShowClearButton}
              />
            </PopoverContent>
          </Popover>
        ) : (
          <ParametersContent shouldShowClearButton={shouldShowClearButton} />
        )}
        {showFunctions && (
          <LegacyControlProvider>
            <Functions />
          </LegacyControlProvider>
        )}
      </div>
    </ParameterProvider>
  ),
);
