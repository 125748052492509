import { useCallback, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

export const useTabNavigation = (promptId: string, versionId: string) => {
  const [showVersions, setShowVersions] = useState(true);
  const location = useLocation();

  const getCurrentTabIndex = useCallback(() => {
    if (location.pathname.includes("/playground")) return 1;
    if (location.pathname.includes("/analytics")) return 2;
    return 0;
  }, [location.pathname]);

  useEffect(() => {
    if (getCurrentTabIndex() > 0) setShowVersions(true);
  }, [getCurrentTabIndex, location.pathname]);

  const handleTabChange = (index: number) => {
    const workspaceId = location.pathname.split("/")[2];
    const basePath = `/workspace/${workspaceId}/prompt/${promptId}/version/${versionId}`;

    if (index === 0) return basePath;

    if (index === 1) return `${basePath}/playground/`;
    if (index === 2) return `${basePath}/analytics`;
    return "";
  };

  return { showVersions, setShowVersions, getCurrentTabIndex, handleTabChange };
};
