import {
  IPlayground,
  usePlayground,
} from "@/components/Playground2/playground-store";
import { useState } from "react";

const useRewind = (output: IPlayground) => {
  const playground = usePlayground();
  const [isConfirmOpen, setIsConfirmOpen] = useState(false);

  const rewind = () => setIsConfirmOpen(true);
  const handleConfirmRewind = () => playground.rewind(output);

  return { isConfirmOpen, setIsConfirmOpen, rewind, handleConfirmRewind };
};

export default useRewind;
