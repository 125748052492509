import { useQuery } from "@tanstack/react-query";
import { PlaygroundSession } from "./useUpdatePlaygroundSession";
import { ENDPOINTS } from "@/api/application-api";
import { authHeader } from "@/utils/headers";
import { useAuth } from "@/context/auth-context";
import { Variable } from "@/components/InputVariableConfigurator/types";
import { handleResponse } from "@/utils/errorResponseHandlers";

type PlaygroundSessionResponse = {
  success: boolean;
  playground_session: PlaygroundSession;
  input_variables: Variable[];
};

const usePlaygroundSessionFromId = (
  workspaceId: number,
  sessionId: string,
  enabled: boolean = true,
) => {
  const userToken = useAuth()?.userToken;
  return useQuery<PlaygroundSessionResponse>({
    queryKey: ["playground-session", workspaceId, sessionId],
    queryFn: async () => {
      try {
        const response = await fetch(
          `${ENDPOINTS.playground_sessions(workspaceId)}/${sessionId}`,
          {
            headers: authHeader(userToken!),
          },
        );
        return handleResponse(response);
      } catch (error) {
        throw error;
      }
    },
    enabled,
    retry: false,
  });
};

export default usePlaygroundSessionFromId;
