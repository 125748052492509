import { useEffect, useState } from "react";

import { Model } from "@/queries/models";
import { HUGGINGFACE_PROVIDER } from "@/components/ModelParameters/constants";

import { SelectModelDropdown } from "../ui/custom-dropdown";

import { ModelDropdownProps } from "./types";

export const ModelDropdown = ({
  selectedModel,
  handleSelectModel,
  isChat,
  provider,
  models,
}: ModelDropdownProps) => {
  const [filteredModels, setFilteredModels] = useState<Model[]>(models);

  useEffect(() => {
    setFilteredModels(models);
  }, [models]);

  useEffect(() => {
    if (provider !== HUGGINGFACE_PROVIDER) {
      const filteredModels = models.filter((m) => m.is_chat === isChat);
      setFilteredModels([...filteredModels]);
    }
  }, [isChat, models, provider]);

  const modelNames = filteredModels
    .filter((m) => !m.is_internal)
    .map((m) => m.display_name ?? m.llm_model_name);

  return (
    <SelectModelDropdown
      model={selectedModel || null}
      setModel={(name) => {
        handleSelectModel(name || "");
      }}
      options={modelNames}
      width="full"
      size="lg"
    />
  );
};
