import { flexRender, Row, Table as TableType } from "@tanstack/react-table";

import { Button } from "@/components/ui/button";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import { ChevronsUpDown, SortAsc, SortDesc } from "lucide-react";
import LoadingSpinner from "../LoadingSpinner";
import { useNavigate } from "react-router-dom";

interface Props<TData> {
  table: TableType<TData>;
  isLoading?: boolean;
  emptyMessage: any;
  linkTo?: (row: Row<TData>) => string;
}

const DataTable = <TData,>({
  table,
  isLoading = true,
  emptyMessage,
  linkTo,
}: Props<TData>) => {
  const navigate = useNavigate();

  const handleRowClick = (e: React.MouseEvent, row: Row<TData>) => {
    if (!linkTo) {
      return;
    }

    if (e.metaKey || e.ctrlKey) {
      window.open(linkTo(row), "_blank");
    } else {
      navigate(linkTo(row));
    }
  };

  return (
    <div className="h-full rounded-md border">
      <Table>
        <TableHeader>
          {table.getHeaderGroups().map((headerGroup) => (
            <TableRow key={headerGroup.id}>
              {headerGroup.headers.map((header) => {
                return (
                  <TableHead key={header.id} className="whitespace-nowrap">
                    {header.column.getCanSort() ? (
                      <Button
                        variant="ghost"
                        className="-ml-3 h-8"
                        onClick={header.column.getToggleSortingHandler()}
                      >
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext(),
                        )}
                        {header.column.getIsSorted() === "desc" ? (
                          <SortDesc className="ml-2 h-4 w-4" />
                        ) : header.column.getIsSorted() === "asc" ? (
                          <SortAsc className="ml-2 h-4 w-4" />
                        ) : (
                          <ChevronsUpDown className="ml-2 h-4 w-4" />
                        )}
                      </Button>
                    ) : (
                      <Button variant="ghost" className="-ml-3 h-8" disabled>
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext(),
                        )}
                      </Button>
                    )}
                  </TableHead>
                );
              })}
            </TableRow>
          ))}
        </TableHeader>
        <TableBody>
          {table.getRowModel().rows?.length ? (
            table.getRowModel().rows.map((row) => (
              <TableRow
                key={row.id}
                className={linkTo ? "cursor-pointer hover:bg-gray-100" : ""}
                data-state={row.getIsSelected() && "selected"}
                onClick={(e) => handleRowClick(e, row)}
              >
                {row.getVisibleCells().map((cell) => {
                  return (
                    <TableCell key={cell.id}>
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext(),
                      )}
                    </TableCell>
                  );
                })}
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell
                colSpan={table.getAllColumns().length}
                className="h-24 text-center"
              >
                {isLoading ? <LoadingSpinner size={5} /> : emptyMessage}
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </div>
  );
};

export { DataTable };
