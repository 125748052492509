import { useDatasetGroup } from "@/queries/datasets";
import { DatasetRowRead } from "@/types/dataset-rows";
import { DatasetRead } from "@/types/datasets";
import { UseQueryResult } from "@tanstack/react-query";
import { useMemo } from "react";

export const useData = (
  userToken: string | null,
  datasetId: number | undefined,
  datasetGroupId: number | undefined,
  datasetsQuery: UseQueryResult<any>,
  datasetRowsQuery: UseQueryResult<any>,
) => {
  const { data: datasetGroup, isLoading: loading } = useDatasetGroup(
    userToken!,
    String(datasetGroupId),
  );

  const datasets = useMemo(() => {
    return (
      datasetsQuery.data?.datasets?.sort((a: DatasetRead, b: DatasetRead) => {
        // Order drafts to top, otherwise order by version number
        if (a.version_number === -1) return -1;
        if (b.version_number === -1) return 1;
        return b.version_number - a.version_number;
      }) || []
    );
  }, [datasetsQuery.data?.datasets]);

  const activeDataset = useMemo((): DatasetRead | undefined => {
    return datasets.find(({ id }: { id: number }) => id === datasetId!);
  }, [datasets, datasetId]);

  const currentDataset = useMemo(
    () => datasets.find(({ id }: DatasetRead) => id === datasetId!),
    [datasets, datasetId],
  );

  const isDraft = useMemo(() => {
    if (!datasetId) return false;
    if (!currentDataset) return false;
    return currentDataset.version_number === -1;
  }, [datasetId, currentDataset]);

  const nonDraftVersionsExist = useMemo(() => {
    return datasets.some(
      ({ version_number }: DatasetRead) => version_number !== -1,
    );
  }, [datasets]);

  const rows = useMemo(() => {
    const datasetRows = datasetRowsQuery.data?.dataset_rows ?? [];
    let columnOrder: string[] = datasetRowsQuery.data?.column_names ?? [];

    // If columnOrder is empty (older datasets) and there's at least one row, use keys from the first row
    if (columnOrder.length === 0 && datasetRows.length > 0) {
      columnOrder = Object.keys(datasetRows[0].variables);
    }

    const res = datasetRows.map((datasetRow: DatasetRowRead) => {
      const orderedVariables = columnOrder.reduce(
        (acc, key) => {
          acc[key] = datasetRow.variables[key] ?? "";
          return acc;
        },
        {} as Record<string, string>,
      );

      return { dataset_row_id: datasetRow.id, ...orderedVariables };
    });
    if (isDraft)
      res.push({
        dataset_row_id: -1,
      }); // Insert 'addRow' row
    return res;
  }, [
    datasetRowsQuery.data?.column_names,
    datasetRowsQuery.data?.dataset_rows,
    isDraft,
  ]);

  const isLoading =
    !currentDataset ||
    loading ||
    datasetRowsQuery.isLoading ||
    datasetsQuery.isLoading ||
    datasetsQuery.isFetching;

  return {
    datasetGroup: datasetGroup?.dataset_group,
    datasets,
    isDraft,
    nonDraftVersionsExist,
    rows,
    isLoading,
    activeDataset,
  };
};
