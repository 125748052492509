import { ENDPOINTS } from "@/api/application-api";
import { Breadcrumbs } from "@/components/Breadcrumbs";
import ConditionalModal from "@/components/ConditionalModal";
import DeleteReleaseLabelGroupModal from "@/components/DeleteReleaseLabelGroupModal";
import EditPromptRegistryObjectsModal from "@/components/EditPromptRegistryObjectsModal";
import { Button } from "@/components/ui/button";
import { useAuth } from "@/context/auth-context";
import { useUser } from "@/context/user-context";
import { ToastType } from "@/enums";
import {
  useSetReleaseLabelGroup,
  useSetWorkflowReleaseLabelGroup,
} from "@/queries/ab-releases";
import { ListPromptVersion } from "@/types";
import {
  Conditional,
  DynamicReleaseLabel,
  OperationConditional,
} from "@/types/conditionals";
import { ReleaseLabelGroupReadDetailResponse } from "@/types/release-label-groups";
import { authHeader } from "@/utils/headers";
import { displayErrorToast, displayToast } from "@/utils/toast";
import { SplitIcon } from "lucide-react";
import React, { useEffect, useMemo, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import LoadingSpinner from "../LoadingSpinner";
import { WorkflowVersion } from "../Workflows/types";
import ChooseVersion from "./ChooseVersion";

import {
  ConfirmationModal,
  ConfirmationModalVariant,
} from "../ConfirmationModal";
import ConditionalRenderer from "./ConditionalRenderer";
import { RegistryNameSpan } from "./RegistryName";
import ReleaseLabelGroupTabs from "./ReleaseLabelGroupTabs";

export const CONDITIONAL_OPERATOR_TO_STRING: Record<string, string> = {
  "=": "=",
  "!=": "!=",
  in: "in",
  not_in: "not in",
};

const ReleaseLabelGroup: React.FC<{ isWorkflow?: boolean }> = ({
  isWorkflow,
}) => {
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const navigate = useNavigate();
  const [conditionals, setConditionals] = useState<Conditional[]>([]);
  const [redirectPath, setRedirectPath] = useState<string | null>(null);
  const [editingConditional, setEditingConditional] =
    useState<OperationConditional | null>(null);
  const [editingConditionalIndex, setEditingConditionalIndex] = useState<
    number | null
  >(null);
  const [isConditionalModalOpen, setConditionalModalOpen] = useState(false);
  const [
    isDeleteReleaseLabelGroupModalOpen,
    setDeleteReleaseLabelGroupModalOpen,
  ] = useState(false);
  const [
    isEditPromptRegistryObjectsModalOpen,
    setEditPromptRegistryObjectsModalOpen,
  ] = useState(false);
  const [originalConditionals, setOriginalConditionals] = useState<
    Conditional[]
  >([]);
  const [promptLabelName, setPromptLabelName] = useState<string>("");
  const { releaseLabelGroupId } = useParams<{ releaseLabelGroupId: string }>();
  const { userToken } = useAuth() || {};
  const { activeWorkspaceId } = useUser();
  const {
    mutateAsync: setReleaseLabelGroup,
    isLoading: isSetReleaseLabelGroupLoading,
  } = useSetReleaseLabelGroup(
    userToken!,
    activeWorkspaceId!,
    releaseLabelGroupId!,
  );
  const {
    mutateAsync: setWorkflowReleaseLabelGroup,
    isLoading: isSetWorkflowReleaseLabelGroupLoading,
  } = useSetWorkflowReleaseLabelGroup(
    userToken!,
    activeWorkspaceId!,
    releaseLabelGroupId!,
  );
  const [searchParams, setSearchParams] = useSearchParams();
  const promptId = searchParams.get("prompt");
  const [activeTab, setActiveTab] = useState<number | null>(
    promptId ? parseInt(promptId) : null,
  );
  const [isLoading, setIsLoading] = useState(true);

  const [selectedPromptVersion, setSelectedPromptVersion] = useState<
    ListPromptVersion | WorkflowVersion | null
  >(null);
  const [isWorkflowReleaseLabelGroup, setIsWorkflowReleaseLabelGroup] =
    useState(false);

  useEffect(() => {
    if (conditionals.length > 0 && !activeTab) {
      const firstId =
        conditionals[0].dynamic_release_labels[0]?.prompt_registry_id ||
        conditionals[0].dynamic_release_labels[0]?.workflow_id;
      setActiveTab(firstId!);
    }
  }, [conditionals, activeTab]);

  useEffect(() => {
    if (!promptId && activeTab) {
      setSearchParams({ prompt: activeTab.toString() });
    }
  }, [activeTab, promptId, setSearchParams]);

  useEffect(() => {
    if (!releaseLabelGroupId) return;

    const fetchReleaseLabelGroup = async () => {
      setIsLoading(true);
      const url = new URL(
        `${
          isWorkflow
            ? ENDPOINTS.workflow_release_label_groups
            : ENDPOINTS.release_label_groups
        }/${releaseLabelGroupId}`,
      );

      try {
        const response = await fetch(url.toString(), {
          headers: authHeader(userToken!),
        });

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const res = await response.json();
        const { release_label_group: prompt_release_label_group, success } =
          res as ReleaseLabelGroupReadDetailResponse;

        const release_label_group = isWorkflow
          ? res.workflow_release_label_group
          : prompt_release_label_group;

        if (success) {
          const sortedConditionals = [...release_label_group.conditionals].sort(
            (a, b) => (a.position || 0) - (b.position || 0),
          );
          setConditionals(sortedConditionals);
          setOriginalConditionals(sortedConditionals);
          setPromptLabelName(
            release_label_group.prompt_label_name ||
              release_label_group?.workflow_label_name,
          );
          setIsWorkflowReleaseLabelGroup(isWorkflow || false);
        } else {
          throw new Error("Failed to fetch A/B Release data");
        }
      } catch (error) {
        console.error("Error fetching release label group:", error);
        displayErrorToast(`Error fetching A/B Release data: ${error}`);
      } finally {
        setIsLoading(false);
      }
    };

    fetchReleaseLabelGroup();
  }, [releaseLabelGroupId, userToken, isWorkflow]);

  const hasChanges = useMemo(() => {
    return (
      JSON.stringify(conditionals) !== JSON.stringify(originalConditionals)
    );
  }, [conditionals, originalConditionals]);

  const handleAddConditionalButtonClick = () => {
    setEditingConditional(null);
    setEditingConditionalIndex(null);
    setConditionalModalOpen(true);
  };

  const handleDeleteConditionalIconClick = (conditional: Conditional) => {
    if (conditional.is_default) {
      setDeleteReleaseLabelGroupModalOpen(true);
      return;
    }

    setConditionals((prevConditionals) =>
      prevConditionals.filter(
        (prevConditional) =>
          !(
            "field" in prevConditional &&
            prevConditional.field === conditional.field &&
            prevConditional.operator === conditional.operator &&
            prevConditional.value === conditional.value
          ),
      ),
    );
  };

  const handleEditConditionalIconClick = (
    conditional: OperationConditional,
    index: number,
  ) => {
    setEditingConditional(conditional);
    setEditingConditionalIndex(index);
    setConditionalModalOpen(true);
  };

  const handleSaveButtonClick = async () => {
    if (conditionals && releaseLabelGroupId) {
      try {
        const { success, message } = isWorkflowReleaseLabelGroup
          ? await setWorkflowReleaseLabelGroup(conditionals)
          : await setReleaseLabelGroup(conditionals);
        if (success) {
          displayToast("A/B release updated successfully", ToastType.success);
          setOriginalConditionals(conditionals);
        } else {
          const pydanticError = message as {
            loc: string[];
            msg: string;
            type: string;
          }[];
          displayErrorToast(`Error: ${pydanticError[0].msg}`);
        }
      } catch (error) {
        const typedError = error as {
          message: { type: string; loc: (string | number)[]; msg: string }[];
          success: boolean;
        };
        console.error("Failed to save the release label group:", error);

        let errorMessage = "Failed to save changes: ";
        if (typedError?.message?.length > 0) {
          const firstError = typedError.message[0];
          errorMessage += firstError.msg;
          if (firstError.loc?.length > 0) {
            errorMessage += ` at ${firstError.loc.join(".")}`;
          }
        } else {
          errorMessage += "Please try again.";
        }

        displayErrorToast(errorMessage);
      }
    }
  };

  const onConditionalModalSubmit = (conditional: OperationConditional) => {
    if (editingConditional) {
      const updatedConditionals = [...conditionals];
      updatedConditionals[editingConditionalIndex!] = conditional;
      setConditionals(updatedConditionals);
    } else {
      setConditionals((prevConditionals) => [conditional, ...prevConditionals]);
    }
    setConditionalModalOpen(false);
  };

  useEffect(() => {
    if (redirectPath && !hasChanges) {
      navigate(redirectPath);
      setRedirectPath(null);
    }
  }, [redirectPath, navigate, hasChanges]);

  const promptRegistryIds = useMemo(() => {
    const ids = new Set<number>();
    conditionals.forEach((conditional) => {
      conditional.dynamic_release_labels.forEach((label) => {
        ids.add(label.prompt_registry_id || label.workflow_id!);
      });
    });
    return Array.from(ids);
  }, [conditionals]);

  const renderConditionals = () => {
    return conditionals.map((conditional: Conditional, index: number) => {
      const filteredLabels = conditional.dynamic_release_labels.filter(
        (label) =>
          (!isWorkflow ? label.prompt_registry_id : label.workflow_id) ===
          activeTab,
      );

      return (
        <ConditionalRenderer
          key={index}
          conditional={conditional}
          index={index}
          promptRegistryIds={[activeTab!]}
          activeTab={activeTab}
          filteredLabels={filteredLabels}
          handleEditConditionalIconClick={handleEditConditionalIconClick}
          handleDeleteConditionalIconClick={handleDeleteConditionalIconClick}
          setConditionals={setConditionals}
          promptLabelName={promptLabelName}
          type={isWorkflowReleaseLabelGroup ? "workflow" : "prompt"}
        />
      );
    });
  };

  const [initialIsNewReleaseLabelGroup, setInitialIsNewReleaseLabelGroup] =
    useState<boolean | null>(null);

  useEffect(() => {
    if (conditionals.length > 0 && promptRegistryIds.length > 0) {
      const isNewReleaseLabelGroup = (() => {
        return (
          (promptRegistryIds.length === 1 &&
            conditionals.length === 1 &&
            conditionals[0]?.dynamic_release_labels.length === 1) ||
          (conditionals.length === 1 &&
            conditionals[0]?.dynamic_release_labels.every(
              (label) => label.percentage === 100,
            ) &&
            conditionals[0]?.dynamic_release_labels.length ===
              promptRegistryIds.length)
        );
      })();

      if (
        initialIsNewReleaseLabelGroup === null ||
        initialIsNewReleaseLabelGroup === true
      ) {
        setInitialIsNewReleaseLabelGroup(isNewReleaseLabelGroup);
      }
    }
  }, [conditionals, initialIsNewReleaseLabelGroup, promptRegistryIds]);

  const handleInitialVersionAdd = ({ registryId }: { registryId: number }) => {
    if (!selectedPromptVersion) {
      displayErrorToast(
        `Please select a ${
          isWorkflowReleaseLabelGroup ? "workflow" : "prompt"
        } version before saving.`,
      );
      return;
    }

    const newDynamicReleaseLabel = {
      is_real_label: false,
      percentage: 0,
      ...(isWorkflowReleaseLabelGroup
        ? {
            workflow_id: registryId,
            workflow_version_id: selectedPromptVersion.id,
            workflow_version_number: selectedPromptVersion.number,
          }
        : {
            prompt_registry_id: registryId,
            prompt_version_id: selectedPromptVersion.id,
            prompt_version_number: selectedPromptVersion.number,
          }),
    };

    const updatedConditionals = conditionals.map((conditional, index) => {
      if (index === 0) {
        return {
          ...conditional,
          dynamic_release_labels: [
            ...conditional.dynamic_release_labels,
            newDynamicReleaseLabel,
          ],
        };
      }
      return conditional;
    });

    setConditionals(updatedConditionals);
  };

  if (isLoading) {
    return (
      <>
        <div className="px-1 pb-8 pt-1">
          <Breadcrumbs
            items={["Registry", "A/B Releases", promptLabelName]}
            navigateUrl={`/workspace/${activeWorkspaceId}/ab-releases`}
            pageTitle={`Dynamic Release Label`}
            pageSubtitle="Configure the prompt template retrieved when fetching this release label. Build segmentation rules to dynamically select the prompt version for different users."
          />
        </div>
        <div className="border-gray-2000 mx-auto mt-6 max-w-2xl rounded-lg border bg-gray-50 p-8 shadow-lg">
          <div className="text-center">
            <LoadingSpinner size={10} />
          </div>
        </div>
      </>
    );
  }

  if (promptRegistryIds.length === 0) {
    displayErrorToast("Failed to load A/B Release data. Please try again.");
    return (
      <>
        <div className="px-1 pb-8 pt-1">
          <Breadcrumbs
            items={["Registry", "A/B Releases", promptLabelName]}
            navigateUrl={`/workspace/${activeWorkspaceId}/ab-releases`}
            pageTitle={`Dynamic Release Label`}
            pageSubtitle="Configure the prompt template retrieved when fetching this release label. Build segmentation rules to dynamically select the prompt version for different users."
          />
        </div>
      </>
    );
  }

  return (
    <>
      <div className="px-1 pb-8 pt-1">
        <Breadcrumbs
          items={["Registry", "A/B Releases", promptLabelName]}
          navigateUrl={`/workspace/${activeWorkspaceId}/ab-releases`}
          pageTitle={`Dynamic Release Label`}
          pageSubtitle="Configure the prompt template retrieved when fetching this release label. Build segmentation rules to dynamically select the prompt version for different users."
        />
        {initialIsNewReleaseLabelGroup ? (
          <div className="mx-auto mt-6 max-w-2xl rounded-lg border border-gray-200 bg-gray-50 p-8 shadow-lg">
            <div className="text-left">
              <div className="mb-4 flex items-center">
                <div className="mr-4 rounded-md border border-blue-200 bg-gradient-to-tl from-blue-100 to-white p-3 shadow-sm">
                  <SplitIcon className="h-6 w-6 text-blue-700" />
                </div>
              </div>
              <h1 className="mb-2 text-2xl font-bold text-gray-900">
                New A/B Release
              </h1>
              <p className="mb-6 text-base text-gray-600">
                Configure your split release. PromptLayer will randomly select
                one of the chosen prompt versions each time you make a request.
              </p>
              <div className="mb-4 rounded-md border border-gray-300 bg-gray-200 p-3">
                <p className="font-base text-sm text-gray-700">
                  Everytime you fetch{" "}
                  <span className="font-semibold text-blue-600">
                    <RegistryNameSpan
                      id={promptRegistryIds[0]}
                      type={isWorkflowReleaseLabelGroup ? "workflow" : "prompt"}
                    />{" "}
                    (with the label "{promptLabelName}")
                  </span>{" "}
                  PromptLayer will randomly select one of the following:
                </p>
              </div>
              <div>
                <div className="mb-4 py-4">
                  <div className="mb-3 flex items-center justify-between text-gray-700">
                    <span className="text-sm font-medium text-gray-400">
                      Current configuration
                    </span>
                    <span className="inline-flex items-center rounded-md border border-blue-300 bg-blue-50 px-4 py-2 text-xs font-medium text-blue-800 shadow-sm">
                      Version #
                      {
                        conditionals[0].dynamic_release_labels.filter(
                          (label) =>
                            (isWorkflowReleaseLabelGroup
                              ? label.workflow_id
                              : label.prompt_registry_id) ===
                            promptRegistryIds[0],
                        )[0]?.[
                          (isWorkflowReleaseLabelGroup
                            ? "workflow_version_number"
                            : "prompt_version_number") as keyof DynamicReleaseLabel
                        ] as any
                      }
                    </span>
                  </div>
                  <div className="flex items-center text-gray-700">
                    <span className="mr-2 text-sm font-medium text-gray-400">
                      Select another prompt version
                    </span>
                    <div className="flex-1">
                      <div className="ml-auto w-full max-w-[300px]">
                        <ChooseVersion
                          registryId={promptRegistryIds[0]}
                          conditional={conditionals[0]}
                          selectedVersion={selectedPromptVersion}
                          setSelectedVersion={setSelectedPromptVersion}
                          isWorkflow={isWorkflowReleaseLabelGroup}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex justify-center border-t border-gray-200 pt-8">
                <Button
                  onClick={() =>
                    handleInitialVersionAdd({
                      registryId: promptRegistryIds[0],
                    })
                  }
                  disabled={!selectedPromptVersion}
                  className="rounded-md bg-blue-600 px-24 py-3 text-sm text-white transition duration-300 hover:bg-blue-700"
                >
                  Build Experiment
                </Button>
              </div>
            </div>
          </div>
        ) : (
          <>
            <ReleaseLabelGroupTabs
              promptLabelName={promptLabelName}
              promptRegistryIds={promptRegistryIds}
              type={isWorkflowReleaseLabelGroup ? "workflow" : "prompt"}
              activeTab={activeTab}
              setActiveTab={setActiveTab}
              setEditPromptRegistryObjectsModalOpen={
                setEditPromptRegistryObjectsModalOpen
              }
            />
            <div className="mt-6 overflow-x-auto">
              <table className="w-full table-auto flex-col">
                <tbody>{renderConditionals()}</tbody>
              </table>
            </div>
            <div className="mt-4 flex items-center justify-between">
              <Button
                onClick={handleAddConditionalButtonClick}
                variant="secondaryLightOutline"
              >
                Add new Segment
              </Button>
              <div>
                <Button disabled={!hasChanges} onClick={handleSaveButtonClick}>
                  Save
                </Button>
              </div>
            </div>
          </>
        )}
      </div>
      {isConditionalModalOpen && (
        <ConditionalModal
          editingConditional={editingConditional}
          onConditionalModalSubmit={onConditionalModalSubmit}
          setOpen={setConditionalModalOpen}
        />
      )}
      {isDeleteReleaseLabelGroupModalOpen && (
        <DeleteReleaseLabelGroupModal
          open={isDeleteReleaseLabelGroupModalOpen}
          releaseLabelGroupId={parseInt(releaseLabelGroupId!, 10)}
          setOpen={setDeleteReleaseLabelGroupModalOpen}
        />
      )}
      {isEditPromptRegistryObjectsModalOpen && (
        <EditPromptRegistryObjectsModal
          initialRegistryIds={promptRegistryIds}
          isOpen={isEditPromptRegistryObjectsModalOpen}
          labelName={promptLabelName}
          isWorkflow={isWorkflowReleaseLabelGroup}
          setConditionals={setConditionals}
          setOpen={setEditPromptRegistryObjectsModalOpen}
        />
      )}
      <ConfirmationModal
        title="Unsaved Changes"
        description="You have unsaved changes. Are you sure you want to leave?"
        handleOnConfirm={(path) => {
          handleSaveButtonClick();
          setRedirectPath(path || "");
        }}
        isLoading={
          isSetReleaseLabelGroupLoading || isSetWorkflowReleaseLabelGroupLoading
        }
        isOpen={isConfirmModalOpen}
        isNavigationBlocked={hasChanges}
        setIsOpen={setIsConfirmModalOpen}
        leaveButtonText="Leave"
        saveButtonText="Save Changes"
        headerVariant={ConfirmationModalVariant.warning}
      />
    </>
  );
};

export default ReleaseLabelGroup;
