import {
  useAutoSaveSession,
  useInitializeSessionOnMount,
  useHydrateSession,
} from "./sessions";

const usePlaygroundSession = () => {
  useInitializeSessionOnMount();
  useAutoSaveSession();
  useHydrateSession();
};

export default usePlaygroundSession;
