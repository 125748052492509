import { handleResponse } from "@/utils/errorResponseHandlers";
import { jsonAuthHeaders } from "@/utils/headers";
import { useMutation } from "@tanstack/react-query";
import { ENDPOINTS } from "@/api/application-api";
import { Conditional } from "@/types/conditionals";
import { useQueryClient } from "@tanstack/react-query";
import { ResponseWithStatus } from "../types";

export const useSetReleaseLabelGroup = (
  userToken: string,
  workspace_id: number,
  releaseLabelGroupId: string,
) => {
  const queryClient = useQueryClient();

  return useMutation<ResponseWithStatus<string>, Error, Conditional[]>(
    (conditionals) =>
      fetch(`${ENDPOINTS.release_label_groups}/${releaseLabelGroupId}`, {
        method: "PUT",
        headers: jsonAuthHeaders(userToken),
        body: JSON.stringify({
          workspace_id,
          conditionals,
        }),
      }).then(handleResponse),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([ENDPOINTS.release_label_groups]);
      },
    },
  );
};
