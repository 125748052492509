import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
} from "@/components/ui/Tooltip";
import { useCheckOverflow } from "@/hooks";
import { TooltipArrow, TooltipTrigger } from "@radix-ui/react-tooltip";

export const FolderPath = ({ topLevelFolder }: { topLevelFolder: string }) => {
  const { ref, isOverflowing } = useCheckOverflow({});

  return isOverflowing ? (
    <TooltipProvider>
      <Tooltip delayDuration={0}>
        <TooltipTrigger className="w-full max-w-[250px] truncate text-left">
          <span className="ml-1 text-gray-600">{topLevelFolder}</span>
        </TooltipTrigger>
        <TooltipContent
          className="rounded bg-gray-800 p-2 text-sm text-white"
          side="top"
          sideOffset={10}
        >
          {topLevelFolder}
          <TooltipArrow className="fill-current text-gray-800" />
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>
  ) : (
    <span ref={ref} className="ml-1 w-full max-w-[400px] text-gray-600">
      {topLevelFolder}
    </span>
  );
};
