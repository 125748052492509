import {
  IPlayground,
  usePlayground,
} from "@/components/Playground2/playground-store";
import { BasicTooltip } from "@/components/ui/Tooltip";
import { Button } from "@/components/ui/button";
import { ArrowTopLeftIcon } from "@radix-ui/react-icons";
import { RewindIcon } from "lucide-react";

const useExtraControls = (output: IPlayground, rewind: () => void) => {
  const playground = usePlayground();

  const outputJSON = JSON.stringify(output.rewindBlueprint);
  const playgroundJSON = JSON.stringify(playground.blueprint);

  const outputInputVariables = JSON.stringify(
    output.raw_input_variable_sets.length === 1 &&
      output.raw_input_variable_sets.filter((s) => s.length).length === 0
      ? []
      : output.raw_input_variable_sets,
  );
  const playgroundInputVariables = JSON.stringify(
    playground.raw_input_variable_sets.length === 1 &&
      playground.raw_input_variable_sets.filter((s) => s.length).length === 0
      ? []
      : playground.raw_input_variable_sets,
  );

  const hasDifferentInputVariables =
    outputInputVariables !== playgroundInputVariables;

  const isDifferent =
    outputJSON !== playgroundJSON || hasDifferentInputVariables;

  return (message_id: string) =>
    playground.latestOutput === output &&
    !playground.latestOutput.isMessageCompleted(message_id)
      ? []
      : [
          <BasicTooltip
            key="popout"
            content={
              playground.isChat
                ? "Pop message into the chat"
                : "Pop in is only available for chat mode"
            }
          >
            <Button
              variant="default"
              onClick={() => playground.popIn(output, message_id)}
              disabled={!playground.isChat}
              className="gap-2 whitespace-nowrap"
            >
              <ArrowTopLeftIcon className="h-4 w-4" />
              <span>Pop in</span>
            </Button>
          </BasicTooltip>,
          isDifferent ? (
            <BasicTooltip
              key="rewind"
              content="Revert to this playground state"
            >
              <Button variant="outline" onClick={rewind} className="gap-2">
                <RewindIcon className="h-4 w-4" />
                <span>Rewind</span>
              </Button>
            </BasicTooltip>
          ) : (
            <></>
          ),
        ];
};

export default useExtraControls;
