import { Content, PromptBlueprint } from "@/types";
import { IndividualRun } from "@/types/metadata";
import { v4 as uuidv4 } from "uuid";
import { Variable } from "../InputVariableConfigurator/types";
export type PlaygroundType = "chat" | "completion";

export const DEFAULT_OUTPUT_PAGE_SIZE = 20;

export const DEFAULT_MESSAGE_TYPE = "user";

export const MESSAGE_TYPES = [
  "system",
  "user",
  "assistant",
  "function",
  "tool",
  "placeholder",
  "developer",
];

export type MessageType =
  | "system"
  | "user"
  | "assistant"
  | "function"
  | "tool"
  | "placeholder"
  | "developer";

export interface Message {
  id: string;
  role: MessageType;
  content: Content[];
}

export const DEFAULT_SYSTEM_MESSAGE: Message = {
  id: uuidv4(),
  role: DEFAULT_MESSAGE_TYPE,
  content: [],
};

export interface ModelParameters {
  temperature: number;
  top_p: number;
  frequency_penalty: number;
  presence_penalty: number;
  response_format?: null;
}

export const DEFAULT_MODEL_PARAMETERS: ModelParameters = {
  temperature: 1,
  top_p: 1,
  frequency_penalty: 0,
  presence_penalty: 0,
  response_format: null,
};

export const DEFAULT_MODEL_COMPLETION_PARAMETERS: ModelParameters = {
  temperature: 1,
  top_p: 1,
  frequency_penalty: 0,
  presence_penalty: 0,
};

export const DEFAULT_CHAT_MODEL: Model = {
  name: "gpt-4o",
  provider: "openai",
  parameters: DEFAULT_MODEL_PARAMETERS,
};

export const DEFAULT_COMPLETION_MODEL: Model = {
  name: "gpt-3.5-turbo-instruct",
  provider: "openai",
  parameters: DEFAULT_MODEL_COMPLETION_PARAMETERS,
};

export const DEFAULT_MODEL: Model = DEFAULT_CHAT_MODEL;

export const DEFAULT_MESSAGE_1: Content = {
  type: "text",
  text: "You are a helpful assistant.",
};

export const DEFAULT_MESSAGE_1_ROLE: MessageType = "system";
export const DEFAULT_MESSAGE_2_ROLE: MessageType = "user";

export const DEFAULT_MESSAGE_2: Content = {
  type: "text",
  text: "Help me with prompt engineering in 3 sentences or less.",
};

export interface Model {
  name: string;
  provider: string;
  parameters: ModelParameters;
}

export interface PlaygroundConfig {
  functionsType: "functions";
  messages: Message[];
  model: Model;
  parameters: ModelParameters;
  promptTemplate: string;
  promptTemplateType: "chat";
  provider_base_url_name: string | null;
  template: string;
  templateFormat: string;
  type: PlaygroundType;
}

export interface ToolCallUIData {
  id: string;
  _id?: string;
  name: string;
  arguments: string;
}

export interface VariableSet {
  id: string;
  variables: Variable[];
}

export interface VariableSetOperation extends VariableSet {
  request_id?: number;
  message_id?: string;
  completed?: boolean;
  run?: IndividualRun;
  error?: string;
  position?: number;
}

export interface Operation {
  id: string;
  sets: Record<string, VariableSetOperation>;
  output_instance_id?: string;
}

export type TemplateFormat = "f-string" | "jinja2";
export const TEMPLATE_FORMATS: TemplateFormat[] = ["f-string", "jinja2"];

export interface RunGroupRequest {
  id: string;
  playground_session_id: string;
  shared_prompt_blueprint: PromptBlueprint;
  individual_run_requests: Array<{
    input_variables: Record<string, any>;
    run_group_position: number;
  }>;
}

export interface RunGroupResponse {
  success: boolean;
  run_group: {
    id: string;
    created_at: string;
    updated_at: string;
    playground_session_id: string;
    shared_prompt_blueprint: PromptBlueprint;
    individual_run_requests: Array<{
      id: number;
      user_id: number;
      input_variables: Record<string, any>;
      is_chat: boolean;
      message: any;
      content: any;
      request_id: any;
      status: string;
      status_message: any;
      created_at: string;
      finished_at: any;
      workspace_id: number;
      run_group_id: string;
      run_group_position: number;
      price: any;
    }>;
  };
  warnings: string[];
}
