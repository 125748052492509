import { classNames } from "@/utils/strings";
import { Tab } from "@headlessui/react";
import {
  ChatIcon,
  CheckIcon,
  ScaleIcon,
  XIcon,
} from "@heroicons/react/outline";
import { ExternalLinkIcon, User } from "lucide-react";
import moment from "moment";
import { Link, useNavigate, useParams } from "react-router-dom";

import VersionSelectorShimmer from "./VersionSelectorShimmer";
import AddReleaseLabelButton from "./AddReleaseLabelButton";
import { WorkflowVersion } from "../Workflows/types";

import { ListPromptVersion } from "@/types";
import ExperimentModal from "../ExperimentModal";
import { useState, useRef, useEffect, useCallback, ReactNode } from "react";
import { useUser } from "@/context/user-context";
import { MagnifyingGlassIcon } from "@radix-ui/react-icons";
import { displayErrorToast } from "@/utils/toast";
import { useCreateABtest } from "@/queries";
import { useAuth } from "@/context/auth-context";
import { LabelsSelect } from "../LabelsSelect";
import { ReportScore } from "@/types/evaluate";
import { VersionScore } from "./VersionScore";
import {
  ConfirmationModal,
  ConfirmationModalVariant,
} from "../ConfirmationModal";
import { InfiniteQueryObserverBaseResult } from "@tanstack/react-query";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@radix-ui/react-tooltip";

const requestCountFormatter = Intl.NumberFormat("en-US", {
  notation: "compact",
});

// TooltipAction component for DRY code
interface TooltipActionProps {
  icon: ReactNode;
  label: string;
  onClick?: (e: React.MouseEvent) => void;
  to?: string;
  openInNewTab?: boolean;
}

const TooltipAction = ({
  icon,
  label,
  onClick,
  to,
  openInNewTab,
}: TooltipActionProps) => {
  const baseClassName =
    "flex cursor-pointer items-center gap-1.5 rounded px-2 py-1.5 text-sm text-gray-700 transition-colors hover:bg-gray-100 bg-white border border-blue-200 shadow-sm";

  const handleClick = (e: React.MouseEvent) => {
    if (openInNewTab && to) {
      window.open(to, "_blank", "noopener,noreferrer");
    } else if (onClick) {
      onClick(e);
    }
  };

  const content = (
    <>
      <span className="flex h-6 w-6 items-center justify-center rounded-full bg-gray-50 p-1 text-gray-600">
        {icon}
      </span>
      <span className="text-xs font-medium">{label}</span>
    </>
  );

  return to && !openInNewTab ? (
    <Link to={to} onClick={handleClick} className={baseClassName}>
      {content}
    </Link>
  ) : (
    <div onClick={handleClick} className={baseClassName}>
      {content}
    </div>
  );
};

export interface VersionSelectorProps {
  versions?: (WorkflowVersion | ListPromptVersion)[];
  dynamicReleaseLabelsRegistry?: {
    [version: number]: { name: string; id: number }[];
  };
  versionEvaluationScoresRegistry?: { [key: number]: ReportScore | null };
  showVersionList: boolean;
  setShowVersionList: (value: boolean) => void;
  onAddReleaseLabel?: (label: string, versionId: number) => Promise<unknown>;
  onRemoveReleaseLabel?: (labelId: number) => Promise<unknown>;
  counts?: { [index: number]: number };
  navigateToVersionLink: (versionNumber: number) => string;
  fetchNextPage?: InfiniteQueryObserverBaseResult["fetchNextPage"];
  className?: string;
  isWorkflow?: boolean;
}

const VersionSelector = ({
  versions,
  showVersionList,
  setShowVersionList,
  onAddReleaseLabel = async () => {},
  onRemoveReleaseLabel = async () => {},
  dynamicReleaseLabelsRegistry = {},
  versionEvaluationScoresRegistry = {},
  counts = {},
  navigateToVersionLink,
  isWorkflow = false,
  fetchNextPage,
  className,
}: VersionSelectorProps) => {
  const { versionId: versionNumber, promptId } = useParams();

  const activeWorkspaceId = useUser()?.activeWorkspaceId;

  const [selectedReleaseLabelGroupId, setSelectedReleaseLabelGroupId] =
    useState<number | null>(null);
  const [isExperimentModalOpen, setExperimentModalOpen] = useState(false);
  const tabListRef = useRef<HTMLDivElement>(null);
  const observerRef = useRef<IntersectionObserver | null>(null);
  const loadingRef = useRef<HTMLDivElement>(null);
  const [isLoadingMore, setIsLoadingMore] = useState(false);

  const handleDynamicReleaseLabelClick = (release_label_group_id: number) => {
    setSelectedReleaseLabelGroupId(release_label_group_id);
    setExperimentModalOpen(true);
  };

  const allLabels = versions?.map((v) => v.labels).flat() || [];

  const userToken = useAuth()?.userToken;

  const createABtest = useCreateABtest(userToken!);
  const navigate = useNavigate();

  const [versionIdForABTest, setVersionIdForABTest] = useState<number | null>(
    null,
  );
  const [labelsSelectOpen, setLabelsSelectOpen] = useState(false);

  // Setup Intersection Observer for infinite scrolling
  const handleObserver = useCallback(
    (entries: IntersectionObserverEntry[]) => {
      const [target] = entries;
      if (target.isIntersecting && fetchNextPage) {
        setIsLoadingMore(true);
        fetchNextPage().finally(() => {
          setIsLoadingMore(false);
        });
      }
    },
    [fetchNextPage],
  );

  useEffect(() => {
    if (!loadingRef.current || !fetchNextPage) return;

    observerRef.current = new IntersectionObserver(handleObserver, {
      root: tabListRef.current,
      rootMargin: "0px",
      threshold: 0.1,
    });

    observerRef.current.observe(loadingRef.current);

    return () => {
      if (observerRef.current) {
        observerRef.current.disconnect();
      }
    };
  }, [fetchNextPage, handleObserver]);

  const handleABTestButtonClick = (
    promptId: number,
    promptVersionId: number,
  ) => {
    if (allLabels.length === 0) {
      createABtest.mutate(
        {
          promptId,
          promptVersionId,
          workspaceId: activeWorkspaceId!,
        },
        {
          onSuccess: (res) => {
            if (res?.success && res?.release_label_group?.id) {
              navigate(
                `/workspace/${activeWorkspaceId}/ab-releases/prompt-release-group/${res?.release_label_group?.id}?prompt=${promptId}`,
              );
            } else {
              displayErrorToast(
                res?.message ?? "There's a problem with the AB Test request",
              );
            }
          },
          onError: () => {
            displayErrorToast("There's a problem with the AB Test request");
          },
        },
      );

      return;
    }

    setVersionIdForABTest(promptVersionId);
    setLabelsSelectOpen(true);
  };

  const [labelToDelete, setLabelToDelete] = useState<{
    name: string;
    id: number;
  } | null>(null);
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);

  if (!versions) return <VersionSelectorShimmer />;
  if (!showVersionList) return null;

  return (
    <>
      <Tab.Group>
        <div
          className={`relative flex max-w-[25%] flex-col rounded border ${
            className || ""
          }`}
        >
          <div className="flex items-center justify-between rounded-t-lg border-b border-gray-200 bg-gray-50 px-3 py-1">
            <button
              className="text-xs text-gray-500 hover:text-gray-700"
              onClick={() => setShowVersionList(!showVersionList)}
            >
              Hide
            </button>
          </div>
          <Tab.List
            className="relative max-h-full overflow-y-scroll"
            ref={tabListRef}
          >
            {versions.map((version) => {
              const { id, created_at, commit_message, number, labels } =
                version;
              const author =
                "user_email" in version ? version.user_email : version.author;

              const dynamicReleaseLabels =
                dynamicReleaseLabelsRegistry[version.id] || [];

              const isActiveVersion = Number(versionNumber) === number;

              return (
                <Tab
                  key={id}
                  className={classNames(
                    "group w-full select-none overflow-x-hidden outline-none",
                    isActiveVersion ? "bg-blue-100" : "",
                  )}
                >
                  <TooltipProvider delayDuration={0}>
                    <Tooltip>
                      <TooltipTrigger asChild>
                        <Link
                          to={navigateToVersionLink(number)}
                          className="relative block"
                        >
                          <div className="relative mx-6 border-l border-gray-300 p-2 text-start">
                            <div className="absolute -left-1.5 mt-1.5 h-3 w-3 rounded-full border border-gray-500 bg-white"></div>
                            <div className="mx-6 flex items-center justify-between">
                              <div className="flex flex-shrink-0 flex-row items-center gap-x-1">
                                <div className="text-start font-medium">{`Version ${number}`}</div>{" "}
                                {counts[number] ? (
                                  <span className="rounded-full bg-gray-300 px-2 py-0.5 text-xs font-medium text-gray-800">
                                    {requestCountFormatter.format(
                                      counts[number],
                                    )}
                                  </span>
                                ) : null}
                              </div>
                            </div>
                            <div className="ml-6 flex items-center justify-between">
                              <div className="truncate overflow-ellipsis py-0.5 text-xs text-gray-500">
                                <span>{moment(created_at).fromNow()}</span>
                                {author && (
                                  <>
                                    <span className="px-1 text-gray-400">
                                      •
                                    </span>
                                    <User className="inline h-3 w-auto pr-1" />
                                    <span className="truncate overflow-ellipsis">
                                      {author}
                                    </span>
                                  </>
                                )}
                              </div>
                            </div>
                            {commit_message && (
                              <div className="ml-6 flex items-center justify-between">
                                <div className="py-0.5 text-xs text-gray-600">
                                  <ChatIcon
                                    className="mr-1 inline h-4 w-auto pb-0.5"
                                    aria-hidden="true"
                                  />
                                  {commit_message}
                                </div>
                              </div>
                            )}

                            {versionEvaluationScoresRegistry[id] && (
                              <div className="ml-6 flex items-center justify-between">
                                <div className="py-0.5 text-xs text-gray-600">
                                  <VersionScore
                                    score={versionEvaluationScoresRegistry[id]}
                                  />
                                </div>
                              </div>
                            )}
                            {"report_id" in version &&
                              version.report_id &&
                              !versionEvaluationScoresRegistry[id] && (
                                <div className="ml-6 flex items-center justify-between">
                                  <div className="py-0.5 text-xs text-gray-600">
                                    <div className="flex items-center justify-between">
                                      <CheckIcon
                                        className="mr-1 inline h-3.5 w-auto"
                                        aria-hidden="true"
                                      />
                                      <div className="text-xs">Evaluated</div>
                                    </div>
                                  </div>
                                </div>
                              )}

                            <div className="ml-6 flex items-center justify-between">
                              <div className="flex flex-wrap gap-1 py-0.5 text-xs">
                                {dynamicReleaseLabels.map((label) => (
                                  <div
                                    className="group relative rounded-sm border border-dashed border-blue-500 px-2 py-0.5 text-xs font-medium text-blue-400 hover:bg-blue-50"
                                    onClick={(e) => {
                                      e.preventDefault();
                                      e.stopPropagation();
                                      handleDynamicReleaseLabelClick(label.id);
                                    }}
                                    key={label.name}
                                  >
                                    {label.name}
                                  </div>
                                ))}
                                {labels.map((label) => (
                                  <div
                                    key={`${label.name}-${label.id}`}
                                    className={classNames(
                                      "group/Label relative rounded-sm border px-2 py-0.5 text-xs font-medium",
                                      isActiveVersion
                                        ? "border-gray-400 bg-blue-50 text-gray-600"
                                        : "border-gray-200 text-gray-500",
                                      "hover:bg-red-100 hover:text-red-400",
                                      isActiveVersion
                                        ? "hover:border-red-300"
                                        : "hover:border-red-200",
                                    )}
                                    onClick={(e) => {
                                      e.preventDefault();
                                      e.stopPropagation();
                                      setLabelToDelete(label);
                                      setIsConfirmationModalOpen(true);
                                    }}
                                  >
                                    {label.name}
                                    <div
                                      className="absolute -right-1.5 -top-1.5"
                                      onClick={(e) => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                        setLabelToDelete(label);
                                        setIsConfirmationModalOpen(true);
                                      }}
                                    >
                                      <XIcon className="invisible h-3 w-3 cursor-pointer text-red-400 group-hover/Label:visible" />
                                    </div>
                                  </div>
                                ))}
                                <div
                                  onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                  }}
                                >
                                  <AddReleaseLabelButton
                                    isEmpty={labels.length === 0}
                                    onSave={(label) =>
                                      onAddReleaseLabel(label, version.id)
                                    }
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </Link>
                      </TooltipTrigger>
                      <TooltipContent
                        className="flex  -translate-x-1/2  flex-col flex-wrap gap-1 rounded-md text-xs text-black"
                        side="right"
                        sideOffset={5}
                        align="center"
                      >
                        {!isWorkflow &&
                          !dynamicReleaseLabelsRegistry?.[id] &&
                          allLabels.length > 0 && (
                            <TooltipAction
                              icon={<ScaleIcon className="inline h-3 w-3" />}
                              label="Start A/B Test"
                              onClick={() =>
                                handleABTestButtonClick(Number(promptId), id)
                              }
                            />
                          )}
                        {"report_id" in version && version.report_id && (
                          <TooltipAction
                            icon={
                              <ExternalLinkIcon className="inline h-3 w-3" />
                            }
                            label="Open Evaluation"
                            to={`/workspace/${activeWorkspaceId}/evaluate/reports/${version.report_id}`}
                            openInNewTab={true}
                          />
                        )}
                        {!isWorkflow && Number(versionNumber) !== number && (
                          <TooltipAction
                            icon={
                              <MagnifyingGlassIcon className="inline h-3 w-3" />
                            }
                            label="View Diff"
                            to={`/workspace/${activeWorkspaceId}/prompt/${promptId}/version/${versionNumber}/diff/${number}`}
                          />
                        )}
                      </TooltipContent>
                    </Tooltip>
                  </TooltipProvider>
                </Tab>
              );
            })}
            {/* Intersection observer target element */}
            {fetchNextPage && (
              <div ref={loadingRef} className="h-4 w-full">
                {isLoadingMore && (
                  <div className="flex justify-center py-2">
                    <div className="h-4 w-4 animate-spin rounded-full border-2 border-gray-300 border-t-blue-500"></div>
                    <span className="ml-2 text-xs text-gray-500">
                      Loading more versions...
                    </span>
                  </div>
                )}
              </div>
            )}
          </Tab.List>
        </div>
      </Tab.Group>
      {isExperimentModalOpen && selectedReleaseLabelGroupId && (
        <ExperimentModal
          promptId={promptId}
          releaseLabelGroupId={selectedReleaseLabelGroupId}
          setOpen={setExperimentModalOpen}
        />
      )}
      <ConfirmationModal
        title={"Are you sure you want to remove this label?"}
        MarkdownDescription={
          <>
            {`Release label `} <b>{`"${labelToDelete?.name}"`}</b>
            {` will be removed.`}
          </>
        }
        saveButtonVariant={ConfirmationModalVariant.destructive}
        isOpen={isConfirmationModalOpen && !!labelToDelete}
        headerVariant={ConfirmationModalVariant.destructive}
        setIsOpen={setIsConfirmationModalOpen}
        leaveButtonText={"Cancel"}
        saveButtonText={"Remove Label"}
        useBlocker={false}
        handleOnConfirm={function (): void {
          labelToDelete && onRemoveReleaseLabel(labelToDelete.id);
          setIsConfirmationModalOpen(false);
        }}
      />
      <LabelsSelect
        promptVersion={{
          prompt_id: Number(promptId),
          version_id: versionIdForABTest!,
        }}
        open={labelsSelectOpen}
        labels={allLabels as any}
        isLoading={false}
        setOpen={setLabelsSelectOpen}
      />
    </>
  );
};

export default VersionSelector;
