import { makeAutoObservable, observable, action } from "mobx";
import { Operation, VariableSet } from "./types";
import { v4 } from "uuid";

class OperationStore {
  @observable private _operations: Map<string, Operation> = new Map();

  constructor() {
    makeAutoObservable(this);
  }

  @action
  createNewOperation({
    input_variable_sets,
  }: {
    input_variable_sets: VariableSet[];
  }) {
    const operationId = v4();
    const sets = input_variable_sets.reduce(
      (acc, set) => ({ ...acc, [set.id]: set }),
      {} as Record<string, VariableSet>,
    );

    this._operations.set(operationId, { id: operationId, sets });

    return operationId;
  }

  get getAssociatedMessageId() {
    return (operation_id: string, request_id: number) => {
      const operation = this.getOperation(operation_id);
      if (!operation) {
        return null;
      }
      const messageId = Object.values(operation.sets).find(
        (set) => set.request_id === request_id,
      )?.message_id;
      return messageId;
    };
  }

  get ongoingOperations() {
    return this._operations.size;
  }

  get isRunning() {
    return this.ongoingOperations >= 1;
  }

  get getOperation() {
    return (operation_id: string) => {
      const operation = this._operations.get(operation_id);
      return operation;
    };
  }

  get getOperations() {
    return () => {
      return this._operations;
    };
  }

  @action
  deleteOperation(operation_id: string) {
    this._operations.delete(operation_id);
  }

  get getAssociatedOperationByOutputId() {
    return (output_id: string) => {
      const res: [string, Operation] | undefined = Array.from(
        this._operations.entries(),
      ).find(
        ([, value]: [string, Operation]) =>
          value?.output_instance_id === output_id,
      );

      return res?.[1] as Operation | undefined;
    };
  }

  get getAssociatedOperationByRequestId() {
    return (request_id: number) => {
      const res = Array.from(this._operations.entries()).find(
        ([_, operation]) =>
          Object.values(operation.sets).some(
            (set) => set.request_id === request_id,
          ),
      );

      return res?.[1];
    };
  }

  @action
  assignOutputId(operation_id: string, output_id: string) {
    const operation = this.getOperation(operation_id);
    if (!operation) {
      return;
    }
    operation.output_instance_id = output_id;
  }

  get getAssociatedSetId() {
    return (request_id: number) => {
      return Array.from(this._operations.values()).find((operation) =>
        Object.values(operation.sets).some(
          (set) => set.request_id === request_id,
        ),
      )?.id;
    };
  }

  @action
  assignError(operation_id: string, request_id: number, error: string) {
    const operation = this.getOperation(operation_id);
    if (!operation) {
      return;
    }

    // Find the set that matches the request_id directly from the operation
    const matchingSet = Object.entries(operation.sets).find(
      ([, set]) => set.request_id === request_id,
    );

    if (!matchingSet) {
      return;
    }

    const setId = matchingSet[0];
    if (operation.sets[setId]) {
      operation.sets[setId].error = error;
    }
  }

  @action
  assignMessageId(
    operation_id: string,
    message_id: string,
    request_id: number,
  ) {
    const operation = this.getOperation(operation_id);

    if (!operation) return;

    const set = Object.entries(operation.sets).find(
      ([, set]) => set.request_id === request_id,
    );
    if (!set) {
      return;
    }
    const setId = set[0];
    operation.sets[setId].message_id = message_id;
  }

  @action
  assignSetRequestId(
    operation_id: string,
    variable_set_id: string,
    request_id: number,
  ) {
    const operation = this.getOperation(operation_id);
    if (!operation) {
      return;
    }
    operation.sets[variable_set_id].request_id = request_id;
  }

  @action assignSetPosition(
    operation_id: string,
    variable_set_id: string,
    position: number,
  ) {
    const operation = this.getOperation(operation_id);
    if (!operation) {
      return;
    }
    operation.sets[variable_set_id].position = position;
  }
}

export default OperationStore;
