import { ENDPOINTS } from "@/api/application-api";
import { Variable } from "@/components/InputVariableConfigurator/types";

import { PromptBlueprint } from "@/types";
import { handleResponse } from "@/utils/errorResponseHandlers";
import { jsonAuthHeaders } from "@/utils/headers";
import { useMutation, UseMutationOptions } from "@tanstack/react-query";

export interface PlaygroundSessionRequest {
  id: string;
  name: string;
  prompt_blueprint: PromptBlueprint;
  input_variables: Variable[][];
}

export interface PlaygroundSession extends PlaygroundSessionRequest {
  workspace_id: number;
  created_at: string;
  updated_at: string;
}

export interface PlaygroundSessionResponse {
  success: boolean;
  playground_session: PlaygroundSession;
}

const useUpdatePlaygroundSession = (
  userToken: string,
  options?: UseMutationOptions<
    PlaygroundSessionResponse,
    Error,
    { workspaceId: number; session: PlaygroundSessionRequest }
  >,
) => {
  return useMutation<
    PlaygroundSessionResponse,
    Error,
    { workspaceId: number; session: PlaygroundSessionRequest }
  >(
    (variables) =>
      fetch(ENDPOINTS.playground_sessions(variables.workspaceId), {
        method: "PUT",
        headers: jsonAuthHeaders(userToken),
        body: JSON.stringify(variables.session),
      }).then(handleResponse),
    options,
  );
};

export default useUpdatePlaygroundSession;
