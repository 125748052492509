import { useAuth } from "@/context/auth-context";
import { useUser } from "@/context/user-context";
import { useDeletePromptTemplate } from "@/queries";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

export const usePromptDeletion = (promptId: string) => {
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const navigate = useNavigate();
  const auth = useAuth();
  const userToken = auth?.userToken!;
  const { activeWorkspaceId } = useUser();
  const deletePrompt = useDeletePromptTemplate(userToken!);

  const handleDeletePrompt = async () => {
    const response = await deletePrompt.mutateAsync({ id: parseInt(promptId) });
    if (response.status === 200) {
      const data = await response.json();
      if (data.success) {
        navigate(`/workspace/${activeWorkspaceId}/prompt`);
      }
    } else {
      const errorMessage = await response.json();
      console.log(errorMessage);
    }
  };

  return { isDeleteDialogOpen, setIsDeleteDialogOpen, handleDeletePrompt };
};
