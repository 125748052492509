import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { BasicTooltip } from "./ui/Tooltip";
import { FileVideo2 } from "lucide-react";

interface ImageVariableProps {
  children: React.ReactNode;
  imageVariable: string;
  onOpenChange?: (open: boolean) => void;
  open?: boolean;
}

function ImageVariableModal({
  children,
  imageVariable,
  onOpenChange,
  open,
}: ImageVariableProps) {
  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogTrigger>
        <BasicTooltip
          content="Add a media placeholder variable"
          usePortal={false}
        >
          <div>
            <FileVideo2 className="h-4 w-4 text-primary hover:text-blue-400" />
            {imageVariable ? (
              <div className="absolute right-0.5 top-0.5 h-1.5 w-1.5 rounded-full bg-red-500" />
            ) : null}
          </div>
        </BasicTooltip>
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Media Variable</DialogTitle>
          <DialogDescription>
            Set this input variable to dynamically load an array of media.
            Variable value must be an array of either valid urls or base64
          </DialogDescription>
        </DialogHeader>
        {children}
      </DialogContent>
    </Dialog>
  );
}

export default ImageVariableModal;
