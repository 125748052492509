import { useAuth } from "@/context/auth-context";
import { useUpload } from "@/queries";
import { Media } from "@/types";
import DropZone from "./DropZone";

import UploadAreaContent from "./UploadAreaContent";

const MediaUploader = ({
  selectedMedia,
  onMediaSelect,
  onDeleteMedia,
  fileInputRef,
  readonly = false,
}: {
  selectedMedia?: Media[];
  onMediaSelect: (media?: Media) => void;
  onDeleteMedia: (index: number) => void;
  fileInputRef: React.RefObject<HTMLInputElement>;
  readonly?: boolean;
}) => {
  const auth = useAuth();
  const upload = useUpload();

  const handleMedia = async (file?: File) => {
    if (!file || !auth?.userToken || readonly) return;
    const getMediaType = (fileType: string) => {
      if (fileType.startsWith("image/")) return "image";
      if (fileType.startsWith("video/")) return "video";
      if (fileType.startsWith("audio/")) return "audio";
      if (fileType === "application/pdf") return "application/pdf";
      return "image"; // fallback default
    };

    upload.mutate(
      { userToken: auth.userToken, file },
      {
        onSuccess: (data) => {
          if (!data) return;
          onMediaSelect({
            type: getMediaType(file.type),
            url: data.file_url,
            title: file.name,
          });
          if (fileInputRef.current) {
            fileInputRef.current.value = "";
          }
        },
      },
    );
  };

  if (readonly) return null;

  return (
    <div className="flex flex-col">
      <DropZone
        isLoading={upload.isLoading}
        onDrop={handleMedia}
        onClick={() => {
          if (!upload.isLoading && !readonly) {
            fileInputRef.current?.click();
          }
        }}
      >
        <input
          type="file"
          ref={fileInputRef}
          className="hidden"
          accept="image/*,audio/*,video/*,application/pdf"
          disabled={upload.isLoading || readonly}
          onChange={(e) => handleMedia(e.target.files?.[0] as File)}
        />
        <UploadAreaContent isLoading={upload.isLoading} />
      </DropZone>
    </div>
  );
};

export default MediaUploader;
