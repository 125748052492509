import {
  ConfirmationModal,
  ConfirmationModalVariant,
} from "@/components/ConfirmationModal";
import { useAuth } from "@/context/auth-context";
import { useDeleteRunGroup } from "@/queries";
import { displayErrorToast } from "@/utils/toast";

import { RawBlueprintDiffViewer } from "@/components/ui/CommitMessageDialog/BlueprintDiffViewer";
import ErrorDisplay from "@/components/ui/ErrorDisplay";
import { AnimatePresence, motion } from "framer-motion";
import { observer } from "mobx-react-lite";
import { useState } from "react";
import { IPlayground, usePlayground } from "../../../playground-store";
import useExtraControls from "./hooks/useExtraControls";
import useRewind from "./hooks/useRewind";
import MessageWithRun from "./MessageWithRun";
import OutputHeader from "./OutputHeader";

interface OutputCardProps {
  output: IPlayground;
}

const OutputCard = ({ output }: OutputCardProps) => {
  const { isConfirmOpen, setIsConfirmOpen, rewind, handleConfirmRewind } =
    useRewind(output);
  const playground = usePlayground();
  const userToken = useAuth()?.userToken!;
  const deleteRunGroup = useDeleteRunGroup(userToken);
  const extraControls = useExtraControls(output, rewind);
  const [isCollapsed, setIsCollapsed] = useState(false);

  const genericRun = Object.values(output.currentOperation?.sets || {}).find(
    (set) => set.run,
  )?.run;

  const dateStarted = genericRun?.created_at;

  const onDelete = () => {
    const operationId = output.currentOperation!.id;

    deleteRunGroup.mutate(
      {
        workspaceId: playground.workspace_id,
        runGroupId: operationId,
      },
      {
        onSuccess: () => playground.deleteOutput(output),
        onError: () => displayErrorToast("Error deleting output:"),
      },
    );
  };

  return (
    <>
      <OutputHeader
        provider={output.provider ?? ""}
        modelName={output.model_name ?? ""}
        createdAt={dateStarted}
        onDelete={onDelete}
        isCollapsed={isCollapsed}
        onToggleCollapse={() => setIsCollapsed(!isCollapsed)}
      />
      <AnimatePresence initial={false}>
        {!isCollapsed && (
          <motion.div
            initial={{ height: 0, opacity: 0 }}
            animate={{ height: "auto", opacity: 1 }}
            exit={{ height: 0, opacity: 0 }}
            transition={{
              type: "spring",
              duration: 0.3,
              bounce: 0,
            }}
            className="overflow-hidden"
          >
            <div className="flex h-full w-full flex-row space-x-2 overflow-x-auto">
              {output.outputMessages.map((message) => (
                <div
                  key={`${message.id}`}
                  className={`${
                    output.outputMessages.length === 1
                      ? "w-full"
                      : "w-full flex-1"
                  } min-w-[350px] flex-shrink-0`}
                >
                  <MessageWithRun
                    message={message}
                    getMessageRun={output.getMessageRun}
                    extraControls={extraControls}
                  />
                </div>
              ))}
              {Object.values(output.currentOperation?.sets || {}).some(
                (set) => set.error,
              ) && (
                <div className="flex h-full w-full flex-col gap-y-2 p-2">
                  {Object.values(output.currentOperation?.sets || {})
                    .filter((set) => set.error)
                    .map((set) => (
                      <ErrorDisplay>{set.error}</ErrorDisplay>
                    ))}
                </div>
              )}
            </div>
          </motion.div>
        )}
      </AnimatePresence>
      <ConfirmationModal
        title="Confirm Rewind"
        description="Are you sure you want to rewind to this state? This will override your current prompt configuration."
        handleOnConfirm={() => {
          handleConfirmRewind();
          setIsConfirmOpen(false);
        }}
        setIsOpen={setIsConfirmOpen}
        isOpen={isConfirmOpen}
        leaveButtonText="Cancel"
        saveButtonText="Confirm"
        exitButtonVariant="outline"
        headerVariant={ConfirmationModalVariant.warning}
      >
        <RawBlueprintDiffViewer
          oldBlueprint={playground.blueprint}
          newBlueprint={output.rewindBlueprint}
        />
      </ConfirmationModal>
    </>
  );
};

export default observer(OutputCard);
