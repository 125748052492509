import { zodResolver } from "@hookform/resolvers/zod";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { z } from "zod";
import logo from "@/assets/logo/promptlayer-cake-color.svg";
import { FormInput } from "@/components/ui/FormInput";
import { createAccountSchema } from "./schema";
import { useCreateAccount } from "@/api/application-api";
import { useAuth } from "@/context/auth-context";
import { useGclid } from "@/hooks/useGclidTracking";
import AuthSubmitButton from "@/components/AuthSubmitButton";
import { formFields } from "./constants";
import { Button } from "@/components/ui/button";
import { useSetUserToFreePlan } from "@/queries";
import posthog from "posthog-js";

const CreateAccount = () => {
  const auth = useAuth();
  const [shouldRedirectToOnboarding, setShouldRedirectToOnboarding] =
    useState(false);
  const { mutate: createAccount, isLoading: isCreateAccountLoading } =
    useCreateAccount();
  const navigate = useNavigate();
  const gclid = useGclid();

  useEffect(() => {
    if (auth?.userToken && !shouldRedirectToOnboarding) navigate("/");
  }, [auth, navigate, shouldRedirectToOnboarding]);

  const form = useForm({
    mode: "onBlur",
    reValidateMode: "onBlur",
    defaultValues: {
      email: "",
      name: "",
      password: "",
      verifyPassword: "",
    },
    resolver: zodResolver(createAccountSchema),
  });
  const { mutateAsync: setUserToFreePlan } = useSetUserToFreePlan(() => {
    navigate("/onboarding");
  });

  const onSubmit = async (values: z.infer<typeof createAccountSchema>) =>
    createAccount(
      {
        email: values.email,
        name: values.name,
        password: values.password,
        gclid,
      },
      {
        onSuccess: async (data) => {
          setShouldRedirectToOnboarding(true);
          const { access_token, user } = data;
          auth?.login(access_token, user);
          posthog.capture("Signed Up");
          await setUserToFreePlan({ userToken: access_token });
        },
      },
    );

  return (
    <>
      <div className="flex flex-col items-center justify-center gap-2">
        <img src={logo} alt="logo" className="h-[52px] w-[52px]" />
        <span className="text-3xl font-bold text-gray-900">
          Create an Account
        </span>
      </div>
      <form
        className="flex flex-col gap-3 py-3"
        onSubmit={form.handleSubmit(onSubmit)}
      >
        {formFields.map((field) => (
          <FormInput
            {...form.register(
              field.name as keyof z.infer<typeof createAccountSchema>,
            )}
            key={field.name}
            label={field.label}
            placeholder={field.placeholder}
            type={field.type}
            error={
              form.formState.errors[
                field.name as keyof z.infer<typeof createAccountSchema>
              ]?.message
            }
          />
        ))}
        <AuthSubmitButton
          loading={isCreateAccountLoading}
          disabled={!form.formState.isValid}
          text="Create Account"
        />
      </form>
      <div className="text-center text-sm text-gray-600">
        Already have an account?{" "}
        <Button variant="inlineLink" onClick={() => navigate("/login")}>
          Log In.
        </Button>
      </div>
    </>
  );
};

export default CreateAccount;
