import { cn } from "@/lib/utils";
import { LanguageSelectorTabsProps } from "./types";
import { BookOpenIcon } from "@heroicons/react/outline";
import { Button } from "@/components/ui/button";

export const LanguageSelectorTabs = ({
  action,
  selectedLanguage,
  setSelectedLanguage,
  languages,
  LanguageNameMap,
  docsLink,
}: LanguageSelectorTabsProps) => {
  return (
    <div className="relative flex h-full w-full items-center justify-between rounded-tl-md rounded-tr-md border border-b-0 border-gray-200 pr-2">
      <div className="flex items-center">
        {languages.map((language) => (
          <div
            key={language}
            className={cn(
              "cursor-pointer border-b-2 border-transparent px-2 py-1 text-sm font-medium transition-all duration-200 ease-in-out",
              selectedLanguage === language
                ? "border-b-blue-500 text-blue-500"
                : "hover:bg-gray-50",
            )}
            onClick={() => setSelectedLanguage(language)}
          >
            {LanguageNameMap[language]}
          </div>
        ))}
      </div>
      {action}
      {docsLink && (
        <Button
          variant="link"
          onClick={() => window.open(docsLink, "_blank")}
          className="flex w-fit items-center gap-1 p-0 text-xs font-medium"
        >
          <BookOpenIcon className="mr-1 h-4 w-4" />
          Learn more
        </Button>
      )}
    </div>
  );
};
