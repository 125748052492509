import { useAuth } from "@/context/auth-context";
import { useUser } from "@/context/user-context";
import {
  usePromptVersion,
  usePromptRegistry,
  usePromptVersions,
} from "@/queries";

export const usePromptTemplateData = (
  promptId: string,
  versionId: string | undefined,
) => {
  const auth = useAuth();
  const userToken = auth?.userToken!;
  const { activeWorkspaceId } = useUser();

  const promptVersion = usePromptVersion(
    userToken!,
    parseInt(promptId),
    versionId ? parseInt(versionId) : null,
  );

  const promptRegistry = usePromptRegistry(userToken!, Number(promptId));
  const promptVersionsQuery = usePromptVersions(userToken!, {
    promptRegistryId: parseInt(promptId),
    workspaceId: activeWorkspaceId!,
  });

  return {
    promptVersion,
    promptRegistry,
    promptVersionsQuery,
    totalVersions: promptVersionsQuery.data?.pages[0]?.total || 0,
    promptName: promptRegistry.data?.prompt_name,
  };
};
