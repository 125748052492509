import { DatasetGroupWrite } from "@/types/dataset-groups";

import { ENDPOINTS } from "@/api/application-api";
import { DatasetGroupRead } from "@/types/dataset-groups";
import { useQuery } from "@tanstack/react-query";
import { authHeader } from "@/utils/headers";
import { handleResponse } from "@/utils/errorResponseHandlers";

import { Response } from "../types";

export const useDatasetGroup = (userToken: string, datasetGroupId: string) => {
  return useQuery<
    Response<DatasetGroupRead & DatasetGroupWrite, "dataset_group", string>
  >([ENDPOINTS.dataset_groups, datasetGroupId], () => {
    return fetch(`${ENDPOINTS.dataset_groups}/${datasetGroupId}`, {
      headers: authHeader(userToken),
    }).then(handleResponse);
  });
};
