import { ComponentType } from "@/queries/models/types";
import { Select } from "../ParameterSelect";
import { ParameterInput } from "../ParameterInput";
import { ParameterControlContentProps } from "./types";
import { observer } from "mobx-react-lite";
import { Slider } from "@/components/Slider";
import { DropdownValue } from "../ParameterSelect/types";
import { ParameterValue } from "@/components/ModelParameters/utils";

export const ParameterControlContent = observer(
  ({
    parameter,
    value,
    onChange,
    isDisabled,
    readOnly,
  }: ParameterControlContentProps) => {
    switch (parameter.componentType) {
      case ComponentType.SLIDER:
        return (
          <Slider
            value={value as string}
            defaultValue={String(parameter.default)}
            onValueChange={onChange}
            min={parameter.min}
            hasInput
            max={parameter.max}
            isDisabled={isDisabled}
            readOnly={readOnly}
          />
        );
      case ComponentType.INPUT:
        return (
          <ParameterInput
            value={value?.toString()}
            type={parameter.max ? "number" : "text"}
            defaultValue={Number(parameter.default)}
            onChange={onChange}
            isDisabled={isDisabled}
            placeholder={parameter.placeholder || ""}
            readOnly={readOnly}
          />
        );
      case ComponentType.DROPDOWN:
        return (
          <Select
            isDisabled={isDisabled}
            selectedDropdownValue={value as DropdownValue}
            options={parameter.options || []}
            onChange={(value) => onChange(value as ParameterValue)}
            defaultValue={String(parameter.default)}
            placeholder={parameter.placeholder || ""}
            readOnly={readOnly}
          />
        );
      default:
        return null;
    }
  },
);
