import { useRef, useEffect, RefObject, useCallback } from "react";
import { Variable } from "../types";

const useAutoScroll = (
  inputVariables: Variable[],
  scrollContainerRef: RefObject<HTMLDivElement>,
) => {
  const lastItemRef = useRef<HTMLDivElement>(null);
  const prevInputVariablesLength = useRef(inputVariables.length);

  const scrollToBottom = useCallback(() => {
    if (scrollContainerRef.current) {
      const container = scrollContainerRef.current;
      const targetScrollTop = container.scrollHeight - container.clientHeight;
      const startTime = performance.now();
      const duration = 300; // Animation duration in milliseconds

      const animateScroll = (currentTime: number) => {
        const elapsedTime = currentTime - startTime;
        const progress = Math.min(elapsedTime / duration, 1);
        const easeProgress = 1 - Math.pow(1 - progress, 3); // Cubic ease-out

        container.scrollTop =
          container.scrollTop +
          (targetScrollTop - container.scrollTop) * easeProgress;

        if (progress < 1) {
          requestAnimationFrame(animateScroll);
        }
      };

      requestAnimationFrame(animateScroll);
    }
  }, [scrollContainerRef]);

  useEffect(() => {
    if (inputVariables.length > prevInputVariablesLength.current) {
      setTimeout(scrollToBottom, 100);
    }
    prevInputVariablesLength.current = inputVariables.length;
  }, [inputVariables.length, scrollToBottom]);

  return { scrollContainerRef, lastItemRef, scrollToBottom };
};

export default useAutoScroll;
