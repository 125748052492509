import { ENDPOINTS } from "@/api/application-api";
import { jsonAuthHeaders } from "@/utils/headers";
import { useQuery } from "@tanstack/react-query";
import { ModelsResponse } from "./types";
import { Response } from "../types";
import { handleResponse } from "@/utils/errorResponseHandlers";

export const useModels = (userToken: string, workspace_id: string) => {
  return useQuery(
    ["models", workspace_id],
    async (): Promise<Response<ModelsResponse, "results", string>> => {
      const response = await fetch(ENDPOINTS.models(workspace_id), {
        headers: jsonAuthHeaders(userToken),
        method: "GET",
      });
      return handleResponse(response);
    },
    {
      staleTime: 1000 * 60 * 60 * 24,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchInterval: false,
      refetchIntervalInBackground: false,
    },
  );
};
