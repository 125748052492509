import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Textarea } from "@/components/ui/textarea";
import { ToolCall } from "@/types";

import { Cross2Icon } from "@radix-ui/react-icons";
import { Terminal } from "lucide-react";
import { observer } from "mobx-react-lite";
import { BaseSyntheticEvent } from "react";

export type Props = {
  toolCalls: ToolCall[];
  onChangeToolCall: (toolCall: ToolCall) => void;
  onDeleteToolCall: (toolCall: ToolCall) => void;
  onAddToolCall: () => void;
  readonly?: boolean;
};

const ToolCalls = ({
  toolCalls,
  onChangeToolCall,
  onDeleteToolCall,
  onAddToolCall,
  readonly = false,
}: Props) => {
  const hasTools = toolCalls && toolCalls.length > 0;

  const handleChange = (toolCall: ToolCall) => (e: BaseSyntheticEvent) => {
    const key: keyof ToolCall = e.target.name;
    const updatedTool = { ...toolCall };

    if (key.includes("function.")) {
      const functionKey = key.split(
        "function.",
      )[1] as keyof ToolCall["function"];
      updatedTool.function[functionKey] = e.target.value;
    } else {
      updatedTool[key] = e.target.value;
    }

    onChangeToolCall(updatedTool);
  };

  return (
    <div
      className={`relative ml-9 mr-3 box-border flex h-fit w-auto flex-col justify-center rounded-lg bg-gray-50 py-4 pl-4 ${
        (!readonly &&
          "before:min-h-3 before:absolute before:-left-5 before:bottom-8 before:-z-0 before:inline-block before:h-full before:w-9 before:border-b-2 before:border-l-2 before:border-gray-200 before:content-['']") ||
        "before:min-h-3 mt-2 before:absolute before:-left-5 before:bottom-8 before:-z-0 before:inline-block before:h-full before:w-5 before:border-b-2 before:border-l-2 before:border-gray-200 before:content-['']"
      } group-hover:bg-gray-100`}
    >
      {hasTools && (
        <div className="mb-1 w-full px-4">
          {toolCalls.map((toolCall, index) => {
            const onChange = handleChange(toolCall);
            return (
              <div key={`tool_${index}`}>
                <div className="flex items-center justify-between">
                  <div className="font-mono font-bold">
                    <Terminal className="mr-1 inline-block h-4 w-4" />
                  </div>

                  {!readonly && (
                    <Button
                      variant="destructiveLink"
                      onClick={() => onDeleteToolCall(toolCall)}
                    >
                      <Cross2Icon />
                    </Button>
                  )}
                </div>

                <div className="mb-4">
                  <div className="mb-2 flex w-full flex-row">
                    <Input
                      className=" h-9 flex-1 rounded-none border-gray-200 font-mono text-sm focus:ring-0 group-hover:border-gray-300"
                      placeholder="Tool Id"
                      name="id"
                      onChange={onChange}
                      value={toolCall.id}
                      disabled={readonly}
                    />
                    <Input
                      className=" ml-2 h-9 flex-1 rounded-none border-gray-200 font-mono text-sm focus:ring-0 group-hover:border-gray-300"
                      placeholder="Name"
                      onChange={onChange}
                      name="function.name"
                      value={toolCall.function.name}
                      disabled={readonly}
                    />
                  </div>
                  <Textarea
                    className=" h-9 w-full rounded-none border-gray-200 font-mono text-sm focus:ring-0 group-hover:border-gray-300"
                    placeholder={"Arguments"}
                    onChange={onChange}
                    name="function.arguments"
                    value={toolCall.function.arguments}
                    disabled={readonly}
                  />
                </div>
              </div>
            );
          })}
        </div>
      )}
      {!readonly && (
        <div>
          <Button onClick={onAddToolCall} variant={"outline"} size={"sm"}>
            + Tool Call
          </Button>
        </div>
      )}
    </div>
  );
};

export default observer(ToolCalls);
