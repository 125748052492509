import { CodeExecutionConfiguration, ReportColumn } from "@/types/evaluate";

import CodeEditor from "@/components/CodeEditor";
import { languages } from "@/components/CodeEditor/constants";
import { useCallback, useMemo, useState } from "react";
import { useBandaid } from "../../utils/BandaidContext";
import { ModalStep } from "../ModalRouter";
import SourceName from "../SourceName";
import useColumnLanguage from "./hooks/useColumnLanguage";
import useMonacoCompletion from "./hooks/useMonacoCompletion";
import useValidation from "./hooks/useValidation";

interface CodeColumnBuilderProps {
  columnData: Partial<ReportColumn>;
  availableColumns: ReportColumn[];
  navigatePrevious: () => void;
  saveColumnAction: (newColumnDataArg: ReportColumn) => void;
  editable: boolean;
}

const CodeColumnBuilder: React.FC<CodeColumnBuilderProps> = ({
  columnData,
  navigatePrevious,
  saveColumnAction,
  editable,
  availableColumns,
}) => {
  const [name, setName] = useState<string>(columnData.name || "");

  const columnNamesInScope = useMemo(
    () =>
      availableColumns
        .filter((column) => column.name !== columnData.name)
        .map((column) => column.name),
    [availableColumns, columnData.name],
  );

  const { language, code, setCode, handleLanguageChange } =
    useColumnLanguage(columnData);
  useMonacoCompletion(language, columnNamesInScope);
  const { dataIsValid, validate } = useValidation(name, code);

  const saveEndpointAction = useCallback(() => {
    if (!validate()) return;

    saveColumnAction({
      ...columnData,
      name: name?.trim(),
      configuration: {
        code: code?.trim(),
        language: language.toUpperCase(),
      } as CodeExecutionConfiguration,
    } as ReportColumn);
  }, [columnData, name, code, language, saveColumnAction, validate]);

  const bandaid = useBandaid();

  const editorOptions = useMemo(
    () => ({
      readOnly: !editable,
    }),
    [editable],
  );

  return (
    <ModalStep
      navigatePrevious={navigatePrevious}
      navigateNext={saveEndpointAction}
      nextButtonText={editable ? "Save Step" : "Done"}
    >
      <div className="grid grid-cols-3 items-center gap-4">
        <div className="col-span-3">
          <div className="text-lg font-semibold">Configure Code Execution</div>
          <div className="max-w-md text-sm text-gray-500">
            {bandaid
              ? "Write code that will execute when this node is triggered. Access data through the `data` variable. Return the transformed value. Stdout will be ignored."
              : "Write code that will execute for each row. Access data through the `data` variable. Return the cell value. Stdout will be ignored."}
          </div>
        </div>
        <SourceName
          name={name!}
          columnCount={availableColumns.length}
          isValid={dataIsValid.name}
          setName={setName}
          isNode={bandaid}
          readonly={!editable}
        />
        <div className="col-span-3">
          <CodeEditor
            languages={languages}
            selectedLanguage={language}
            editorOptions={editorOptions}
            languageSelectorLabel="Programming Language"
            code={code}
            dataIsValid={dataIsValid}
            controlsType="dropdown"
            handleCodeChange={setCode}
            handleLanguageChange={handleLanguageChange}
          />
        </div>
      </div>
    </ModalStep>
  );
};

export default CodeColumnBuilder;
