import DropdownButton from "@/components/DropdownButton";
import { BasicTooltip } from "@/components/ui/Tooltip";
import { Popover } from "@headlessui/react";
import { DotsVerticalIcon } from "@heroicons/react/outline";
import { useState } from "react";
import { Button } from "../button";
import { Input } from "../input";

interface DropdownItem {
  name: string;
  value: string;
  icon: React.ComponentType<any>;
  onClick: () => void;
  disabled: boolean;
}

export interface GenericSelectorProps<T> {
  value: T | null;
  onChange: (newValue: T) => void;
  placeholder: string;
  autoFocus?: boolean;
  onKeyDown: (event: React.KeyboardEvent) => void;
  disabled: boolean;
}

interface OptionInputPopoverProps<T extends string | number> {
  dropdownItems?: DropdownItem[];
  onSubmit?: (value: T, selectedOption: string) => void;
  buttonIcon?: React.ReactNode;
  inputPlaceholder?: string;
  submitButtonText?: string;
  submittingButtonText?: string;
  tooltipContent?: string;
  minWidth?: string;
  className?: string;
  isLoading?: boolean;
  renderSelector?: (
    props: GenericSelectorProps<T>,
    selectedOption: string,
  ) => React.ReactNode;
}

const OptionInputPopover = <T extends string | number>({
  dropdownItems = [],
  onSubmit,
  buttonIcon = <DotsVerticalIcon className="inline h-6" />,
  inputPlaceholder = "Enter",
  submitButtonText = "Import",
  submittingButtonText = "Importing...",
  tooltipContent = "Select an option",
  minWidth = "450px",
  className = "relative inline-block",
  isLoading = false,
  renderSelector,
}: OptionInputPopoverProps<T>) => {
  const [selectedOption, setSelectedOption] = useState<string | null>(null);
  const [inputValue, setInputValue] = useState<T | null>(null);

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (!inputValue || !selectedOption) return;

    if (onSubmit) {
      onSubmit(inputValue, selectedOption);
    }
    setSelectedOption(null);
    setInputValue(null);
  };

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === "Escape") {
      setSelectedOption(null);
      setInputValue(null);
    }
  };

  const selectorProps: GenericSelectorProps<T> = {
    value: inputValue,
    onChange: setInputValue,
    placeholder: `${inputPlaceholder} ${selectedOption} URL`,
    autoFocus: true,
    onKeyDown: handleKeyDown,
    disabled: isLoading,
  };

  return (
    <div className={className}>
      <Popover>
        <DropdownButton
          dropdownContent={null}
          dropdownItems={dropdownItems.map((item) => ({
            ...item,
            onClick: () => {
              setSelectedOption(item.value);
              item.onClick();
            },
          }))}
        >
          <BasicTooltip content={tooltipContent}>
            <Button
              variant={"ghost"}
              onClick={() => {
                setSelectedOption(null);
                setInputValue(null);
              }}
              className="flex items-center rounded-full"
              tabIndex={-1}
            >
              {buttonIcon}
              <span className="ml-2">{submitButtonText}</span>
            </Button>
          </BasicTooltip>
        </DropdownButton>

        {selectedOption && (
          <Popover.Panel
            static
            className={`absolute right-0 top-full z-10 mt-2 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5`}
            style={{ minWidth: minWidth }}
          >
            <div className="p-4">
              <form onSubmit={handleSubmit} className="flex items-center gap-2">
                <div className="flex-1">
                  {renderSelector ? (
                    renderSelector(selectorProps, selectedOption)
                  ) : (
                    <Input
                      type="text"
                      value={inputValue?.toString() || ""}
                      onChange={(e) => setInputValue(e.target.value as T)}
                      placeholder={`${inputPlaceholder} ${selectedOption} URL`}
                      autoFocus
                      onKeyDown={handleKeyDown}
                      disabled={isLoading}
                    />
                  )}
                </div>
                <div className="flex gap-2">
                  <Button
                    type="button"
                    variant={"link"}
                    onClick={() => {
                      setSelectedOption(null);
                      setInputValue(null);
                    }}
                    className="text-sm text-gray-500 hover:text-gray-700"
                    disabled={isLoading}
                  >
                    Cancel
                  </Button>
                  <Button type="submit" disabled={isLoading || !inputValue}>
                    {isLoading ? submittingButtonText : submitButtonText}
                  </Button>
                </div>
              </form>
            </div>
          </Popover.Panel>
        )}
      </Popover>
    </div>
  );
};

export default OptionInputPopover;
