import ContentArea from "@/components/ContentArea";
import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
} from "@/components/ui/dialog";
import { runInAction } from "mobx";
import { memo } from "react";
import { IMessageStore } from "./message-store";
import RoleSelector from "./RoleSelector";

const FullscreenDialog = ({
  isOpen,
  onOpenChange,
  message,
  readonly = false,
}: {
  isOpen: boolean;
  onOpenChange: (open: boolean) => void;
  message: IMessageStore;
  readonly?: boolean;
}) => (
  <Dialog open={isOpen} onOpenChange={onOpenChange}>
    <DialogContent className="flex h-[90vh] max-w-[90vw] flex-col">
      <DialogHeader className="shrink-0 ">
        <div className="flex items-center justify-between">
          <div className="flex items-center gap-4">
            <RoleSelector
              selected={message.role}
              readonly={readonly}
              onSelect={(role) => runInAction(() => message.setRole(role))}
            />
          </div>
        </div>
      </DialogHeader>
      <div className="flex-1 overflow-hidden">
        <ContentArea
          onChange={(e) => !readonly && message.setMessage(e.target.value)}
          value={message.content}
          className="h-full rounded-none text-sm"
          readOnly={readonly}
        />
      </div>
      <DialogFooter className="shrink-0 p-4">
        <Button onClick={() => onOpenChange(false)}>Close</Button>
      </DialogFooter>
    </DialogContent>
  </Dialog>
);

export default memo(FullscreenDialog);
