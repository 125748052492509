import { usePlayground } from "@/components/Playground2/playground-store";
import { useState, useEffect } from "react";

const useAutoOpen = (
  count: number,
  setIsCollapsed: (value: boolean) => void,
) => {
  const [previousCount, setPreviousCount] = useState<number>(count);
  const playground = usePlayground();

  useEffect(() => {
    if (
      (count !== undefined &&
        previousCount !== undefined &&
        count > previousCount) ||
      playground.isPendingNewMessage
    ) {
      setIsCollapsed(false);
    }
    setPreviousCount(count);
  }, [count, playground.isPendingNewMessage, previousCount, setIsCollapsed]);
};

export default useAutoOpen;
