import { ENDPOINTS } from "@/api/application-api";
import { handleResponse } from "@/utils/errorResponseHandlers";
import { authHeader } from "@/utils/headers";
import { useQuery } from "@tanstack/react-query";

interface DatasetRow {
  id: number;
  dataset_id: number;
  variables: Record<string, string>;
}

interface DatasetRowsResponse {
  column_names: string[];
  dataset_rows: DatasetRow[];
  has_next: boolean;
  has_prev: boolean;
  next_num: number | null;
  page: number;
  pages: number;
  per_page: number;
  prev_num: number | null;
  success: boolean;
  total: number;
}

export const useDatasetRows = (
  userToken: string,
  dataset_id: number | null,
  page: number,
  pageSize: number,
  sortBy?: string,
  sortOrder?: string,
  q?: string,
) => {
  return useQuery<DatasetRowsResponse>(
    [
      ENDPOINTS.dataset_rows,
      userToken,
      dataset_id,
      page,
      pageSize,
      sortBy,
      sortOrder,
      q,
    ],
    () => {
      if (!dataset_id)
        return {
          column_names: [],
          dataset_rows: [],
          has_next: false,
          has_prev: false,
          next_num: null,
          page: 1,
          pages: 0,
          per_page: pageSize,
          prev_num: null,
          success: false,
          total: 0,
        };
      const url = new URL(ENDPOINTS.dataset_rows);
      url.searchParams.set("dataset_id", dataset_id.toString());
      url.searchParams.set("page", page.toString());
      url.searchParams.set("per_page", pageSize.toString());
      sortBy && url.searchParams.set("sort_by", sortBy);
      sortOrder && url.searchParams.set("sort_order", sortOrder);
      if (q) {
        url.searchParams.set("q", q);
      }
      return fetch(url, {
        headers: authHeader(userToken),
      }).then(handleResponse);
    },
    {
      enabled: dataset_id !== null,
      retry: false,
      placeholderData: {
        column_names: [],
        dataset_rows: [],
        has_next: false,
        has_prev: false,
        next_num: null,
        page: 1,
        pages: 0,
        per_page: pageSize,
        prev_num: null,
        success: false,
        total: 0,
      },
    },
  );
};

export default useDatasetRows;
