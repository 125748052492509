import { DropdownValue } from "./types";

export const isObjectValue = (value: DropdownValue) =>
  typeof value === "object" && value !== null && "type" in value;

export const isJsonSchema = (value: DropdownValue) =>
  isObjectValue(value) && value.type === "json_schema";

export const getDisplayValue = (value: DropdownValue) => {
  if (value === null) return value;
  if (typeof value === "string") return value;
  return value.type;
};
