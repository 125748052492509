import { createContext, PropsWithChildren, useContext, useState } from "react";
import { LOCAL_STORAGE } from "@/constants";
import { User } from "@/types/user";
import posthog from "posthog-js";
import { getName } from "@/utils/getName";
export type AuthContextType = {
  login: (access_token: string, userData: User) => void;
  logout: () => void;
  user: User | null;
  userToken: string | null;
};

export const AuthContext = createContext<AuthContextType | undefined>(
  undefined,
);

const getInitialAccessToken = (): string | null => {
  return localStorage.getItem(LOCAL_STORAGE.ACCESS_TOKEN);
};

const getInitialUserData = (): User | null => {
  const localStorageUser = localStorage.getItem(LOCAL_STORAGE.USER);
  return localStorageUser ? (JSON.parse(localStorageUser) as User) : null;
};

const AuthProvider = (props: PropsWithChildren<{}>) => {
  const [accessToken, setAccessToken] = useState<string | null>(
    getInitialAccessToken(),
  );

  const [user, setUser] = useState<User | null>(getInitialUserData());

  const login = (access_token: string, userData: User) => {
    localStorage.setItem(LOCAL_STORAGE.ACCESS_TOKEN, access_token);
    localStorage.setItem(LOCAL_STORAGE.USER, JSON.stringify(userData));
    setAccessToken(access_token);
    const [firstName, lastName] = getName(userData.name || "");
    posthog.identify(userData.id.toString(), {
      email: userData.email,
      first_name: firstName,
      last_name: lastName,
      created_at: userData.created_at,
    });
    setUser(userData);
  };

  const logout = () => {
    localStorage.removeItem(LOCAL_STORAGE.ACCESS_TOKEN);
    localStorage.removeItem(LOCAL_STORAGE.ACTIVE_WORKSPACE_ID);
    localStorage.removeItem(LOCAL_STORAGE.USER);
    localStorage.removeItem("lastSeenFeatureVersion");
    posthog.reset();
    setAccessToken(null);
    setUser(null);
  };

  return (
    <AuthContext.Provider
      value={{
        login,
        logout,
        user,
        userToken: accessToken,
      }}
      {...props}
    />
  );
};

const useAuth = () => useContext(AuthContext);

export { AuthProvider, useAuth };
