import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { useAuth } from "@/context/auth-context";
import { useUser } from "@/context/user-context";
import { useDatasetGroups } from "@/queries";
import { observer } from "mobx-react-lite";
import { useCallback, useEffect, useMemo, useRef } from "react";
import { Dataset } from "../types";

interface VersionSelectProps {
  isReadOnly?: boolean;
  selectedDataset: string;
  selectedVersion: number;
  onVersionChange: (version: number) => void;
}

const VersionSelect = observer(
  ({
    isReadOnly,
    selectedDataset,
    selectedVersion,
    onVersionChange,
  }: VersionSelectProps) => {
    const versionSelectRef = useRef<HTMLButtonElement>(null);
    const { data } = useDatasetGroups(
      useAuth()?.userToken!,
      Number(useUser()?.activeWorkspaceId),
    );
    const dataset_groups = useMemo(() => data?.dataset_groups || [], [data]);

    const versions = useMemo(
      () =>
        dataset_groups
          .find(({ name }: { name: string }) => name === selectedDataset)
          ?.datasets.map((dataset: Dataset) => dataset.version_number)
          .sort((a: number, b: number) => b - a) || [],
      [dataset_groups, selectedDataset],
    );

    useEffect(() => {
      if (selectedDataset && selectedVersion <= 0 && versions.length > 0) {
        onVersionChange(versions[0]);
      }
    }, [selectedDataset, selectedVersion, versions, onVersionChange]);

    const handleVersionChange = useCallback(
      (value: string) => {
        onVersionChange(Number(value));
      },
      [onVersionChange],
    );

    return (
      <Select
        onValueChange={handleVersionChange}
        value={String(selectedVersion || "")}
        disabled={isReadOnly || !selectedDataset}
      >
        <SelectTrigger
          className="w-full whitespace-nowrap bg-white"
          ref={versionSelectRef}
        >
          <SelectValue placeholder="Select Version">
            {(selectedVersion > 0 && `Version #${selectedVersion}`) || (
              <span className=" text-muted-foreground">Select a version</span>
            )}
          </SelectValue>
        </SelectTrigger>
        <SelectContent className="max-h-[300px] overflow-y-auto">
          {versions
            .filter((version: number) => version > 0)
            .map((version: number) => (
              <SelectItem key={version} value={String(version)}>
                Version #{version}
              </SelectItem>
            ))}
        </SelectContent>
      </Select>
    );
  },
);

export default VersionSelect;
