import Badge from "@/components/Badge";

export const ComparisonBadge = ({
  percentageDifference,
}: {
  percentageDifference: number;
}) => {
  const isImprovement = percentageDifference > 0;
  const isNoChange = percentageDifference === 0;

  return (
    <div className="flex items-center justify-center">
      <Badge
        variant={
          isNoChange ? "default" : isImprovement ? "positive" : "negative"
        }
        className="text-sm"
      >
        <span className="mr-1">
          {isImprovement ? "↑" : isNoChange ? "–" : "↓"}
        </span>
        {Math.abs(percentageDifference).toFixed(1)}%
      </Badge>
    </div>
  );
};
