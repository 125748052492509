import { useParams } from "react-router-dom";
import { SlidersHorizontal } from "lucide-react";

import { Button } from "@/components/ui/button";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import { useModels } from "@/queries/models";
import { useAuth } from "@/context/auth-context";
import LoadingSpinner from "@/components/LoadingSpinner";

import { getParameters } from "./utils";
import { PromptParametersProps } from "./types";
import { ParametersWithTitle } from "./ParametersWithTitle";
import { ModelAndProviderPreview } from "./ModelAndProviderPreview";
import { FunctionDialog } from "@/components/FunctionsModal";
import { PlaygroundStoreContext } from "@/components/Playground2/playground-store";
import { Function_ } from "@/types";
import { useContext } from "react";
import LegacyControlProvider from "@/components/Playground2/Sidebar/ControlBar/LegacyControlProvider";

export const ParametersPreview = ({
  promptData,
  isChat,
  displayAsPopover = true,
}: PromptParametersProps) => {
  const { workspaceId } = useParams();
  const auth = useAuth();
  const playground = useContext(PlaygroundStoreContext);
  const { data: modelConfigs, isLoading: isLoadingModels } = useModels(
    auth?.userToken!,
    workspaceId!,
  );

  if (isLoadingModels) {
    return (
      <div className="flex h-full w-[132px] items-center justify-center">
        <LoadingSpinner size={4} />
      </div>
    );
  }

  const parameters = getParameters(
    promptData,
    modelConfigs?.results || [],
    isChat,
  );

  const modelName =
    promptData?.metadata?.model?.name || promptData?.inference_client_name;

  return (
    <>
      {displayAsPopover ? (
        <Popover>
          <PopoverTrigger asChild>
            <Button variant="ghost">
              <div
                className={`relative flex flex-row items-center ${
                  parameters.length > 0
                    ? "after:absolute after:-right-2 after:-top-0 after:h-2 after:w-2 after:rounded-full after:bg-blue-500"
                    : ""
                }`}
              >
                <SlidersHorizontal className="mr-2 h-4 w-4" />
                Parameters
              </div>
            </Button>
          </PopoverTrigger>
          <PopoverContent side="top" className="w-[480px] overflow-visible p-4">
            <div className="flex flex-col gap-3">
              <div className="flex items-center justify-between">
                <h4 className="text-base font-medium leading-none">
                  Parameters
                </h4>
                <a
                  className="text-xs text-blue-500 hover:text-blue-400"
                  target="_blank"
                  rel="noreferrer"
                  href="https://docs.promptlayer.com/features/prompt-registry#metadata-2"
                >
                  Learn more
                </a>
              </div>
              {modelName ? (
                <div
                  className="max-h-[53vh] overflow-y-auto"
                  style={{ scrollbarGutter: "stable" }}
                >
                  <div className="w-full pb-2">
                    <ModelAndProviderPreview promptData={promptData} />
                    <ParametersWithTitle parameters={parameters} />
                  </div>
                </div>
              ) : (
                <p className="text-sm italic text-gray-400">
                  No parameters set for this prompt version
                </p>
              )}
            </div>
          </PopoverContent>
        </Popover>
      ) : (
        <div
          className="max-h-[53vh] w-full overflow-y-auto"
          style={{ scrollbarGutter: "stable" }}
        >
          <div className="w-full pb-2">
            <ModelAndProviderPreview promptData={promptData} />
            <ParametersWithTitle parameters={parameters} />
          </div>
        </div>
      )}
      {playground && (
        <LegacyControlProvider>
          <FunctionDialog
            functions={
              playground.blueprint.prompt_template.functions as Function_[]
            }
            functionCall={undefined}
            functionsType={playground.functionType}
            jsonSchema={
              playground.model?.parameters.response_format?.json_schema
            }
          />
        </LegacyControlProvider>
      )}
    </>
  );
};
