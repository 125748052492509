import DeleteAllRowsButton from "@/components/DatasetEditor/Header/DeleteAllRowsButton";
import { useContext, useMemo, useState } from "react";
import { DatasetEditorContext } from "..";
import AddFromRequestHistoryButton from "./AddFromRequestHistoryButton";
import ArchiveButton from "./ArchiveButton";
import DeleteButton from "./DeleteButton";
import DownloadButton from "./DownloadButton";
import EditButton from "./EditButton";
import FilterParamsButton from "./FilterParamsButton";
import UploadDataButton from "./UploadDataButton";
import {
  ConfirmationModal,
  ConfirmationModalVariant,
} from "@/components/ConfirmationModal";
import { useNavigate } from "react-router-dom";
import { ResourceHeader } from "@/components/ResourceHeader";
import moment from "moment";
import { VersionSelector } from "./VersionSelector";

const DatasetHeader = ({ isLoading }: { isLoading: boolean }) => {
  const navigate = useNavigate();
  const {
    datasetGroup,
    activeDataset,
    isDraft,
    handleDeleteDatasetGroup,
    isSavingDataset,
    handleSaveAsNewVersionClick,
    handleDatasetGroupNameEdit,
  } = useContext(DatasetEditorContext);

  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [isDiscarded, setIsDiscarded] = useState(false);

  const draftModeStyle = "rounded-lg border-orange-200 bg-orange-100";
  const normalModeStyle = "border-gray-300 bg-gray-50";

  const actions = useMemo(() => {
    return [
      <FilterParamsButton activeDataset={activeDataset} />,
      <DownloadButton />,
      <DeleteAllRowsButton />,
      <DeleteButton setIsDiscarded={setIsDiscarded} />,
      <EditButton />,
      <ArchiveButton onDelete={handleDeleteDatasetGroup} />,
    ];
  }, [activeDataset, handleDeleteDatasetGroup, setIsDiscarded]);

  return (
    <div
      className={`flex w-full flex-col items-start gap-2.5 whitespace-nowrap rounded-lg border px-6 py-6 sm:flex-col sm:items-start sm:gap-x-2.5 lg:justify-between ${
        isDraft ? draftModeStyle : normalModeStyle
      }`}
    >
      <ResourceHeader
        title={datasetGroup?.name || ""}
        className="w-full"
        onTitleEdit={handleDatasetGroupNameEdit}
        id={datasetGroup?.id || ""}
        createdAt={
          datasetGroup?.created_at
            ? moment(datasetGroup?.created_at).format("MMM DD, YYYY hh:mm a")
            : ""
        }
        actions={actions}
        isLoading={isLoading}
      />
      <div className="flex w-full flex-col items-start gap-x-2 gap-y-2">
        <div className="flex items-center space-x-2">
          {isDraft && (
            <span className={`rounded-full bg-orange-200 px-2 py-1 text-xs`}>
              You are in Draft Mode
            </span>
          )}
          <VersionSelector
            handleDeleteDatasetGroup={handleDeleteDatasetGroup}
          />
        </div>
        {!isLoading && isDraft && (
          <div className="flex gap-2.5">
            <AddFromRequestHistoryButton />
            <UploadDataButton />
          </div>
        )}
        <ConfirmationModal
          title="Unsaved Changes"
          isNavigationBlocked={isDraft && !isDiscarded}
          leaveButtonText="Leave as Draft"
          saveButtonText="Save & Continue"
          handleOnConfirm={(nextLocationPathname) => {
            handleSaveAsNewVersionClick(() => {
              if (nextLocationPathname) {
                navigate(nextLocationPathname);
              }
            });
          }}
          description="If you exit now, your dataset will remain in draft mode. Would you like to save your changes or leave it as draft?"
          isLoading={isSavingDataset}
          isOpen={isConfirmModalOpen}
          headerVariant={ConfirmationModalVariant.warning}
          setIsOpen={setIsConfirmModalOpen}
        />
      </div>
    </div>
  );
};

export default DatasetHeader;
