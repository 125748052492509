import { Media } from "@/types";
import SelectedMedia from "./SelectedMedia";

interface SelectedMediaGridProps {
  selectedMedia: Media[];
  onDeleteMedia: (index: number) => void;
  onMediaSelect: (media?: Media) => void;
  readonly: boolean;
}

const SelectedMediaGrid = ({
  selectedMedia,
  onDeleteMedia,
  onMediaSelect,
  readonly,
}: SelectedMediaGridProps) => {
  return (
    selectedMedia &&
    selectedMedia.length > 0 && (
      <div className="mt-3 grid grid-cols-2 gap-3 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5">
        {selectedMedia.map((media, index) => (
          <div className="h-24 break-inside-avoid sm:h-28" key={index}>
            <SelectedMedia
              media={media}
              onDelete={() => onDeleteMedia(index)}
              onMediaSelect={onMediaSelect}
              readonly={readonly}
            />
          </div>
        ))}
      </div>
    )
  );
};

export default SelectedMediaGrid;
