import { useContext, useMemo } from "react";
import ArchiveButton from "./ArchiveButton";
import VersionHistoryMenu from "./VersionHistoryMenu";
import { DatasetEditorContext } from "..";
import useDatasets from "./VersionHistoryMenu/hooks/useDatasets";
import { useParams } from "react-router-dom";

export const VersionSelector = ({
  handleDeleteDatasetGroup,
}: {
  handleDeleteDatasetGroup: () => void;
}) => {
  const { datasetId } = useParams();
  const { isDraft, datasetGroup, isLoading, datasets } =
    useContext(DatasetEditorContext);

  const { currentVersion, sortedDatasets } = useDatasets(datasets, datasetId);

  const isArchived = useMemo(() => !!datasetGroup?.is_deleted, [datasetGroup]);

  return (
    <>
      <VersionHistoryMenu
        currentVersion={currentVersion}
        sortedDatasets={sortedDatasets}
        datasets={datasets}
        isDraft={isDraft}
      />
      {!isLoading && isArchived && !isDraft && (
        <ArchiveButton onDelete={handleDeleteDatasetGroup} />
      )}
    </>
  );
};
