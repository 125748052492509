import PaginatedSelect, {
  SelectedValue,
} from "@/components/ui/PaginatedSelect";
import { useAuth } from "@/context/auth-context";
import { useUser } from "@/context/user-context";
import { usePromptVersions } from "@/queries";
import { ListPromptVersion } from "@/types";
import { useRef, useCallback, useEffect } from "react";
import { useNavigate } from "react-router-dom";

const PromptTemplateVersionSelector = ({
  versionId,
  promptId,
}: {
  promptId: number;
  versionId: number;
}) => {
  const workspaceId = useUser()?.activeWorkspaceId!;
  const userToken = useAuth()?.userToken!;
  const versionsPageRef = useRef(1);
  const navigate = useNavigate();
  const promptVersionsQuery = usePromptVersions(userToken || "", {
    promptRegistryId: promptId,
    workspaceId: workspaceId,
    page: versionsPageRef.current,
  });

  const getVersionsQuery = useCallback(
    (queryPage: number) => {
      versionsPageRef.current = queryPage;
      return promptVersionsQuery;
    },
    [promptVersionsQuery],
  );

  useEffect(() => {
    promptVersionsQuery.refetch();
  }, [promptVersionsQuery]);

  const extractVersionsData = useCallback((data: any) => {
    if (!data) return { data: [], totalPages: 0 };

    const items = data.pages.flatMap((page: any) => page.items);
    const totalPages = data.pages[0]?.totalPages || 0;

    return {
      data: items,
      totalPages,
    };
  }, []);

  const handleVersionChange = (value: SelectedValue<ListPromptVersion>) =>
    navigate(
      `/workspace/${workspaceId}/prompt/${promptId}/version/${value.id}/analytics`,
    );

  return (
    <div className="flex flex-row items-center gap-x-2 overflow-visible">
      <PaginatedSelect<ListPromptVersion, any>
        query={getVersionsQuery}
        renderItem={(item) => (
          <div className="flex flex-col">
            <span className="font-medium">Version {item.number}</span>
            <span className="text-xs text-gray-500">{item.commit_message}</span>
            <div className="mt-1 flex flex-wrap gap-1">
              {Array.isArray(item.labels) &&
                item.labels.map((label, index) => (
                  <span
                    key={index}
                    className="inline-flex items-center rounded border  bg-white px-2 py-0.5 text-xs font-medium"
                  >
                    {label.name}
                  </span>
                ))}
            </div>
          </div>
        )}
        getItemValue={(item) => item.number.toString()}
        value={
          versionId
            ? {
                id: String(versionId),
                item: {
                  id: String(versionId),
                  name: `Version ${versionId}`,
                },
              }
            : undefined
        }
        onValueChange={handleVersionChange}
        placeholder="Select a version..."
        extractData={extractVersionsData}
      />
    </div>
  );
};

export default PromptTemplateVersionSelector;
