import {
  ExclamationIcon,
  TrashIcon,
  InformationCircleIcon,
} from "@heroicons/react/outline";

export const ICON_VARIANTS = {
  default: (
    <div className="flex h-10 w-full max-w-[40px] items-center justify-center rounded-full bg-blue-100">
      <InformationCircleIcon className="h-5 w-5 text-blue-600" />
    </div>
  ),
  warning: (
    <div className="flex h-10 w-full max-w-[40px] items-center justify-center rounded-full bg-amber-100">
      <ExclamationIcon className="h-5 w-5 text-amber-600" />
    </div>
  ),
  destructive: (
    <div className="flex h-10 w-full max-w-[40px] items-center justify-center rounded-full bg-red-100">
      <TrashIcon className="h-5 w-5 text-red-600" />
    </div>
  ),
};
