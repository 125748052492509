import SchemaParameterStore, {
  ISchemaParameter,
} from "./SchemaParameter/schema-parameter-store";

interface ValidationError {
  field: string;
  message: string;
  path: string[];
}

export const validateJson = (
  json: any,
  path: string[] = [],
): ValidationError[] => {
  const errors: ValidationError[] = [];

  if (!json) {
    errors.push({
      field: path.join(".") || "root",
      message: `JSON content is empty or undefined at path: ${path.join(".")}`,
      path,
    });
    return errors;
  }

  // Validate required fields
  const requiredFields = ["name", "type", "title"];
  requiredFields.forEach((field) => {
    if (!json[field]) {
      errors.push({
        field: path.length ? `${path.join(".")}.${field}` : field,
        message: `Missing required field: ${field} at path: ${path.join(".")}`,
        path: [...path, field],
      });
    }
  });

  // Validate type field
  if (
    json.type &&
    !["object", "array", "string", "number", "boolean"].includes(json.type)
  ) {
    errors.push({
      field: path.length ? `${path.join(".")}.type` : "type",
      message: `Invalid type: ${json.type} at path: ${path.join(
        ".",
      )}. Must be one of: object, array, string, number, boolean`,
      path: [...path, "type"],
    });
  }

  // Validate object properties
  if (json.type === "object" && json.properties) {
    Object.entries(json.properties).forEach(([key, value]) => {
      errors.push(...validateJson(value, [...path, "properties", key]));
    });
  }

  // Validate array items
  if (json.type === "array" && json.items) {
    if (!json.items.properties) {
      errors.push({
        field: path.length ? `${path.join(".")}.items` : "items",
        message: `Array items must have properties defined at path: ${path.join(
          ".",
        )}`,
        path: [...path, "items"],
      });
    } else {
      errors.push(
        ...validateJson(json.items.properties, [
          ...path,
          "items",
          "properties",
        ]),
      );
    }
  }

  return errors;
};

export const convertJsonToParameter = (
  json: any,
  parent?: SchemaParameterStore,
  expandedStateMap?: Map<string, boolean>,
): SchemaParameterStore => {
  const param = new SchemaParameterStore(0, true, parent);
  param.name = json.name;
  param.title = json.title;
  param.content = json.description;
  param.type = json.type;
  const rawRequirements = json.required || json?.items?.required || [];
  json.enum && param.setEnums(json.enum);

  // Restore expanded state if provided
  if (expandedStateMap && json.name && expandedStateMap.has(json.name)) {
    if (expandedStateMap.get(json.name)) {
      param._collapsed = false;
    }
  }

  if (json.type === "object" && json.properties) {
    // Create nested expanded state map if possible
    const nestedExpandedStateMap = new Map<string, boolean>();
    if (Array.isArray(param.content)) {
      (param.content as SchemaParameterStore[]).forEach((child) => {
        nestedExpandedStateMap.set(child.name, child.isExpanded);
      });
    }

    param.content = Object.entries(json.properties || {}).map(
      ([key, value]: [string, any]) => {
        const childParam = convertJsonToParameter(
          { ...value, name: key },
          param,
          nestedExpandedStateMap,
        );
        return childParam;
      },
    );
  } else if (json.type === "array" && json.items) {
    // Similar approach for array items
    const nestedExpandedStateMap = new Map<string, boolean>();
    if (Array.isArray(param.content)) {
      (param.content as SchemaParameterStore[]).forEach((child) => {
        nestedExpandedStateMap.set(child.name, child.isExpanded);
      });
    }

    if (json.items.properties) {
      param.content = Object.entries(json.items.properties || {}).map(
        ([key, value]) => {
          const childParam = convertJsonToParameter(
            { ...(value as object), name: key },
            param,
            nestedExpandedStateMap,
          );
          return childParam;
        },
      );
    } else {
      param.content = json.items;
    }
  }

  param._required = (rawRequirements as unknown as string[]).map(
    // resolve child requirements
    (name) =>
      (param.content as SchemaParameterStore[]).find((p) => p.name === name)!,
  );

  return param;
};

export const convertParameterToJson = (
  param: ISchemaParameter,
  allowAdditionalProperties: boolean = false,
): {
  name: string;
  type: string;
  description: string;
  title: string;
  properties?: Record<string, any>;
  required?: string[];
  additionalProperties?: boolean;
  items?: any;
} => {
  const baseObj = {
    name: param.name,
    type: param.type,
    description: typeof param.content == "string" ? param.content : "",
    title: param.title,
    required: param._required?.map((p) => p?.name || "invalid_reference1"),
    enum: param.enum,
  };

  if (param.type === "object" && Array.isArray(param.content)) {
    const properties: Record<string, any> = {};

    param.content.forEach((childParam) => {
      properties[childParam.name] = {
        ...convertParameterToJson(childParam),
        name: undefined,
      };
    });

    return {
      ...baseObj,
      additionalProperties: allowAdditionalProperties,
      properties,
    };
  }

  if (param.type === "array" && Array.isArray(param.content)) {
    const properties: Record<string, any> = {};

    param.content.forEach((childParam) => {
      properties[childParam.name] = {
        ...convertParameterToJson(childParam),
        name: undefined,
      };
    });
    return {
      ...baseObj,
      required: undefined,
      items: {
        // review reasoning
        properties: properties,
        required: baseObj.required,
        type: "object",
        additionalProperties: allowAdditionalProperties,
      },
    };
  } else if (param.type === "array" && typeof param.content === "object") {
    return {
      ...baseObj,
      items: {
        ...param.content,
      },
    };
  }

  return baseObj;
};

export const validatePresetFields = (json: any, presets: string[]): boolean => {
  if (typeof json !== "object" || json === null) {
    return true;
  }

  return Object.keys(json).every((key) => {
    // Skip preset validation for direct children of properties
    if (key === "properties") {
      return Object.keys(json[key]).every((propKey) => {
        // Skip validation for direct property keys
        return validatePresetFields(json[key][propKey], presets);
      });
    }

    if (!presets.includes(key)) {
      console.log("FAIL", key);
      return false;
    }

    if (typeof json[key] === "object" && json[key] !== null) {
      if (Array.isArray(json[key])) {
        return json[key].every((item: any) =>
          validatePresetFields(item, presets),
        );
      }
      return validatePresetFields(json[key], presets);
    }

    return true;
  });
};

export const stripDummyFields = (json: any): any => {
  const FIELDS_TO_REMOVE = ["isRequired", "isEnum", "hasAdditionalProperties"];

  if (!json || typeof json !== "object") {
    return json;
  }

  if (Array.isArray(json)) {
    return json.map((item) => stripDummyFields(item));
  }

  const result: any = {};

  for (const [key, value] of Object.entries(json)) {
    if (FIELDS_TO_REMOVE.includes(key)) {
      continue;
    }

    if (typeof value === "object" && value !== null) {
      result[key] = stripDummyFields(value);
    } else {
      result[key] = value;
    }
  }

  return result;
};
