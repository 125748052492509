import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { HumanConfiguration, ReportColumn } from "@/types/evaluate";
import { useState } from "react";
import { ModalStep } from "../ModalRouter";
import SourceName from "../SourceName";

const HUMAN_INPUT_DATA_TYPE: Record<string, any> = {
  number: {
    label: "Number",
    component_options: ["text", "slider"],
  },
  string: {
    label: "Text",
    component_options: ["text"],
  },
};

const uiComponentToLabel: Record<string, string> = {
  text: "Textbox",
  slider: "Slider",
};

const HumanInputColumnBuilder = ({
  columnData,
  availableColumns,
  navigatePrevious,
  saveColumnAction,
  editable,
}: {
  columnData: Partial<ReportColumn>;
  availableColumns: ReportColumn[];
  navigatePrevious: () => void;
  saveColumnAction: (newColumnDataArg: ReportColumn) => void;
  editable: boolean;
}) => {
  const [name, setName] = useState<string | null>(columnData.name || null);
  const [dataType, setDataType] = useState<string | null>(
    columnData.configuration?.data_type || null,
  );
  const [uiComponent, setUiComponent] = useState<string | null>(
    columnData.configuration?.ui_element?.component || null,
  );
  const [uiComponentConfig, setUiComponentConfig] = useState<any | {}>(
    columnData.configuration?.ui_element || {},
  );

  const [invalidUiComponentErrorMessage, setInvalidUiComponentErrorMessage] =
    useState("UI Component is invalid.");

  const [dataIsValid, setDataIsValid] = useState<{
    name: boolean;
    dataType: boolean;
    uiComponent: boolean;
  }>({
    name: true,
    dataType: true,
    uiComponent: true,
  });

  const saveEndpointAction = () => {
    if (!name) {
      setDataIsValid({ name: false, dataType: true, uiComponent: true });
      return;
    } else if (!dataType) {
      setDataIsValid({ name: true, dataType: false, uiComponent: true });
      return;
    } else if (!uiComponent) {
      setInvalidUiComponentErrorMessage("UI Component is required.");
      setDataIsValid({ name: true, dataType: true, uiComponent: false });
      return;
    } else if (
      uiComponent &&
      uiComponent === "slider" &&
      (!uiComponentConfig?.min || !uiComponentConfig?.max)
    ) {
      setInvalidUiComponentErrorMessage("Please provide a min and max value.");
      setDataIsValid({ name: true, dataType: true, uiComponent: false });
      return;
    } else if (uiComponent && uiComponent === "slider") {
      const min = uiComponentConfig?.min;
      const max = uiComponentConfig?.max;
      if (min && max && Number(min) >= Number(max)) {
        setInvalidUiComponentErrorMessage(
          "Min value must be less than max value.",
        );
        setDataIsValid({ name: true, dataType: true, uiComponent: false });
        return;
      }
    }

    setDataIsValid({ name: true, dataType: true, uiComponent: true });

    saveColumnAction({
      ...columnData,
      name: name,
      configuration: {
        data_type: dataType,
        ui_element: {
          component: uiComponent,
          ...uiComponentConfig,
        },
      } as HumanConfiguration,
    } as ReportColumn);
  };

  const uiComponentOptions = dataType
    ? HUMAN_INPUT_DATA_TYPE[dataType]?.component_options
    : [];
  const uiComponentSelector = uiComponentOptions.length > 0 && (
    <>
      <label htmlFor="dataType" className="col-span-1">
        UI Component
      </label>
      <div className="relative col-span-2">
        <DropdownMenu>
          <DropdownMenuTrigger className="w-full">
            {uiComponent ? (
              uiComponentToLabel[uiComponent]
            ) : (
              <span className="font-normal text-gray-500">
                Select a data type...
              </span>
            )}
          </DropdownMenuTrigger>
          <DropdownMenuContent>
            {uiComponentOptions.map((option: string) => (
              <DropdownMenuItem
                key={option}
                onSelect={() => {
                  setUiComponent(option);
                  setUiComponentConfig({});
                }}
              >
                {uiComponentToLabel[option]}
              </DropdownMenuItem>
            ))}
          </DropdownMenuContent>
        </DropdownMenu>
      </div>
    </>
  );

  let uiComponentPreview = null;
  if (uiComponent === "text") {
    let sampleText;
    if (dataType === "number") {
      sampleText = "123";
      uiComponentPreview = (
        <input
          className={`col-span-2 rounded border border-gray-300 px-2 py-1 font-mono`}
          value={sampleText}
          type="number"
        />
      );
    } else {
      sampleText =
        "This prompt output is not amazing because it lacks specific examples.";
      uiComponentPreview = (
        <textarea
          className={`col-span-2 rounded border border-gray-300 px-2 py-1 text-sm`}
          value={sampleText}
          rows={4}
        />
      );
    }
  } else if (uiComponent === "slider") {
    const min = uiComponentConfig?.min || 0;
    const max = uiComponentConfig?.max || 10;
    uiComponentPreview = (
      <div>
        <div className="flex items-center gap-x-2 text-xs text-gray-500">
          <span>{min}</span>
          <input
            type="range"
            id="slider"
            className={`rounded border border-gray-300 py-1`}
            min={min}
            max={max}
            defaultValue={5}
          />
          <span>{max}</span>
        </div>
      </div>
    );
  }

  return (
    <ModalStep
      navigatePrevious={navigatePrevious}
      navigateNext={saveEndpointAction}
      nextButtonText={editable ? "Save Step" : "Done"}
    >
      <div className="grid grid-cols-3 items-center gap-4">
        <div className="col-span-3">
          <div className="text-lg font-semibold">Configure Human Input</div>
          <div className="max-w-md text-sm text-gray-500">
            This column will be populated by a human. Please configure the data
            type then fill it out manually.
          </div>
        </div>
        <SourceName
          name={name!}
          columnCount={availableColumns.length}
          isValid={dataIsValid.name}
          setName={setName}
          isNode={false}
          readonly={!editable}
        />
        <label htmlFor="dataType" className="col-span-1">
          Data Type:
        </label>
        <div className="relative col-span-2">
          <DropdownMenu>
            <DropdownMenuTrigger className="w-full">
              {dataType ? (
                HUMAN_INPUT_DATA_TYPE[dataType].label
              ) : (
                <span className="font-normal text-gray-500">
                  Select a data type...
                </span>
              )}
            </DropdownMenuTrigger>
            <DropdownMenuContent>
              {Object.keys(HUMAN_INPUT_DATA_TYPE).map((type: string) => (
                <DropdownMenuItem
                  key={type}
                  onSelect={() => {
                    setDataType(type);
                    setUiComponent(null);
                    setUiComponentConfig({});
                  }}
                >
                  {HUMAN_INPUT_DATA_TYPE[type].label}
                </DropdownMenuItem>
              ))}
            </DropdownMenuContent>
          </DropdownMenu>
        </div>
        {!dataIsValid.dataType && (
          <>
            <div className="col-span-1 -mt-4">&nbsp;</div>
            <div className="col-span-2 -mt-4 text-left text-xs text-red-500">
              Data Type is invalid.
            </div>
          </>
        )}
        {uiComponentSelector}
        {!dataIsValid.uiComponent && (
          <>
            <div className="col-span-1 -mt-4">&nbsp;</div>
            <div className="col-span-2 -mt-4 text-left text-xs text-red-500">
              {invalidUiComponentErrorMessage}
            </div>
          </>
        )}
        {uiComponent === "slider" && (
          <div className="col-span-3 mx-4 my-1 grid grid-cols-3 gap-2 rounded-md bg-gray-50 p-6">
            <label htmlFor="min" className="col-span-1">
              Min:
            </label>
            <input
              id="min"
              className={`col-span-2 rounded border border-gray-300 px-2 py-1 disabled:cursor-not-allowed disabled:bg-gray-50`}
              value={uiComponentConfig?.min || ""}
              onChange={(e) => {
                const value = e.target.value;
                setUiComponentConfig({
                  ...uiComponentConfig,
                  min: value,
                });
              }}
              disabled={!editable}
            />
            <label htmlFor="max" className="col-span-1">
              Max:
            </label>
            <input
              id="max"
              className={`col-span-2 rounded border border-gray-300 px-2 py-1 disabled:cursor-not-allowed disabled:bg-gray-50`}
              value={uiComponentConfig?.max || ""}
              onChange={(e) => {
                const value = e.target.value;
                setUiComponentConfig({
                  ...uiComponentConfig,
                  max: value,
                });
              }}
              disabled={!editable}
            />
          </div>
        )}
        {uiComponentPreview && (
          <div className="col-span-3 rounded-sm border border-dashed p-4">
            <div className="text-base font-semibold">Preview</div>
            <div className="max-w-md text-xs text-gray-500">
              This is how the column will look in the table.
            </div>
            <div className="mt-2">{uiComponentPreview}</div>
          </div>
        )}
      </div>
    </ModalStep>
  );
};

export default HumanInputColumnBuilder;
