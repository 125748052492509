import { useParseInputVariables } from "@/queries";
import { usePlayground } from "../playground-store";
import { useAuth } from "@/context/auth-context";
import { reaction, toJS } from "mobx";
import { useEffect, useState } from "react";

const useAvailableInputVariables = () => {
  const playground = usePlayground();
  const authContext = useAuth();
  const userToken = authContext!.userToken!;
  const [debouncedBlueprint, setDebouncedBlueprint] = useState({
    ...toJS(playground.blueprint),
  });

  useEffect(() => {
    const dispose = reaction(
      () => playground.template_format,
      () => {
        setDebouncedBlueprint({ ...toJS(playground.blueprint) });
      },
    );
    return dispose;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [playground.template_format]);

  useEffect(() => {
    const dispose = reaction(
      () => playground.messagesContent,
      () => {
        setDebouncedBlueprint({ ...toJS(playground.blueprint) });
      },
      {
        delay: 800,
      },
    );

    return () => dispose();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [playground.messagesContent]);

  // Add reaction to blueprint changes
  useEffect(() => {
    const dispose = reaction(
      () => playground.blueprint,
      (blueprint) => {
        setDebouncedBlueprint({ ...toJS(blueprint) });
      },
      {
        delay: 800,
      },
    );

    return () => dispose();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const parseInputVariables = useParseInputVariables(
    userToken,
    debouncedBlueprint,
  );

  useEffect(() => {
    const data = parseInputVariables.data;
    const resetErrors = () =>
      playground.messages.forEach((message) => message.setParsingError(""));
    if (!parseInputVariables.isLoading && data) {
      resetErrors();
      if (data.success) {
        playground.setDetectedInputVariables(
          data.input_variables.filter((v) => v),
        );
      } else {
        const fullMessage = data.message[0].msg || "";
        const errorMessages = fullMessage.split("\n");
        errorMessages.shift();

        errorMessages.forEach((message) => {
          const index = parseInt(message.match(/index\s+(\d+):/)?.[1] || "0");
          const associatedMessage = playground.getMessageByIndex(index);
          if (associatedMessage) {
            associatedMessage.setParsingError(message.split(":")[1]);
          }
        });
      }
    }
  }, [parseInputVariables, playground]);
};

export default useAvailableInputVariables;
