import { Toggle } from "@/components/Toggle";

import { ToggleSize } from "@/components/Toggle";

interface ParameterLabelProps {
  id: string;
  name: string;
  labelClasses: string;
  disabledClass: string;
  isDisabled?: boolean;
  required?: boolean;
  onToggle: (isToggled: boolean) => void;
  shouldShowToggle?: boolean;
  readOnly?: boolean;
}

export const ParameterLabel = ({
  id,
  name,
  labelClasses,
  disabledClass,
  isDisabled,
  required,
  shouldShowToggle = true,
  onToggle,
  readOnly,
}: ParameterLabelProps) => (
  <div
    className={`mr-2 flex items-center justify-between ${
      readOnly ? "w-[150px]" : "w-full"
    }`}
  >
    <label
      htmlFor={id}
      className={readOnly ? "text-sm font-medium" : labelClasses}
    >
      <p className={isDisabled ? disabledClass : ""}>{name}</p>
    </label>
    {shouldShowToggle && !readOnly && (
      <Toggle
        size={ToggleSize.Small}
        enabled={!isDisabled}
        disabled={required}
        setEnabled={onToggle}
      />
    )}
  </div>
);
