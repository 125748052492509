import {
  useMessageUpdateListener,
  useNewMessageListener,
  useRunCompleteListener,
  useTemplateAppendListener,
} from "./listeners";

const useWebSocketListeners = () => {
  useTemplateAppendListener();
  useNewMessageListener();
  useMessageUpdateListener();
  useRunCompleteListener();
};

export default useWebSocketListeners;
