import {
  Accordion,
  AccordionItem,
  AccordionContent,
  AccordionTrigger,
} from "@/components/ui/accordion";
import { ParameterLabel } from "../ParameterLabel";
import { ParameterGroupProps } from "./types";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { Parameter } from "..";

export const ParameterGroup = observer(
  ({
    disabledClass,
    labelClasses,
    parameter,
    readOnly,
    isDisabled,
    shouldShowToggle,
  }: ParameterGroupProps) => {
    const [accordionValue, setAccordionValue] = useState<string>(
      !parameter.isDisabled && !readOnly
        ? parameter.paramId || ""
        : "read-only",
    );

    useEffect(() => {
      if (readOnly) return;

      setAccordionValue(!parameter.isDisabled ? parameter.paramId || "" : "");
    }, [parameter.isDisabled, parameter.paramId, readOnly]);

    return (
      <Accordion
        type="single"
        collapsible={!readOnly}
        value={accordionValue}
        onValueChange={(val) => setAccordionValue(val)}
        className={parameter.isDisabled ? disabledClass : ""}
      >
        <AccordionItem
          value={readOnly ? "read-only" : parameter.paramId ?? ""}
          className={readOnly ? "border-none" : ""}
        >
          <AccordionTrigger className="pt-0" disabled={readOnly}>
            <ParameterLabel
              id={parameter.paramId ?? ""}
              name={parameter.name ?? ""}
              labelClasses={labelClasses}
              shouldShowToggle={shouldShowToggle}
              disabledClass={disabledClass}
              isDisabled={parameter.isDisabled || isDisabled}
              onToggle={(isEnabled) => {
                parameter.updateDisabled(isEnabled);
              }}
              readOnly={readOnly}
            />
          </AccordionTrigger>
          <AccordionContent className="flex flex-col gap-2 pl-1">
            {parameter.parameters?.map((param) => (
              <Parameter
                key={param.paramId}
                shouldShowToggle={false}
                isDisabled={parameter.isDisabled || isDisabled}
                parameter={param}
                readOnly={readOnly}
              />
            ))}
          </AccordionContent>
        </AccordionItem>
      </Accordion>
    );
  },
);
