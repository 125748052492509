import { observer } from "mobx-react-lite";

import { Skeleton } from "@/components/ui/skeleton";
import { AuthProvider, useAuth } from "@/context/auth-context";
import { UserProvider, useUser } from "@/context/user-context";
import { useDatasetGroups } from "@/queries";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import useWidget from "../../useWidget";
import { WidgetProps } from "../../Widget";
import WidgetBase from "../WidgetBase";
import ExamplesWidgetProvider, { useExamples } from "./example-widget-store";
import ExamplePreviewer from "./ExamplePreviewer";
import ExampleSelectionDialog from "./ExampleSelectionDialog";
import { DatasetSelect, VersionSelect } from "./selectors";
import { Dataset } from "./types";

const ExampleSelectionSkeleton = ({ isReadOnly }: { isReadOnly: boolean }) => {
  return (
    <WidgetBase
      title={`Select Examples from Dataset`}
      size={isReadOnly ? "small" : "medium"}
      onDelete={undefined}
    >
      <div className="relative flex w-full flex-col space-y-4">
        <Skeleton width="100%" height="2rem" />
        <Skeleton width="100%" height="2rem" />
        <Skeleton width="100%" height="8rem" />
      </div>
    </WidgetBase>
  );
};

const ExamplesWidgetContent = observer(({ id, readOnly }: WidgetProps) => {
  const store = useExamples();
  const { deleteWidget } = useWidget(id, store.widgetValue);
  const isReadOnly = readOnly ?? false;

  const { data, isLoading } = useDatasetGroups(
    useAuth()?.userToken!,
    Number(useUser()?.activeWorkspaceId),
  );
  const dataset_groups = data?.dataset_groups || [];

  const selectedDatasetId = dataset_groups
    .find(({ name }: { name: string }) => name === store.selectedDataset)
    ?.datasets.find(
      (dataset: Dataset) => dataset.version_number === store.selectedVersion,
    )?.id;

  const columnDummyData = store.visibleColumns.reduce(
    (acc, column) => {
      acc[column] = "Example Value";
      return acc;
    },
    {} as Record<string, string>,
  );

  if (isLoading) {
    return <ExampleSelectionSkeleton isReadOnly={isReadOnly} />;
  }

  return (
    <WidgetBase
      title={
        store.selectedDataset
          ? `${store.selectedDataset}${
              store.selectedVersion ? ` v${store.selectedVersion}` : ""
            } (${store.selectedExamples.length})`
              .slice(0, 50)
              .concat(store.selectedDataset.length > 50 ? "..." : "")
          : "Select Dataset Examples"
      }
      size={isReadOnly ? "small" : "medium"}
      onDelete={(!readOnly && deleteWidget) || undefined}
    >
      <DatasetSelect
        datasetGroups={dataset_groups}
        isReadOnly={isReadOnly}
        selectedDataset={store.selectedDataset}
        setSelectedDataset={(dataset) => store.setSelectedDataset(dataset)}
        datasetId={selectedDatasetId}
      />
      <VersionSelect
        isReadOnly={isReadOnly || !selectedDatasetId}
        selectedDataset={store.selectedDataset}
        selectedVersion={store.selectedVersion}
        onVersionChange={(version) => store.setSelectedVersion(version)}
      />
      {!isReadOnly &&
        selectedDatasetId &&
        store.selectedDataset &&
        store.selectedVersion > 0 && (
          <ExampleSelectionDialog
            key="example-selection"
            datasetId={selectedDatasetId}
            selectedExamples={store.selectedExamples}
            onAddExample={(example) => store.addExample(example)}
          />
        )}
      <div className="my-4 h-px w-full bg-gray-200" />
      <ExamplePreviewer
        data={columnDummyData}
        displayType={store.dataType as "json" | "xml"}
        onChange={(type) => !readOnly && store.setDataType(type)}
      />
    </WidgetBase>
  );
});

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

const ExamplesWidget = (props: WidgetProps) => {
  return (
    <AuthProvider>
      <QueryClientProvider client={queryClient}>
        <UserProvider>
          <ExamplesWidgetProvider match={props.match}>
            <ExamplesWidgetContent {...props} />
          </ExamplesWidgetProvider>
        </UserProvider>
      </QueryClientProvider>
    </AuthProvider>
  );
};

export default ExamplesWidget;
