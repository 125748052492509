import { usePlayground } from "@/components/Playground2/playground-store";
import { useEffect, useState } from "react";

import usePromptNameResolver from "./usePromptNameResolver";

const useTemplateForm = () => {
  const playground = usePlayground();

  const promptId = playground.selectedTemplateVersion?.prompt_id;

  const promptName = usePromptNameResolver(promptId!);

  const [saveType, setSaveType] = useState<"update" | "create">(
    promptId ? "update" : "create",
  );
  const [selectedTemplate, setSelectedTemplate] = useState(promptName);
  const [selectedTemplateId, setSelectedTemplateId] = useState<number>(
    promptId || -1,
  );
  const [newTemplateName, setNewTemplateName] = useState("");

  useEffect(() => {
    setSaveType(promptId ? "update" : "create");
    setSelectedTemplateId(promptId || -1);
  }, [promptId]);

  useEffect(() => {
    if (promptId && !selectedTemplate) {
      setSelectedTemplate(promptName);
    }
  }, [promptId, promptName, selectedTemplate]);

  return {
    saveType,
    setSaveType,
    selectedTemplate,
    setSelectedTemplate,
    selectedTemplateId,
    setSelectedTemplateId,
    newTemplateName,
    setNewTemplateName,
  };
};

export default useTemplateForm;
