import { observer } from "mobx-react-lite";
import { usePlayground } from "../playground-store";
import ControlBar from "./ControlBar";
import InputVariableWidget from "./InputVariableWidget";
import OutputsWidget from "./OutputsWidget";

const Sidebar = () => {
  const playground = usePlayground();
  return (
    <div className="relative h-full w-full">
      <div className="flex h-full w-full flex-col overflow-y-hidden scroll-smooth">
        <ControlBar />
        <div className="relative h-full flex-1 overflow-y-auto ">
          <div className="absolute flex h-full w-full flex-1 flex-col gap-y-2 overflow-y-auto">
            <InputVariableWidget />
            {playground.isChat && <OutputsWidget />}
          </div>
        </div>
      </div>
    </div>
  );
};

export default observer(Sidebar);
