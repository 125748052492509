import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import {
  MinMaxConfiguration,
  MinMaxType,
  minMaxTypes,
  ReportColumn,
} from "@/types/evaluate";
import { formatInputVariable } from "@/utils/evaluate";
import { useState } from "react";
import { useBandaid } from "../utils/BandaidContext";
import { ModalStep } from "./ModalRouter";
import SourceName from "./SourceName";

const MinMaxBuilder = ({
  columnData,
  availableColumns,
  navigatePrevious,
  saveColumnAction,
  editable,
}: {
  columnData: Partial<ReportColumn>;
  availableColumns: ReportColumn[];
  navigatePrevious: () => void;
  saveColumnAction: (newColumnDataArg: ReportColumn) => void;
  editable: boolean;
}) => {
  const [name, setName] = useState<string | null>(columnData.name || null);
  const [jsonPath, setJsonPath] = useState<string | null>(
    columnData.configuration?.json_path ?? undefined,
  );
  const [column, setColumn] = useState<string | null>(
    columnData.configuration?.source || null,
  );

  const [type, setType] = useState<MinMaxType>(
    columnData.configuration?.type || "min",
  );

  const [dataIsValid, setDataIsValid] = useState<{
    name: boolean;
    jsonPath?: boolean;
    column: boolean;
  }>({
    name: true,
    jsonPath: true,
    column: true,
  });

  const saveEndpointAction = () => {
    if (!name) {
      setDataIsValid({ name: false, jsonPath: true, column: true });
      return;
    } else if (!column) {
      setDataIsValid({ name: true, jsonPath: true, column: false });
      return;
    }

    setDataIsValid({ name: true, jsonPath: true, column: true });

    saveColumnAction({
      ...columnData,
      name: name,
      configuration: {
        json_path: jsonPath,
        type,
        source: column,
      } as MinMaxConfiguration,
    } as ReportColumn);
  };

  const bandaid = useBandaid();

  return (
    <ModalStep
      navigatePrevious={navigatePrevious}
      navigateNext={saveEndpointAction}
      nextButtonText={editable ? "Save Step" : "Done"}
    >
      <div className="grid grid-cols-3 items-center gap-4">
        <div className="col-span-3">
          <div className="text-lg font-semibold">Configure Min Max</div>
          <div className="max-w-md text-sm text-gray-500">
            This step will compute the min or max value. <br /> Use
            $[*].fieldName to select a field from each object in an array. If
            blank, compares entire elements.
          </div>
        </div>
        <SourceName
          name={name!}
          columnCount={availableColumns.length}
          isValid={dataIsValid.name}
          setName={setName}
          isNode={bandaid}
          readonly={!editable}
        />

        <label htmlFor="json-path" className="col-span-1">
          JSON Path:
        </label>
        <div className="col-span-2 flex flex-col gap-2">
          <input
            id="json-path"
            className={`rounded border ${
              !dataIsValid.jsonPath ? "border-red-500" : "border-gray-300"
            } px-2 py-1 disabled:cursor-not-allowed disabled:bg-gray-50`}
            value={jsonPath || ""}
            onChange={(e) => setJsonPath(e.target.value)}
            disabled={!editable}
            placeholder="$[*].value"
          />
        </div>

        <SelectType value={type} setValue={setType} />

        <label htmlFor="column" className="col-span-1">
          {bandaid ? "Node:" : "Column:"}
        </label>
        <DropdownMenu>
          <DropdownMenuTrigger
            disabled={!editable}
            className="col-span-2 w-full"
          >
            {column || (
              <span className="font-normal text-gray-500">
                Select a {bandaid ? "node" : "column"}...
              </span>
            )}
          </DropdownMenuTrigger>
          <DropdownMenuContent>
            {availableColumns.map((column) => (
              <DropdownMenuItem
                key={column.name}
                onSelect={() => setColumn(column.name)}
              >
                {formatInputVariable(column.column_type, column.name)}
              </DropdownMenuItem>
            ))}
          </DropdownMenuContent>
        </DropdownMenu>
        {!dataIsValid.column && (
          <>
            <div className="col-span-1 -mt-4">&nbsp;</div>
            <div className="col-span-2 -mt-4 text-left text-xs text-red-500">
              {bandaid ? "Node" : "Column"} is required
            </div>
          </>
        )}
      </div>
    </ModalStep>
  );
};

const SelectType = ({
  value,
  setValue,
}: {
  value: MinMaxType;
  setValue: (newValue: MinMaxType) => void;
}) => {
  return (
    <>
      <label htmlFor="name" className="col-span-1">
        Min/Max
      </label>
      <DropdownMenu>
        <DropdownMenuTrigger className="col-span-2 w-full">
          {minMaxTypes.find((t) => t === value) || (
            <span className="font-normal text-gray-500">Type ...</span>
          )}
        </DropdownMenuTrigger>
        <DropdownMenuContent>
          {minMaxTypes.map((type) => (
            <DropdownMenuItem key={type} onSelect={() => setValue(type)}>
              {type}
            </DropdownMenuItem>
          ))}
        </DropdownMenuContent>
      </DropdownMenu>
    </>
  );
};

export default MinMaxBuilder;
