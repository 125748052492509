import { useQueryClient } from "@tanstack/react-query";

import { ENDPOINTS } from "@/api/application-api";
import { useMutation } from "@tanstack/react-query";
import { jsonAuthHeaders } from "@/utils/headers";
import { handleResponse } from "@/utils/errorResponseHandlers";

export const useSaveDataset = (userToken: string) => {
  const queryClient = useQueryClient();

  return useMutation(
    ({ dataset_id }: { dataset_id: string }) =>
      fetch(ENDPOINTS.save_dataset, {
        method: "POST",
        headers: jsonAuthHeaders(userToken),
        body: JSON.stringify({
          dataset_id,
        }),
      }).then(handleResponse),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([ENDPOINTS.datasets]);
        queryClient.invalidateQueries([ENDPOINTS.save_dataset]);
      },
    },
  );
};
