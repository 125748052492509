import { Parameter } from "@/queries/models/types";

export const convertKwargsToReadableFormat = (
  kwargs: Record<string, string | number | boolean | object>,
  parameters: Parameter[],
) => {
  return Object.entries(kwargs || {})
    .filter(
      ([key, value]) =>
        (typeof value !== "object" || key === "thinking") &&
        !["system", "system_instruction", "inference_client_name"].includes(
          key,
        ),
    )
    .flatMap(([key, value]) => {
      if (typeof value === "object") {
        const entries = Object.entries(value);
        return entries.map(([k, v]) => {
          const strValue = String(v || "");
          return {
            name: k
              .replace(/_/g, " ")
              .replace(/\b\w/g, (char) => char.toUpperCase()),
            value: strValue.charAt(0).toUpperCase() + strValue.slice(1),
          };
        });
      }

      return {
        name: parameters?.find((param) => param.param_id === key)?.name || key,
        value: value?.toString(),
      };
    });
};
