import { PromptVersion } from "@/types/apiGetters";
import { useCallback, useState } from "react";

const useTemplateSubmit = (
  saveType: "update" | "create",
  newTemplateName: string,
  selectedTemplateId: number,
  handleCreate: (
    name: string,
    data: {
      commitMessage?: string;
      evaluationId?: number;
    },
  ) => void,
  handleUpdate: (
    id: number,
    data: {
      commitMessage?: string;
      evaluationId?: number;
    },
  ) => Promise<PromptVersion>,
  isValid: () => boolean,
  setOpen: (open: boolean) => void,
) => {
  const [promptVersion, setPromptVersion] = useState<PromptVersion | null>();
  const submit = useCallback(
    (commitMessage?: string, evaluationId?: number) => {
      if (!isValid()) return;

      if (saveType === "create" && newTemplateName) {
        handleCreate(newTemplateName, { commitMessage, evaluationId });
      } else if (saveType === "update" && selectedTemplateId) {
        handleUpdate(selectedTemplateId, { commitMessage, evaluationId })
          .then((version) => {
            setPromptVersion(version);
          })
          .catch();
      }
      setOpen(false);
    },
    [
      saveType,
      newTemplateName,
      selectedTemplateId,
      handleCreate,
      handleUpdate,
      isValid,
      setOpen,
    ],
  );
  return [promptVersion, submit] as const;
};

export default useTemplateSubmit;
