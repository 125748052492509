import { useAuth } from "@/context/auth-context";
import { usePromptVersion } from "@/queries";
import { useParams } from "react-router-dom";

const usePromptVersionFromParams = () => {
  const { promptId, versionNumber } = useParams();
  const promptRegistryId = Number(promptId);
  const promptVersionNumber = Number(versionNumber);

  const auth = useAuth();
  const userToken = auth?.userToken!;

  const promptVersionQuery = usePromptVersion(
    userToken,
    promptRegistryId,
    promptVersionNumber,
  );

  return promptVersionQuery;
};

export default usePromptVersionFromParams;
