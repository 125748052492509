import { useQueryClient } from "@tanstack/react-query";

import { ENDPOINTS } from "@/api/application-api";
import { handleResponse } from "@/utils/errorResponseHandlers";
import { jsonAuthHeaders } from "@/utils/headers";
import { useMutation } from "@tanstack/react-query";

export const useSetDraftDatasetStateFromDataset = (userToken: string) => {
  const queryClient = useQueryClient();

  return useMutation(
    ({ datasetId }: { datasetId: number }) =>
      fetch(ENDPOINTS.draft_dataset_state_from_dataset, {
        method: "PATCH",
        headers: jsonAuthHeaders(userToken),
        body: JSON.stringify({
          dataset_id: datasetId,
        }),
      }).then(handleResponse),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([ENDPOINTS.datasets]);
        queryClient.invalidateQueries([ENDPOINTS.dataset_rows]);
      },
    },
  );
};
