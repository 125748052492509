import {
  ChatFunctionCall,
  Function_,
  FunctionsType,
  JSONSchema,
} from "@/types";
import { Dispatch, SetStateAction } from "react";

export enum ParametersEditorModeEnum {
  JSON = "json",
  INTERACTIVE = "interactive",
}

export type FunctionDialogProps = {
  onJSONChange?: (json: JSONSchema) => void;
  onSubmit?: (values: Function_) => void;
  functions: Function_[];
  setFunctions?: (functions: Function_[]) => void;
  functionCall?: ChatFunctionCall;
  setFunctionCall?: (functionCall: ChatFunctionCall) => void;
  functionsType?: FunctionsType;
  setFunctionsType?: (functionsType: FunctionsType) => void;
  jsonSchema?: JSONSchema | false;
};

export type FunctionDialogContextType = {
  functionCallValue?: string;
  isAddingFunction: boolean;
  setIsAddingFunction: (isAddingFunction: boolean) => void;
  editingFunction: Function_ | null;
  setEditingFunction: (function_: Function_ | null) => void;
  isEditing: boolean;
  parameterEditorMode: ParametersEditorModeEnum;
  setParameterEditorMode: Dispatch<SetStateAction<ParametersEditorModeEnum>>;
} & FunctionDialogProps;

export type FunctionFormValues = Function_ & {
  json: Function_;
};
