import { useCallback } from "react";
import VersionSelector, { VersionSelectorProps } from "../../VersionSelector";
import { IWorkflow } from "../WorkflowCanvas/workflow-store";

export interface WorkflowVersionSelectorProps extends VersionSelectorProps {
  workflow: IWorkflow;
  refreshVersions: () => void;
}

const WorkflowVersionSelector = (props: WorkflowVersionSelectorProps) => {
  const { workflow, refreshVersions, ...selectorProps } = props;

  const onRemoveReleaseLabel = useCallback(
    async (labelId: number) => {
      try {
        await workflow.removeReleaseLabel(labelId);
        refreshVersions();
      } catch (error) {
        console.error("Failed to delete release label:", error);
      }
    },
    [refreshVersions, workflow],
  );

  const onAddReleaseLabel = async (labelName: string) => {
    const response = await workflow.addReleaseLabel(
      labelName,
      workflow.version_id!,
    );
    if (typeof response === "function")
      return () => {
        response(refreshVersions);
      };
    else refreshVersions();
  };
  return (
    <VersionSelector
      isWorkflow
      {...selectorProps}
      onAddReleaseLabel={onAddReleaseLabel}
      onRemoveReleaseLabel={onRemoveReleaseLabel}
    />
  );
};

export default WorkflowVersionSelector;
