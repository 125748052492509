import { ENDPOINTS } from "@/api/application-api";
import { Variable } from "@/components/InputVariableConfigurator/types";
import { Message } from "@/components/Playground2/types";
import { useAuth } from "@/context/auth-context";
import { PromptBlueprint } from "@/types";
import { authHeader } from "@/utils/headers";
import { useQuery } from "@tanstack/react-query";

export interface RunGroup {
  id: string;
  created_at: string;
  updated_at: string;
  playground_session_id: string;
  provider: string;
  model: string;
  shared_prompt_blueprint: PromptBlueprint;

  individual_run_requests: Array<{
    id: string;
    user_id: number;
    model_provider: string;
    prompt_blueprint: PromptBlueprint;
    input_variables: Variable[];
    is_chat: boolean;
    request_id: number;
    status: string;
    status_message: null;
    message: Message | null;
    created_at: string;
    finished_at: null;
    workspace_id: number;
    run_group_id: string;
    run_group_position: number;
    price: number;
  }>;
}

interface GetRunGroupsResponse {
  success?: boolean;
  run_groups?: RunGroup[];
  has_next?: boolean;
  has_prev?: boolean;
  total?: number;
}

export const useGetRunGroups = (
  workspaceId: number,
  sessionId: string,
  options?: { page?: number; perPage?: number },
  forceRefresh?: unknown,
) => {
  const queryKey = [
    "runGroups",
    workspaceId,
    sessionId,
    options?.page,
    options?.perPage,
    forceRefresh,
  ];

  const userToken = useAuth()?.userToken!;

  return useQuery<GetRunGroupsResponse>({
    queryKey,
    queryFn: async () => {
      const params = new URLSearchParams({
        page: (options?.page || 1).toString(),
        per_page: (options?.perPage || 10).toString(),
        playground_session_id: sessionId,
      });

      const response = await fetch(
        `${ENDPOINTS.run_groups(workspaceId)}?${params.toString()}`,
        {
          method: "GET",
          headers: authHeader(userToken),
        },
      );

      if (!response.ok) {
        throw new Error("Failed to fetch run groups");
      }

      return response.json();
    },
    keepPreviousData: true,
  });
};
