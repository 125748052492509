import { observer } from "mobx-react-lite";
import { useCallback, useEffect } from "react";

import { useParameters } from "../store";
import { getDefaultModel } from "../utils";

import { EditParameters } from "./EditParameters";

export const ParametersContent = observer(
  ({ shouldShowClearButton = true }: { shouldShowClearButton?: boolean }) => {
    const store = useParameters();

    const setDefaultParameters = useCallback(() => {
      const defaultModel = getDefaultModel(
        store.apiModels || [],
        store.isPromptChat,
      );

      store.updateModel(defaultModel);
    }, [store]);

    useEffect(() => {
      if (store.promptData) {
        store.updateExternalData(store.promptData);
      }
    }, [store.promptData]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
      if (!store.modelName && !store.provider) {
        setDefaultParameters();
      }
    }, [setDefaultParameters, store.modelName, store.provider]);

    return <EditParameters shouldShowClearButton={shouldShowClearButton} />;
  },
);
