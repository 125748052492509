import LoadingSpinner from "@/components/LoadingSpinner";
import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import { useCallback, useEffect, useState } from "react";
import { useBlocker, useNavigate } from "react-router-dom";
import { ICON_VARIANTS } from "./constants";
import {
  ConfirmationModalProps,
  ConfirmationModalVariant,
  Location,
} from "./types";
import { shouldBlockTransition } from "./utils";

// Base modal component without navigation blocking
const BaseConfirmationModal = ({
  title,
  isNavigationBlocked = false,
  leaveButtonText,
  saveButtonText,
  handleOnConfirm,
  handleLeave,
  handleClose,
  setIsOpen,
  isOpen,
  isLoading,
  description,
  headerVariant = ConfirmationModalVariant.default,
  saveButtonVariant = "default",
  exitButtonVariant = "destructiveOutline",
  MarkdownDescription,
  children,
  nextLocation = null,
  shouldIgnoreBlocker = false,
  setShouldIgnoreBlocker = () => {},
}: Omit<ConfirmationModalProps, "useBlocker"> & {
  nextLocation?: Location | null;
  shouldIgnoreBlocker?: boolean;
  setShouldIgnoreBlocker?: (value: boolean) => void;
}) => {
  const navigate = useNavigate();

  // Handle tab/browser close events
  useEffect(() => {
    const handleBeforeUnload = (e: BeforeUnloadEvent) => {
      if (isNavigationBlocked && !shouldIgnoreBlocker) {
        e.preventDefault();
        // Standard browser dialog
        e.returnValue = "";
        return "";
      }
      return undefined;
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [isNavigationBlocked, shouldIgnoreBlocker]);

  useEffect(() => {
    if (shouldIgnoreBlocker && nextLocation) {
      navigate(nextLocation.pathname);
    }
  }, [shouldIgnoreBlocker, nextLocation, navigate]);

  const onLeave = useCallback(() => {
    handleLeave?.();
    setIsOpen(false);
    setShouldIgnoreBlocker(true);
  }, [handleLeave, setIsOpen, setShouldIgnoreBlocker]);

  const onConfirm = useCallback(() => {
    if (handleOnConfirm) {
      handleOnConfirm(nextLocation?.pathname);
      return;
    }

    setIsOpen(false);
    setShouldIgnoreBlocker(true);
  }, [
    handleOnConfirm,
    nextLocation?.pathname,
    setIsOpen,
    setShouldIgnoreBlocker,
  ]);

  const onClose = useCallback(() => {
    handleClose?.();
    setIsOpen(false);
  }, [handleClose, setIsOpen]);

  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent className="flex flex-col gap-3">
        <DialogHeader>
          <DialogTitle className="flex items-center gap-3">
            {ICON_VARIANTS[headerVariant]}
            <span>{title}</span>
          </DialogTitle>
        </DialogHeader>
        <div className="mb-2 flex flex-col gap-y-2">
          <DialogDescription>
            {description || MarkdownDescription}
          </DialogDescription>
          {children}
        </div>
        <DialogFooter className="flex w-full">
          <Button variant={exitButtonVariant} onClick={onLeave}>
            {leaveButtonText}
          </Button>
          <Button
            disabled={isLoading}
            variant={saveButtonVariant}
            onClick={onConfirm}
          >
            {isLoading ? <LoadingSpinner size={4} /> : saveButtonText}
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

// Modal with blocker functionality
const BlockerConfirmationModal = (
  props: Omit<ConfirmationModalProps, "useBlocker">,
) => {
  const [shouldIgnoreBlocker, setShouldIgnoreBlocker] = useState(false);
  const [nextLocation, setNextLocation] = useState<Location | null>(null);
  const { isNavigationBlocked = false, setIsOpen } = props;

  useBlocker((transition) => {
    const isBlockerEnforced = isNavigationBlocked && !shouldIgnoreBlocker;
    if (
      transition.currentLocation.pathname === transition.nextLocation.pathname
    )
      return false;
    if (shouldBlockTransition(transition, isBlockerEnforced)) {
      setNextLocation(transition.nextLocation);
      setIsOpen(true);
      return true;
    } else if (isNavigationBlocked && !shouldIgnoreBlocker) {
      setIsOpen(false);
      setShouldIgnoreBlocker(true);
      setNextLocation(transition.nextLocation);
    }
    return false;
  });

  return (
    <BaseConfirmationModal
      {...props}
      nextLocation={nextLocation}
      shouldIgnoreBlocker={shouldIgnoreBlocker}
      setShouldIgnoreBlocker={setShouldIgnoreBlocker}
    />
  );
};

// Main exported component that chooses which implementation to use
export const ConfirmationModal = (props: ConfirmationModalProps) => {
  const { useBlocker: shouldUseBlocker = true } = props;

  // Decide which implementation to render based on shouldUseBlocker prop
  if (shouldUseBlocker) {
    return <BlockerConfirmationModal {...props} />;
  }

  return <BaseConfirmationModal {...props} />;
};

export * from "./types";
