import logoLong from "@/assets/logo/promptlayer-long.png";
import SearchBar from "@/components/SearchBar";
import FineTuneDatasets from "@/components/SidebarSearchLayout/components/FineTuneDatasets";
import RequestLogs from "@/components/SidebarSearchLayout/components/RequestLogs";
import Settings from "@/components/SidebarSearchLayout/components/Settings";
import Spans from "@/components/SidebarSearchLayout/components/Spans";
import { useIsCurrentPageNumber } from "@/components/SidebarSearchLayout/hooks/useIsCurrentPageNumber";
import { useKeepRelevantParams } from "@/components/SidebarSearchLayout/hooks/useKeepRelevantParams";
import { NAVIGATION } from "@/constants";
import { useUser } from "@/context/user-context";
import { classNames } from "@/utils/strings";
import { Dialog, Transition } from "@headlessui/react";
import { XIcon } from "@heroicons/react/outline";
import {
  Dispatch,
  Fragment,
  ReactNode,
  RefObject,
  SetStateAction,
  useRef,
} from "react";
import { Link, useNavigate } from "react-router-dom";
import { Request } from "@/types/requests";
import { UseInfiniteQueryResult } from "@tanstack/react-query";

const Sidebar = ({
  sidebarCollapsed,
  sidebarOpen,
  setSidebarOpen,
  requestsOn,
  requests,
  requestLogs,
  Switch,
}: {
  sidebarCollapsed: boolean;
  sidebarOpen: boolean;
  setSidebarOpen: Dispatch<SetStateAction<boolean>>;
  requestsOn: boolean;
  requests: Request[];
  requestLogs: UseInfiniteQueryResult<any, unknown>;
  Switch: ReactNode;
}) => {
  const navigate = useNavigate();
  const userContext = useUser();
  const keepRelevantParams = useKeepRelevantParams();
  const isCurrentPageName = useIsCurrentPageNumber();

  const inputRef = useRef<HTMLInputElement>(null);

  const renderDesktopSidebar = () => {
    return (
      <div
        className={`hidden transition-all duration-100 ease-in-out md:fixed md:inset-y-0 md:flex md:flex-col ${
          sidebarCollapsed ? "w-20" : "w-80"
        }`}
      >
        <div className={` flex flex-grow flex-col overflow-y-auto pt-10`}>
          {!sidebarCollapsed ? (
            <SideBarLayout
              inputRef={inputRef}
              requestsOn={requestsOn}
              Switch={Switch}
            >
              {requestsOn ? (
                <RequestLogs
                  inputRef={inputRef}
                  requestsOn={requestsOn}
                  requests={requests}
                  requestLogs={requestLogs}
                />
              ) : (
                <Spans inputRef={inputRef} />
              )}
            </SideBarLayout>
          ) : (
            renderSidebarCollapsed()
          )}
        </div>
      </div>
    );
  };

  const renderMobileSidebar = () => {
    return (
      <Transition.Root show={sidebarOpen} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-40 md:hidden"
          onClose={setSidebarOpen}
        >
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-600 bg-opacity-75" />
          </Transition.Child>

          <div className="fixed inset-0 z-40 flex">
            <Transition.Child
              as={Fragment}
              enter="transition ease-in-out duration-300 transform"
              enterFrom="-translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="translate-x-0"
              leaveTo="-translate-x-full"
            >
              <div className="relative flex w-full max-w-xs flex-1 flex-col bg-white pb-4 pt-5">
                <Transition.Child
                  as={Fragment}
                  enter="ease-in-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in-out duration-300"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="absolute right-0 top-0 -mr-12 pt-2">
                    <button
                      type="button"
                      className="ml-1 flex h-10 w-10 items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                      onClick={() => setSidebarOpen(false)}
                    >
                      <span className="sr-only">Close sidebar</span>
                      <XIcon
                        className="h-6 w-6 text-white"
                        aria-hidden="true"
                      />
                    </button>
                  </div>
                </Transition.Child>
                <div className="flex flex-shrink-0 items-center px-4">
                  <div className="flex-1 text-xl font-bold tracking-wide">
                    <span
                      className="cursor-pointer hover:text-gray-600"
                      onClick={() => navigate("/")}
                    >
                      <img src={logoLong} alt="PromptLayer Logo" width={180} />
                    </span>
                  </div>
                  {/* Settings dropdown */}
                  <Settings />
                </div>
                <div className="bg-blue flex w-full py-3">
                  <nav
                    className="mx-auto w-full items-center px-1 text-center"
                    aria-label="Global"
                  >
                    <div className="flex flex-col space-x-1 ">
                      {/* Mobile version */}
                      {NAVIGATION.map((item) => (
                        <Link
                          key={item.name}
                          to={`/workspace/${userContext!.activeWorkspace?.id}/${
                            item.href
                          }/?${keepRelevantParams()}`}
                        >
                          <button
                            className={classNames(
                              isCurrentPageName(item)
                                ? "bg-gray-200 font-medium text-gray-900 hover:text-gray-900"
                                : "font-normal text-gray-900 hover:bg-gray-50 hover:text-gray-900",
                              "inline-flex w-full cursor-pointer items-center rounded-md px-3 py-2 text-xs",
                            )}
                          >
                            {item.name}
                          </button>
                        </Link>
                      ))}
                      <hr className="mt-2" />
                      {/* <FineTuneDatasets mobile={true} /> */}
                    </div>
                  </nav>
                </div>
              </div>
            </Transition.Child>
            <div className="w-14 flex-shrink-0">
              {/* Dummy element to force sidebar to shrink to fit close icon */}
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    );
  };

  const renderSidebarCollapsed = () => {
    return (
      <div
        className={`mt-5 h-full flex-col overflow-y-hidden ${
          sidebarCollapsed ? "w-20" : "hidden"
        }`}
      >
        <SearchBar
          inputRef={inputRef}
          collapsed={true}
          requestsOn={requestsOn}
        />
      </div>
    );
  };
  return (
    <>
      {renderMobileSidebar()}
      {renderDesktopSidebar()}
    </>
  );
};

const SideBarLayout = ({
  children,
  inputRef,
  requestsOn,
  Switch,
}: {
  children: ReactNode;
  inputRef: RefObject<HTMLInputElement>;
  requestsOn: boolean;
  Switch: ReactNode;
}) => {
  return (
    <div className="mt-3 flex flex-col overflow-y-hidden ">
      <FineTuneDatasets />
      <div className={`flex flex-grow flex-col overflow-y-hidden`}>
        <SearchBar inputRef={inputRef} requestsOn={requestsOn} />
        {Switch}
        {children}
      </div>
    </div>
  );
};
export default Sidebar;
