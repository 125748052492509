import * as SliderPrimative from "@radix-ui/react-slider";

import { Input } from "../ui/input";
import { SliderProps } from "./types";

export const Slider = ({
  onValueChange,
  value,
  isDisabled,
  hasInput,
  min,
  max,
  step,
  defaultValue,
  readOnly,
}: SliderProps) => {
  const onBlur = () => {
    if (value === "") {
      onValueChange(defaultValue?.toString() ?? "");
    }
  };

  const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value
      .replace(/[^0-9.+-]/g, "")
      .replace(/^0{2,}/, "0.")
      .replace(/^0(\d)/, "$1")
      .replace(/([+-])[+-]+/g, "$1");

    onValueChange(value);
  };

  const handleSliderChange = (value: number[]) => {
    onValueChange(value[0].toString());
  };

  return (
    <div className="flex items-center gap-2">
      <SliderPrimative.Root
        className="relative flex h-[20px] w-full items-center"
        defaultValue={[parseFloat(defaultValue?.toString() || "50")]}
        max={max || 100}
        step={step || 0.01}
        min={min || 0}
        aria-label="Volume"
        value={[parseFloat(value) || 0]}
        onValueChange={handleSliderChange}
        disabled={isDisabled || readOnly}
      >
        <SliderPrimative.Track className="relative h-[10px] flex-1 rounded-full bg-gray-200">
          <SliderPrimative.Range
            className={`absolute ${
              isDisabled ? "bg-gray-200" : "bg-blue-500"
            } h-[10px] rounded-l-full rounded-r-[500rem]`}
          />
        </SliderPrimative.Track>
        <SliderPrimative.Thumb className="block h-[20px] w-[20px] rounded-full bg-white drop-shadow-md" />
      </SliderPrimative.Root>
      {hasInput ? (
        <Input
          value={value.toString()}
          onBlur={onBlur}
          disabled={isDisabled}
          readOnly={readOnly}
          className="h-6 w-[40px] rounded-md border border-gray-300 px-1.5 py-1 text-xs"
          onChange={onInputChange}
        />
      ) : null}
    </div>
  );
};
