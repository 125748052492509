import { useFunctionDialogContext } from "@/components/FunctionsModal";
import { Function_ } from "@/types";
import {
  DragDropContext,
  Draggable,
  DraggableProvided,
  Droppable,
  DroppableProvided,
  DropResult,
} from "@hello-pangea/dnd";
import { MenuIcon, PencilIcon, XIcon } from "@heroicons/react/outline";
import { ReactNode } from "react";
import { FunctionDefinitionBox } from "./FunctionDefinitionBox";

const FunctionElement = ({
  _function,
  draggableIcon,
  onNavigateToFunction,
}: {
  _function: Function_;
  draggableIcon: ReactNode;
  onNavigateToFunction: () => void;
}) => {
  const { isEditing, setEditingFunction } = useFunctionDialogContext();
  return (
    <div className="flex items-center gap-x-4 pb-2">
      {isEditing ? draggableIcon : null}
      <div className="flex-1">
        <FunctionDefinitionBox
          functionName={_function.name}
          functionDeclaration={_function}
        />
      </div>
      {isEditing ? (
        <>
          <button
            onClick={() => setEditingFunction(_function)}
            className="text-gray-600 hover:text-gray-500"
          >
            <PencilIcon className="h-4 w-4" />
          </button>
          <button
            onClick={onNavigateToFunction}
            className="text-red-400 hover:text-red-500"
          >
            <XIcon className="h-4 w-4" />
          </button>
        </>
      ) : null}
    </div>
  );
};

export const FunctionsList = () => {
  const { functions, setFunctions } = useFunctionDialogContext();
  const countFunctions = functions?.length || 0;

  const onDragEnd = (result: DropResult) => {
    if (!result.destination) {
      return;
    }
    const items = Array.from(functions);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    if (setFunctions !== undefined) {
      // Only undefined when `isEditing` is false
      setFunctions(items);
    }
  };

  if (countFunctions === 0) {
    return (
      <div className="pb-3 pt-1 text-sm italic text-gray-500">
        No functions defined
      </div>
    );
  } else {
    return (
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="functions">
          {(provided: DroppableProvided) => (
            <div
              {...provided.droppableProps}
              ref={provided.innerRef}
              className="py-2"
            >
              {(functions || []).map((_function, index) => (
                <Draggable
                  key={`${_function.name}-${index}`}
                  draggableId={_function.name}
                  index={index}
                >
                  {(provided: DraggableProvided) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                    >
                      <FunctionElement
                        _function={_function}
                        onNavigateToFunction={() =>
                          setFunctions!(functions.filter((_, i) => i !== index))
                        }
                        draggableIcon={
                          <div {...provided.dragHandleProps}>
                            <MenuIcon className="h-4 w-4 cursor-pointer text-gray-400" />
                          </div>
                        }
                      />
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    );
  }
};
