import { Breadcrumbs as BaseBreadcrumbs } from "@/components/Breadcrumbs";
import { Playground } from "@/components/Playground2";

import { ResourceHeader } from "@/components/ResourceHeader";
import { Button } from "@/components/ui/button";
import { useAuth } from "@/context/auth-context";
import { useUser } from "@/context/user-context";
import { usePromptRegistry, usePromptVersions } from "@/queries";
import { PromptBlueprint } from "@/types";
import usePromptVersionFromParams from "./hooks/usePromptVersionFromParams";

const Breadcrumbs = ({
  promptName,
  versionNumber,
}: {
  promptName?: string;
  versionNumber?: number;
}) => {
  const navElements = [
    "Registry",
    "Prompts",
    promptName || "Prompt Name",
    `${versionNumber || ""}`,
    "Edit",
  ];
  const user = useUser();
  const workspaceId = user?.activeWorkspaceId!;
  const navigateUrl = `/workspace/${workspaceId}/home/`;
  return <BaseBreadcrumbs items={navElements} navigateUrl={navigateUrl} />;
};

const EditPromptPage = () => {
  const { data: version, isLoading: isLoadingVersion } =
    usePromptVersionFromParams();
  const userToken = useAuth()?.userToken!;
  const workspaceId = useUser()?.activeWorkspaceId!;
  const { data: promptRegistry, isLoading: isLoadingRegistry } =
    usePromptRegistry(userToken, version?.prompt_id);

  const { data: promptVersionsQuery, isLoading: isLoadingPromptVersions } =
    usePromptVersions(userToken, {
      promptRegistryId: promptRegistry?.id,
      workspaceId,
    });

  const totalVersions = promptVersionsQuery?.pages[0].total || 0;
  const promptName = promptRegistry?.prompt_name;
  const isLoading =
    isLoadingVersion || isLoadingRegistry || isLoadingPromptVersions;

  if (!version) return null;

  return (
    <div className="flex h-full flex-col p-1 pb-4">
      <div className="mb-3">
        <Breadcrumbs promptName={promptName} versionNumber={version.number} />
      </div>
      <ResourceHeader
        title={promptName!}
        onTitleEdit={function ({
          onSuccess,
          onError,
          newTitle,
        }: {
          onSuccess?: (newTitle?: string) => void;
          onError?: () => void;
          newTitle: string;
        }): void {
          throw new Error("Function not implemented.");
        }}
        id={version.id}
        createdAt={version.created_at}
        versionInfo={{
          current: version.number,
          total: totalVersions,
        }}
        format={
          version.prompt_template.type === "chat"
            ? version.prompt_template.messages[0].template_format
            : version.prompt_template.template_format
        }
        tags={promptRegistry?.tags || []}
        onTagsEdit={() => {}}
        actions={[<Button>Test</Button>]}
        showVersions={false}
        onToggleVersions={() => {}}
        isLoading={isLoading}
      />
      <Playground
        blueprint={version as PromptBlueprint}
        selectedTemplateVersion={version}
      />
    </div>
  );
};

export default EditPromptPage;
