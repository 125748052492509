import { useEffect } from "react";
import { usePlayground } from "../../playground-store";
import usePlaygroundSessionFromId from "@/queries/playground/usePlaygroundSessionFromId";
import { usePromptVersion } from "@/queries";
import { useAuth } from "@/context/auth-context";
import { useSearchParams } from "react-router-dom";
import { runInAction } from "mobx";

const useHydrateSession = () => {
  const playground = usePlayground();

  const { data, isError, error } = usePlaygroundSessionFromId(
    playground.workspace_id,
    playground.instanceId,
    playground.isPendingSessionInitialization,
  );

  const [searchParams] = useSearchParams();
  const prompt_id = searchParams.get("prompt_id");
  const prompt_version = searchParams.get("prompt_version");

  const userToken = useAuth()?.userToken!;

  const { data: promptVersionData } = usePromptVersion(
    userToken,
    prompt_id !== null ? parseInt(prompt_id) : null,
    prompt_version !== null ? parseInt(prompt_version) : null,
  );

  useEffect(() => {
    if (promptVersionData && !playground.selectedTemplateVersion)
      runInAction(() =>
        playground.setSelectedTemplateVersion(promptVersionData),
      );
  }, [promptVersionData, playground]);

  useEffect(() => {
    if (
      (isError || (data && !data?.success)) &&
      playground.isPendingSessionInitialization
    )
      playground.setIsPendingSessionInitialization(false);
  }, [isError, playground, error, data?.success, data]);

  useEffect(() => {
    if (data && playground.isPendingSessionInitialization) {
      runInAction(() => playground.initSession(data.playground_session));
    }
  }, [data, playground]);
};

export default useHydrateSession;
