import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
} from "@/components/ui/select";

import { ProviderDropdownProps } from "./types";
import { mapProviderToDisplayName } from "./utils";

export const ProviderDropdown = ({
  selectedProvider,
  handleSelectProvider,
  providers,
}: ProviderDropdownProps) => (
  <Select value={selectedProvider} onValueChange={handleSelectProvider}>
    <SelectTrigger className="w-full justify-between border-gray-300 bg-white py-2 pl-4 pr-2 text-sm hover:border-gray-400 hover:bg-gray-50">
      {mapProviderToDisplayName(selectedProvider) || (
        <span className="text-gray-400">Select provider...</span>
      )}
    </SelectTrigger>
    <SelectContent>
      {providers?.map((provider) => (
        <SelectItem key={provider.provider_name} value={provider.provider_name}>
          {mapProviderToDisplayName(provider.provider_name)}
        </SelectItem>
      ))}
    </SelectContent>
  </Select>
);
