import { cn } from "@/lib/utils";
import React from "react";

type BadgeProps = React.HTMLAttributes<HTMLDivElement> & {
  variant?: "default" | "positive" | "negative";
  hasPolygon?: boolean;
};

const variants = {
  default: "bg-gray-100 text-gray-800 hover:bg-gray-200",
  positive: "bg-green-100 text-green-800 hover:bg-green-200",
  negative: "bg-red-100 text-red-800 hover:bg-red-200",
};

const Badge = React.forwardRef<HTMLDivElement, BadgeProps>(
  ({ className, variant = "default", hasPolygon = true, ...props }, ref) => {
    return (
      <div
        ref={ref}
        className={cn(
          "relative inline-block px-2 py-1 text-sm font-medium transition-all duration-200 ease-in-out",
          variants[variant],
          className,
        )}
        style={{
          clipPath: hasPolygon
            ? "polygon(0% 0%, 90% 0%, 100% 50%, 90% 100%, 0% 100%)"
            : undefined,
        }}
        {...props}
      />
    );
  },
);
Badge.displayName = "Badge";

export default Badge;
