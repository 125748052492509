import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
} from "@/components/ui/select";
import { observer } from "mobx-react-lite";
import { usePlayground } from "../../playground-store";
import { TEMPLATE_FORMATS } from "../../types";

const FormatSelector = ({ readonly }: { readonly?: boolean }) => {
  const playground = usePlayground();
  return (
    <Select
      value={playground.template_format}
      disabled={readonly}
      onValueChange={playground.setTemplateFormat}
    >
      <SelectTrigger className="truncate whitespace-nowrap">
        {playground.template_format}
      </SelectTrigger>
      <SelectContent>
        {TEMPLATE_FORMATS.map((format) => {
          return (
            <SelectItem key={format} value={format}>
              {format}
            </SelectItem>
          );
        })}
      </SelectContent>
    </Select>
  );
};

export default observer(FormatSelector);
