import { useState } from "react";

const useFullscreen = () => {
  const [isFullscreen, setIsFullscreen] = useState(false);
  return {
    isFullscreen,
    setIsFullscreen,
    open: () => setIsFullscreen(true),
    close: () => setIsFullscreen(false),
  };
};

export default useFullscreen;
