import { Label } from "@/components/ui/label";
import { ProviderDropdown } from "@/components/ProviderDropdown";
import { useParameters } from "../store";
import { observer } from "mobx-react-lite";

export const LLMProvider = observer(() => {
  const store = useParameters();

  return (
    <div className="flex-0 mb-4 md:mb-0">
      <div className="mb-4">
        <Label
          htmlFor="version-select"
          className="mb-2 block text-sm font-medium text-gray-700"
        >
          LLM Provider
        </Label>
        <div className="relative">
          <ProviderDropdown
            selectedProvider={store.provider || ""}
            handleSelectProvider={(newProvider) => {
              store.updateProvider(newProvider);
            }}
            providers={store.apiModels || []}
          />
        </div>
      </div>
    </div>
  );
});
