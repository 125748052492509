import { IndividualRun } from "@/types/metadata";

const getFinalMessage = (run: IndividualRun) => {
  return (run.request?.prompt_version?.prompt_template as any)?.messages?.pop();
};

const parseTypeOptimistically = (value: any) => {
  try {
    const jsonValue = JSON.parse(value);
    return jsonValue;
  } catch (e) {
    return value;
  }
};

export { getFinalMessage, parseTypeOptimistically };
