import { useAuth } from "@/context/auth-context";
import { useUpdateRequestScore } from "@/queries";
import { Menu } from "@headlessui/react";
import DropdownButton from "./DropdownButton";
import LoadingSpinner from "./LoadingSpinner";
import { ScoreRadioGroup } from "./ScoreRadioGroup";
import { Slider } from "./Slider";

export const ScoreDropdown = ({
  score,
  scores,
  setScore,
  requestId,
  requestScore,
  setResetScore,
  resetScore,
}: {
  score: number[];
  scores: Array<Record<string, number>>;
  setScore: (score: number[]) => void;
  requestId: string;
  requestScore: number[] | null;
  setResetScore: (reset: boolean) => void;
  resetScore: boolean;
}) => {
  const userToken = useAuth()?.userToken || "";
  const updateRequestScore = useUpdateRequestScore();
  const loading = updateRequestScore.isLoading;

  const currentScore =
    score[0] && score[0] <= 100 ? score[0] : score[0] === 0 ? 0 : 50;

  const handleSubmit = () => {
    updateRequestScore.mutate({
      score: resetScore ? score[0] : currentScore,
      scoreName: "default",
      requestId,
      resetScore,
      userToken,
    });
  };

  return (
    <h1 className="rounded bg-blue-100  p-2 py-1 text-sm">
      <DropdownButton
        dropdownItems={[]}
        width="w-[22rem]"
        dropdownContent={
          <div className="z-50 flex flex-col space-y-2 px-4 py-3">
            <div>
              <ScoreRadioGroup
                setResetScore={setResetScore}
                resetScore={resetScore}
              />
              <div className="flex flex-row items-center justify-between">
                <Slider
                  onValueChange={(value) => setScore([parseInt(value)])}
                  value={currentScore.toString()}
                  isDisabled={resetScore}
                />
                <input
                  type="number"
                  name="score"
                  min={1}
                  max={100}
                  value={currentScore}
                  onChange={(e) => {
                    e.target.value.length < 4 && e.target.value.length !== 0
                      ? setScore([parseInt(e.target.value)])
                      : e.target.value.length === 0
                      ? setScore([0])
                      : setScore([100]);
                  }}
                  className={`w-[25%] rounded border border-gray-200 text-center focus:border-gray-200 focus:ring-0 ${
                    resetScore ? "text-gray-300" : ""
                  } `}
                />
              </div>
            </div>
            <Menu.Button
              className="mt-6 w-full rounded bg-blue-500 p-2 text-white hover:bg-blue-400 disabled:cursor-not-allowed disabled:bg-gray-300"
              onClick={handleSubmit}
              disabled={loading}
            >
              {loading ? <LoadingSpinner size={5} /> : "Apply"}
            </Menu.Button>
            {scores &&
              scores.length > 0 &&
              Object.entries(scores).map(([key, otherScore]) => (
                <div key={key} className="mt-2 rounded bg-gray-100 p-2">
                  {otherScore &&
                    Object.entries(otherScore).map(([name, value], index) => (
                      <div
                        className="flex flex-row justify-between text-sm text-gray-600"
                        key={index}
                      >
                        <p>{`${name}`}</p>
                        <p>{`${JSON.stringify(value)}`}</p>
                      </div>
                    ))}
                </div>
              ))}
          </div>
        }
      >
        <div className="flex flex-row items-center justify-around">
          <p className="text-blue-600">
            <>
              {loading ? (
                <LoadingSpinner size={5} />
              ) : scores.length === 0 && scores != null ? (
                `Score ${requestScore ?? "N/A"}`
              ) : (
                "Scores"
              )}
            </>
          </p>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={2.5}
            stroke="currentColor"
            className="ml-1 h-3 w-3 text-blue-600"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M19.5 8.25l-7.5 7.5-7.5-7.5"
            />
          </svg>
        </div>
      </DropdownButton>
    </h1>
  );
};
