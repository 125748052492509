export const useIsCurrentPageNumber = () => {
  const isCurrentPageNumber = (navigationItem: {
    name: string;
    href: string;
    altHrefs?: string[];
  }) => {
    // Get the current path from the URL
    const path = window.location.pathname;

    // Split the path into segments
    const pathSegments = path.split("/").filter((segment) => segment);

    // For URLs like the ones specified, we need to check if the main section (e.g., 'prompt') matches
    // and not just if the string appears anywhere in the path

    // Check if the href is a direct match for the second segment after workspace ID
    // This assumes URLs like /workspace/[id]/[href]/...
    if (pathSegments.length >= 3) {
      const relevantSegment = pathSegments[2]; // The segment after workspace ID

      // Direct match with the href
      if (relevantSegment === navigationItem.href) {
        return true;
      }

      // Check alternative hrefs
      if (
        navigationItem.altHrefs &&
        navigationItem.altHrefs.some((alt) => alt === relevantSegment)
      ) {
        return true;
      }
    }

    return false;
  };

  return isCurrentPageNumber;
};
