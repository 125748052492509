import { ModelParameters } from "@/components/ModelParameters";
import DetectedInputVariables from "./DetectedInputVariables";
import { usePlayground } from "../../playground-store";
import { MetadataDialog } from "@/components/metadata-dialog";
import { toJS } from "mobx";
import ThreadsOverlayManager from "@/components/ThreadsOverlayManager";
import { useParams } from "react-router-dom";
import MessagesSettings from "../MessagesHeader/MessagesSettings";
import { ParametersPreview } from "@/components/ModelParameters/AdvancedParameters/ParametersPreview";
import { PromptBlueprint, PromptVersion } from "@/types";
import { observer } from "mobx-react-lite";
import ReactDOM from "react-dom";

const MessagesFooter = observer(() => {
  const playground = usePlayground();
  const { promptId, versionId } = useParams();

  return (
    <div className="mx-auto flex w-full flex-row justify-between gap-x-2 border-t pt-2">
      <div className="flex w-full flex-row items-center gap-2 overflow-x-auto ">
        <DetectedInputVariables />
      </div>
      <div className="ml-auto flex  flex-row items-center gap-2 ">
        {playground.metadata && (
          <MetadataDialog
            setMetadata={
              playground.readonly
                ? undefined
                : (metadata) => {
                    playground.setMetadata(metadata);
                  }
            }
            metadata={toJS(playground.metadata)}
          />
        )}
        {playground.readonly ? (
          <ParametersPreview
            promptData={playground.blueprint as Partial<PromptVersion>}
          />
        ) : (
          <ModelParameters
            className="w-full justify-between"
            isPromptChat={playground.isChat}
            promptData={playground.blueprint}
            onUpdate={(promptData) =>
              playground.applyPromptBlueprintToState(
                promptData as Partial<PromptBlueprint>,
              )
            }
          />
        )}
        {playground.readonly &&
          document.querySelector("#template-header") &&
          ReactDOM.createPortal(
            <MessagesSettings />,
            document.querySelector("#template-header")!,
          )}
      </div>
      {promptId && versionId && playground.readonly && (
        <ThreadsOverlayManager
          promptRegistryId={Number(promptId)}
          promptVersionNumber={Number(versionId)}
        />
      )}
    </div>
  );
});

export default MessagesFooter;
