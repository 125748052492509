import { useAuth } from "@/context/auth-context";
import { useUser } from "@/context/user-context";
import { usePrompt, usePromptVersion } from "@/queries";
import { PromptBlueprint } from "@/types";
import { PromptVersion } from "@/types/apiGetters";
import {
  OneLevelNestedPaths,
  getPromptDataForDiffView,
} from "@/utils/getPromptDataForDiffView";
import ReactDiffViewer, { DiffMethod } from "react-diff-viewer";
import { useParams } from "react-router-dom";

interface DiffViewerProps {
  blueprint: PromptBlueprint;
  promptName?: string;
}

interface RawDiffViewerProps {
  oldBlueprint: PromptBlueprint | PromptVersion;
  newBlueprint: PromptBlueprint | PromptVersion;
}

const RawBlueprintDiffViewer = ({
  oldBlueprint,
  newBlueprint,
}: RawDiffViewerProps) => {
  const fieldsToShow: OneLevelNestedPaths<PromptVersion | PromptBlueprint>[] = [
    "inference_client_name",
    "provider_base_url_name",
    "metadata.model",
    "prompt_template.functions",
    "prompt_template.tools",
    "prompt_template",
  ];

  const oldValue = getPromptDataForDiffView(oldBlueprint, fieldsToShow);
  const newValue = getPromptDataForDiffView(newBlueprint, fieldsToShow);

  return (
    <div className="max-h-96 overflow-y-scroll break-all px-3 py-5">
      <ReactDiffViewer
        oldValue={oldValue}
        newValue={newValue}
        splitView={false}
        hideLineNumbers={true}
        showDiffOnly={true}
        extraLinesSurroundingDiff={2}
        compareMethod={DiffMethod.SENTENCES}
      />
    </div>
  );
};

const BlueprintDiffViewer = ({
  blueprint: promptVersionData,
  promptName,
}: DiffViewerProps) => {
  const params = useParams();
  const promptId = params.promptId;
  const versionNumber = params.versionId ?? params.versionNumber;
  const promptRegistryId = Number(promptId);
  const promptVersionNumber = Number(versionNumber);
  const auth = useAuth();
  const userToken = auth?.userToken!;
  const activeWorkspaceId = useUser()?.activeWorkspaceId;
  const promptVersionQuery = usePromptVersion(
    userToken,
    promptRegistryId,
    promptVersionNumber,
  );

  const promptQuery = usePrompt(userToken, {
    workspace_id: activeWorkspaceId!,
    prompt_name: promptName!,
  });

  const promptVersion = promptVersionQuery.data || promptQuery.data;
  if (!promptVersion) return null;

  return (
    <RawBlueprintDiffViewer
      oldBlueprint={promptVersion}
      newBlueprint={promptVersionData}
    />
  );
};

export default BlueprintDiffViewer;
export { RawBlueprintDiffViewer };
