import InvalidJsonError from "@/components/InvalidJsonError";
import { Button } from "@/components/ui/button";
import { Label } from "@/components/ui/label";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import { canParseJson } from "@/utils/jsonParsing";
import { arrayOfDictsToDict, dictToArrayOfDicts } from "@/utils/utils";
import {
  DocumentTextIcon,
  PlusIcon,
  TrashIcon,
} from "@heroicons/react/outline";
import { TooltipArrow } from "@radix-ui/react-tooltip";
import { BracesIcon, Link, PencilLine } from "lucide-react";
import { useMemo, useState } from "react";
import { formatJson } from "../utils/utils";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "./ui/Tooltip";
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTrigger,
} from "./ui/dialog";

type Props = {
  metadata: { [key: string]: any };
  setMetadata?: React.Dispatch<React.SetStateAction<{ [key: string]: any }>>;
  error?: string | null;
  setError?: React.Dispatch<React.SetStateAction<string | null>>;
};

const MetadataTable = ({
  data,
  onDelete,
  isEditing,
  setData,
}: {
  data: { key: string; value: any }[];
  onDelete: (index: number) => void;
  isEditing: boolean;
  setData: (data: { key: string; value: any }[]) => void;
}) => {
  const handleInputChange = (index: number, key: string, value: string) => {
    const newData = [...data];
    newData[index] = { key, value };
    setData && setData(newData);
  };

  const [jsonToggleByIndex, setJsonToggleByIndex] = useState<
    Record<number, boolean>
  >({});

  return (
    <Table className="font-mono">
      <TableHeader className="sticky top-0 z-10 bg-white">
        <TableRow>
          <TableHead className={`${isEditing ? "px-4" : ""} w-1/3 font-bold`}>
            Key
          </TableHead>
          <TableHead className={`${isEditing ? "px-4" : ""} w-2/3 font-bold`}>
            Value
          </TableHead>
          {isEditing && <TableHead className="w-1/10"></TableHead>}
        </TableRow>
      </TableHeader>
      <TableBody className="font-mono text-gray-400">
        {data?.map((item, index) => (
          <TableRow key={index}>
            <TableCell className="pl-2">
              {isEditing ? (
                <input
                  type="text"
                  className="border-none pl-2 text-sm text-gray-600"
                  value={item.key}
                  onChange={(e) =>
                    handleInputChange(index, e.target.value, item.value)
                  }
                />
              ) : (
                <span className="text-gray-600">{item.key}</span>
              )}
            </TableCell>
            <TableCell className="pl-2">
              {isEditing ? (
                <>
                  {jsonToggleByIndex[index] ? (
                    <>
                      <textarea
                        className="h-[150px] w-full border-none pl-2 text-sm text-gray-600"
                        value={item.value}
                        onChange={(e) =>
                          handleInputChange(index, item.key, e.target.value)
                        }
                      />
                      {canParseJson(item.value) ? null : <InvalidJsonError />}
                    </>
                  ) : (
                    <input
                      type="text"
                      className="w-full border-none pl-2 text-sm text-gray-600"
                      value={item.value}
                      onChange={(e) =>
                        handleInputChange(index, item.key, e.target.value)
                      }
                    />
                  )}
                </>
              ) : (
                <pre className="text-gray-600">{formatJson(item.value)}</pre>
              )}
            </TableCell>
            {isEditing && (
              <TableCell>
                <TooltipProvider>
                  <div className="flex gap-x-2">
                    <Tooltip delayDuration={0}>
                      <TooltipTrigger asChild>
                        {jsonToggleByIndex[index] ? (
                          <Button
                            size="icon"
                            variant="outline"
                            onClick={() =>
                              setJsonToggleByIndex({
                                ...jsonToggleByIndex,
                                [index]: false,
                              })
                            }
                          >
                            <PencilLine className="h-4 w-4" />
                          </Button>
                        ) : (
                          <Button
                            size="icon"
                            variant="outline"
                            onClick={() => {
                              handleInputChange(
                                index,
                                item.key,
                                formatJson(item.value),
                              );
                              setJsonToggleByIndex({
                                ...jsonToggleByIndex,
                                [index]: true,
                              });
                            }}
                          >
                            <BracesIcon className="h-4 w-4" />
                          </Button>
                        )}
                      </TooltipTrigger>
                      <TooltipContent
                        side="top"
                        sideOffset={10}
                        className="max-w-sm rounded bg-gray-800 p-2 text-sm text-white"
                      >
                        {jsonToggleByIndex[index]
                          ? "View as plain text"
                          : "View as JSON"}
                        <TooltipArrow className="fill-current text-gray-800" />
                      </TooltipContent>
                    </Tooltip>
                    <Button
                      size="icon"
                      variant="outline"
                      onClick={() => onDelete(index)}
                    >
                      <TrashIcon className="h-4 w-4" />
                    </Button>
                  </div>
                </TooltipProvider>
              </TableCell>
            )}
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

export const MetadataDialog = ({
  metadata,
  setMetadata,
  error,
  setError,
}: Props) => {
  const [open, setOpen] = useState(false);

  const isEditing = !!setMetadata;

  const [metadataArray, setMetadataArray] = useState(
    dictToArrayOfDicts(metadata),
  );

  const columns = useMemo(() => metadataArray, [metadataArray]);

  const lastKeyIsEmpty = useMemo(
    () =>
      metadataArray &&
      metadataArray?.length > 0 &&
      metadataArray[metadataArray.length - 1].key === "",
    [metadataArray],
  );

  const addNewRow = () => {
    if (
      Array.isArray(metadataArray) &&
      (!metadataArray.length || !lastKeyIsEmpty)
    ) {
      setMetadataArray(metadataArray.concat({ key: "", value: "" }));
    }
  };
  const hasMetadata = useMemo(() => {
    return Object.keys(metadata).length > 0;
  }, [metadata]);

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>
        <Button variant="ghost">
          <div
            className={`relative flex flex-row items-center ${
              hasMetadata
                ? "after:absolute after:-right-2 after:-top-0 after:h-2 after:w-2 after:rounded-full after:bg-blue-500"
                : ""
            }`}
          >
            <DocumentTextIcon className="mr-1 h-4 w-auto" aria-hidden="true" />
            Metadata
          </div>
        </Button>
      </DialogTrigger>
      <DialogContent className="flex max-h-[800px] min-h-[100px] max-w-2xl flex-col">
        <DialogHeader className="text-center text-xl">
          <div className="flex flex-col">
            <Label className="text-xl font-bold">
              {metadataArray?.length === 0
                ? "No Metadata Available"
                : `${isEditing ? "Setup " : ""}Template Metadata`}
            </Label>
            <p className="text-sm text-gray-500">
              <a
                href="https://docs.promptlayer.com/features/prompt-registry#metadata-2"
                target="_blank"
                rel="noreferrer"
                className="text-sm text-gray-500 hover:text-gray-400"
              >
                Assign key-value metadata information to prompt template
                versions. Learn more on our docs.{" "}
                <Link className="inline h-4 w-4 pl-1" />
              </a>
            </p>
          </div>
        </DialogHeader>
        {error && (
          <div
            className="relative mx-auto mb-4 max-w-xl rounded border border-red-400 px-2 py-2 text-center text-red-700"
            role="alert"
          >
            <strong className="pr-2 font-bold">Error!</strong>
            <span className="block sm:inline">{error}</span>
          </div>
        )}
        {metadataArray?.length > 0 && (
          <MetadataTable
            data={columns}
            isEditing={isEditing}
            setData={(data) => setMetadataArray(data)}
            onDelete={(index: number) => {
              if (index >= 0 && index < metadataArray.length) {
                const newMetadata = [...metadataArray];
                newMetadata.splice(index, 1);
                setMetadataArray(newMetadata);
              }
            }}
          />
        )}
        {isEditing && (
          <DialogFooter>
            <Button variant="outline" onClick={addNewRow}>
              <PlusIcon className="h-5 w-5" />
              Add new key-value pair
            </Button>
            <Button
              variant="default"
              onClick={() => {
                const keys = metadataArray.map((item) => item.key);
                const hasDuplicateKeys = keys.some(
                  (key, index) => keys.indexOf(key) !== index,
                );
                if (metadataArray.some((item) => item.key === "model")) {
                  setError && setError("Keyword 'model' is protected.");
                } else if (!lastKeyIsEmpty && !hasDuplicateKeys) {
                  setOpen(false);
                  setError && setError(null);
                  setMetadata(arrayOfDictsToDict(metadataArray));
                } else {
                  setError &&
                    setError(
                      "There is an empty key or duplicate keys in your metadata table.",
                    );
                }
              }}
            >
              Save Changes
            </Button>
          </DialogFooter>
        )}
      </DialogContent>
    </Dialog>
  );
};
