import { StatusCell } from "@/components/Evaluate/TableComponents/RowCell/CellTypes/StatusCell";
import { ColumnType } from "@/types/evaluate";
import { CheckCircleIcon, XCircleIcon } from "@heroicons/react/outline";
import { TableCell as Cell } from "../../table";

interface CellProps {
  placeholder?: boolean;
  children?: React.ReactNode;
  className?: string;
  [key: string]: any;
}

const getBooleanColors = (value: boolean) =>
  value
    ? "bg-green-100 group-hover:bg-green-200 border-green-200 group-hover:border-green-300"
    : "bg-red-100 group-hover:bg-red-200 border-red-200 group-hover:border-red-300";

const getCellType = (cellValue: any) => {
  const parsedType = typeof cellValue;
  const numberRegex = /^-?\d+(\.\d+)?$/;

  return {
    isBoolean:
      parsedType === "boolean" ||
      (parsedType === "string" &&
        ["true", "false"].includes(cellValue?.toLowerCase())),
    isNumber:
      parsedType === "number" ||
      (parsedType === "string" &&
        !isNaN(parseFloat(cellValue)) &&
        numberRegex.test(cellValue)),
  };
};

const BooleanIcon = ({ value }: { value: boolean }) =>
  value ? (
    <CheckCircleIcon className="h-5 w-5 text-green-600" />
  ) : (
    <XCircleIcon className="h-5 w-5 text-red-600" />
  );

const TableCell = ({
  children,
  placeholder,
  className = "",
  reportColumn,
  isLastCell,
  isFirstCell,
  value,
  ...props
}: CellProps) => {
  const baseClasses = `
    group/Cell relative h-[50px] max-h-[200px] min-h-[50px] max-w-full
    overflow-y-auto border-r border-b focus:z-[1]
    p-0 text-xs ${isFirstCell ? "border-l-0" : ""} ${
      isLastCell ? "border-r-0" : ""
    }
    transition-colors duration-150 ease-in-out
    group-hover:bg-gray-50 group-hover:border-gray-200
  `;

  const placeholderClasses = placeholder
    ? "bg-gray-50 group-hover:border-gray-200 group-hover:bg-gray-100"
    : "bg-white group-hover:bg-gray-50";

  const cellType = value ? getCellType(value?.display_value?.value) : null;
  if (
    reportColumn?.column_type !== ColumnType.COMPARE &&
    (reportColumn?.is_part_of_score || isLastCell) &&
    value
  ) {
    const { value: cellValue } = value.display_value || { value };
    const { isBoolean, isNumber } = cellType || {
      isBoolean: false,
      isNumber: false,
    };
    const booleanValue =
      cellValue === true ||
      (typeof cellValue === "string" && cellValue?.toLowerCase() === "true");

    const status = value?.status.toLowerCase();

    const isNotQueued =
      status !== "queued" || reportColumn?.column_type === ColumnType.HUMAN;

    const scoreClasses = `
      ${baseClasses}
      ${isBoolean ? getBooleanColors(booleanValue) : ""}
      ${isNumber ? "font-mono font-medium text-center w-16 text-lg" : "w-16"}
      shadow-sm
    `;

    if (!isNotQueued) {
      return (
        <Cell className={className} {...props}>
          <StatusCell status={status} />
        </Cell>
      );
    }

    return (
      <Cell className={`${className} ${scoreClasses}`} {...props}>
        {isBoolean ? (
          <span className="absolute inset-0 flex items-center justify-center">
            <BooleanIcon value={booleanValue} />
          </span>
        ) : (
          <div className="flex h-full items-center justify-center">
            {children}
          </div>
        )}
      </Cell>
    );
  }

  return (
    <Cell
      className={`${className} ${baseClasses} ${placeholderClasses} shadow-sm`}
      {...props}
    >
      {children}
    </Cell>
  );
};

export default TableCell;
