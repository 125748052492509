import { Node } from "@xyflow/react";
import { InputVariables, WorkflowNode, WorkflowNodeStatus } from "./types";
import { v4 } from "uuid";
import { Variable } from "../InputVariableConfigurator/types";

const EDGE_COLORS = {
  COMPLETED: "#10b981", // Green for completed
  RUNNING: "#3b82f6", // Blue for running
  QUEUED: "#fbbf24", // Yellow for queued
  DEFAULT: "#ddd", // Light gray for default/unknown states
};

export const getEdgeColor = (
  type: WorkflowNodeStatus | undefined,
  selected = false,
): string =>
  EDGE_COLORS[type?.toUpperCase() as keyof typeof EDGE_COLORS] || selected
    ? "#3b82f6"
    : EDGE_COLORS.DEFAULT;

function positionNodes(sortedNodes: WorkflowNode[]): Node[] {
  const LEVEL_WIDTH: number = 350;
  const NODE_HEIGHT: number = 200;
  const NODE_WIDTH: number = 300;
  const MARGIN: number = 300;

  const calculateNodeLevels = (
    nodes: WorkflowNode[],
  ): Record<string, number> => {
    const nodeLevels: Record<string, number> = {};
    const visited: Set<string> = new Set();

    const calculateLevel = (nodeId: string): number => {
      if (visited.has(nodeId)) return nodeLevels[nodeId];
      visited.add(nodeId);

      const node = nodes.find((n) => n.id === nodeId);
      if (!node) return 0;

      if (node.dependencies.length === 0) {
        nodeLevels[nodeId] = 0;
      } else {
        const maxDependencyLevel = Math.max(
          ...node.dependencies.map(calculateLevel),
        );
        nodeLevels[nodeId] = maxDependencyLevel + 1;
      }

      return nodeLevels[nodeId];
    };

    nodes.forEach((node) => {
      if (!visited.has(node.id)) {
        calculateLevel(node.id);
      }
    });

    return nodeLevels;
  };

  const groupNodesByLevel = (
    nodes: WorkflowNode[],
    levels: Record<string, number>,
  ): Record<number, WorkflowNode[]> => {
    return nodes.reduce(
      (acc, node) => {
        const level = levels[node.id];
        if (!acc[level]) acc[level] = [];
        acc[level].push(node);
        return acc;
      },
      {} as Record<number, WorkflowNode[]>,
    );
  };

  const calculatePositions = (
    nodesByLevel: Record<number, WorkflowNode[]>,
  ): Record<string, { x: number; y: number }> => {
    const positions: Record<string, { x: number; y: number }> = {};
    const levelHeights: Record<number, number> = {};

    Object.entries(nodesByLevel).forEach(([level, nodes]) => {
      const numNodes = nodes.length;
      const levelHeight = numNodes * NODE_HEIGHT + (numNodes - 1) * MARGIN;
      levelHeights[Number(level)] = levelHeight;
    });

    const maxHeight = Math.max(...Object.values(levelHeights));

    Object.entries(nodesByLevel).forEach(([level, nodes]) => {
      const levelHeight = levelHeights[Number(level)];
      const x = Number(level) * (LEVEL_WIDTH + MARGIN) + NODE_WIDTH / 2;
      const startY = -maxHeight / 2 + (maxHeight - levelHeight) / 2;

      nodes.forEach((node, index) => {
        const y = startY + index * (NODE_HEIGHT + MARGIN) + NODE_HEIGHT / 2;
        if (!isNaN(x) && !isNaN(y)) {
          positions[node.id] = { x, y };
        } else {
          console.error(`Invalid position calculated for node ${node.id}`, {
            level,
            index,
            startY,
          });
        }
      });
    });

    return positions;
  };

  const nodeLevels = calculateNodeLevels(sortedNodes);
  const nodesByLevel = groupNodesByLevel(sortedNodes, nodeLevels);
  const positions = calculatePositions(nodesByLevel);

  return sortedNodes.map((node) => ({
    id: node.id,
    type: "basic",
    position: node.configuration.position ||
      positions[node.id] || { x: 0, y: 0 },
    data: {
      label: node.name,
      id: node.id,
      type: node.configuration?.function_name?.toUpperCase() || node.node_type,
    },
  }));
}
const getAllLinkedDependencies = <
  T extends { dependencies?: string[]; name: string },
>(
  getNodeById: (id: string) => T | undefined,
  nodeId: string,
): string[] => {
  const node = getNodeById(nodeId);
  if (!node?.dependencies) return [];

  const seen = new Set<string>();
  const dependencies: string[] = [];

  const traverse = (id: string) => {
    const current = getNodeById(id);
    if (!current?.dependencies) return;

    for (const depId of current.dependencies) {
      const depNode = getNodeById(depId);
      if (depNode?.name && !seen.has(depNode.name)) {
        seen.add(depNode.name);
        dependencies.push(depNode.name);
        traverse(depId);
      }
    }
  };

  traverse(nodeId);
  return dependencies;
};

const withConfigurationNameToIdRemapping = (
  configuration: any,
  nodes: WorkflowNode[],
): any => {
  const remapValue = (value: any): any => {
    if (typeof value === "string") {
      const node = nodes.find((node) => node.name === value);
      return node ? node.id : value;
    } else if (Array.isArray(value)) {
      return value.map(remapValue);
    } else if (typeof value === "object" && value !== null) {
      return Object.fromEntries(
        Object.entries(value).map(([k, v]) => [
          k,
          k === "template" ? v : remapValue(v),
        ]),
      );
    }
    return value;
  };

  return remapValue(configuration);
};

const withConfigurationIdToNameRemapping = (
  configuration: any,
  nodes: WorkflowNode[],
): any => {
  const remapValue = (value: any): any => {
    if (typeof value === "string") {
      const node = nodes.find((node) => node.id === value);
      return node ? node.name : value;
    } else if (Array.isArray(value)) {
      return value.map(remapValue);
    } else if (typeof value === "object" && value !== null) {
      return Object.fromEntries(
        Object.entries(value).map(([k, v]) => [k, remapValue(v)]),
      );
    }
    return value;
  };

  return remapValue(configuration);
};

const nodeHasSource = (node: WorkflowNode, source_id: string): boolean => {
  const configuration = node.configuration;
  return (
    configuration.source === source_id ||
    configuration.first_source === source_id ||
    configuration.second_source === source_id ||
    !!configuration.sources?.includes(source_id)
  );
};

const withNameToIdRemapping = (nodes: WorkflowNode[]): WorkflowNode[] => {
  return nodes.map((node) => {
    const { dependencies } = node;
    const getAssociatedNode = (name: string): WorkflowNode | undefined =>
      nodes.find((node) => node.name === name);

    return {
      ...node,
      configuration: withConfigurationNameToIdRemapping(
        node.configuration || {},
        nodes,
      ),
      dependencies: dependencies.map(
        (dep) => getAssociatedNode(dep)?.id || dep,
      ),
    };
  });
};

const withIdToNameRemapping = (nodes: WorkflowNode[]): WorkflowNode[] =>
  nodes.map((node) => {
    const { dependencies } = node;
    const getAssociatedNode = (id: string): WorkflowNode | undefined =>
      nodes.find((node) => node.id === id);

    return {
      ...node,
      configuration: withConfigurationIdToNameRemapping(
        node.configuration || {},
        nodes,
      ),
      dependencies: dependencies.map(
        (dep) => getAssociatedNode(dep)?.name || dep,
      ),
    };
  });

const withIdToNameRemappingForOneNode = (
  node: WorkflowNode,
  nodes: WorkflowNode[],
): WorkflowNode => {
  const { dependencies } = node;
  const getAssociatedNode = (id: string): WorkflowNode | undefined =>
    nodes.find((n) => n.id === id);

  return {
    ...node,
    configuration: withConfigurationIdToNameRemapping(
      node.configuration || {},
      nodes,
    ),
    dependencies: dependencies.map(
      (dep) => getAssociatedNode(dep)?.name || dep,
    ),
  };
};

const formatRawNodeType = (value: string): string =>
  value
    .replaceAll("_", " ")
    .toLowerCase()
    .replace(/\b\w/g, (c) => c.toUpperCase());

const formatRawNodeTypeToDisplayName = (
  type: string,
  columnTypes: Record<string, { displayName: string; description: string }>,
): string => columnTypes[type]?.displayName || type;

const prepareInputVariables = (
  requiredInputVariables: InputVariables | undefined | null,
): Variable[] => {
  if (!requiredInputVariables) {
    return [];
  }

  return Object.entries(requiredInputVariables).map(([key, value]) => ({
    key,
    value,
    id: v4(),
  }));
};

export {
  formatRawNodeType,
  formatRawNodeTypeToDisplayName,
  getAllLinkedDependencies,
  nodeHasSource,
  positionNodes,
  prepareInputVariables,
  withConfigurationIdToNameRemapping,
  withIdToNameRemapping,
  withIdToNameRemappingForOneNode,
  withNameToIdRemapping,
};
