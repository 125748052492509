import { useMutation, useQueryClient } from "@tanstack/react-query";
import { jsonAuthHeaders } from "@/utils/headers";
import { ENDPOINTS } from "@/api/application-api";
import { handleResponse } from "@/utils/errorResponseHandlers";

import { Response } from "../types";

interface ConfigureReportScoreCardParams {
  columnNames: string[];
  code?: string;
  codeLanguage?: "PYTHON" | "JAVASCRIPT";
}

interface ScoreCard {
  id: number;
  created_at: string;
  updated_at: string;
  is_blueprint: boolean;
  name: string;
  comment: string | null;
  score_configuration: string | null;
  score: number | null;
  score_matrix: string | null;
  score_calculation_error: string | null;
  deleted: boolean;
  parent_report_id: number;
  dataset_id: number;
  user_id: number;
  workspace_id: number;
}

export const useConfigureReportScoreCard = (
  userToken: string,
  reportId: number,
) => {
  const queryClient = useQueryClient();
  return useMutation<
    Response<ScoreCard, "report", string>,
    Error,
    ConfigureReportScoreCardParams
  >((params) => {
    const url = new URL(
      `${ENDPOINTS.reports}/${reportId.toString()}/score-card`,
    );
    return fetch(url.toString(), {
      method: "PATCH",
      headers: jsonAuthHeaders(userToken),
      body: JSON.stringify({
        column_names: params.columnNames,
        code: params.code,
        code_language: params.codeLanguage,
      }),
    })
      .then(handleResponse)
      .then((json) => {
        if (json.success) {
          // on success, refetch report & report columns
          queryClient.invalidateQueries([ENDPOINTS.reports, reportId]);
          queryClient.invalidateQueries([ENDPOINTS.report_columns, reportId]);
        }

        return json;
      });
  });
};
