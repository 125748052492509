import { URL_PARAM_KEYS } from "@/utils/utils";

export const handleAddMetadataToSearchParams = (
  searchParams: URLSearchParams,
  setSearchParams: (
    params:
      | URLSearchParams
      | ((prevParams: URLSearchParams) => URLSearchParams),
  ) => void,
  key: string,
  value: string,
) => {
  setSearchParams((previous) => {
    previous.append(URL_PARAM_KEYS.METADATA, JSON.stringify({ key, value }));
    return previous;
  });
};
