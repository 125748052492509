import { useState, useEffect } from "react";

export const useIsMobile = (mobileWidth = 768, mobileHeight = 500): boolean => {
  const [isMobile, setIsMobile] = useState<boolean>(false);

  useEffect(() => {
    const checkMobile = () => {
      setIsMobile(
        window.innerWidth <= mobileWidth || window.innerHeight <= mobileHeight,
      );
    };

    checkMobile();

    window.addEventListener("resize", checkMobile);

    return () => window.removeEventListener("resize", checkMobile);
  }, [mobileWidth, mobileHeight]);

  return isMobile;
};

export default useIsMobile;
