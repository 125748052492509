import { action, computed, makeObservable, observable } from "mobx";
import { useLocalObservable } from "mobx-react-lite";
import { createContext, useContext, useEffect } from "react";
import { useParams } from "react-router-dom";

import { useAuth } from "@/context/auth-context";
import { useInferenceClientsList } from "@/queries";
import { useModels } from "@/queries/models";
import { Parameter, Provider } from "@/queries/models/types";
import { InferenceClient, JSONSchema } from "@/types/prompt-blueprint";

import { DEFAULT_MODEL_NAMES, HUGGINGFACE_PROVIDER } from "../constants";
import { PromptData } from "../types";
import {
  convertToBlueprintModel,
  getDefaultModel,
  getModelParams,
  mapInitialDataWithExistingParams,
} from "../utils";

import { Model, ParameterProviderProps } from "./types";
import { ParameterStore } from "./parameter-store";
import LoadingSpinner from "@/components/LoadingSpinner";

class ParametersStore {
  @observable private _parameters = new Map<string, ParameterStore>();
  @observable private _modelName?: string;
  @observable private _modelProvider?: string;
  @observable private _inferenceClientName?: string | null;
  @observable private _providerBaseURL?: string | null;
  @observable private _isPromptChat?: boolean;
  @observable private _apiModels?: Provider[];
  @observable private _inferenceClients?: InferenceClient[];
  @observable private _JSONSchema?: JSONSchema;
  @observable private onUpdate?: (promptData: PromptData) => void;

  constructor(
    promptData?: PromptData,
    apiModels?: Provider[],
    isPromptChat: boolean = false,
    inferenceClients?: InferenceClient[],
    onUpdate?: (promptData: PromptData) => void,
  ) {
    makeObservable(this);
    this._modelName = promptData?.metadata?.model?.name;
    this._modelProvider = promptData?.metadata?.model?.provider;
    this._inferenceClientName = promptData?.inference_client_name;
    this._providerBaseURL = promptData?.provider_base_url_name;
    this._inferenceClients = inferenceClients;
    this._isPromptChat = isPromptChat;
    this._apiModels = apiModels;
    this.onUpdate = onUpdate;

    if (promptData?.metadata?.model?.provider === HUGGINGFACE_PROVIDER) {
      this.updateHuggingFaceProvider();

      return;
    }

    this._parameters = new Map(
      promptData?.metadata?.model?.parameters?.map((param: Parameter) => [
        param.param_id,
        new ParameterStore(param),
      ]),
    );
  }

  private setParametersFromArray(parameters: any[]) {
    this._parameters.clear();

    if (!parameters?.length) return;

    parameters.forEach((param) => {
      this._parameters.set(param.param_id, new ParameterStore(param));
    });

    const responseFormatParam = this._parameters.get("response_format");

    if (
      responseFormatParam?.options?.some(
        (option: any) => option?.value?.type === "json_schema",
      ) &&
      this._JSONSchema
    ) {
      responseFormatParam.updateValue({
        type: "json_schema",
        json_schema: this._JSONSchema,
      });
    }
  }

  private getParametersForModel(
    provider?: string,
    model?: string | null,
  ): any[] {
    if (!provider) return [];

    if (provider === HUGGINGFACE_PROVIDER) {
      const huggingFaceModel = this._apiModels
        ?.find((p) => p.provider_name === HUGGINGFACE_PROVIDER)
        ?.model_configs.find((m) => m.display_name === model);

      return huggingFaceModel?.params || [];
    }

    return getModelParams(this._apiModels, provider, model || "");
  }

  @computed get apiModels() {
    return this._apiModels;
  }

  @computed get inferenceClients() {
    return this._inferenceClients;
  }

  @computed get isPromptChat() {
    return this._isPromptChat;
  }

  @computed get parameters() {
    return Array.from(this._parameters.values());
  }

  @computed get parametersMap() {
    return this._parameters;
  }

  @computed get modelName() {
    return this._modelName;
  }

  @computed get provider() {
    return this._modelProvider;
  }

  @computed get inferenceClientName() {
    return this._inferenceClientName;
  }

  @computed get providerBaseURL() {
    return this._providerBaseURL;
  }

  @computed get isCustomParametersSet() {
    return (
      !!this._modelName && !!this._modelProvider && this._parameters.size > 0
    );
  }

  @computed get promptData(): PromptData | undefined {
    if (!this._modelProvider || !this._parameters.size) return undefined;

    const parameters = Array.from(this._parameters.values()).map(
      (p) => p.getParameter,
    );

    const convertedData = convertToBlueprintModel({
      metadata: {
        model: {
          provider: this._modelProvider,
          name: this._modelName || "",
          parameters,
        },
      },
      inference_client_name: this._inferenceClientName,
      provider_base_url_name: this._providerBaseURL,
    });

    if (
      convertedData?.metadata?.model?.parameters?.response_format?.json_schema
    ) {
      this._JSONSchema =
        convertedData.metadata?.model?.parameters?.response_format?.json_schema;
    }

    return convertedData;
  }

  @action
  createCustomParameter(key: string) {
    const newParam = new ParameterStore({
      param_id: key,
      name: key,
      component_type: undefined,
      default: null,
      is_disabled: false,
      is_custom: true,
      value: 0,
    });

    this._parameters.set(key, newParam);
  }

  @action
  deleteCustomParameter(key: string) {
    this._parameters.delete(key);
  }

  @action
  updateModel(modelUpdate: Partial<Model>) {
    this._modelName = modelUpdate.name;
    this._modelProvider = modelUpdate.provider;
    this.setParametersFromArray(modelUpdate.parameters || []);
  }

  @action
  updateModelName(modelName: string) {
    if (modelName === this._modelName) return;

    if (this._modelProvider === HUGGINGFACE_PROVIDER) {
      this._modelName = undefined;
      this._inferenceClientName = modelName;
    } else {
      this._modelName = modelName;
    }

    this.setParametersFromArray(
      this.getParametersForModel(this._modelProvider, modelName),
    );
  }

  @action
  clearModelData() {
    this._modelName = undefined;
    this._modelProvider = undefined;
    this._parameters.clear();
  }

  @action
  updateProviderBaseURL(providerName: string) {
    this._providerBaseURL = providerName;
  }

  @action
  updateInferenceClientName(inferenceClientName: string) {
    this._inferenceClientName = inferenceClientName;

    if (this._modelProvider === HUGGINGFACE_PROVIDER) {
      this.setParametersFromArray(
        this.getParametersForModel(HUGGINGFACE_PROVIDER, inferenceClientName),
      );
    }
  }

  @action
  updateHuggingFaceProvider() {
    const updatedModels = this.apiModels?.map((provider) => {
      if (provider.provider_name === HUGGINGFACE_PROVIDER) {
        const internalModels = provider.model_configs.filter(
          (model) => model.is_internal,
        );

        const templateParams =
          internalModels.find((model) => model.is_chat === this.isPromptChat)
            ?.params || [];

        const generatedModels =
          this._inferenceClients?.map((client) => ({
            llm_model_name: client.model,
            display_name: client.name,
            is_chat: this.isPromptChat,
            is_internal: false,
            params: templateParams,
          })) || [];

        return {
          provider_name: provider.provider_name,
          model_configs: [...internalModels, ...generatedModels],
        };
      }

      return provider;
    });

    this._apiModels = updatedModels as Provider[];

    const modelParams = this.getParametersForModel(
      this._modelProvider,
      this._inferenceClientName,
    );

    this.updateModel({
      name: this._modelName,
      provider: this._modelProvider,
      parameters: modelParams,
    });
  }

  @action
  updateProvider(provider: string) {
    if (provider === this._modelProvider) return;

    this._modelProvider = provider;

    if (provider === HUGGINGFACE_PROVIDER) {
      this._modelName = undefined;
      this._providerBaseURL = undefined;
      this._parameters.clear();

      this.updateHuggingFaceProvider();
    } else {
      const defaultModel = this.isPromptChat
        ? DEFAULT_MODEL_NAMES[provider as keyof typeof DEFAULT_MODEL_NAMES]
        : undefined;
      this._modelName = defaultModel;
      this._inferenceClientName = undefined;

      this.setParametersFromArray(
        this.getParametersForModel(provider, defaultModel),
      );
    }
  }

  @action
  setModels(apiModels: Provider[]) {
    this._apiModels = apiModels;
  }

  @action
  setInferenceClients(inferenceClients: InferenceClient[]) {
    this._inferenceClients = inferenceClients;

    if (this._modelProvider === HUGGINGFACE_PROVIDER) {
      this.updateHuggingFaceProvider();
    }
  }

  @action
  setIsPromptChat(isPromptChat: boolean) {
    this._isPromptChat = isPromptChat;
  }

  @action
  updateExternalData(promptData: PromptData) {
    if (this.onUpdate) {
      this.onUpdate(promptData);
    }
  }
}

const ParametersContext = createContext<ParametersStore | undefined>(undefined);

export const ParameterProvider = ({
  children,
  promptData,
  isPromptChat,
  onUpdate,
}: ParameterProviderProps) => {
  const auth = useAuth();
  const { workspaceId } = useParams();
  const { data, isLoading: isModelsLoading } = useModels(
    auth?.userToken || "",
    workspaceId || "",
  );
  const { data: inferenceClientData, isLoading: isInferenceClientsLoading } =
    useInferenceClientsList(auth?.userToken || "", workspaceId || "");

  const store = useLocalObservable(() => {
    const modelParams = getModelParams(
      data?.results,
      promptData?.metadata?.model?.provider,
      promptData?.metadata?.model?.name,
    );

    const mappedData =
      promptData && data?.results
        ? mapInitialDataWithExistingParams(promptData, modelParams)
        : undefined;

    return new ParametersStore(
      mappedData,
      data?.results,
      isPromptChat,
      inferenceClientData?.inference_clients,
      onUpdate,
    );
  });

  useEffect(() => {
    if (!store.provider || store.isPromptChat === isPromptChat) return;

    store.setIsPromptChat(isPromptChat);

    if (store.provider === HUGGINGFACE_PROVIDER) {
      store.updateHuggingFaceProvider();
      return;
    }

    const defaultModel = getDefaultModel(store.apiModels || [], isPromptChat);
    store.updateModel(defaultModel);
  }, [isPromptChat, store]);

  useEffect(() => {
    if (!store.inferenceClients?.length) {
      store.setInferenceClients(inferenceClientData?.inference_clients || []);
    }
  }, [store.inferenceClients, inferenceClientData?.inference_clients, store]);

  useEffect(() => {
    if (data?.results && !store.apiModels?.length) {
      store.setModels(data?.results as Provider[]);

      if (promptData?.metadata?.model?.provider === HUGGINGFACE_PROVIDER) {
        store.updateProvider(HUGGINGFACE_PROVIDER);
        store.updateInferenceClientName(
          promptData?.inference_client_name || "",
        );
        store.updateHuggingFaceProvider();

        return;
      }

      const modelParams = getModelParams(
        data?.results,
        promptData?.metadata?.model?.provider,
        promptData?.metadata?.model?.name,
      );

      const mappedData = mapInitialDataWithExistingParams(
        promptData,
        modelParams,
      );

      if (mappedData) {
        store.updateModel({
          name: mappedData.metadata?.model?.name,
          provider: mappedData.metadata?.model?.provider,
          parameters: mappedData.metadata?.model?.parameters,
        });
      }
    }
  }, [promptData, data?.results, store, isPromptChat]);

  if (isModelsLoading || isInferenceClientsLoading) {
    return (
      <div className="flex h-full w-[132px] items-center justify-center">
        <LoadingSpinner size={4} />
      </div>
    );
  }

  return (
    <ParametersContext.Provider value={store}>
      {children}
    </ParametersContext.Provider>
  );
};

export const useParameters = (): ParametersStore => {
  const context = useContext(ParametersContext);
  if (context === undefined) {
    throw new Error("useParameters must be used within a ParameterProvider");
  }
  return context;
};
