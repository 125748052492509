import { Playground, PlaygroundSession } from "@/components/Playground2";
import MessagesShimmer from "@/components/Playground2/Messages/MessagesShimmer";
import { useAuth } from "@/context/auth-context";
import { useEditPromptTemplate } from "@/queries";
import { Tab } from "@headlessui/react";

import { useParams, Link, useNavigate } from "react-router-dom";
import PromptTemplateVersionSelector from "./PromptTemplateVersionSelector";
import PromptTemplateAnalytics from "./PromptTemplateAnalytics";
import { ResourceHeader } from "@/components/ResourceHeader";
import { useUser } from "@/context/user-context";
import { getTemplateFormat } from "@/utils/utils";
import { Stethoscope, PenSquare } from "lucide-react";
import {
  TrashIcon,
  ViewListIcon,
  ChartBarIcon,
  PlayIcon,
} from "@heroicons/react/outline";
import {
  ViewListIcon as ViewListIconSolid,
  ChartBarIcon as ChartBarIconSolid,
  PlayIcon as PlayIconSolid,
} from "@heroicons/react/solid";
import { DeleteItem } from "@/components/DeleteItem";
import Breadcrumbs from "./Breadcrumbs";
import {
  usePromptDeletion,
  usePromptTemplateData,
  useTabNavigation,
  useXrayMode,
} from "./hooks";
import { useEffect, useRef } from "react";
import { v4 } from "uuid";
import { Button } from "@/components/ui/button";

const PromptTemplateOverview = () => {
  const { promptId, versionId } = useParams();
  const { promptVersion, promptRegistry, totalVersions, promptName } =
    usePromptTemplateData(promptId!, versionId);

  const overviewSessionId = useRef(v4());
  const navigate = useNavigate();

  useEffect(() => {
    overviewSessionId.current = v4();
  }, [versionId]);

  const { getCurrentTabIndex, handleTabChange, showVersions, setShowVersions } =
    useTabNavigation(promptId!, versionId!);
  const { isDeleteDialogOpen, setIsDeleteDialogOpen, handleDeletePrompt } =
    usePromptDeletion(promptId!);
  const { isXrayMode, setIsXrayMode, isXrayAvailable } =
    useXrayMode(promptVersion);

  const editPromptTemplate = useEditPromptTemplate();
  const { activeWorkspaceId } = useUser();
  const auth = useAuth();
  const userToken = auth?.userToken!;

  const navigateToPlayground = () => {
    navigate(handleTabChange(1));
  };

  return (
    <div className="flex h-full flex-col p-1 pb-4">
      <div className="mb-3">
        <Breadcrumbs />
      </div>
      <ResourceHeader
        showVersions={showVersions}
        onToggleVersions={() => setShowVersions(!showVersions)}
        isLoading={promptRegistry.isLoading}
        title={promptName || ""}
        minimal={true}
        onTitleEdit={function ({
          newTitle,
        }: {
          onSuccess?: (newTitle?: string) => void;
          onError?: () => void;
          newTitle: string;
        }): void {
          if (!promptRegistry.data) return;
          editPromptTemplate.mutate({
            prompt_id: Number(promptId),
            userToken: userToken!,
            basePromptTemplate: {
              prompt_name: newTitle,
              tags: promptRegistry.data.tags,
              folder_id: promptRegistry.data.folder_id!,
              workspace_id: activeWorkspaceId!,
            },
            activeWorkspaceId: activeWorkspaceId!,
          });
        }}
        id={promptId!}
        format={
          (promptVersion.data &&
            getTemplateFormat(promptVersion.data.prompt_template)) ||
          "f-string"
        }
        tags={promptRegistry.data?.tags || []}
        onTagsEdit={(tags) => {
          if (!promptRegistry.data) return;
          editPromptTemplate.mutate({
            prompt_id: Number(promptId),
            userToken: userToken!,
            basePromptTemplate: {
              prompt_name: promptRegistry.data.prompt_name,
              tags,
              folder_id: promptRegistry.data.folder_id!,
              workspace_id: activeWorkspaceId!,
            },
            activeWorkspaceId: activeWorkspaceId!,
          });
        }}
        createdAt={promptRegistry.data?.created_at || ""}
        actions={[
          {
            icon: <TrashIcon className="mr-1.5 h-4 w-4" aria-hidden="true" />,
            label: "Delete",
            onClick: () => setIsDeleteDialogOpen(true),
            variant: "destructiveOutline",
          },
        ]}
      />
      <Tab.Group selectedIndex={getCurrentTabIndex()} onChange={() => {}}>
        <Tab.List className="mb-1 mt-2 flex items-center justify-between border-b border-gray-200">
          <div className="flex">
            <Tab
              as={Link}
              to={handleTabChange(0)}
              className={({ selected }) =>
                `flex items-center px-4 py-2 text-sm font-medium focus:outline-none
                ${
                  selected
                    ? "border-b-2 border-blue-600 text-blue-600"
                    : "text-gray-500 hover:border-gray-300 hover:text-gray-700"
                }`
              }
            >
              {getCurrentTabIndex() === 0 ? (
                <ViewListIconSolid
                  className="mr-1.5 h-4 w-4"
                  aria-hidden="true"
                />
              ) : (
                <ViewListIcon className="mr-1.5 h-4 w-4" aria-hidden="true" />
              )}
              Overview
            </Tab>
            <Tab
              as={Link}
              to={getCurrentTabIndex() === 1 ? "#" : handleTabChange(1)}
              onClick={(e) => {
                if (getCurrentTabIndex() === 1) e.preventDefault();
              }}
              className={({ selected }) =>
                `flex items-center px-4 py-2 text-sm font-medium focus:outline-none
                ${
                  selected
                    ? "border-b-2 border-blue-600 text-blue-600"
                    : "text-gray-500 hover:border-gray-300 hover:text-gray-700"
                }`
              }
            >
              {getCurrentTabIndex() === 1 ? (
                <PlayIconSolid className="mr-1.5 h-4 w-4" aria-hidden="true" />
              ) : (
                <PlayIcon className="mr-1.5 h-4 w-4" aria-hidden="true" />
              )}
              Playground
            </Tab>
            <Tab
              as={Link}
              to={handleTabChange(2)}
              className={({ selected }) =>
                `flex items-center px-4 py-2 text-sm font-medium focus:outline-none
                ${
                  selected
                    ? "border-b-2 border-blue-600 text-blue-600"
                    : "text-gray-500 hover:border-gray-300 hover:text-gray-700"
                }`
              }
            >
              {getCurrentTabIndex() === 2 ? (
                <ChartBarIconSolid
                  className="mr-1.5 h-4 w-4"
                  aria-hidden="true"
                />
              ) : (
                <ChartBarIcon className="mr-1.5 h-4 w-4" aria-hidden="true" />
              )}
              Analytics
            </Tab>
          </div>
        </Tab.List>
        <Tab.Panels className="flex-grow overflow-auto">
          <Tab.Panel
            className="h-full rounded-xl bg-white focus:outline-none"
            tabIndex={-1}
          >
            <div className="flex h-full flex-row gap-x-2">
              <PromptTemplateVersionSelector
                selectedVersionId={promptVersion.data?.id}
                setShowVersionList={setShowVersions}
                showVersionList={showVersions}
                className="z-[1] mt-2"
              />
              <div className="flex h-full flex-1 flex-col overflow-auto">
                {promptVersion.data && !promptVersion.isLoading ? (
                  <>
                    <div className="sticky top-0 flex w-full flex-col bg-white pl-3 pr-2 shadow-sm">
                      <div className="flex w-full items-center justify-between py-2">
                        <h2 className="flex items-center text-xs font-normal text-gray-700">
                          <span className="mr-1">Viewing Version</span>
                          <span className="font-medium text-gray-900">
                            {versionId}
                          </span>
                          <span className="mx-1 text-gray-500">of</span>
                          <span className="font-medium text-gray-900">
                            {totalVersions >= Number(versionId)
                              ? totalVersions
                              : versionId}
                          </span>
                        </h2>
                        <div
                          id={"template-header"}
                          className="flex items-center gap-x-2"
                        >
                          {isXrayAvailable && (
                            <Button
                              onClick={() =>
                                setIsXrayMode((prev: boolean) => !prev)
                              }
                              variant={isXrayMode ? "default" : "outline"}
                              size="sm"
                            >
                              <Stethoscope
                                className="mr-1.5 h-4 w-4"
                                aria-hidden="true"
                              />
                              X-Ray
                            </Button>
                          )}

                          {getCurrentTabIndex() === 0 && (
                            <Button
                              onClick={navigateToPlayground}
                              variant="outline"
                              size="sm"
                              aria-label="Edit in Playground"
                            >
                              <PenSquare
                                className="mr-1.5 h-4 w-4"
                                aria-hidden="true"
                              />
                              Edit in Playground
                            </Button>
                          )}
                        </div>
                      </div>
                      {promptVersion.data?.commit_message && (
                        <div className="mb-2 rounded-md border border-gray-200 bg-gray-50 p-3">
                          <h3 className="mb-1 text-xs font-medium uppercase text-gray-500">
                            Commit Message
                          </h3>
                          <p className="text-sm text-gray-700">
                            {promptVersion.data.commit_message}
                          </p>
                        </div>
                      )}
                    </div>
                    <div className="h-full flex-1 overflow-auto">
                      <Playground
                        readonly
                        key={`${promptVersion.data.id}_${isXrayMode}`}
                        selectedTemplateVersion={promptVersion.data}
                        blueprint={{
                          prompt_template: isXrayMode
                            ? promptVersion.data.rendered_template
                            : promptVersion.data.prompt_template,
                          provider_base_url_name:
                            promptVersion.data.provider_base_url_name,
                          commit_message:
                            promptVersion.data.commit_message ?? undefined,
                          metadata: promptVersion.data.metadata,
                          report_id: promptVersion.data.report_id ?? undefined,
                        }}
                      />
                    </div>
                  </>
                ) : (
                  <div className="h-full">
                    <MessagesShimmer />
                  </div>
                )}
              </div>
            </div>
          </Tab.Panel>
          <Tab.Panel
            className="relative -top-1  h-full rounded-xl bg-white focus:outline-none"
            tabIndex={-1}
          >
            <div className="h-full">
              {promptVersion.data && (
                <PlaygroundSession
                  editor
                  instanceId={overviewSessionId.current}
                  selectedTemplateVersion={promptVersion.data}
                  blueprint={{
                    prompt_template: promptVersion.data.prompt_template,
                    provider_base_url_name:
                      promptVersion.data.provider_base_url_name,
                    commit_message:
                      promptVersion.data.commit_message ?? undefined,
                    metadata: promptVersion.data.metadata,
                    report_id: promptVersion.data.report_id ?? undefined,
                  }}
                />
              )}
            </div>
          </Tab.Panel>
          <Tab.Panel
            className="h-full rounded-xl bg-white focus:outline-none"
            tabIndex={-1}
          >
            <PromptTemplateAnalytics />
          </Tab.Panel>
        </Tab.Panels>
      </Tab.Group>
      {promptRegistry.data && (
        <DeleteItem
          open={isDeleteDialogOpen}
          setOpen={setIsDeleteDialogOpen}
          setDelete={() => handleDeletePrompt()}
          itemName={promptRegistry.data?.prompt_name}
          itemType="template"
        />
      )}
    </div>
  );
};

export default PromptTemplateOverview;
