import { useState } from "react";

const useVariableSetEditing = () => {
  const [editingSetId, setEditingSetId] = useState<string | null>(null);
  const [isEditingSet, setIsEditingSet] = useState(false);

  const startEditing = (setId: string) => {
    setEditingSetId(setId);
    setIsEditingSet(true);
  };

  const stopEditing = () => {
    setIsEditingSet(false);
  };

  return {
    editingSetId,
    isEditingSet,
    startEditing,
    stopEditing,
  };
};

export default useVariableSetEditing;
