import { useRef, useCallback } from "react";

const useScrollToNewElement = () => {
  const containerRef = useRef<HTMLDivElement>(null);
  const scrollToEnd = useCallback(() => {
    if (containerRef.current) {
      const container = containerRef.current;
      const scrollOptions: ScrollToOptions = {
        left: container.scrollWidth,
        behavior: "smooth",
      };
      container.scrollTo(scrollOptions);
    }
  }, []);

  return { containerRef, scrollToEnd };
};

export default useScrollToNewElement;
