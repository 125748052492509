export const getName = (name: string) => {
  if (!name) return [];

  const firstSpaceIndex = name.indexOf(" ");

  if (firstSpaceIndex === -1) {
    return [name];
  }

  const firstName = name.substring(0, firstSpaceIndex);
  const lastName = name.substring(firstSpaceIndex + 1);

  return [firstName, lastName];
};
