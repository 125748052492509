import { useDatasetRows } from "../datasets";

export const useDatasetInputVariables = (
  userToken: string,
  datasetId: number | null,
) => {
  const { data, isFetching, error, refetch } = useDatasetRows(
    userToken,
    datasetId,
    1,
    1,
  );

  if (!data || !data.dataset_rows || data.dataset_rows.length === 0) {
    return { isFetching, error, refetch };
  }

  const variables = data.dataset_rows[0].variables;

  return { data: variables, isFetching, error, refetch };
};
