import { AlertTriangleIcon } from "lucide-react";
import React from "react";

interface ErrorDisplayProps {
  title?: string;
  children?: React.ReactNode;
}

const ErrorDisplay = ({
  children = "Something went wrong",
  title = "Error",
}: ErrorDisplayProps) => (
  <div className="flex flex-col items-center justify-center rounded-lg border border-red-100 bg-red-50 p-4 text-center">
    <div className="mb-2 flex items-center">
      <AlertTriangleIcon className="mr-2 h-4 w-4 text-red-800" />
      <h3 className="text-sm font-bold text-red-800">{title}</h3>
    </div>
    <p className="max-w-md font-mono text-sm text-red-600">{children}</p>
  </div>
);

export default ErrorDisplay;
