import { useAuth } from "@/context/auth-context";
import { useUpdatePlaygroundSession } from "@/queries";
import { useRef, useEffect } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { usePlayground } from "../../playground-store";

const useIntializeSessionOnMount = () => {
  const userToken = useAuth()?.userToken || "";
  const { sessionId } = useParams();
  const currentPath = window.location.pathname;
  const isPlaygroundPath = currentPath.includes("playground");
  const navigate = useNavigate();
  const location = useLocation();
  const { instanceId } = usePlayground();
  const updatePlaygroundSession = useUpdatePlaygroundSession(userToken, {
    onSuccess: () => {
      if (
        !sessionId &&
        isPlaygroundPath &&
        window.location.pathname === currentPath
      ) {
        try {
          const pathWithoutInstance = currentPath.replace(
            new RegExp(`/${instanceId}/?$`),
            "",
          );

          const baseUrl = pathWithoutInstance.endsWith("/")
            ? `${pathWithoutInstance}${instanceId}`
            : `${pathWithoutInstance}/${instanceId}`;

          if (window.location.pathname !== baseUrl) {
            setTimeout(() => {
              navigate(`${baseUrl}${location.search}`, { replace: true });
            }, 0);
          }
        } catch (error) {
          console.error("Navigation error:", error);
          if (!window.location.pathname.includes(instanceId)) {
            navigate(`${currentPath}/${instanceId}${location.search}`, {
              replace: true,
            });
          }
        }
      }
    },
    onError: (error) => {
      console.error("Failed to update playground session:", error);
    },
  });
  const sessionInitialized = useRef(false);
  const isRunning = useRef(false);
  const playground = usePlayground();

  useEffect(() => {
    isRunning.current = false;
    sessionInitialized.current = false;
    updatePlaygroundSession.reset();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sessionId]);

  useEffect(() => {
    if (
      sessionInitialized.current ||
      isRunning.current ||
      playground.isPendingSessionInitialization
    )
      return;

    isRunning.current = true;
    updatePlaygroundSession.mutate({
      workspaceId: playground.workspace_id,
      session: {
        id: playground.instanceId,
        name: "Not implemented",
        prompt_blueprint: playground.blueprint,
        input_variables: playground.input_variable_sets.map(
          (set) => set.variables,
        ),
      },
    });
  }, [playground, updatePlaygroundSession]);
};

export default useIntializeSessionOnMount;
