import { ComponentRef, forwardRef } from "react";
import { SlidersHorizontal } from "lucide-react";

import { Button, ButtonProps } from "@/components/ui/button";

import { useParameters } from "./store";

export const ParametersTrigger = forwardRef<
  ComponentRef<typeof Button>,
  ButtonProps
>(({ ...props }, ref) => {
  const store = useParameters();

  return (
    <Button ref={ref} {...props} variant="ghost">
      <div
        className={`relative flex flex-row items-center ${
          store.isCustomParametersSet
            ? "after:absolute after:-right-2 after:-top-0 after:h-2 after:w-2 after:rounded-full after:bg-blue-500"
            : ""
        }`}
      >
        <SlidersHorizontal className="mr-2 h-4 w-4" />
        Parameters
      </div>
    </Button>
  );
});
