import { usePlayground } from "@/components/Playground2/playground-store";
import { useAuth } from "@/context/auth-context";
import { useUser } from "@/context/user-context";
import { useCreatePromptTemplate, useCreatePromptVersion } from "@/queries";
import { PromptVersion } from "@/types/apiGetters";
import { parseErrorMessage } from "@/utils/errors";
import { displayErrorToast } from "@/utils/toast";
import { useNavigate } from "react-router-dom";

const useSaveTemplate = () => {
  const userToken = useAuth()?.userToken;
  const createPromptTemplateMutation = useCreatePromptTemplate(userToken || "");
  const updatePromptTemplateMutation = useCreatePromptVersion(userToken || "");
  const workspaceId = useUser()?.activeWorkspaceId;
  const navigate = useNavigate();
  const playground = usePlayground();

  const handleCreate = (name: string) => {
    createPromptTemplateMutation.mutate(
      {
        ...playground.prompt_template,

        prompt_template: {
          prompt_name: name,
          workspace_id: workspaceId!,
        },
      },
      {
        onError: (error: any) => {
          displayErrorToast(
            error?.message ||
              "An error occurred while creating the prompt template",
          );
        },
        onSuccess: (data) => {
          if ("success" in data) {
            const message =
              typeof data.message === "string"
                ? data.message
                : parseErrorMessage(data.message);
            displayErrorToast(
              message ?? "An error occurred while creating the prompt template",
            );
            return;
          }
          playground.reassignInitialPayload();
          const prompt_id = data.prompt_version.prompt_id;
          setTimeout(
            () => navigate(`/workspace/${workspaceId}/prompt/${prompt_id}`),
            1,
          );
        },
      },
    );
  };

  const handleUpdate = (
    templateId: number,
    data: {
      commitMessage?: string;
      evaluationId?: number;
    },
  ): Promise<PromptVersion> => {
    return new Promise((resolve, reject) => {
      updatePromptTemplateMutation.mutate(
        {
          ...playground.blueprint,
          commit_message: data.commitMessage,
          report_id: data.evaluationId,
          prompt_id: templateId,
        },
        {
          onError: (error: any) => {
            displayErrorToast(
              error?.message ||
                "An error occurred while updating the prompt template",
            );
            reject();
          },
          onSuccess: (data) => {
            if ("success" in data) {
              const message =
                typeof data.message === "string"
                  ? data.message
                  : parseErrorMessage(data.message);
              displayErrorToast(
                message ??
                  "An error occurred while updating the prompt template",
              );
              return;
            }
            playground.reassignInitialPayload();
            resolve(data);
          },
        },
      );
    });
  };

  return {
    handleCreate,
    handleUpdate,
    isCreating: createPromptTemplateMutation.isLoading,
    isUpdating: updatePromptTemplateMutation.isLoading,
  };
};

export default useSaveTemplate;
