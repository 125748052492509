import { motion } from "framer-motion";
import { ReactNode, forwardRef } from "react";

const variants = {
  initial: { opacity: 0, y: 20, scale: 0.95 },
  animate: { opacity: 1, y: 0, scale: 1 },
  exit: { opacity: 0, scale: 0.95, transition: { duration: 0.1 } },
};

const transition = {
  type: "spring",
  stiffness: 350,
  damping: 35,
  mass: 0.7,
};

const AnimatedElement = forwardRef<
  HTMLDivElement,
  {
    children: ReactNode;
    className?: string;
  }
>(({ children, className }, ref) => {
  return (
    <motion.div
      ref={ref}
      layout
      initial="initial"
      animate="animate"
      exit="exit"
      variants={variants}
      transition={{
        ...transition,
      }}
      className={className}
    >
      {children}
    </motion.div>
  );
});

export default AnimatedElement;
