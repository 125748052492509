import { Button } from "@/components/ui/button";
import { BasicTooltip } from "@/components/ui/Tooltip";
import { AnimatePresence, motion } from "framer-motion";
import { Loader2 } from "lucide-react";
import { observer } from "mobx-react-lite";
import { useCallback, useEffect, useState } from "react";

interface RunButtonProps {
  run: () => void;
  isRunning?: boolean;
  disabled?: boolean;
  buttonText?: {
    idle?: string;
    running?: string;
  };
  tooltipText?: {
    idle?: string;
    running?: string;
  };
  tooltipSide?: "top" | "bottom" | "left" | "right";
}

const DEFAULT_TEXTS = {
  button: {
    idle: "Run",
    running: "Running...",
  },
  tooltip: {
    idle: "Run the request",
    running: "Request in progress...",
  },
};

const SHORTCUT_CMD_ENTER = {
  display: "⌘ ↩",
} as const;

const RunButton = ({
  run,
  isRunning,
  disabled,
  buttonText = DEFAULT_TEXTS.button,
  tooltipText = DEFAULT_TEXTS.tooltip,
  tooltipSide = "top",
}: RunButtonProps) => {
  const [isAnimating, setIsAnimating] = useState(false);

  const onClick = useCallback(() => {
    setIsAnimating(true);
    run();
    setTimeout(() => setIsAnimating(false), 500);
  }, [run]);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.metaKey && event.key === "Enter") {
        if (isRunning || disabled) return;
        event.preventDefault();
        onClick();
      }
    };

    window.addEventListener("keydown", handleKeyDown);
    return () => window.removeEventListener("keydown", handleKeyDown);
  }, [isRunning, disabled, onClick]);

  return (
    <BasicTooltip
      content={
        isRunning ? tooltipText.running : `${tooltipText.idle} (⌘ + Enter)`
      }
      side={tooltipSide}
    >
      <div className="pointer-events-auto z-10">
        <AnimatePresence>
          <motion.div
            initial={{ scale: 1 }}
            animate={
              isRunning
                ? {
                    scale: [1, 1.02, 1],
                    transition: {
                      repeat: Infinity,
                      duration: 1.2,
                      ease: "easeInOut",
                    },
                  }
                : isAnimating
                ? {
                    scale: [1, 1.03, 1],
                    transition: {
                      duration: 0.2,
                      ease: "easeOut",
                    },
                  }
                : {}
            }
            whileTap={isRunning ? undefined : { scale: 0.98 }}
            transition={{
              type: "spring",
              stiffness: 400,
              damping: 15,
            }}
          >
            <Button
              onClick={onClick}
              disabled={isRunning || disabled}
              className="min-w-[120px] transition-all duration-200 ease-in-out hover:brightness-105"
            >
              <div className="flex items-center justify-center gap-2">
                {isRunning && (
                  <Loader2 className="h-4 w-4 animate-spin opacity-70" />
                )}
                <span>{isRunning ? buttonText.running : buttonText.idle}</span>
                {!isRunning && (
                  <span className="select-none opacity-60 transition-opacity duration-200 ease-in-out">
                    {SHORTCUT_CMD_ENTER.display}
                  </span>
                )}
              </div>
            </Button>
          </motion.div>
        </AnimatePresence>
      </div>
    </BasicTooltip>
  );
};

export default observer(RunButton);
