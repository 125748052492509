import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { observer } from "mobx-react-lite";
import { MESSAGE_TYPES, MessageType } from "../../types";

interface RoleSelectorProps {
  selected: MessageType;
  onSelect: (type: MessageType) => void;
  readonly?: boolean;
}

const RoleSelector = ({
  selected,
  onSelect,
  readonly = false,
}: RoleSelectorProps) => {
  const types = MESSAGE_TYPES;

  const formatValue = (value: string) => {
    return value.charAt(0).toUpperCase() + value.slice(1).toLowerCase();
  };

  return (
    <Select
      defaultValue={selected}
      onValueChange={onSelect}
      disabled={readonly}
    >
      <SelectTrigger className="bg-white font-bold">
        <SelectValue placeholder={formatValue(selected)} />
      </SelectTrigger>
      <SelectContent>
        <SelectGroup>
          <SelectLabel>Roles</SelectLabel>
          {types.map((type) => (
            <SelectItem key={type} value={type}>
              {formatValue(type)}
            </SelectItem>
          ))}
        </SelectGroup>
      </SelectContent>
    </Select>
  );
};

export default observer(RoleSelector);
