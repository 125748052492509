import { WEBSOCKET_MESSAGE_NAME } from "@/constants";
import { useUser } from "@/context/user-context";
import { useChannel } from "ably/react";
import { usePlayground } from "../../playground-store";

const useNewMessageListener = () => {
  const { user } = useUser();
  const playground = usePlayground();

  const handleNewMessage = (payload: any) => {
    const {
      payload: { message },
      individual_run_request_id,
    } = JSON.parse(payload.data);

    if (!playground.isRequestValid(individual_run_request_id)) return;

    const operation =
      playground.operationStore.getAssociatedOperationByRequestId(
        individual_run_request_id,
      );

    // Update pre-existing output instance
    if (operation?.output_instance_id) {
      const output = playground.getOutput(operation.output_instance_id!)!;
      const newId = output.addMessage(message);
      playground.operationStore.assignMessageId(
        operation.id,
        newId,
        individual_run_request_id,
      );
      return;
    }

    // Initialize output instance
    const clonedPlayground = playground.clone(operation);
    const newId = clonedPlayground.addMessage(message);

    const output = playground.addOutput(clonedPlayground);
    playground.operationStore.assignOutputId(operation!.id, output.instanceId);

    playground.operationStore.assignMessageId(
      operation!.id,
      newId,
      individual_run_request_id,
    );
  };

  useChannel(
    `user:${user.id}`,
    WEBSOCKET_MESSAGE_NAME.CREATE_NEW_MESSAGE,
    handleNewMessage,
  );
};

export default useNewMessageListener;
