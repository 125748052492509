import { ENDPOINTS } from "@/api/application-api";
import { useAuth } from "@/context/auth-context";
import { ToastType } from "@/enums";
import { Metadata, Model, PromptBlueprint, Tool, ToolChoice } from "@/types";
import { IndividualRun } from "@/types/metadata";
import { InputVariableRow } from "@/types/playground";
import { handleResponse } from "@/utils/errorResponseHandlers";
import { jsonAuthHeaders } from "@/utils/headers";
import { displayToast } from "@/utils/toast";
import {
  action,
  computed,
  makeAutoObservable,
  observable,
  toJS,
  transaction,
} from "mobx";
import { useLocalObservable } from "mobx-react-lite";
import { createContext, useContext } from "react";
import { useParams } from "react-router-dom";
import { v4 } from "uuid";
import { IMessageStore, MessageStore } from "./Messages/Message/message-store";

import { PlaygroundSession } from "@/queries/playground/useUpdatePlaygroundSession";
import { PromptVersion } from "@/types/apiGetters";
import { Variable } from "../InputVariableConfigurator/types";
import OperationStore from "./operation-store";
import {
  DEFAULT_CHAT_MODEL,
  DEFAULT_COMPLETION_MODEL,
  DEFAULT_MESSAGE_1,
  DEFAULT_MESSAGE_1_ROLE,
  DEFAULT_MESSAGE_2,
  DEFAULT_MESSAGE_2_ROLE,
  DEFAULT_MESSAGE_TYPE,
  DEFAULT_MODEL,
  DEFAULT_OUTPUT_PAGE_SIZE,
  Message,
  ModelParameters,
  Operation,
  PlaygroundType,
  RunGroupRequest,
  RunGroupResponse,
  TemplateFormat,
  VariableSet,
} from "./types";
import { getFinalMessage, parseTypeOptimistically } from "./utils";

export class PlaygroundStore {
  private _mode: PlaygroundType = "chat";
  @observable private _messages: IMessageStore[] = [
    new MessageStore({
      content: [DEFAULT_MESSAGE_1],
      role: DEFAULT_MESSAGE_1_ROLE,
    }),
    new MessageStore({
      content: [DEFAULT_MESSAGE_2],
      role: DEFAULT_MESSAGE_2_ROLE,
    }),
  ];
  private _previous_messages: MessageStore[] = [
    new MessageStore({
      content: [DEFAULT_MESSAGE_1],
      role: DEFAULT_MESSAGE_1_ROLE,
    }),
    new MessageStore({
      content: [DEFAULT_MESSAGE_2],
      role: DEFAULT_MESSAGE_2_ROLE,
    }),
  ];
  @observable private _input_variables: Variable[] = [];
  @observable private _input_variable_sets: VariableSet[] = [];
  @observable private _previous_input_variable_sets: VariableSet[] = [];
  @observable private _detected_input_variables: string[] = [];
  private _model: Model | null = DEFAULT_MODEL;
  private _metadata: Metadata | null = null;
  private _template_format: TemplateFormat = "f-string";
  private _functions: any = {};
  private _structured_output: any = {};

  private _workspace_id: number;
  private _latestIndividualRunRequestId: number = -1;
  private _runs: IndividualRun[] = [];
  private _inference_client_name: string | null = null;
  private _provider_base_url_name: string | null = null;

  private _outputs: IPlayground[] = [];
  private _initial_outputs: IPlayground[] = [];
  private _initial_outputs_page: number = 1;
  private userToken: string;
  private _error: string | null = null;
  private _instanceId: string = v4();
  private _tools?: Tool[];
  private _tool_choice: ToolChoice | undefined = undefined;
  private _selected_template_verison?: PromptVersion = undefined;
  private _operation_store: OperationStore = new OperationStore();
  private _current_operation: Operation | null = null;
  private _initial_payload: string | null = null;
  private _is_pending_session_initialization: boolean = false;
  private _is_saving_session = false;
  private _readonly: boolean = false;
  private _editor: boolean = false;

  constructor({
    workspace_id,
    prompt_blueprint,
    input_variables,
    input_variable_sets,
    userToken,
    selected_template_version,
    operation,
    instance_id,
    outputs,
    readonly,
    override_id,
    editor,
  }: {
    workspace_id: number;
    prompt_blueprint?: Partial<PromptBlueprint>;
    input_variables?: InputVariableRow[];
    input_variable_sets?: VariableSet[];
    userToken: string;
    selected_template_version?: PromptVersion;
    operation?: Operation;
    instance_id?: string;
    outputs?: IPlayground[];
    readonly?: boolean;
    override_id?: string;
    editor?: boolean;
  }) {
    if (instance_id) {
      this._instanceId = instance_id;
      this._is_pending_session_initialization = true;
    } else if (override_id) {
      this._instanceId = override_id;
      this._is_pending_session_initialization = true;
    }

    if (editor) this._editor = editor;

    if (outputs) this._outputs = outputs;

    if (readonly) this._readonly = readonly;

    this._workspace_id = workspace_id;
    this.userToken = userToken;

    if (operation) this._current_operation = operation;
    if (prompt_blueprint) this.applyPromptBlueprintToState(prompt_blueprint);

    if (input_variable_sets)
      this._input_variable_sets = input_variable_sets.map((set) => ({
        id: v4(),
        variables: set.variables,
      }));

    if (input_variables)
      this._input_variable_sets = [
        {
          id: v4(),
          variables: input_variables.map((variable) => ({
            ...variable,
            id: v4(),
          })),
        },
      ];
    if (selected_template_version)
      this._selected_template_verison = selected_template_version;
    this._initial_payload = JSON.stringify(this.payload);
    makeAutoObservable(this, {}, { autoBind: true, deep: true });
  }

  get isEditor() {
    return this._editor;
  }

  get isSavingSession() {
    return this._is_saving_session;
  }

  get providerBaseUrlName() {
    return this._provider_base_url_name;
  }

  @action setIsSavingSession(isSavingSession: boolean) {
    this._is_saving_session = isSavingSession;
  }

  get sourcedSnippets() {
    return this.selectedTemplateVersion?.sourced_snippets || [];
  }

  get workspace_id() {
    return this._workspace_id;
  }

  get readonly() {
    return this._readonly;
  }

  get functionType() {
    if (this._tools && this._tools.length > 0) return "tools";
    return "functions";
  }

  @action setMetadata(metadata: Metadata) {
    this._metadata = metadata;
  }

  @action
  initSession(session: PlaygroundSession) {
    this.applyPromptBlueprintToState(session.prompt_blueprint);
    this._input_variable_sets = session.input_variables.map((set) => ({
      id: v4(),
      variables: set,
    }));
    this._initial_payload = JSON.stringify(this.payload);
    this._is_pending_session_initialization = false;
  }

  get isPendingSessionInitialization() {
    return this._is_pending_session_initialization;
  }

  @action setIsPendingSessionInitialization(
    isPendingSessionInitialization: boolean,
  ) {
    this._is_pending_session_initialization = isPendingSessionInitialization;
  }

  get hasBeenEdited() {
    return this._initial_payload !== JSON.stringify(this.payload);
  }

  @action setDetectedInputVariables(detected_input_variables: string[]) {
    this._detected_input_variables = detected_input_variables;
  }

  get instanceId() {
    return this._instanceId;
  }

  get currentOperation() {
    return this._current_operation;
  }

  @action setCurrentOperation(operation: Operation) {
    this._current_operation = operation;
  }

  get operationStore() {
    return this._operation_store;
  }

  get error() {
    return this._error;
  }

  get selectedTemplateVersion() {
    return this._selected_template_verison;
  }

  @action setSelectedTemplateVersion(version: PromptVersion) {
    this._selected_template_verison = version;
  }

  get messagesContent() {
    return this.messages
      .map(
        (message) =>
          `${message.content} ${
            message.media_variable
          } ${message.selected_media?.map((_, index) => index)}`,
      )
      .join("\n");
  }

  get inputVariableSetsContent() {
    return JSON.stringify(this._input_variable_sets.map((v) => v.variables));
  }

  reassignInitialPayload() {
    this._initial_payload = JSON.stringify(this.payload);
  }

  @action async run() {
    if (this.outputsPage > 1) this.setInitialOutputsPage(1);
    let sets = this._input_variable_sets;
    if (sets.length === 0) sets = [{ id: v4(), variables: [] }];

    const id = this.operationStore.createNewOperation({
      input_variable_sets: toJS(sets),
    });

    try {
      const runGroupPayload: RunGroupRequest = {
        id,
        playground_session_id: this.instanceId,
        shared_prompt_blueprint: this.blueprint,
        individual_run_requests: sets.map((set, index) => ({
          input_variables: set.variables.reduce(
            (acc, variable) => ({
              ...acc,
              [variable.key]: parseTypeOptimistically(variable.value),
            }),
            {},
          ),
          run_group_position: index + 1,
        })),
      };

      const createNewRunGroup = await fetch(
        ENDPOINTS.run_groups(this._workspace_id),
        {
          method: "POST",
          headers: jsonAuthHeaders(this.userToken),
          body: JSON.stringify(runGroupPayload),
        },
      );

      const runGroupData: RunGroupResponse =
        await handleResponse(createNewRunGroup);

      if (!runGroupData.success) {
        const errorMsg =
          "Whoops, something seems to have gone wrong. Please try again.";
        displayToast(errorMsg, ToastType.error);
        this.operationStore.deleteOperation(id);
        return;
      }

      if (runGroupData.warnings && runGroupData.warnings.length > 0) {
        runGroupData.warnings.forEach((warning) => {
          displayToast(warning, ToastType.warning);
        });
      }

      if (
        runGroupData.run_group &&
        runGroupData.run_group.individual_run_requests
      ) {
        runGroupData.run_group.individual_run_requests.forEach(
          (request, index) => {
            const setId = sets[index]?.id;
            if (setId && request.id) {
              this.operationStore.assignSetRequestId(id, setId, request.id);
              this.operationStore.assignSetPosition(
                id,
                setId,
                request.run_group_position,
              );
            }
          },
        );
      }
    } catch (error) {
      console.error("Failed to create run group:", error);
      displayToast(
        "Failed to create run group. Please try again.",
        ToastType.error,
      );
      this.operationStore.deleteOperation(id);
    }
  }

  getMatchingBatchOperationSet(request_id: number) {
    if (!this._current_operation) return;
    const setList = Object.values(this._current_operation.sets);
    return setList.find((set) => set.request_id === request_id);
  }

  @action setOperationRun(run: IndividualRun) {
    if (!this._current_operation) return;

    const matchingSet = this.getMatchingBatchOperationSet(run.id!);

    if (matchingSet) {
      this._current_operation.sets[matchingSet.id].run = run;
      const finalMessage = getFinalMessage(run);
      if (finalMessage)
        this.updateMessage(matchingSet.message_id!, finalMessage);
    }
  }

  getMessageRun(message_id: string): IndividualRun | null {
    if (!this._current_operation) {
      return null;
    }
    return (
      Object.values(this._current_operation.sets).find(
        (set) => set?.message_id === message_id,
      )?.run || null
    );
  }

  getMessagePosition(message_id: string) {
    if (!this._current_operation) {
      return 0;
    }
    return (
      Object.values(this._current_operation.sets).find(
        (set) => set?.message_id === message_id,
      )?.position || 0
    );
  }

  @action setOperationMessageCompleted(request_id: number) {
    if (!this._current_operation) return;
    const matchingSetId = Object.values(this._current_operation.sets).find(
      (set) => set.request_id === request_id,
    )?.id;
    if (matchingSetId) {
      this._current_operation.sets[matchingSetId].completed = true;
    }
  }

  @action setRequestError(request_id: number, error: string) {
    if (!this._current_operation) return;
    const matchingSetId = Object.values(this._current_operation.sets).find(
      (set) => set.request_id === request_id,
    )?.id;
    if (matchingSetId) {
      this._current_operation.sets[matchingSetId].error = error;
    }
  }

  isOperationMessage(message_id: string) {
    if (!this._current_operation) return false;

    return Object.values(this._current_operation.sets).some(
      (set) => set?.message_id === message_id,
    );
  }

  get pendingOutputs() {
    return Array.from(this.operationStore.getOperations().values()).filter(
      (op) => !op?.output_instance_id,
    ).length;
  }

  get outputMessages() {
    return this.messages
      .filter((message) => this.isOperationMessage(message.id))
      .sort((a, b) => {
        return this.getMessagePosition(a.id) - this.getMessagePosition(b.id);
      });
  }

  isValidOperation(operation_id: string) {
    return !!this.operationStore.getOperation(operation_id);
  }

  isRequestValid(request_id: number) {
    // Used for filtering erroneous requests
    const isValid =
      this.operationStore.getAssociatedOperationByRequestId(request_id);
    return !!isValid;
  }

  isPendingBatchRequestInitialization(operation: Operation) {
    if (!operation) return false;

    return Object.values(operation.sets || {}).some((set) => set.message_id);
  }

  get detectedInputVariables() {
    return this._detected_input_variables;
  }

  get metadata() {
    return this._metadata;
  }

  get toolChoice() {
    return this._tool_choice ?? undefined;
  }

  @action
  applyPromptBlueprintToState(prompt_blueprint: Partial<PromptBlueprint>) {
    if (!prompt_blueprint) return;

    this._provider_base_url_name = prompt_blueprint.provider_base_url_name!;
    this._inference_client_name = prompt_blueprint.inference_client_name!;
    if (prompt_blueprint.metadata) {
      this._model = prompt_blueprint.metadata.model || DEFAULT_MODEL;
      const clone = { ...prompt_blueprint.metadata };
      delete clone.model;
      this._metadata = clone;
    }

    if (prompt_blueprint.prompt_template) {
      const template = prompt_blueprint.prompt_template;

      if ("tools" in template) {
        this._tools = template.tools;
      }

      if ("tool_choice" in template) {
        this._tool_choice = template.tool_choice as ToolChoice;
      }

      if (template.type === "chat") {
        this._mode = "chat";

        this._template_format =
          template.messages?.[0]?.template_format || "f-string";

        this._messages = template.messages.map((msg) => {
          return new MessageStore({
            ...msg,
          });
        });
        if (template.functions) {
          this._functions = template.functions.reduce(
            (acc: Record<string, any>, fn) => {
              acc[fn.name] = {
                description: fn.description,
                parameters: fn.parameters,
              };
              return acc;
            },
            {},
          );
        }
      } else {
        this._mode = "completion";
        this._template_format = template.template_format;
        this._messages = [
          new MessageStore({
            content: [
              {
                type: "text",
                text:
                  template.content[0].type === "text"
                    ? template.content[0].text
                    : "",
              },
            ],
            role: "assistant",
          }),
        ];
      }

      if ("template_format" in template) {
        this._template_format = template.template_format as TemplateFormat;
      }
    }
  }

  get latestIndividualRun() {
    return this._runs?.[0];
  }

  get runs() {
    return this._runs;
  }

  @action
  addInputVariable(variable: Variable) {
    this._input_variables.push(variable);
  }

  @action
  updateInputVariable(set_id: string) {
    return action((id: string, key: string, value: string | object) => {
      const setIndex = this._input_variable_sets.findIndex(
        (v) => v.id === set_id,
      );
      if (setIndex !== -1) {
        const variableIndex = this._input_variable_sets[
          setIndex
        ].variables.findIndex((v) => v.id === id);
        if (variableIndex !== -1) {
          // Update existing variable
          this._input_variable_sets[setIndex].variables[variableIndex] = {
            ...this._input_variable_sets[setIndex].variables[variableIndex],
            key,
            value,
          };
        } else {
          // Add new variable
          this._input_variable_sets[setIndex].variables.push({
            id,
            key,
            value,
          });
        }
      }
    });
  }

  @action clearInputVariableSet(set_id: string) {
    const setIndex = this._input_variable_sets.findIndex(
      (v) => v.id === set_id,
    );
    if (setIndex !== -1) {
      this._input_variable_sets[setIndex].variables = [];
    }
  }

  @action
  removeInputVariable(set_id: string) {
    return action((id: string) => {
      const set = this._input_variable_sets.find((s) => s.id === set_id);
      if (set) {
        const variableIndex = set.variables.findIndex((v) => v.id === id);
        if (variableIndex !== -1) {
          set.variables.splice(variableIndex, 1);
        }
      }
    });
  }

  get latestIndividualRunRequestId() {
    return this._latestIndividualRunRequestId;
  }

  @action setLatestIndividualRunRequestId(id: number) {
    this._latestIndividualRunRequestId = id;
  }

  @action addRun(run: IndividualRun) {
    this._runs.unshift(run);
  }

  get mode() {
    return this._mode;
  }

  set mode(value: PlaygroundType) {
    this._mode = value;
  }

  @computed
  get messages() {
    return this.isChat ? this._messages : this._messages.slice(0, 1);
  }

  @action
  addMessage(message: Message) {
    const newMessage = new MessageStore({
      ...message,
    });
    this._messages.push(newMessage);
    return newMessage.id;
  }

  @action
  addEmptyMessage() {
    transaction(() => {
      const newMessage = new MessageStore({
        content: [],
        role: DEFAULT_MESSAGE_TYPE,
      });
      this._messages.push(newMessage);
    });
  }

  @action
  updateMessage(message_id: string, value: Message) {
    const message = this._messages.find((msg) => msg.id === message_id);
    message && message.replace(value);
  }

  @action
  appendToTemplate(message: string) {
    const completion_message: MessageStore = this._messages[0];
    completion_message.setMessage(completion_message.content + message);
  }

  deleteMessage(id: string) {
    if (this.currentOperation) {
      const relatedSet = Object.values(this.currentOperation.sets).find(
        (set) => set.message_id === id,
      );
      if (relatedSet) delete this.currentOperation.sets[relatedSet.id];
    }
    const index = this._messages.findIndex((message) => message.id === id);
    if (index !== -1) {
      this._messages.splice(index, 1);
    }
  }

  @action
  setModel(model: Model) {
    this._model = model;
  }

  @action setInputVariableRows(values: InputVariableRow[]) {
    this._input_variables = values.map((row) => ({
      id: v4(),
      key: row.key,
      value: row.value,
    }));
  }

  @action
  update(params: {
    model?: Model;
    inference_client_name: string | null;
    provider_base_url_name: string | null;
    functions: any;
    tools: any;
    tool_choice: any;
  }): void {
    // Cache stringified values to avoid multiple stringifications
    const modelString = params.model ? JSON.stringify(params.model) : null;
    const currentModelString = this._model ? JSON.stringify(this._model) : null;
    const functionsString = JSON.stringify(params.functions);
    const currentFunctionsString = JSON.stringify(this._functions);
    const toolsString = JSON.stringify(params.tools);
    const currentToolsString = JSON.stringify(this._tools);
    const toolChoiceString = JSON.stringify(params.tool_choice);

    // Batch updates in single transaction
    transaction(() => {
      if (params.tool_choice && toolChoiceString !== currentToolsString)
        this._tool_choice = params.tool_choice;
      if (params.model && modelString !== currentModelString)
        this._model = params.model;

      if (this._inference_client_name !== params.inference_client_name)
        this._inference_client_name = params.inference_client_name;

      if (this._provider_base_url_name !== params.provider_base_url_name)
        this._provider_base_url_name = params.provider_base_url_name;

      if (functionsString !== currentFunctionsString)
        this._functions = params.functions || {};

      if (toolsString !== currentToolsString) this._tools = params.tools || {};
    });
  }

  get model() {
    return this._model;
  }

  get provider() {
    return this._model?.provider;
  }

  get model_name() {
    return this._model?.name;
  }

  @action setProvider(provider: string) {
    if (this._model) {
      this._model.provider = provider;
    } else {
      this._model = {
        provider,
        name: "",
        parameters: {},
      };
    }
  }

  get hasOutputContent() {
    return (
      (this.isPendingNewMessage && this.isChat) ||
      this.outputs.length > 0 ||
      this.pendingOutputs > 0
    );
  }

  @action setModelName(modelName: string) {
    if (this._model) {
      this._model.name = modelName;
    }
  }

  get isPendingNewMessage() {
    return this.pendingOutputs > 0;
  }

  get input_variables() {
    return this.input_variable_sets[0]?.variables || [];
  }

  get parameters() {
    return this._model?.parameters || {};
  }

  setParameter<K extends keyof ModelParameters>(
    key: K,
    value: ModelParameters[K],
  ) {
    if (this._model) {
      this._model.parameters[key] = value;
    }
  }

  setParameters(parameters: ModelParameters) {
    if (this._model) {
      this._model.parameters = parameters;
    }
  }

  getParameterValue<K extends keyof ModelParameters>(key: K) {
    const value = this._model?.parameters[key];
    if (typeof value === "object") {
      return (value as any).default;
    } else {
      return value;
    }
  }

  get template_format() {
    return this._template_format;
  }

  set template_format(value: TemplateFormat) {
    this._template_format = value;
  }

  @action
  setTemplateFormat(value: TemplateFormat) {
    this._template_format = value;
  }

  get functions() {
    return this._functions;
  }

  get combinedToolsAndFunctions() {
    const functions = this._functions
      ? Object.entries(this._functions).map(([key, value]) => ({
          name: key,
          ...(value as any),
        }))
      : [];
    const tools = this._tools ? this._tools.map(({ function: fn }) => fn) : [];
    return [...tools, ...functions];
  }

  setFunction(key: string, value: any) {
    this._functions[key] = value;
  }

  get structured_output() {
    return this._structured_output;
  }

  get isChat() {
    return this._mode === "chat";
  }

  @computed({ keepAlive: true })
  get payload() {
    return {
      input_variables: this._input_variable_sets.map((v) => v.variables),
      prompt_blueprint: toJS(this.blueprint),
      workspace_id: this._workspace_id,
    };
  }

  get prompt_template() {
    return {
      prompt_template: {
        prompt_name: Math.random().toString(36).substring(7),
        workspace_id: this._workspace_id,
      },
      prompt_version: this.blueprint,
    };
  }

  hasMessage(id: string) {
    return this._messages.find((message) => message.id === id);
  }

  get functionToolCallContent() {
    return `${JSON.stringify(this._tools)} ${JSON.stringify(this._functions)}`;
  }

  @computed({ keepAlive: true })
  get blueprint(): PromptBlueprint {
    return {
      inference_client_name: this._inference_client_name || null,
      metadata: {
        ...toJS(this._metadata),
        model: toJS(this._model!),
      },
      prompt_template:
        this._mode === "chat"
          ? {
              type: "chat" as const,
              messages: toJS(
                this._messages.map((message) => ({
                  ...(message.schema as any),
                  template_format: this._template_format,
                })),
              ),
              tools: this._tools,
              tool_choice: this._tool_choice,
              input_variables: [],
              functions: toJS(
                Object.entries(this._functions).map(
                  ([name, value]: [string, any]) => ({
                    name: value.name || name,
                    description: value.description,
                    parameters: value.parameters,
                  }),
                ),
              ),
            }
          : {
              type: "completion" as const,
              content: toJS(this._messages[0].rawContent),
              input_variables: [],
              template_format: this._template_format,
            },
      provider_base_url_name: this._provider_base_url_name,
    };
  }

  @action
  toggleMode() {
    transaction(() => {
      const copied_messages = Array.from(this._messages);
      const copied_previous_messages = Array.from(this._previous_messages);
      this._previous_messages = copied_messages;
      this._messages = observable.array(copied_previous_messages);
      this._mode = this._mode === "chat" ? "completion" : "chat";
      this._model =
        this._mode === "chat" ? DEFAULT_CHAT_MODEL : DEFAULT_COMPLETION_MODEL;
      const prevSetsCopy = Array.from(this._previous_input_variable_sets);
      this._previous_input_variable_sets = this._input_variable_sets;
      this._input_variable_sets = observable.array(prevSetsCopy || []);
    });
  }

  setStructuredOutput(value: any) {
    this._structured_output = value;
  }

  @action
  clone(operation?: Operation): PlaygroundStore {
    // TODO: debug lost parameter settings
    // Create deep clones of complex nested objects
    const clonedBlueprint = JSON.parse(JSON.stringify(toJS(this.blueprint)));
    const clonedVariables = JSON.parse(
      JSON.stringify(toJS(this.input_variable_sets)),
    );

    return new PlaygroundStore({
      workspace_id: this._workspace_id,
      prompt_blueprint: clonedBlueprint,
      instance_id: operation?.output_instance_id || undefined,
      input_variable_sets: clonedVariables,
      userToken: this.userToken,
      operation: operation,
    });
  }

  get localOutputs() {
    return this._outputs;
  }

  get outputs() {
    // If we're on page > 1, only show initial outputs
    if (this.outputsPage > 1) {
      return this._initial_outputs.slice(0, DEFAULT_OUTPUT_PAGE_SIZE);
    }

    const combined = [
      ...this._outputs,
      ...this._initial_outputs.filter(
        (elem) =>
          !this._outputs.find(
            (output) =>
              output.currentOperation?.id === elem.currentOperation?.id,
          ),
      ),
    ];

    return combined.slice(0, DEFAULT_OUTPUT_PAGE_SIZE);
  }

  get isOutputCompleted() {
    return (
      this.currentOperation?.sets &&
      Object.values(this.currentOperation.sets).every((set) => set.completed)
    );
  }

  get isMessageCompleted() {
    return (message_id: string) => {
      return !!Object.values(this.currentOperation?.sets || {}).find(
        (set) => set.message_id === message_id,
      )?.completed;
    };
  }

  get latestOutput() {
    return this._outputs[0];
  }

  @action deleteOutput(output: IPlayground) {
    const operation = this.operationStore.getAssociatedOperationByOutputId(
      output.instanceId,
    );

    if (operation) {
      this.operationStore.deleteOperation(operation.id);
    }

    // Filter from both collections
    this._outputs = this._outputs.filter(
      (item) => item.instanceId !== output.instanceId,
    );

    this._initial_outputs = this._initial_outputs.filter(
      (item) => item.instanceId !== output.instanceId,
    );

    // Avoid pagination issues when deleting the last item on a page
    if (this.outputs.length === 0 && this.outputsPage > 1) {
      this.setInitialOutputsPage(Math.max(1, this.outputsPage - 1));
    }
  }

  @action addOutput(output: IPlayground) {
    this._outputs.unshift(output);
    return output;
  }

  @action setOutputs(outputs: IPlayground[]) {
    this._outputs = outputs;
  }

  get outputsPage() {
    return this._initial_outputs_page;
  }

  @action setInitialOutputsPage(page: number) {
    this._initial_outputs_page = page;
  }

  @action setInitialOutputs(outputs: IPlayground[]) {
    this._initial_outputs = outputs;
  }

  getOutput(output_id: string) {
    return this._outputs.find((output) => output.instanceId === output_id);
  }

  get remainingBatchMessages() {
    return this.outputMessages.length;
  }

  get latestMessage() {
    return this.messages[this.messages.length - 1];
  }

  getMessage(message_id: string) {
    return this.messages.find((message) => message.id === message_id);
  }

  getMessageByIndex(index: number) {
    return this.messages[index];
  }

  @action popIn(output: IPlayground, message_id: string) {
    const lastOutputMessage = new MessageStore(
      output.getMessage(message_id)!.schema,
    );
    lastOutputMessage.resetId();
    transaction(() => {
      this._messages.push(lastOutputMessage);
    });
  }

  @action rewind(output: IPlayground) {
    this._input_variable_sets = toJS(output._input_variable_sets);
    const outputCopy = output.clone();
    if (this.isChat)
      output.outputMessages.forEach(() => outputCopy._messages.pop()); // Remove the output messages

    this.applyPromptBlueprintToState(outputCopy.blueprint);
  }

  get rewindBlueprint() {
    const outputCopy = this.clone();
    if (this.isChat)
      this.outputMessages.forEach(() => outputCopy._messages.pop()); // Remove the output messages
    return outputCopy.blueprint;
  }

  /* Input Variable Sets */

  get input_variable_sets() {
    return this._input_variable_sets;
  }

  get raw_input_variable_sets() {
    return this._input_variable_sets.map((set) => set.variables);
  }

  @action addEmptyInputVariableSet(): string {
    const newInputVariableSet: VariableSet = {
      id: v4(),
      variables: this.detectedInputVariables.map((varName) => ({
        id: v4(),
        key: varName,
        value: "",
      })),
    };
    this._input_variable_sets.push(newInputVariableSet);
    return newInputVariableSet.id;
  }
  @action
  addInputVariableSet(input_variable_set: VariableSet) {
    this._input_variable_sets.push(input_variable_set);
  }

  @action duplicateInputVariableSet(set: VariableSet) {
    const id = v4();
    const newInputVariableSet: VariableSet = {
      id,
      variables: toJS(set.variables),
    };
    this._input_variable_sets.push(newInputVariableSet);
    return id;
  }

  @action
  updateInputVariableSet(id: string, field: "variables", newValue: Variable[]) {
    const input_variable_set = this._input_variable_sets.find(
      (v) => v.id === id,
    );
    if (input_variable_set) {
      input_variable_set.variables = newValue;
    }
  }

  @action
  removeInputVariableSet(id: string) {
    const index = this._input_variable_sets.findIndex((v) => v.id === id);
    if (index !== -1) {
      this._input_variable_sets.splice(index, 1);
    }
  }

  get canAddInputVariableSet() {
    return (
      (this.isChat && this._input_variable_sets.length <= 4) ||
      this._input_variable_sets.length === 0
    );
  }
}

export const PlaygroundStoreContext = createContext<PlaygroundStore | null>(
  null,
);

export const PlaygroundStoreProvider: React.FC<{
  children: React.ReactNode;
  value?: {
    prompt_blueprint?: Partial<PromptBlueprint>;
    input_variables?: InputVariableRow[];
    selected_template_version?: PromptVersion;
    readonly?: boolean;
    instance_id?: string;
    editor?: boolean;
  };
}> = ({ children, value }) => {
  const { workspaceId, sessionId } = useParams();
  const userToken = useAuth()?.userToken || "";
  const store = useLocalObservable(
    () =>
      new PlaygroundStore({
        prompt_blueprint: value?.prompt_blueprint,
        input_variables: value?.input_variables,
        workspace_id: Number(workspaceId),
        userToken,
        selected_template_version: value?.selected_template_version,
        instance_id: sessionId,
        override_id: value?.instance_id,
        readonly: value?.readonly,
        editor: value?.editor,
      }),
  );

  return (
    <PlaygroundStoreContext.Provider value={store}>
      {children}
    </PlaygroundStoreContext.Provider>
  );
};

export const usePlayground = () => {
  const store = useContext(PlaygroundStoreContext);
  if (!store) {
    throw new Error("useStore must be used within a StoreProvider");
  }
  return store;
};

export const withPlaygroundState = <
  P extends {
    blueprint?: Partial<PromptBlueprint>;
    inputVariables?: InputVariableRow[];
    selectedTemplateVersion?: PromptVersion;
    readonly?: boolean;
    editor?: boolean;
    instanceId?: string;
  },
>(
  Component: React.ComponentType<P>,
) => {
  return (props: P) => {
    return (
      <PlaygroundStoreProvider
        value={{
          prompt_blueprint: props.blueprint,
          input_variables: props.inputVariables,
          selected_template_version: props.selectedTemplateVersion,
          readonly: props.readonly,
          editor: props.editor,
          instance_id: props.instanceId,
        }}
      >
        <Component {...props} />
      </PlaygroundStoreProvider>
    );
  };
};

export type IPlayground = InstanceType<typeof PlaygroundStore>;
