import { XIcon } from "@heroicons/react/solid";
import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";

import { useWorkflow } from "../workflow-context";
import { SaveWorkflowMutationResponse } from "../workflow-queries";

interface ErrorBannerProps {
  title?: string;
  message?: SaveWorkflowMutationResponse["message"];
  icon?: React.ReactNode;
  className?: string;
}

const DefaultIcon = () => (
  <svg
    className="h-5 w-5 text-red-400"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 20 20"
    fill="currentColor"
    aria-hidden="true"
  >
    <path
      fillRule="evenodd"
      d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
      clipRule="evenodd"
    />
  </svg>
);

const ErrorBanner: React.FC<ErrorBannerProps> = ({
  title = "Something went wrong",
  message = "We're sorry, but something went wrong.",
  icon = <DefaultIcon />,
  className = "",
}) => {
  const workflow = useWorkflow();
  const errors = workflow.generalErrors;
  const hasErrors = errors.length > 0;
  const [isVisible, setIsVisible] = useState(false);

  const humanReadableMessage =
    typeof errors === "string"
      ? errors
      : errors instanceof Array
      ? errors.map((item: any) => {
          return (
            <div key={item.loc.join(".")} className="mb-2">
              {item.msg}
              <br />
              {item.loc.length > 0 && (
                <span className="text-xs text-red-600">
                  Location: {item.loc.join(".")}
                </span>
              )}
            </div>
          );
        })
      : errors;

  const handleDismiss = () => {
    setIsVisible(false);
    setTimeout(() => {
      workflow.setErrors(null);
    }, 300);
  };

  useEffect(() => {
    if (hasErrors) {
      setIsVisible(true);
    }
  }, [hasErrors]);

  return (
    <div className="pointer-events-none absolute inset-0 z-[3] flex items-start justify-center">
      <div
        className={` mt-4 w-full max-w-md rounded-md border-l-4 border-red-500 bg-red-100 p-4 shadow-md transition-all duration-300 ease-in-out ${className} ${
          isVisible
            ? "pointer-events-auto translate-y-0 opacity-100"
            : "pointer-events-none -translate-y-4 opacity-0"
        }`}
      >
        <div className="flex justify-between">
          <div className="flex">
            <div className="flex-shrink-0">{icon}</div>
            <div className="ml-3">
              <h3 className="text-sm font-medium text-red-800">{title}</h3>
              <div className="mt-2 text-sm text-red-700">
                {humanReadableMessage}
              </div>
            </div>
          </div>
          <button
            onClick={handleDismiss}
            className="text-red-400 transition-colors duration-200 hover:text-red-500 focus:outline-none"
          >
            <XIcon className="h-5 w-5" />
          </button>
        </div>
      </div>
    </div>
  );
};

export default observer(ErrorBanner);
