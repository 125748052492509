import { Link } from "react-router-dom";

import logo from "@/assets/logo/promptlayer-cake-color.svg";
import { Button } from "@/components/ui/button";

import {
  MOBILE_SCHEDULE_DEMO_BUTTON_TEXT,
  MOBILE_WARNING_MESSAGE,
} from "./constants";

export const NoMobileSupportMessage = () => {
  const shouldShowCalendly = localStorage.getItem("showScheduleCallModal");

  return (
    <div className="absolute inset-0 flex flex-col items-center justify-center gap-4 bg-white p-4">
      <img src={logo} alt="PromptLayer logo" className="h-14 w-14" />
      <p className="text-lg font-bold">We don't support mobile devices yet.</p>
      <p className="text-center text-sm text-gray-500">
        {shouldShowCalendly
          ? MOBILE_SCHEDULE_DEMO_BUTTON_TEXT
          : MOBILE_WARNING_MESSAGE}
      </p>
      {shouldShowCalendly && (
        <div className="flex flex-col gap-2">
          <Button variant="outline">
            <Link to="https://calendly.com/d/crwt-mj8-nnc/promptlayer-demo-with-david-and-jon">
              Schedule a demo
            </Link>
          </Button>
        </div>
      )}
    </div>
  );
};
