import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/Tooltip";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";

import { CurlyBracesIcon } from "lucide-react";
import { observer } from "mobx-react-lite";
import { useWorkflow } from "../../../workflow-context";
import {
  ReadOnlyVariableList,
  TooltipVariableList,
} from "./InputVariablesList";
import WorkflowInputVariableConfigurator from "./WorkflowInputVariableConfigurator";

interface InputVariablesProps {
  readonly: boolean;
}

const InputVariables = ({ readonly }: InputVariablesProps) => {
  const { input_variables } = useWorkflow();
  const variableCount = input_variables.length;
  const hasVariables = variableCount > 0;

  return (
    <Dialog>
      <TooltipProvider>
        <Tooltip defaultOpen={false}>
          <TooltipTrigger asChild>
            <DialogTrigger
              className={`${
                !hasVariables
                  ? "duration-[1100ms] animate-pulse"
                  : "duration-200"
              } pointer-events-auto relative flex h-[36px] w-[fit-content] items-center justify-center rounded-md border-gray-100 bg-white px-2 shadow-md transition-colors hover:bg-gray-50`}
            >
              <CurlyBracesIcon className="mr-1 h-4 w-4 text-gray-600" />
              <span className="text-sm font-medium text-gray-600">
                Input Variables
              </span>
              {hasVariables && (
                <div className="absolute -right-1 -top-1 flex h-4 w-4 items-center justify-center rounded-full bg-blue-500 text-[10px] text-white">
                  {variableCount}
                </div>
              )}
            </DialogTrigger>
          </TooltipTrigger>
          <TooltipContent side="bottom">
            {variableCount > 0 ? (
              <TooltipVariableList variables={input_variables} />
            ) : (
              <div>No input variables defined</div>
            )}
          </TooltipContent>
        </Tooltip>
      </TooltipProvider>
      <DialogContent className="max-h-[60vh] max-w-3xl">
        {readonly ? (
          <div className="h-full w-full overflow-auto rounded-lg bg-white p-6 shadow-sm">
            <h2 className="mb-2 text-2xl font-semibold text-gray-800">
              Input Variables
              <span className="ml-2 text-sm font-normal text-gray-500">
                (Read-only)
              </span>
            </h2>
            <p className="mb-6 text-sm text-gray-600">
              These are the input variables defined for this agent.
            </p>
            {hasVariables ? (
              <>
                <div className="mb-2 grid grid-cols-3 gap-4 rounded-t-lg bg-gray-100 p-3 text-sm font-medium text-gray-700">
                  <div>Name</div>
                </div>
                <ReadOnlyVariableList variables={input_variables} />
              </>
            ) : (
              <div className="flex h-[200px] items-center justify-center rounded-lg border-2 border-gray-300 bg-gray-50">
                <p className="text-center text-gray-500">
                  No input variables defined. Add some to get started!
                </p>
              </div>
            )}
          </div>
        ) : (
          <>
            <DialogHeader>
              <DialogTitle>Edit Input Variables</DialogTitle>
              <DialogDescription>
                Input variables allow you to create reusable values that can be
                referenced in your nodes.
                <p className="mt-1 text-sm text-gray-600">
                  Note: Only variable names are saved. Values are only used at
                  runtime preview.
                </p>
              </DialogDescription>
            </DialogHeader>
            <WorkflowInputVariableConfigurator />
          </>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default observer(InputVariables);
