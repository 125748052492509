import { ParameterProps } from "./types";
import { ComponentType } from "@/queries/models/types";
import { ParameterGroup } from "./ParameterGroup";
import { BasicParameter } from "./BasicParameter";
import { COMMON_STYLES } from "./constants";
import { observer } from "mobx-react-lite";

export const Parameter = observer(
  ({ parameter, readOnly, shouldShowToggle, isDisabled }: ParameterProps) => {
    const { labelClasses, disabledClass } = COMMON_STYLES;

    if (parameter.componentType === ComponentType.GROUP) {
      return (
        <ParameterGroup
          disabledClass={disabledClass}
          shouldShowToggle={shouldShowToggle}
          labelClasses={labelClasses}
          parameter={parameter}
          readOnly={readOnly}
          isDisabled={parameter.isDisabled || isDisabled}
        />
      );
    }

    return (
      <BasicParameter
        parameter={parameter}
        shouldShowToggle={shouldShowToggle}
        labelClasses={labelClasses}
        disabledClass={disabledClass}
        isDisabled={parameter.isDisabled || isDisabled}
        readOnly={readOnly}
      />
    );
  },
);
