import { BasicTooltip } from "@/components/ui/Tooltip";
import { Button } from "@/components/ui/button";
import { useUser } from "@/context/user-context";
import { IndividualRun } from "@/types/metadata";
import {
  CurrencyDollarIcon,
  RefreshIcon,
  XIcon,
} from "@heroicons/react/outline";
import { observer } from "mobx-react-lite";
import { Link } from "react-router-dom";

const RunInfo = ({
  runData,
  extraControls,
}: {
  runData: IndividualRun;
  extraControls?: JSX.Element[];
}) => {
  const userContext = useUser();

  return (
    <div className="mb-3 overflow-auto rounded-lg border bg-gray-50 p-3 group-hover:bg-gray-100">
      <div className="flex items-center justify-between">
        <div className="flex items-center gap-4">
          {extraControls && (
            <div className="flex items-center gap-2">{extraControls}</div>
          )}
          {runData.status === "running" && (
            <BasicTooltip content={runData.status_message || "Running"}>
              <div className="flex h-5 w-5 items-center justify-center rounded-full bg-blue-100">
                <RefreshIcon className="h-3.5 w-3.5 animate-spin text-blue-500" />
              </div>
            </BasicTooltip>
          )}
          {runData.status === "failed" && (
            <BasicTooltip
              content={runData.status_message || "Failed"}
              usePortal
            >
              <div className="flex h-5 w-5 items-center justify-center rounded-full bg-red-100">
                <XIcon className="h-3.5 w-3.5 text-red-500" />
              </div>
            </BasicTooltip>
          )}
        </div>
        <div className="flex items-center">
          {runData.request_id !== null && (
            <Link
              to={`/workspace/${userContext?.activeWorkspaceId}/request/${runData.request_id}`}
              target="_blank"
            >
              <Button variant="link" className="text-xs">
                Go to Request
              </Button>
            </Link>
          )}
          {(runData.request?.price && (
            <span className="flex items-center text-sm text-gray-600">
              <div className="flex h-5 w-5 items-center justify-center rounded-full">
                <CurrencyDollarIcon className="h-3.5 w-3.5 text-emerald-500" />
              </div>
              <span className="text-xs">
                ${runData.request.price.toFixed(5)}
              </span>
            </span>
          )) ||
            null}
        </div>
      </div>
    </div>
  );
};

export default observer(RunInfo);
