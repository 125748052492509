import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { Input } from "@/components/ui/input";
import React, { BaseSyntheticEvent, useRef, KeyboardEvent } from "react";
import {
  ConfirmationModal,
  ConfirmationModalVariant,
} from "../ConfirmationModal";
import { PlusIcon } from "@heroicons/react/outline";

import { BasicTooltip } from "../ui/Tooltip";

interface AddReleaseLabelButtonProps {
  onSave: (label: string) => Promise<unknown>;
  isEmpty?: boolean;
}

const AddReleaseLabelButton: React.FC<AddReleaseLabelButtonProps> = ({
  onSave,
  isEmpty,
}) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const [label, setLabel] = React.useState("");
  const [isSaving, setIsSaving] = React.useState(false);
  const [showConfirmation, setShowConfirmation] = React.useState(false);
  const responseRef = useRef<(() => void) | null>(null);

  const handleSave = async (e: BaseSyntheticEvent) => {
    e.stopPropagation();
    e.preventDefault();
    setIsSaving(true);
    try {
      const response = await onSave(label);
      if (response) {
        responseRef.current = response as () => void;
        setShowConfirmation(true);
      }
      setIsOpen(false);
      setLabel("");
    } catch (error) {
      console.error("Error saving label:", error);
    } finally {
      setIsSaving(false);
    }
  };

  const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter" && label.trim() && !isSaving) {
      handleSave(e);
    }
  };

  return (
    <>
      <ConfirmationModal
        title={"Are you sure you want to move the label?"}
        description="The selected label name is already in use. This action will remove the existing release label and add it to the selected prompt version."
        isOpen={showConfirmation}
        setIsOpen={setShowConfirmation}
        leaveButtonText={"Cancel"}
        headerVariant={ConfirmationModalVariant.warning}
        saveButtonText={"Save"}
        useBlocker={false}
        handleOnConfirm={() => {
          responseRef.current?.();
          setShowConfirmation(false);
        }}
      />
      <Dialog open={isOpen} onOpenChange={setIsOpen}>
        <BasicTooltip content="Add a new release label">
          <DialogTrigger asChild>
            <button
              className={`flex h-[22px] ${
                isEmpty ? "w-auto px-1" : "w-[22px]"
              } cursor-pointer items-center justify-center rounded-sm border border-dashed border-gray-200 bg-white text-gray-400 shadow-sm outline-none transition-colors duration-200 hover:border-gray-300 hover:bg-gray-50 hover:text-gray-600 focus:outline-none`}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                setIsOpen(true);
              }}
            >
              {isEmpty ? "Release Label" : ""}
              <PlusIcon className={`h-3 w-3 ${isEmpty ? "ml-1" : ""}`} />
            </button>
          </DialogTrigger>
        </BasicTooltip>

        <DialogContent>
          <DialogHeader>
            <DialogTitle>Create a Release Label</DialogTitle>
          </DialogHeader>
          <div>
            <Input
              placeholder="Enter label name (e.g., prod, staging)"
              className="w-full"
              value={label}
              onChange={(e) => setLabel(e.target.value)}
              onKeyDown={handleKeyDown}
              autoFocus
            />
            <p className="mt-2 text-[0.8rem] text-gray-500">
              Release labels help identify important versions. Common examples:
              "prod", "staging", "v1".
            </p>
          </div>
          <DialogFooter>
            <Button
              type="submit"
              onClick={handleSave}
              disabled={isSaving || !label.trim()}
            >
              {isSaving ? "Saving..." : "Create Label"}
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default AddReleaseLabelButton;
