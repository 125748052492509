import { Skeleton } from "@/components/ui/skeleton";

interface VersionSelectorShimmerProps {
  versionCount?: number;
}

const VersionSelectorShimmer: React.FC<VersionSelectorShimmerProps> = ({
  versionCount = 5,
}) => {
  return (
    <div className="relative flex max-w-[25%] flex-col rounded-lg border">
      <div className="flex items-center justify-between rounded-t-lg border-b border-gray-200 bg-gray-50 px-3 py-1">
        <Skeleton width="100px" height="1rem" />
        <Skeleton width="30px" height="1rem" />
      </div>
      <div className="relative max-h-full overflow-y-scroll">
        {Array.from({ length: versionCount }).map((_, index) => (
          <div
            key={index}
            className="group w-full select-none overflow-x-hidden"
          >
            <div className="relative mx-6 border-l border-gray-300 p-2">
              <div className="absolute -left-1.5 mt-1.5 h-3 w-3 rounded-full border border-gray-300 bg-gray-200"></div>
              <div className="mx-6">
                <Skeleton width="80px" height="1rem" className="mb-2" />
                <Skeleton width="200px" height="0.75rem" className="mb-2" />
                <Skeleton width="150px" height="0.75rem" className="mb-2" />
                <div className="flex gap-1">
                  <Skeleton
                    width="60px"
                    height="1.25rem"
                    borderRadius="0.125rem"
                  />
                  <Skeleton
                    width="60px"
                    height="1.25rem"
                    borderRadius="0.125rem"
                  />
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default VersionSelectorShimmer;
