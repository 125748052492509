import { AnimatePresence, motion } from "framer-motion";
import { useState } from "react";
import useAutoOpen from "../OutputsWidget/hooks/useAutoOpen";

const SidebarWidgetHeader = ({
  children,
  count,
  isCollapsed,
  onToggle,
}: {
  children: React.ReactNode;
  count?: number;
  isCollapsed: boolean;
  onToggle: () => void;
}) => {
  return (
    <motion.button
      onClick={onToggle}
      className=" flex w-full items-center justify-between rounded-md px-3 py-2 hover:bg-gray-50"
      whileTap={{ scale: 0.98 }}
    >
      <div className="flex items-center gap-x-2">
        <div className="flex items-center text-sm font-medium text-gray-600">
          <motion.svg
            animate={{ rotate: isCollapsed ? -90 : 0 }}
            transition={{ duration: 0.2 }}
            className="mr-1 h-3.5 w-3.5 text-gray-400"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M19 9l-7 7-7-7"
            />
          </motion.svg>
          {children}
        </div>
        {count !== undefined && (
          <motion.div
            initial={{ scale: 0.8, opacity: 0 }}
            animate={{ scale: 1, opacity: 1 }}
            className="rounded-full bg-gray-100 px-2 py-0.5 text-xs font-medium text-gray-600"
          >
            {count}
          </motion.div>
        )}
      </div>
    </motion.button>
  );
};

interface SidebarWidgetProps {
  children: React.ReactNode;
  name: string;
  count?: number;
  defaultCollapsed?: boolean;
}

const SidebarWidget = ({
  children,
  name,
  count = 0,
  defaultCollapsed = false,
}: SidebarWidgetProps) => {
  const [isCollapsed, setIsCollapsed] = useState(defaultCollapsed);
  useAutoOpen(count, setIsCollapsed);

  return (
    <div className="mx-3 rounded-lg border border-gray-100 bg-white p-2.5 shadow-sm">
      <SidebarWidgetHeader
        count={count}
        isCollapsed={isCollapsed}
        onToggle={() => setIsCollapsed(!isCollapsed)}
      >
        {name}
      </SidebarWidgetHeader>
      <AnimatePresence initial={false}>
        <motion.div
          key="content"
          initial="collapsed"
          animate={isCollapsed ? "collapsed" : "open"}
          exit="collapsed"
          variants={{
            open: { opacity: 1, height: "auto" },
            collapsed: { opacity: 0, height: 0 },
          }}
          transition={{ duration: 0.2 }}
          style={{ overflow: "hidden" }}
        >
          <div className="mt-2">{children}</div>
        </motion.div>
      </AnimatePresence>
    </div>
  );
};

export default SidebarWidget;
