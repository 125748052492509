import { useQuery } from "@tanstack/react-query";
import { ENDPOINTS } from "@/api/application-api";
import { jsonAuthHeaders } from "@/utils/headers";

export const useProviders = (userToken: string) => {
  return useQuery({
    queryKey: ["providers"],
    queryFn: async () => {
      const response = await fetch(ENDPOINTS.providers, {
        headers: jsonAuthHeaders(userToken),
        method: "GET",
      });
      return response.json();
    },
  });
};
