import LoadingSpinner from "@/components/LoadingSpinner";
import { Media } from "@/types";

interface UploadAreaContentProps {
  isLoading: boolean;
  selectedMedia?: Media;
}

const UploadAreaContent = ({
  isLoading,
  selectedMedia,
}: UploadAreaContentProps) => {
  if (selectedMedia) {
    return (
      <div className="flex items-center gap-2">
        <img
          src={selectedMedia.url}
          alt="Selected"
          className="h-8 w-8 object-cover"
        />
        <p className="text-sm text-gray-600">{selectedMedia.title}</p>
      </div>
    );
  }

  if (isLoading) {
    return (
      <div className="flex items-center gap-2">
        <LoadingSpinner size={4} />
        <p className="text-sm text-gray-500">Uploading...</p>
      </div>
    );
  }

  return <p className="text-xs text-gray-500">Drop media or click to upload</p>;
};

export default UploadAreaContent;
