import { useParams, useSearchParams } from "react-router-dom";
import { PromptTemplateVersionStats } from "../../PromptTemplateVersionStats";
import { useAuth } from "@/context/auth-context";
import { useUser } from "@/context/user-context";
import { PromptTemplateVersionRequestsTable } from "../../PromptTemplateVersionRequestsTable";
import { SortingState } from "@tanstack/react-table";
import { useCallback, useMemo, useState } from "react";
import { useRequestPrompt } from "@/queries";
import EmptyTableMessage from "@/components/EmptyTableMessage";
import { DataTable } from "@/components/ui/data-table";
import { TablePagination } from "@/components/ui/table-pagination";
import PromptTemplateVersionSelector from "./PromptTemplateVersionSelector";

const PromptTemplateAnalytics = () => {
  const { promptId, versionId } = useParams();
  const [params] = useSearchParams();
  const getPageSizeFromQueryParam = useCallback(() => {
    const per_page = params.get("per_page");
    return per_page ? Number(per_page) : 5;
  }, [params]);
  const [pageSize, setPageSize] = useState(getPageSizeFromQueryParam());

  const getSortingFromQueryParam = useCallback((): SortingState => {
    const sortBy = params.get("sortBy");
    const sortOrder = params.get("sortOrder");
    return sortBy && sortOrder
      ? [{ id: sortBy, desc: sortOrder === "desc" }]
      : [{ id: "request_start_time", desc: true }];
  }, [params]);
  const workspaceId = useUser()?.activeWorkspaceId;
  const userToken = useAuth()?.userToken;
  const [page, setPage] = useState(1);

  const [sorting, setSorting] = useState<SortingState>(
    getSortingFromQueryParam(),
  );

  const requestsForPrompt = useRequestPrompt(
    promptId!,
    workspaceId!,
    versionId || "",
    userToken || "",
    pageSize,
    page,
    sorting?.[0]?.id,
    sorting?.[0]?.desc ? "desc" : "asc",
  );

  const data = useMemo(() => {
    if (requestsForPrompt?.data) {
      return {
        ...requestsForPrompt.data,
        items: requestsForPrompt.data.items?.map((item: any) => {
          const defaultScore = item.scores
            ? item.scores.find((score: any) => score.default !== undefined)
            : null;
          const otherScores = item.scores
            ? item.scores
                .filter((score: any) => !("default" in score))
                .reduce((acc: any, score: any) => ({ ...acc, ...score }), {})
            : {};
          return {
            ...item,
            score: {
              default: defaultScore ? defaultScore.default : null,
              others: otherScores,
            },
          };
        }),
      };
    }
    return {
      items: [],
      has_next: false,
      has_prev: false,
      pages: 0,
      page: 1,
      per_page: pageSize,
    };
  }, [requestsForPrompt.data, pageSize]);

  const table = PromptTemplateVersionRequestsTable({
    data,
    setSorting,
    sorting,
  });

  const handleSetPage = (newPage: number) => {
    setPage(newPage);
  };

  const handleSetPageSize = (newPageSize: number) => {
    setPageSize(newPageSize);
    setPage(1); // Reset to first page when changing page size
  };

  return (
    <div className="flex h-full flex-col gap-y-2 overflow-hidden pt-2">
      <div className="ml-auto w-64">
        <PromptTemplateVersionSelector
          versionId={Number(versionId!)}
          promptId={Number(promptId!)}
        />
      </div>
      <div className="flex-shrink-0">
        <PromptTemplateVersionStats
          promptId={promptId!}
          versionNumber={parseInt(versionId!) || 0}
          workspaceId={workspaceId!}
        />
      </div>
      <div className="flex min-h-0 flex-1 flex-col space-y-2 overflow-auto">
        <div className="flex-1 overflow-auto">
          <DataTable
            linkTo={(row) =>
              `/workspace/${workspaceId}/request/${row.original.id}`
            }
            table={table}
            emptyMessage={<EmptyTableMessage />}
            isLoading={requestsForPrompt.isLoading}
          />
        </div>
        <div className="flex flex-shrink-0 justify-between">
          <TablePagination
            table={table}
            hasNext={data.has_next}
            hasPrev={data.has_prev}
            pages={data.pages}
            page={data.page}
            pageSize={data.per_page || pageSize}
            pageSizeOptions={[5, 10, 20, 30, 40, 50]}
            setPageIndex={handleSetPage}
            setPageSize={handleSetPageSize}
          />
        </div>
      </div>
    </div>
  );
};

export default PromptTemplateAnalytics;
