import { Skeleton } from "@/components/ui/skeleton";
import { cn } from "@/lib/utils";

interface MessagesShimmerProps {
  count?: number;
  className?: string;
}

export const MessagesShimmer = ({
  count = 3,
  className,
}: MessagesShimmerProps) => {
  return (
    <div className={cn("w-full space-y-4 animate-in fade-in-50", className)}>
      {Array(count)
        .fill(null)
        .map((_, i) => (
          <div
            key={`shimmer_${i}`}
            className="group flex h-full w-full flex-col gap-y-1 rounded-lg border border-gray-100 bg-gray-50/50 px-3 py-3"
          >
            <div className="flex items-center justify-between gap-x-2 pb-2">
              <div className="flex w-full items-center gap-x-2">
                <Skeleton className="h-9 w-[180px] rounded-md bg-gray-200/70" />
                {i % 2 === 0 && (
                  <Skeleton className="h-9 w-[140px] rounded-md bg-gray-200/70" />
                )}
              </div>
              <div className="flex gap-x-2">
                <Skeleton className="h-8 w-8 rounded-md bg-gray-200/70" />
                <Skeleton className="h-8 w-8 rounded-md bg-gray-200/70" />
              </div>
            </div>
            <div className="relative flex h-full w-full flex-col gap-y-1">
              <Skeleton
                className={`min-h-[${
                  i === 0 ? "200px" : "120px"
                }] w-full rounded bg-gray-200/70`}
              />
            </div>

            {i === 0 && (
              <div className="mt-3 grid grid-cols-4 gap-3">
                {[1, 2, 3, 4].map((mediaIndex) => (
                  <Skeleton
                    key={`media_${mediaIndex}`}
                    className="h-24 w-full rounded-md bg-gray-200/70"
                  />
                ))}
              </div>
            )}
          </div>
        ))}
    </div>
  );
};

export default MessagesShimmer;
