import { useAuth } from "@/context/auth-context";
import { useUser } from "@/context/user-context";
import { useGetReport, usePromptVersions } from "@/queries";
import EditPromptPage from ".";

export const usePreviousReportId = ({
  promptRegistryId,
}: {
  promptRegistryId: number;
}) => {
  const auth = useAuth();
  const user = useUser();
  const userToken = auth?.userToken!;
  const workspaceId = user?.activeWorkspaceId!;

  const promptVersionsQuery = usePromptVersions(userToken, {
    promptRegistryId,
    workspaceId,
    perPage: Number.MAX_SAFE_INTEGER,
  });
  const promptVersions =
    promptVersionsQuery.data?.pages.flatMap((page) => page.items) || [];
  const previousReportId =
    promptVersions.find((version) => version.report_id != null)?.report_id ||
    null;

  const report = useGetReport(previousReportId || null);
  const previousBlueprintId = report?.data?.report?.parent_report_id ?? null;
  if (promptVersionsQuery.isLoading || report.isLoading)
    return {
      isLoading: true,
      previousReportId: null,
    };
  return {
    isLoading: false,
    previousReportId: previousBlueprintId,
  };
};

export default EditPromptPage;
