import { NameWithId } from "./types";
import { UseQueryResult } from "@tanstack/react-query";
import { ChevronLeft, ChevronRight } from "lucide-react";
import { observer } from "mobx-react-lite";
import { ReactNode, useEffect, useRef, useState } from "react";
import { Button } from "../button";
import EmptyPlaceholder from "../EmptyPlaceholder";
import { Select, SelectContent, SelectItem, SelectTrigger } from "../select";

export interface SelectedValue<T> {
  id: string;
  item: T;
}

export interface PaginatedSelectProps<
  T,
  TData extends Record<string, unknown>,
> {
  // Instead of direct data, we use a query result
  query: (page: number) => UseQueryResult<TData, unknown> | null;
  renderItem: (item: T) => ReactNode;
  getItemValue: (item: T) => string;
  placeholder?: string;
  onValueChange?: (value: SelectedValue<T>) => void;
  value?: SelectedValue<NameWithId>;
  className?: string;
  disabled?: boolean;
  // Helper function to extract items and totalPages from the query result
  extractData?: (data: TData) => { data: T[]; totalPages: number };
}
const PaginatedSelect = <T, TData extends Record<string, unknown>>({
  query,
  renderItem,
  getItemValue,
  placeholder = "Select an option",
  onValueChange,
  value,
  className,
  disabled = false,
  extractData = (data: TData) =>
    data as unknown as { data: T[]; totalPages: number },
}: PaginatedSelectProps<T, TData>) => {
  const [currentPage, setCurrentPage] = useState(1);
  const contentRef = useRef<HTMLDivElement>(null);
  const [contentHeight, setContentHeight] = useState<number | null>(null);

  const queryResult = query(currentPage);
  const {
    data: queryData,
    isLoading,
    isError,
  } = queryResult || { data: undefined, isLoading: false, isError: false };

  const { data: items = [], totalPages = 0 } = queryData
    ? extractData(queryData)
    : { data: [] as T[], totalPages: 0 };

  useEffect(() => {
    if (!isLoading && contentRef.current && items.length > 0) {
      setContentHeight(contentRef.current.offsetHeight);
    }
  }, [isLoading, items]);

  const handleValueChange = (newValue: string) => {
    if (onValueChange) {
      const selectedItem = items.find(
        (item) => getItemValue(item) === newValue,
      );
      if (selectedItem) {
        onValueChange({
          id: newValue,
          item: selectedItem,
        });
      }
    }
  };

  const goToNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage((prev) => prev + 1);
    }
  };

  const goToPrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prev) => prev - 1);
    }
  };

  return (
    <Select
      value={value?.id || ""}
      onValueChange={handleValueChange}
      disabled={disabled || isLoading}
    >
      <SelectTrigger className={className}>
        <div
          className={`overflow-hidden text-ellipsis whitespace-nowrap ${
            value?.item?.name ? "text-black" : "text-muted-foreground"
          }`}
        >
          {value?.item?.name || (isLoading ? "Loading..." : placeholder)}
        </div>
      </SelectTrigger>
      <SelectContent className="w-[300px]" position="popper" side="top">
        <div
          ref={contentRef}
          style={
            isLoading && contentHeight
              ? { minHeight: `${contentHeight}px` }
              : {}
          }
        >
          {isLoading ? (
            <div className="p-2 text-center">Loading...</div>
          ) : isError ? (
            <div className="p-2 text-center text-red-500">
              Error loading data
            </div>
          ) : items.length === 0 ? (
            <EmptyPlaceholder message="No results found" className="mb-1" />
          ) : (
            <>
              {items.map((item: T, index: number) => (
                <SelectItem
                  key={`${getItemValue(item)}-${index}`}
                  value={getItemValue(item)}
                  className="text-ellipsis whitespace-nowrap"
                >
                  {renderItem(item)}
                </SelectItem>
              ))}
            </>
          )}
        </div>

        {totalPages > 1 && (
          <div className="flex items-center justify-between border-t px-1 py-2">
            <Button
              variant="outline"
              size="sm"
              onClick={goToPrevPage}
              disabled={currentPage === 1}
              className="h-8 w-8 p-0"
            >
              <ChevronLeft className="h-4 w-4" />
            </Button>
            <span className="text-sm">
              Page {currentPage} of {totalPages || 1}
            </span>
            <Button
              variant="outline"
              size="sm"
              onClick={goToNextPage}
              disabled={currentPage === totalPages || totalPages === 0}
              className="h-8 w-8 p-0"
            >
              <ChevronRight className="h-4 w-4" />
            </Button>
          </div>
        )}
      </SelectContent>
    </Select>
  );
};

export default observer(PaginatedSelect);
