import { LOCAL_STORAGE } from "@/constants";
import { PromptBlueprint } from "@/types";
import { IndividualRun } from "@/types/metadata";
import { InputVariableRow } from "@/types/playground";
import {
  createContext,
  PropsWithChildren,
  useContext,
  useEffect,
  useState,
} from "react";
import { FormProvider, useForm } from "react-hook-form";

interface PlaygroundContextProps extends PropsWithChildren {
  inputVariableRows: Array<InputVariableRow>;
  setInputVariableRows: (inputVariableRows: Array<InputVariableRow>) => void;
  latestIndividualRunRequestId: number | null;
  setLatestIndividualRunRequestId: (
    latestIndividualRunRequestId: number | null,
  ) => void;
  error: string;
  setError: (error: string) => void;
  loading: boolean;
  setLoading: (loading: boolean) => void;
  latestIndividualRunResponse: IndividualRun | null;
  setLatestIndividualRunResponse: (
    latestIndividualRunResponse: IndividualRun | null,
  ) => void;
}

export const PlaygroundContext = createContext<PlaygroundContextProps | null>(
  null,
);

export const usePlayground = () => {
  const context = useContext(PlaygroundContext);
  if (!context) {
    throw new Error(
      "usePlayground must be used within a PromptBlueprintProvider",
    );
  }
  return context;
};

interface Props extends PropsWithChildren {
  promptBlueprint: PromptBlueprint;
  inputVariableRows: Array<InputVariableRow>;
}

const useInitialValue = (props: Props) => {
  const [loading, setLoading] = useState(false);
  const [inputVariableRows, setInputVariableRows] = useState(() => {
    if (props.inputVariableRows.length > 0) return props.inputVariableRows;
    const storedRows = localStorage.getItem(LOCAL_STORAGE.INPUT_VARIABLE_ROWS);
    return storedRows ? JSON.parse(storedRows) : [];
  });
  const [latestIndividualRunResponse, setLatestIndividualRunResponse] =
    useState<IndividualRun | null>(null);
  const [latestIndividualRunRequestId, setLatestIndividualRunRequestId] =
    useState<number | null>(null);
  const [error, setError] = useState("");
  return {
    latestIndividualRunRequestId,
    setLatestIndividualRunRequestId,
    latestIndividualRunResponse,
    setLatestIndividualRunResponse,
    error,
    setError,
    inputVariableRows,
    setInputVariableRows,
    loading,
    setLoading,
  };
};

export const PromptBlueprintProvider = (
  props: Props & { onChange?: (data: any) => void },
) => {
  const value = useInitialValue(props);

  const form = useForm<PromptBlueprint>({
    defaultValues: {
      ...props.promptBlueprint,
    },
  });

  form.watch((formData) => {
    if (props.onChange) {
      props.onChange(formData);
    }
  });

  useEffect(() => {
    if (
      JSON.stringify(props.promptBlueprint) === JSON.stringify(form.getValues())
    ) {
      return;
    }

    form.setValue("metadata", {
      ...props.promptBlueprint.metadata,
      model: {
        provider: props.promptBlueprint.metadata?.model?.provider ?? "",
        name: props.promptBlueprint.metadata?.model?.name ?? "",
        parameters: { ...props.promptBlueprint.metadata?.model?.parameters },
      },
    });

    form.setValue(
      "inference_client_name",
      props.promptBlueprint.inference_client_name,
    );
    form.setValue(
      "provider_base_url_name",
      props.promptBlueprint.provider_base_url_name,
    );
  }, [props.promptBlueprint]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <FormProvider {...form}>
      <PlaygroundContext.Provider value={value}>
        {props.children}
      </PlaygroundContext.Provider>
    </FormProvider>
  );
};
