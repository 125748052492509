import { ENDPOINTS } from "@/api/application-api";
import { handleResponse } from "@/utils/errorResponseHandlers";
import { authHeader } from "@/utils/headers";
import { UseQueryResult, useQuery } from "@tanstack/react-query";

export const useRequestLogInputVariables = (
  userToken: string | null | undefined,
  requestLogId: number | null,
): UseQueryResult<{
  success: boolean;
  prompt_input_variables: Record<string, string>;
}> => {
  return useQuery({
    queryKey: ["requestLogInputVariables", requestLogId, userToken],
    queryFn: async () => {
      if (!requestLogId) throw new Error("Request log ID is required");
      if (!userToken) throw new Error("User token is required");

      const response = await fetch(
        ENDPOINTS.request_log_input_variables(requestLogId),
        {
          headers: authHeader(userToken),
        },
      );

      return await handleResponse(response);
    },
    enabled: !!requestLogId && !!userToken,
    retry: false,
  });
};
