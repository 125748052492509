import { observer } from "mobx-react-lite";
import RunButton from "../../../ui/RunButton";
import { usePlayground } from "../../playground-store";
import SaveTemplateDialog from "../../Messages/MessagesHeader/SaveTemplateDialog";

const ControlBar = () => {
  const playground = usePlayground();
  return (
    <>
      <div className="relative mx-auto mb-3 h-[65px] w-full border-b">
        <div className="flex flex-row justify-between p-3">
          {/* <div className="flex flex-row gap-x-1">
            <Button variant="default" className="whitespace-nowrap">
              Ad-Hoc
            </Button>
            <Button variant="outline">Eval</Button>
          </div> */}

          <div className="flex w-full flex-row justify-end gap-x-3">
            {playground.isEditor && (
              <SaveTemplateDialog
                disabled={playground.isSavingSession}
                size="default"
              />
            )}
            <RunButton
              run={playground.run}
              isRunning={playground.operationStore.isRunning}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default observer(ControlBar);
