import { MessageType } from "../types";

const MESSAGE_PLACEHOLDERS: Record<MessageType, string> = {
  system: "",
  user: "",
  assistant: "",
  function: '{"location": "Brooklyn, NY", "weather": "Sunny"}',
  tool: "",
  placeholder: "Name of the placeholder variable...",
  developer: "",
};

const MESSAGE_DETAILS: Record<MessageType, string> = {
  system: "",
  user: "",
  assistant: "",
  function: "",
  tool: "",
  placeholder:
    "This placeholder is used to inject one or more messages into the prompt",
  developer: "",
};

export { MESSAGE_PLACEHOLDERS, MESSAGE_DETAILS };
