import { Breadcrumbs } from "@/components/Breadcrumbs";
import { DeleteItem } from "@/components/DeleteItem";
import { ResourceHeader } from "@/components/ResourceHeader";
import { useAuth } from "@/context/auth-context";

import { observable, runInAction } from "mobx";
import { observer } from "mobx-react-lite";
import { useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { WorkflowVersion } from "../types";
import WorkflowCanvas from "../WorkflowCanvas";
import { IWorkflow } from "../WorkflowCanvas/workflow-store";
import useHeaderActions from "./hooks/useHeaderActions";
import useWorkflowData from "./hooks/useWorkflowData";
import WorkflowExecutionTable from "./WorkflowExecutionTable";
import WorkflowVersionSelector from "./WorkflowVersionSelector";

interface WorkflowProps {
  editing?: boolean;
}

interface DashboardProps {
  showVersionList: boolean;
  versions: WorkflowVersion[];
  workflowRef: React.MutableRefObject<IWorkflow | undefined>;
  observableWorkflowRef: any;
  setShowVersionList: (value: boolean) => void;
  refetchVersions: () => void;
  editing?: boolean;
  userToken: string;
  workflow: IWorkflow;
}

const Workflow = observer(({ editing }: WorkflowProps) => {
  const auth = useAuth();
  const userToken = auth?.userToken || "";
  const {
    workflowId,
    versionId: versionNumber,
    workspaceId,
  } = useParams<{
    workflowId: string;
    versionId: string;
    workspaceId: string;
  }>();

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showVersionList, setShowVersionList] = useState(!editing);

  const navigate = useNavigate();

  const workflowRef = useRef<IWorkflow>();
  const observableWorkflowRef = observable.box(workflowRef.current);

  const { workflow, versions, isLoading, refetchVersions } = useWorkflowData(
    {
      workflowId: workflowId!,
      versionNumber: versionNumber!,
      userToken,
      workspaceId: workspaceId!,
    },
    !!editing,
    navigate,
  );

  const actions = useHeaderActions(!!editing, workflowRef, () => {
    setShowDeleteModal(true);
  });

  const handleTitleEdit = (newTitle: string) => {
    const currentWorkflow = observableWorkflowRef.get();
    if (currentWorkflow) {
      runInAction(() => {
        currentWorkflow.setName(newTitle, true);
      });
    }
  };

  return (
    <div className="flex h-full flex-col p-1">
      <div className="mb-3">
        <Breadcrumbs
          items={
            ["Registry", "Agents", observableWorkflowRef.get()?.name].filter(
              Boolean,
            ) as string[]
          }
          navigateUrl={`/workspace/${workspaceId}/workflow`}
        />
      </div>
      <ResourceHeader
        title={observableWorkflowRef.get()?.name || ""}
        onTitleEdit={({ newTitle }) => handleTitleEdit(newTitle)}
        actions={actions}
        id={workflow.workflow_id!}
        createdAt={workflow.created_at || ""}
        versionInfo={{
          current: Number(versionNumber) || 1,
          total: versions?.length || 0,
        }}
        isLoading={isLoading}
        showVersions={showVersionList}
        onToggleVersions={() => setShowVersionList(!showVersionList)}
      />
      <Dashboard
        showVersionList={showVersionList}
        versions={versions!}
        workflowRef={workflowRef}
        observableWorkflowRef={observableWorkflowRef}
        setShowVersionList={setShowVersionList}
        refetchVersions={refetchVersions}
        editing={editing}
        userToken={userToken}
        workflow={workflow as IWorkflow}
      />

      {!editing && (
        <WorkflowExecutionTable workflowVersionId={workflow.version_id!} />
      )}
      <DeleteItem
        open={showDeleteModal}
        setOpen={setShowDeleteModal}
        setDelete={() => {
          workflowRef.current?.delete(() => {
            navigate(`/workspace/${workspaceId}/workflow`);
          });
          setShowDeleteModal(false);
        }}
        itemName={observableWorkflowRef.get()?.name || workflow.name || ""}
        itemType="agent"
      />
    </div>
  );
});

const Dashboard = ({
  showVersionList,
  versions,
  workflowRef,
  observableWorkflowRef,
  setShowVersionList,
  refetchVersions,
  editing,
  userToken,
  workflow,
}: DashboardProps) => {
  return (
    <div className="mb-6 flex h-[60vh] min-h-[50%] flex-grow flex-row space-x-6">
      <WorkflowVersionSelector
        versions={versions}
        workflow={workflowRef.current!}
        showVersionList={showVersionList}
        setShowVersionList={setShowVersionList}
        refreshVersions={refetchVersions}
        navigateToVersionLink={(version) =>
          `/workspace/${workflow.workspace_id}/workflow/${workflow.id}/version/${version}`
        }
      />
      <WorkflowCanvas
        ref={(ref) => {
          workflowRef.current = ref as IWorkflow;
          runInAction(() => observableWorkflowRef.set(ref as IWorkflow));
        }}
        {...workflow}
        readonly={!editing}
        userToken={userToken}
      />
    </div>
  );
};

export default Workflow;
