import { HUGGINGFACE_PROVIDER } from "@/components/ModelParameters/constants";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "@/components/ui/accordion";
import { ModelAndProviderPreviewProps } from "./types";
import { mapProviderToDisplayName } from "@/components/ProviderDropdown/utils";

export const ModelAndProviderPreview = ({
  promptData,
}: ModelAndProviderPreviewProps) => (
  <Accordion
    collapsible
    type="single"
    defaultValue="provider-info"
    className="w-full"
  >
    <AccordionItem value="provider-info" className="pb-1">
      <AccordionTrigger className="py-1.5 text-sm text-muted-foreground hover:text-gray-400 hover:no-underline">
        Provider Info
      </AccordionTrigger>
      <AccordionContent className="pt-1">
        <div className="grid grid-cols-1 gap-2">
          <div className="flex w-full items-center justify-between gap-2">
            <Label className="text-sm font-medium" htmlFor="provider">
              Model Provider
            </Label>
            <Input
              value={mapProviderToDisplayName(
                promptData?.metadata?.model?.provider ?? "",
              )}
              className="w-full max-w-[220px]"
              readOnly
            />
          </div>
          <div className="flex w-full items-center justify-between gap-2">
            <Label className="text-sm font-medium" htmlFor="model">
              {promptData?.metadata?.model?.provider === "huggingface"
                ? "Inference Client Name"
                : "Model Name"}
            </Label>
            <Input
              value={
                promptData?.metadata?.model?.name ||
                promptData?.inference_client_name
              }
              className="w-full max-w-[220px]"
              readOnly
            />
          </div>
          {promptData?.metadata?.model?.provider !== HUGGINGFACE_PROVIDER &&
            promptData?.provider_base_url_name && (
              <div className="flex w-full items-center justify-between gap-2">
                <p className="text-sm font-medium">Provider Base URL</p>
                <Input
                  type="text"
                  value={promptData?.provider_base_url_name}
                  className="w-full max-w-[220px]"
                  readOnly
                />
              </div>
            )}
        </div>
      </AccordionContent>
    </AccordionItem>
  </Accordion>
);
