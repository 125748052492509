import { Breadcrumbs as BaseBreadcrumbs } from "@/components/Breadcrumbs";
import { useAuth } from "@/context/auth-context";
import { useUser } from "@/context/user-context";
import { useFolders, usePromptRegistry } from "@/queries";
import { Folder } from "@/types/folders";
import { getTruncatedFolderPath } from "@/utils/utils";
import { useMemo } from "react";
import { useParams, useLocation } from "react-router-dom";

const Breadcrumbs = () => {
  const { promptId, versionId } = useParams();
  const { activeWorkspaceId } = useUser();
  const userToken = useAuth()?.userToken!;
  const location = useLocation();

  const isPlaygroundTab = location.pathname.includes("/playground");
  const isAnalyticsTab = location.pathname.includes("/analytics");

  const promptRegistry = usePromptRegistry(userToken, Number(promptId));
  const promptName = promptRegistry.data?.prompt_name || "Loading...";

  const foldersQuery = useFolders(
    userToken!,
    activeWorkspaceId!,
    promptRegistry?.data?.folder_id?.toString(),
  );

  const promptsRootUrl = `/workspace/${activeWorkspaceId}/prompt`;

  const promptVersionBaseUrl = useMemo(
    () =>
      versionId
        ? `/workspace/${activeWorkspaceId}/prompt/${promptId}/version/${versionId}`
        : `/workspace/${activeWorkspaceId}/prompt/${promptId}`,
    [activeWorkspaceId, promptId, versionId],
  );

  const { breadcrumbItems, breadcrumbUrls } = useMemo(() => {
    const items = ["Registry", "Prompts"];
    const urls = [`/workspace/${activeWorkspaceId}/prompt`, promptsRootUrl];

    const folderPath: string[] =
      foldersQuery.data?.path?.map((folder: Folder) => folder.name) ?? [];
    const truncatedFolderPath = getTruncatedFolderPath(folderPath) || [];

    if (foldersQuery.data?.path) {
      truncatedFolderPath.forEach((folderName, index) => {
        const currentPathFolder = foldersQuery.data.path[index];
        if (currentPathFolder) {
          items.push(folderName);
          urls.push(
            `/workspace/${activeWorkspaceId}/prompt-folder/${currentPathFolder.id}`,
          );
        }
      });
    }

    items.push(promptName);
    urls.push(`/workspace/${activeWorkspaceId}/prompt/${promptId}`);

    if (isPlaygroundTab) {
      items.push("Playground");
      urls.push(`${promptVersionBaseUrl}/playground`);
    } else if (isAnalyticsTab) {
      items.push("Analytics");
      urls.push(`${promptVersionBaseUrl}/analytics`);
    }

    // Get previous URL for navigating back when clicking the entire breadcrumb
    const navigateUrl = urls[urls.length - 2] || "";

    return { breadcrumbItems: items, breadcrumbUrls: urls, navigateUrl };
  }, [
    activeWorkspaceId,
    foldersQuery.data.path,
    promptName,
    promptId,
    isPlaygroundTab,
    isAnalyticsTab,
    promptVersionBaseUrl,
    promptsRootUrl,
  ]);

  return (
    <BaseBreadcrumbs
      items={breadcrumbItems}
      navigateUrl={
        breadcrumbItems.length > 1
          ? breadcrumbUrls[breadcrumbUrls.length - 3]
          : ""
      }
    />
  );
};

export default Breadcrumbs;
