import { observer } from "mobx-react-lite";

import { PlusIcon, XIcon } from "@heroicons/react/outline";
import { useParameters } from "@/components/ModelParameters/store";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";

import { CustomParametersProps } from "../types";
import { FUNCTION_MODEL_PARAMS } from "../constants";

export const CustomParameters = observer(
  ({ customParams }: CustomParametersProps) => {
    const store = useParameters();

    const handleAddCustomParameter = () => {
      let index = customParams.length + 1;
      for (const param of customParams) {
        if (param.paramId?.includes(`custom_key_${index}`)) {
          index++;
        }
      }

      const newParamKey = `custom_key_${index}`;
      store.createCustomParameter(newParamKey);
    };

    const renderedParams = customParams
      .filter((p) => !FUNCTION_MODEL_PARAMS.includes(p.paramId ?? ""))
      .map((parameter, index) => {
        return (
          <div
            key={`custom-params-${index}`}
            className="grid grid-cols-3 items-center gap-1"
          >
            <div className="col-span-1 flex flex-row gap-1">
              <Button
                size="tinyIcon"
                variant="ghost"
                onClick={() =>
                  store.deleteCustomParameter(parameter.paramId ?? "")
                }
                className="h-4 w-4 p-0 text-red-500 hover:text-red-700 focus:outline-none"
              >
                <XIcon className="h-4 w-4" />
              </Button>
              <label
                htmlFor={`${index}_label`}
                className="col-span-1 block pl-1 text-xs font-medium"
              >
                Key
              </label>
            </div>
            <Input
              type="text"
              id={`${index}_label`}
              value={parameter.paramId}
              className="col-span-2 rounded-lg border border-gray-300 px-2 py-1 shadow-sm focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm"
              onChange={(e) => parameter.updateKey(e.target.value)}
            />
            <div className="col-span-1">
              <label
                htmlFor={`${index}_input`}
                className="block pl-6 text-xs font-medium"
              >
                Value
              </label>
            </div>
            <Input
              type="number"
              id={`${index}_input`}
              min={0}
              max={3600}
              value={parameter.value as number}
              className="col-span-2 rounded-lg border border-gray-300 px-2 py-1 shadow-sm focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm"
              onChange={(e) => parameter.updateValue(e.target.value)}
            />
          </div>
        );
      });

    return (
      <>
        {renderedParams}
        <Button
          onClick={handleAddCustomParameter}
          variant="link"
          size="sm"
          className="mt-4"
        >
          <PlusIcon className="mr-1 h-3 w-3" />
          Add New Parameter
        </Button>
      </>
    );
  },
);
