import InputVariableConfigurator from "@/components/InputVariableConfigurator";
import { ToastType } from "@/enums";
import { useEditInputVariables } from "@/queries";
import { displayToast } from "@/utils/toast";
import { observer } from "mobx-react-lite";
import { useState } from "react";
import { useWorkflow } from "../../../workflow-context";

const WorkflowInputVariableConfigurator = observer(() => {
  const workflow = useWorkflow();
  const [localInputVariables, setLocalInputVariables] = useState(
    workflow.input_variables,
  );

  const editInputVariables = useEditInputVariables(
    workflow.userToken!,
    workflow.workspace_id!,
  );
  const isLoading = editInputVariables.isLoading;

  const handleSaveChanges = () => {
    editInputVariables.mutate(
      {
        input_variables: localInputVariables.reduce(
          (acc, variable) => ({
            ...acc,
            [variable.key]: variable.value,
          }),
          {},
        ),
      },
      {
        onSuccess: () => {
          workflow.updateInputVariables(localInputVariables);
          displayToast("Successfully saved input variables", ToastType.success);
        },
        onError: () => {
          displayToast("Failed to save input variables", ToastType.error);
        },
      },
    );
  };

  return (
    <InputVariableConfigurator
      inputVariables={localInputVariables}
      isLoading={isLoading}
      onUpdateInputVariable={(id, key, value) => {
        setLocalInputVariables((variables) => {
          const existingVariable = variables.find((v) => v.id === id);
          if (existingVariable) {
            return variables.map((v) =>
              v.id === id ? { ...v, key, value } : v,
            );
          } else {
            return [...variables, { id, key, value }];
          }
        });
      }}
      onRemoveInputVariable={(id) => {
        setLocalInputVariables((variables) =>
          variables.filter((v) => v.id !== id),
        );
      }}
      useSaveButton={true}
      onClearInputVariables={() => setLocalInputVariables([])}
      onClose={handleSaveChanges}
    />
  );
});

export default WorkflowInputVariableConfigurator;
