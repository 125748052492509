import { prepareInputVariables } from "@/components/Workflows/utils";
import {
  useWorkflowDetails,
  useWorkflowNodes,
  useWorkflowVersionEditorState,
  useWorkflowVersions,
} from "@/queries";
import { useEffect, useMemo } from "react";
import { Workflow } from "../../types";

interface WorkflowDataProps {
  workflowId: string;
  versionNumber: string;
  userToken: string;
  workspaceId: string;
}

const useWorkflowData = (
  { workflowId, versionNumber, userToken, workspaceId }: WorkflowDataProps,
  isEditing: boolean,
  navigate: any | undefined = undefined,
) => {
  const {
    data: versionData,
    isLoading: versionsLoading,
    isFetching: isVersionsFetching,
    refetch,
  } = useWorkflowVersions(workflowId!, userToken);

  const {
    data: detailsResponse,
    isLoading: detailsLoading,
    isFetching: isDetailsFetching,
  } = useWorkflowDetails(workflowId!, userToken);

  const detailsData = detailsResponse?.workflow;

  const versionId: number | null = useMemo(
    () =>
      (versionNumber &&
        versionData?.workflow_versions?.find(
          (version) => version.number === Number(versionNumber),
        )?.id) ||
      null,
    [versionData, versionNumber],
  );

  const readonlyEdges = versionData?.workflow_versions?.find(
    (version) => version.id === versionId,
  )?.edges;

  const {
    data: nodeData,
    isLoading: nodesLoading,
    isFetching: isNodesFetching,
  } = useWorkflowNodes(versionId!, userToken);

  const {
    data,
    isLoading: isLoadingEditorState,
    isFetching: isFetchingEditorState,
  } = useWorkflowVersionEditorState(
    (isEditing && versionId!) || undefined,
    (isEditing && userToken) || undefined,
  );

  const isLoading = useMemo(
    () =>
      nodesLoading ||
      versionsLoading ||
      isNodesFetching ||
      isVersionsFetching ||
      detailsLoading ||
      isDetailsFetching ||
      (isEditing && isLoadingEditorState) ||
      (isEditing && isFetchingEditorState),
    [
      detailsLoading,
      isDetailsFetching,
      isEditing,
      isFetchingEditorState,
      isLoadingEditorState,
      isNodesFetching,
      isVersionsFetching,
      nodesLoading,
      versionsLoading,
    ],
  );

  useEffect(() => {
    if (
      !versionId &&
      !isVersionsFetching &&
      !versionsLoading &&
      versionData?.workflow_versions &&
      versionData.workflow_versions.length > 0 &&
      navigate
    ) {
      navigate(
        `/workspace/${workspaceId}/workflow/${workflowId}/version/${versionData.workflow_versions[0].number}`,
        { replace: true },
      );
    }
  }, [
    versionId,
    versionData,
    navigate,
    workspaceId,
    workflowId,
    versionsLoading,
    isLoading,
    isVersionsFetching,
  ]);

  const input_variables = useMemo(() => {
    const currentVersion = versionData?.workflow_versions?.find(
      (version) => version.number === Number(versionNumber),
    );
    return (
      prepareInputVariables(currentVersion?.required_input_variables) || {}
    );
  }, [versionData, versionNumber]);

  const editableWorkflow: Workflow = useMemo(
    () => ({
      workflow_id: Number(workflowId)!,
      id: Number(workflowId)!,
      workspace_id: Number(workspaceId)!,
      name: detailsData?.name || "",
      edges: data?.edges || [],
      commit_message: data?.workflow_version?.commit_message || "",
      nodes: data?.workflow_nodes || [],
      input_variables:
        prepareInputVariables(
          data?.workflow_version?.required_input_variables,
        ) || [],
      created_at: data?.workflow?.created_at,
      version_id: data?.workflow_version?.id,
    }),
    [
      data?.edges,
      data?.workflow?.created_at,
      data?.workflow_nodes,
      data?.workflow_version?.commit_message,
      data?.workflow_version?.id,
      data?.workflow_version?.required_input_variables,
      detailsData?.name,
      workflowId,
      workspaceId,
    ],
  );

  const workflow: Workflow = useMemo(
    () => ({
      workflow_id: Number(workflowId)!,
      id: Number(workflowId)!,
      workspace_id: Number(workspaceId)!,
      name: detailsData?.name || "",
      commit_message: detailsData?.commit_message || "",
      nodes: nodeData?.workflow_nodes || [],
      edges: readonlyEdges || [],
      input_variables: input_variables,
      created_at: detailsData?.created_at,
      version_id: versionId,
    }),
    [
      detailsData?.commit_message,
      detailsData?.created_at,
      detailsData?.name,
      input_variables,
      nodeData?.workflow_nodes,
      readonlyEdges,
      versionId,
      workflowId,
      workspaceId,
    ],
  );

  const versions = versionData?.workflow_versions;
  const refetchVersions = () => {
    refetch();
  };

  return {
    workflow: isEditing ? editableWorkflow : workflow,
    versions,
    isLoading,
    refetchVersions,
  };
};

export default useWorkflowData;
