import { BasicTooltip } from "@/components/ui/Tooltip";

import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { Input } from "@/components/ui/input";
import { Textarea } from "@/components/ui/textarea";
import { DocumentTextIcon, XIcon } from "@heroicons/react/outline";
import { KeyIcon } from "@heroicons/react/solid";
import { Maximize2 } from "lucide-react";
import { useEffect, useRef, useState } from "react";
import { Variable } from "./types";

interface InputVariableProps {
  variable: Variable;
  onChange: (variable: Variable) => void;
  onDelete: () => void;
  tabIndex?: number;
}

const InputVariable = ({
  variable,
  onChange,
  onDelete,
  tabIndex = 0,
}: InputVariableProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    inputRef.current?.focus();
  }, []);

  return (
    <div className="flex w-full items-center gap-x-2">
      <div className="flex-1">
        <div className="relative">
          <BasicTooltip content="Variable name" usePortal={false}>
            <KeyIcon className="absolute left-3 top-2.5 h-4 w-4 text-gray-400" />
          </BasicTooltip>
          <Input
            ref={inputRef}
            placeholder="Variable name"
            className="pl-9 font-mono"
            value={variable.key}
            onChange={(e) => onChange({ ...variable, key: e.target.value })}
            tabIndex={tabIndex}
          />
        </div>
      </div>
      <div className="flex-1">
        <div className="relative">
          <BasicTooltip content="Variable value" usePortal={false}>
            <DocumentTextIcon className="absolute left-3 top-2.5 h-4 w-4 text-gray-400" />
          </BasicTooltip>

          <Dialog open={isOpen} onOpenChange={setIsOpen}>
            <DialogTrigger asChild>
              <BasicTooltip content="Maximize" usePortal={false}>
                <Button
                  variant="ghost"
                  size="tinyIcon"
                  className="absolute bottom-1 right-2"
                  onClick={() => setIsOpen(true)}
                >
                  <Maximize2 className="h-4 w-4" />
                </Button>
              </BasicTooltip>
            </DialogTrigger>
            <DialogContent className="max-w-4xl">
              <DialogHeader>
                <DialogTitle className="font-mono">
                  {variable.key || "Unnamed Variable"}
                </DialogTitle>
              </DialogHeader>
              <Textarea
                placeholder="Value"
                className="min-h-[500px] font-mono"
                value={
                  typeof variable.value !== "string"
                    ? JSON.stringify(variable.value, null, 2)
                    : variable.value
                }
                onChange={(e) =>
                  onChange({ ...variable, value: e.target.value })
                }
              />
            </DialogContent>
          </Dialog>

          <Textarea
            tabIndex={tabIndex + 1}
            placeholder="Value"
            className="h-[36px] max-h-[250px] min-h-[36px] pl-9 pr-8 font-mono"
            autoSize
            value={
              typeof variable.value !== "string"
                ? JSON.stringify(variable.value, null, 2)
                : variable.value
            }
            onChange={(e) => onChange({ ...variable, value: e.target.value })}
          />
        </div>
      </div>
      <BasicTooltip content="Remove variable">
        <Button
          onClick={onDelete}
          variant="destructiveLink"
          size="sm"
          className="h-9 w-9 p-0"
          aria-label="Delete variable"
        >
          <XIcon className="h-4 w-4" />
        </Button>
      </BasicTooltip>
    </div>
  );
};

export default InputVariable;
