import { LOCAL_STORAGE } from "@/constants";
import {
  ChatTemplate,
  CompletionTemplate,
  Message,
  SystemMessage,
  Template,
  TemplateFormat,
  UserMessage,
} from "@/types";

export const dictToArrayOfDicts = (dict: Record<string, unknown>) => {
  return Object.keys(dict).map((key) => ({ key, value: dict[key] }));
};

export const arrayOfDictsToDict = (array: { key: string; value: any }[]) => {
  return array.reduce((acc: any, curr: any) => {
    acc[curr.key] = curr.value;
    return acc;
  }, {});
};

export default function getSubdomain() {
  if (window.location.host === "localhost:3000") {
    return "www";
  } else {
    return window.location.host.split(".")[0];
  }
}

export async function copyTextToClipboard(text: string) {
  if ("clipboard" in navigator) {
    return await navigator.clipboard.writeText(text);
  } else {
    return document.execCommand("copy", true, text);
  }
}

export const formatPrice = (price: number) =>
  `$${parseFloat(price.toFixed(8))}`;

export const URL_PARAM_KEYS = {
  METADATA: "metadata",
  SCORES: "scores",
  TAGS: "tags",
  FAVORITES: "favorites",
  Q: "q",
  START_TIME: "startTime",
  END_TIME: "endTime",
  PROMPT_TEMPLATE: "promptTemplate",
  DEFAULT_DATE_RANGE_LIMIT_IS_ON: "defaultDateRangeLimitIsOn",
};

export const parseUrlParams = (
  urlParams: URLSearchParams,
): {
  tags: string[];
  metadata: Array<{ key: string; value: string }>;
  favorites: string;
  query: string | null;
  scores: Array<{ name: string; operator: string; value: number }>;
  startTime: Date | null;
  endTime: Date | null;
  promptTemplate: { name: string; version_numbers?: number[] } | null;
} => {
  let tags = urlParams.getAll(URL_PARAM_KEYS.TAGS);
  if (tags.length === 1 && tags[0]?.startsWith("[") && tags[0].endsWith("]")) {
    try {
      tags = JSON.parse(tags[0]);
    } catch (e) {
      console.error("Failed to parse tags", e);
    }
  }

  let metadata: Array<{
    key: string;
    value: string;
  }> = [];
  const metadataParam = urlParams.getAll(URL_PARAM_KEYS.METADATA);
  if (metadataParam.length > 0) {
    try {
      metadata = JSON.parse(metadataParam[0]) as Array<{
        key: string;
        value: string;
      }>;
    } catch (e) {
      console.error("Failed to parse metadata", e);
    }
  }

  let scores: Array<{ name: string; operator: string; value: number }> = [];
  const scoresParam = urlParams.getAll(URL_PARAM_KEYS.SCORES);
  if (scoresParam.length > 0) {
    try {
      scores = JSON.parse(scoresParam[0]) as Array<{
        name: string;
        operator: string;
        value: number;
      }>;
    } catch (e) {
      console.error("Failed to parse scores", e);
    }
  }

  const favorites = urlParams.get(URL_PARAM_KEYS.FAVORITES) || "";
  const query = urlParams.get(URL_PARAM_KEYS.Q);

  const startTimeParam = urlParams.get(URL_PARAM_KEYS.START_TIME);
  const endTimeParam = urlParams.get(URL_PARAM_KEYS.END_TIME);
  const startTime = startTimeParam ? new Date(startTimeParam) : null;
  const endTime = endTimeParam ? new Date(endTimeParam) : null;

  let promptTemplate = null;
  const promptTemplateParam = urlParams.get(URL_PARAM_KEYS.PROMPT_TEMPLATE);
  if (promptTemplateParam) {
    try {
      promptTemplate = JSON.parse(promptTemplateParam);
    } catch (e) {
      console.error("Failed to parse prompt template", e);
    }
  }

  return {
    tags,
    metadata,
    favorites,
    query,
    scores,
    startTime,
    endTime,
    promptTemplate,
  };
};

export const removeTrailingCommas = (str: string) => {
  const regex = /,(?!\s*?[{["'\w])/g;
  return str.replace(regex, "");
};

export const keepRelevantSearchParams = (searchParams: URLSearchParams) => {
  const relevantKeys = Object.values(URL_PARAM_KEYS);
  for (const key of searchParams.keys()) {
    if (!relevantKeys.includes(key)) {
      searchParams.delete(key);
    }
  }
  return searchParams.toString();
};

export const getStringContent = (prompt: CompletionTemplate | Message) => {
  if ("role" in prompt && prompt.role === "placeholder") return prompt.name;
  if (!prompt.content || !Array.isArray(prompt.content)) {
    return "";
  }
  return prompt.content
    .map((item) => (item.type === "text" ? item.text : ""))
    .join("");
};

export const getSystemMessage = (chatPrompt: ChatTemplate) => {
  const systemMessage = chatPrompt.messages.find(
    ({ role }) => role === "system",
  );
  if (systemMessage) return systemMessage as SystemMessage;
  return null;
};

export const getFirstChatMessage = (chatPrompt: ChatTemplate) => {
  const firstMessage = chatPrompt.messages[0];
  if (firstMessage) return firstMessage;
  return null;
};

export const getTemplateFormat = (promptTemplate: Template) => {
  if (promptTemplate.type === "completion")
    return promptTemplate.template_format;
  if (promptTemplate.messages.length)
    return promptTemplate.messages[0].template_format;
  return "f-string";
};

export const getFunctionCall = (promptTemplate: ChatTemplate) => {
  if (promptTemplate.function_call) return promptTemplate.function_call;
  if (promptTemplate.tool_choice) {
    if (typeof promptTemplate.tool_choice === "string")
      return promptTemplate.tool_choice;
    return promptTemplate.tool_choice.function;
  }
};

export const getFunctions = (promptTemplate: ChatTemplate) => {
  if (promptTemplate.functions && promptTemplate.functions.length > 0)
    return promptTemplate.functions;
  if (promptTemplate.tools && promptTemplate.tools.length > 0)
    return promptTemplate.tools.map((tool) => tool.function);
  return [];
};

export const getFunctionsType = (promptTemplate: ChatTemplate) => {
  if (promptTemplate.tools && promptTemplate.tools.length > 0) return "tools";
  return "functions";
};

export const getResponseMessage = (chatTemplate: ChatTemplate) =>
  chatTemplate.messages.at(-1);

export const getRequestMessages = (chatTemplate: ChatTemplate) =>
  chatTemplate.messages.slice(0, -1);

export const getMediaVariable = (message: UserMessage): string => {
  const mediaVariable = message.content
    ?.filter((m) => m.type === "media_variable")
    .at(0);
  return mediaVariable ? mediaVariable.name : "";
};

export const formatJson = (json: string) => {
  try {
    return JSON.stringify(JSON.parse(json), null, 2);
  } catch (e) {
    return json;
  }
};
export const getLastMetadataFieldFilter = (): string | null => {
  const filter =
    localStorage.getItem(LOCAL_STORAGE.LAST_METADATA_FIELD_FILTER) || null;
  return filter;
};

export const setLastMetadataFieldFilter = (filter: string) => {
  localStorage.setItem(LOCAL_STORAGE.LAST_METADATA_FIELD_FILTER, filter);
};

export const getSavedTemplateFormatOrDefault = (): TemplateFormat => {
  const format =
    (localStorage.getItem(
      LOCAL_STORAGE.LAST_TEMPLATE_FORMAT,
    ) as TemplateFormat) || "f-string";
  return format;
};

export const setSavedTemplateFormat = (format: TemplateFormat) => {
  localStorage.setItem(LOCAL_STORAGE.LAST_TEMPLATE_FORMAT, format);
};

export const compareArrays = <T>(arr1: Array<T>, arr2: Array<T>) =>
  arr1.length === arr2.length &&
  arr1.every((element, index) => element === arr2[index]);

export const getTruncatedFolderPath = (folderPath: string[]) => {
  if (folderPath?.length >= 5) {
    const newPath = folderPath.slice(-5);
    newPath.unshift("...");
    return newPath;
  }
  return folderPath;
};
export const isValidUrl = (url: string): boolean => {
  try {
    const parsedUrl = new URL(url);
    if (parsedUrl.protocol === "data:") {
      return false;
    }
    return true;
  } catch {
    return false;
  }
};

export const createBlobUrlFromBase64 = (dataUrl: string, type: string) => {
  try {
    const base64Data = dataUrl.split(",")[1];
    const binary = atob(base64Data);
    const array = Uint8Array.from(binary, (char) => char.charCodeAt(0));
    const blob = new Blob([array], { type });
    return URL.createObjectURL(blob);
  } catch (error) {
    console.error("Failed to create Blob URL:", error);
    return null;
  }
};
