import { action, computed, makeObservable, observable } from "mobx";
import { ComponentType, Option, Parameter } from "@/queries/models/types";
import { ParameterValue } from "../utils";

type ParameterStoreData = Partial<Parameter> & {
  parameters?: Parameter[];
};

export class ParameterStore {
  @observable private _parameters?: ParameterStore[];
  @observable private _value?: ParameterValue;
  @observable private _isCustom?: boolean;
  @observable private _componentType?: ComponentType;
  @observable private _placeholder?: string;
  @observable private _options?: Option[];
  @observable private _name?: string;
  @observable private _paramId?: string;
  @observable private _isDisabled?: boolean;
  @observable private _default?: ParameterValue;
  @observable private _min?: number;
  @observable private _max?: number;

  constructor(parameter: ParameterStoreData) {
    this._parameters = parameter.parameters?.map(
      (param) =>
        new ParameterStore({
          ...param,
          value: parameter?.value?.[param.param_id],
        }),
    );
    this._value = parameter.value;
    this._isCustom = parameter.is_custom;
    this._componentType = parameter.component_type;
    this._placeholder = parameter.placeholder;
    this._options = parameter.options;
    this._name = parameter.name;
    this._paramId = parameter.param_id;
    this._isDisabled = parameter.is_disabled;
    this._default = parameter.default;

    if (
      parameter.component_type === ComponentType.SLIDER ||
      parameter.component_type === ComponentType.INPUT
    ) {
      this._min = parameter.min;
      this._max = parameter.max;
    }

    makeObservable(this);
  }

  @action
  updateValue(value: ParameterValue) {
    this._value = value;
  }

  @action
  updateDisabled(isEnabled: boolean) {
    this._isDisabled = !isEnabled;
  }

  @action
  updateKey(key: string) {
    this._paramId = key;
    this._name = key;
  }

  @computed
  get value() {
    return this._value;
  }

  @computed
  get min() {
    if (
      this._componentType === ComponentType.SLIDER ||
      this._componentType === ComponentType.INPUT
    ) {
      return this._min;
    }

    return undefined;
  }

  @computed
  get max() {
    if (
      this._componentType === ComponentType.SLIDER ||
      this._componentType === ComponentType.INPUT
    ) {
      return this._max;
    }

    return undefined;
  }

  @computed
  get placeholder() {
    return this._placeholder;
  }

  @computed
  get options() {
    return this._options;
  }

  @computed
  get parameters() {
    if (this._componentType === ComponentType.GROUP) {
      return this._parameters;
    }

    return [];
  }

  @computed
  get name() {
    return this._name;
  }

  @computed
  get paramId() {
    return this._paramId;
  }

  @computed
  get isDisabled() {
    return !!this._isDisabled;
  }

  @computed
  get isCustom() {
    return !!this._isCustom;
  }

  @computed
  get componentType() {
    return this._componentType;
  }

  @computed
  get default() {
    return this._default;
  }

  @computed
  get getParameter(): Parameter {
    const parameterData = {
      component_type: this._componentType,
      default: this._default,
      is_custom: this._isCustom,
      is_disabled: this._isDisabled,
      max: this._max,
      min: this._min,
      name: this._name,
      param_id: this._paramId,
      parameters: this.parameters?.map((p) => p.getParameter),
      placeholder: this._placeholder,
      options: this._options,
      value: this._value,
    };

    return parameterData as Parameter;
  }
}
