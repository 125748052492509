import { useState } from "react";
import { LanguageType } from "../CodeEditor/types";
import { languages } from "../CodeEditor/constants";
import { Popover, PopoverTrigger } from "../ui/popover";
import { AlertDialog } from "@/components/alert-dialog";
import CodeEditor from "../CodeEditor";
import { CollectionIcon } from "@heroicons/react/outline";

interface CodeHelpModalProps {
  setGroupHelpOpen: (open: boolean) => void;
  groupHelpOpen: boolean;
  code: Record<LanguageType, string>;
  title: string;
  description: string;
  docsLink: string;
  buttonText: string;
}

export const CodeHelpModal = ({
  setGroupHelpOpen,
  groupHelpOpen,
  code,
  title,
  description,
  docsLink,
  buttonText,
}: CodeHelpModalProps) => {
  const [selectedLanguage, setSelectedLanguage] = useState<LanguageType>(
    languages[0],
  );

  return (
    <Popover>
      <PopoverTrigger className="py-1 text-gray-400 hover:text-gray-500 md:ml-5">
        <CollectionIcon className="mr-1 inline h-4 w-4 align-middle" />
        <span
          className="mr-1 align-middle"
          onClick={() => setGroupHelpOpen(true)}
        >
          {buttonText}
        </span>
      </PopoverTrigger>
      <div className="text-blue-500 md:ml-5">
        <AlertDialog
          closeModal={() => setGroupHelpOpen(false)}
          show={groupHelpOpen}
          title={
            <>
              <div>
                {title}{" "}
                <a
                  href={docsLink}
                  target="_blank"
                  className="pl-1 text-sm font-medium text-blue-500 hover:text-blue-400"
                  rel="noreferrer"
                >
                  (Read the docs)
                </a>
              </div>
            </>
          }
        >
          <div className="space-y-4">
            <div>{description}</div>
            <CodeEditor
              showRuntimeDetails={false}
              languages={languages}
              editorOptions={{
                readOnly: true,
              }}
              selectedLanguage={selectedLanguage}
              handleLanguageChange={setSelectedLanguage}
              code={code[selectedLanguage]}
              height="300px"
            />
          </div>
        </AlertDialog>
      </div>
    </Popover>
  );
};
