import { ReportScore } from "@/types/evaluate";
import { SUCCEED_BOOLEAN_THRESHOLD } from "@/utils/evaluate";
import { CheckIcon, XIcon } from "@heroicons/react/outline";

const evaluationScoreText = (
  score: ReportScore | null,
  iconClass: string = "mr-1 inline h-3.5 w-auto",
): {
  scoreFormatted: string;
  scoreText: string;
  scoreColor: string;
  scoreIcon: JSX.Element | null;
} => {
  if (!score) {
    return {
      scoreFormatted: "",
      scoreText: "",
      scoreColor: "",
      scoreIcon: null,
    };
  }

  let scoreText = "";
  let scoreColor = "";
  let scoreFormatted = "";
  let scoreIcon = null;
  score = (score.type && score) || { type: typeof score, value: score };

  if (score.type === "boolean") {
    scoreFormatted = `${score.value.toFixed(0)}%`;
    scoreText =
      score.value === 100 ? "Pass" : `${score.value.toFixed(0)}% passing`;
    scoreColor =
      score.value === 100
        ? "text-green-500 font-semibold"
        : score.value > SUCCEED_BOOLEAN_THRESHOLD
        ? "text-gray-600"
        : "text-red-500 font-semibold";
    scoreIcon =
      score.value > SUCCEED_BOOLEAN_THRESHOLD ? (
        <CheckIcon className={iconClass} />
      ) : (
        <XIcon className={iconClass} />
      );
  } else if (score.type === "number") {
    scoreFormatted = score.value.toFixed(2);
    scoreText = `Score: ${score.value.toFixed(2)}`;
    scoreColor = "text-gray-600";
    scoreIcon = <CheckIcon className={iconClass} />;
  }

  return { scoreFormatted, scoreText, scoreColor, scoreIcon };
};

export const VersionScore = ({ score }: { score: ReportScore | null }) => {
  const { scoreText, scoreColor, scoreIcon } = evaluationScoreText(score);
  return scoreText ? (
    <div className={`flex items-center justify-between ${scoreColor}`}>
      {scoreIcon}
      {scoreText}
    </div>
  ) : null;
};
