import { useReportContext } from "@/components/Evaluate/report-context";
import React, { useCallback, useEffect, useState } from "react";

export interface Filter {
  column_id: string;
  value: string;
}

interface SearchContextProps {
  filters: { [columnId: string]: string[] };
  currentFilterInput: Filter | null;
  updateFilter: (columnId: string, value: string) => void;
  removeFilter: (columnId: string, value: string) => void;
  setCurrentFilterInput: (filter: Filter | null) => void;
  enabled: boolean;
  globalSearchTerm: string | null;
  setGlobalSearchTerm: (term: string | null) => void;
}

const SearchContext = React.createContext<SearchContextProps>({
  filters: {} as { [columnId: string]: string[] },
  currentFilterInput: null as Filter | null,
  setCurrentFilterInput: (args) => {},
  updateFilter: () => {},
  removeFilter: () => {},
  enabled: false,
  globalSearchTerm: "",
  setGlobalSearchTerm: () => {},
});

const useSearchProvider = () => {
  const {
    currentFilterInput,
    setCurrentFilterInput,
    filters,
    setFilters,
    globalSearchTerm,
    setGlobalSearchTerm,
  } = useReportContext();

  useEffect(() => {
    return () => {
      setFilters({});
      setCurrentFilterInput(null);
    };
  }, [setCurrentFilterInput, setFilters]);

  const updateFilter = useCallback(
    (column_id: string, value: string) => {
      setFilters((prevFilters) => {
        const newFilters: { [columnId: string]: string[] } = { ...prevFilters };
        if (value === "") {
          delete newFilters[column_id];
        } else {
          if (!newFilters[column_id]) {
            newFilters[column_id] = [];
          }
          if (!newFilters[column_id].includes(value)) {
            newFilters[column_id] = [...newFilters[column_id], value];
          }
        }
        return newFilters;
      });
    },
    [setFilters],
  );

  const removeFilter = useCallback(
    (columnId: string, value: string) => {
      setFilters((prevFilters) => {
        const newFilters = { ...prevFilters };
        if (newFilters[columnId]) {
          newFilters[columnId] = newFilters[columnId].filter(
            (v) => v !== value,
          );
          if (newFilters[columnId].length === 0) {
            delete newFilters[columnId];
          }
        }
        return newFilters;
      });
    },
    [setFilters],
  );

  const Provider = useCallback(
    ({ children }: { children: React.ReactNode }) => (
      <SearchContext.Provider
        value={{
          enabled: true,
          filters,
          setCurrentFilterInput,
          currentFilterInput,
          updateFilter,
          removeFilter,
          globalSearchTerm,
          setGlobalSearchTerm,
        }}
      >
        {children}
      </SearchContext.Provider>
    ),
    [
      currentFilterInput,
      filters,
      globalSearchTerm,
      removeFilter,
      setCurrentFilterInput,
      setGlobalSearchTerm,
      updateFilter,
    ],
  );
  return [
    Provider,
    {
      filters,
      setCurrentFilterInput,
      currentFilterInput,
      updateFilter,
      removeFilter,
      globalSearchTerm,
      setGlobalSearchTerm,
    },
  ] as const;
};

const useSearch = () => {
  return React.useContext(SearchContext);
};

const useColumnSearch = (column_id: string) => {
  const searchContext = useSearch();
  const [showFilterInput, setShowFilterInput] = useState(
    searchContext && searchContext.currentFilterInput?.column_id === column_id,
  );
  if (!searchContext.enabled)
    return [false, false, (val: boolean) => {}] as const;

  return [true, showFilterInput, setShowFilterInput] as const;
};

export { useColumnSearch, useSearch, useSearchProvider };
