import { FC, useEffect, useState, useCallback } from "react";
import { z } from "zod";
import { ENDPOINTS } from "@/api/application-api";
import { Button } from "@/components/ui/button";
import { useAuth } from "@/context/auth-context";
import { useUser } from "@/context/user-context";
import { useCreateWorkspaceMember } from "@/queries";
import { Dialog, DialogContent } from "@/components/ui/dialog";
import { DialogOverlay } from "@radix-ui/react-dialog";

const emailSchema = z.string().email();

type AddWorkspaceMemberProps = {
  setOpen: (isOpen: boolean) => void;
  isOpen: boolean;
};

const AddWorkspaceMemberModal: FC<AddWorkspaceMemberProps> = ({
  setOpen,
  isOpen,
}) => {
  const [addRequestPending, setAddRequestPending] = useState(false);
  const [displayEmailForm, setDisplayEmailForm] = useState(true);
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState<string | null>(null);
  const [sendInviteRequestPending, setSendInviteRequestPending] =
    useState(false);
  const [displayProPlanWarning, setDisplayProPlanWarning] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState<string | null>(null);

  const authContext = useAuth();
  const userContext = useUser();
  const userToken = authContext!.userToken;
  const createWorkspaceMember = useCreateWorkspaceMember(userToken!);

  useEffect(() => {
    const handleEscape = (event: KeyboardEvent) => {
      if (event.key === "Escape") {
        setOpen(false);
      }
    };

    window.addEventListener("keydown", handleEscape);
    return () => {
      window.removeEventListener("keydown", handleEscape);
    };
  }, [setOpen]);

  const handleAddClick = async () => {
    const validation = emailSchema.safeParse(email);

    if (!validation.success) {
      const message = validation.error.issues
        .map((issue) => issue.message)
        .join(", ");
      setEmailError(message);
      return;
    }

    try {
      setAddRequestPending(true);
      const response = await fetch(ENDPOINTS.get_user_by_email, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
        body: JSON.stringify({ email: email.trim().toLowerCase() }),
      });

      const data = await response.json();

      if (response.ok) {
        if (!data.is_pro_plan) {
          setSelectedUserId(data.user.id);
          setDisplayEmailForm(false);
          setDisplayProPlanWarning(true);
          return;
        }

        await handleConfirmAddUser(data.user.id);
        setOpen(false);
      } else {
        setDisplayEmailForm(false);
        setDisplayProPlanWarning(false);
        setEmailError(null);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setAddRequestPending(false);
    }
  };

  const handleConfirmAddUser = async (userId: string) => {
    await createWorkspaceMember.mutateAsync({
      user_id: parseInt(userId),
      workspace_id: userContext?.activeWorkspaceId!,
    });
    setOpen(false);
  };

  const handleCancel = () => {
    setDisplayEmailForm(true);
    setDisplayProPlanWarning(false);
    setSelectedUserId(null);
    setEmail("");
  };

  const resetModal = useCallback(() => {
    setEmail("");
    setEmailError(null);
    setDisplayEmailForm(true);
    setDisplayProPlanWarning(false);
    setSelectedUserId(null);
  }, []);

  useEffect(() => {
    if (!isOpen) {
      resetModal();
    }
  }, [isOpen, resetModal]);

  const handleSendInvite = async () => {
    try {
      setSendInviteRequestPending(true);
      await fetch(ENDPOINTS.workspace_invite, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
        body: JSON.stringify({
          email: email.trim().toLowerCase(),
          workspace_id: userContext?.activeWorkspaceId,
        }),
      });
      setOpen(false);
    } catch (error) {
      console.error(error);
    } finally {
      setSendInviteRequestPending(false);
    }
  };

  const renderEmailForm = () => {
    if (!displayEmailForm) return null;

    return (
      <div className="mb-4">
        <label
          className="block text-sm font-medium text-gray-700"
          htmlFor="member_email"
        >
          Member Email
        </label>
        <input
          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
          id="member_email"
          name="member_email"
          onChange={(e) => {
            setEmail(e.target.value);
            if (emailError) setEmailError(null);
          }}
          placeholder="Enter member's email"
          required
          type="email"
          value={email}
        />
        {emailError && (
          <p className="text-xs italic text-red-500">{emailError}</p>
        )}
        <div className="mt-4 flex justify-end gap-2 text-center">
          <Button isLoading={addRequestPending} onClick={handleAddClick}>
            Add Member
          </Button>
          <Button onClick={() => setOpen(false)} variant="destructiveOutline">
            Cancel
          </Button>
        </div>
      </div>
    );
  };

  const renderSendInviteForm = () => {
    if (displayEmailForm || displayProPlanWarning) return null;

    return (
      <div className="text-center">
        <p className="mb-4 text-sm text-gray-500">
          This person is not signed up yet. They need to be invited to create an
          account first.
        </p>
        <div className="flex justify-center gap-2">
          <Button onClick={handleCancel} variant="destructiveOutline">
            Cancel
          </Button>
          <Button
            isLoading={sendInviteRequestPending}
            onClick={handleSendInvite}
          >
            Send Invite
          </Button>
        </div>
      </div>
    );
  };

  const renderProPlanWarning = () => {
    if (!displayProPlanWarning) return null;

    return (
      <div className="text-center">
        <p className="mb-4 text-sm text-gray-500">
          This user is not on a pro plan. By adding them to this workspace, you
          will be charged for another pro seat on your account.
        </p>
        <div className="flex justify-center gap-2">
          <Button onClick={handleCancel} variant="outline">
            Cancel
          </Button>
          <Button onClick={() => handleConfirmAddUser(selectedUserId!)}>
            Confirm
          </Button>
        </div>
      </div>
    );
  };

  return (
    <Dialog open={isOpen} onOpenChange={setOpen}>
      <DialogOverlay className="fixed inset-0 bg-gray-800/30 backdrop-blur-sm" />
      <DialogContent>
        <div className="mb-4 text-2xl font-semibold">Add Workspace Member</div>
        {renderEmailForm()}
        {renderSendInviteForm()}
        {renderProPlanWarning()}
      </DialogContent>
    </Dialog>
  );
};

export default AddWorkspaceMemberModal;
