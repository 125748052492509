import { LanguageType } from "@/components/CodeEditor/types";
import { LanguageSelect } from "./LanguageSelect";
import { LanguageSelectorTabs } from "./LanguageTabs";
import { LanguageNameMap } from "../constants";

interface LanguageSelectorProps {
  type: "tabs" | "dropdown";
  action: React.ReactNode;
  languages: LanguageType[];
  selectedLanguage: LanguageType;
  setSelectedLanguage: (language: LanguageType) => void;
  label?: string;
  isReadOnly?: boolean;
  docsLink?: string;
}

export const LanguageSelector = ({
  type,
  action,
  languages,
  selectedLanguage,
  docsLink,
  isReadOnly,
  setSelectedLanguage,
  label,
}: LanguageSelectorProps) => {
  switch (type) {
    case "tabs":
      return (
        <LanguageSelectorTabs
          action={action}
          selectedLanguage={selectedLanguage}
          setSelectedLanguage={setSelectedLanguage}
          languages={languages}
          LanguageNameMap={LanguageNameMap}
          docsLink={docsLink}
        />
      );
    case "dropdown":
      return (
        <LanguageSelect
          languages={languages as LanguageType[]}
          editable={!isReadOnly}
          handleLanguageChange={setSelectedLanguage}
          language={selectedLanguage as LanguageType}
          label={label || "Language"}
        />
      );
  }
};
