import { ENDPOINTS } from "@/api/application-api";
import { handleResponse } from "@/utils/errorResponseHandlers";
import { authHeader } from "@/utils/headers";
import { useQueryClient, useMutation } from "@tanstack/react-query";

const useDeleteRunGroup = (userToken: string) => {
  const queryClient = useQueryClient();
  return useMutation(
    ({
      workspaceId,
      runGroupId,
    }: {
      workspaceId: number;
      runGroupId: string;
    }) =>
      fetch(`${ENDPOINTS.run_groups(workspaceId)}/${runGroupId}`, {
        method: "DELETE",
        headers: authHeader(userToken),
      }).then(handleResponse),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["runGroups"]);
      },
    },
  );
};

export default useDeleteRunGroup;
