import { AnimatePresence } from "framer-motion";
import { Observer, observer } from "mobx-react-lite";

import { Button } from "@/components/ui/button";
import { usePlayground } from "../playground-store";
import AnimatedElement from "./AnimatedElement";
import useMessageScrolling from "./hooks/useMessageScrolling";
import Message, { MessagePlaceholder } from "./Message";
import { IMessageStore } from "./Message/message-store";
import MessagesFooter from "./MessagesFooter";
import MessagesHeader from "./MessagesHeader";

interface MessagesProps {
  messages: IMessageStore[];
  onAddMessage?: () => void;
}

const MessagesList = observer(() => {
  const playground = usePlayground();
  const { messagesEndRef } = useMessageScrolling(playground);
  const MINIMUM_MESSAGES = playground.isChat ? 2 : 1;
  const required_placeholders = MINIMUM_MESSAGES - playground.messages.length;

  return (
    <div
      className="absolute z-0 h-full w-full flex-col overflow-x-hidden pt-2"
      key={playground.mode}
    >
      <AnimatePresence initial={true} mode={"popLayout"}>
        <Observer>
          {() => (
            <>
              {playground.messages.map((message, index) => (
                <AnimatedElement
                  key={message.id}
                  className={`${playground.isChat ? "" : "h-full"}`}
                >
                  <div className="h-full w-full">
                    <Message
                      message={message}
                      index={index}
                      isCompletion={!playground.isChat}
                      isFirst={index === 0}
                      readonly={playground.readonly}
                    />
                  </div>
                </AnimatedElement>
              ))}
              {required_placeholders > 0
                ? Array(required_placeholders)
                    .fill(null)
                    .map((_, i) => (
                      <AnimatedElement key={i}>
                        <MessagePlaceholder key={i} />
                      </AnimatedElement>
                    ))
                : null}
              <div ref={messagesEndRef} />
            </>
          )}
        </Observer>
      </AnimatePresence>
    </div>
  );
});

const Messages = (_: MessagesProps) => {
  const playground = usePlayground();
  const { containerRef } = useMessageScrolling(playground);

  return (
    <div className="flex h-full w-full flex-col gap-y-2">
      <div
        className="flex h-full w-full flex-col overflow-y-hidden scroll-smooth"
        ref={containerRef}
      >
        {!playground.readonly && <MessagesHeader />}
        <div className="relative h-full flex-1 overflow-y-auto ">
          <MessagesList />
        </div>
        <div className="mb-3 ml-3">
          {playground.isChat && !playground.readonly && (
            <Button
              onClick={() => playground.addEmptyMessage()}
              variant={"ghost"}
            >
              + New Message
            </Button>
          )}
        </div>
      </div>
      <MessagesFooter />
    </div>
  );
};

export default observer(Messages);
