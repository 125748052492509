import { observer, Observer } from "mobx-react-lite";
import { useState } from "react";
import {
  ConfirmationModal,
  ConfirmationModalVariant,
} from "../ConfirmationModal";
import CompareColumnLayout from "../ui/CompareColumnLayout";
import useAvailableInputVariables from "./hooks/useAvailableInputVariables";
import useWebSocketListeners from "./hooks/useWebSocketListeners";
import Messages from "./Messages";
import { usePlayground } from "./playground-store";
import Sidebar from "./Sidebar";

const PlaygroundCore = () => {
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const playground = usePlayground();
  useWebSocketListeners();
  useAvailableInputVariables();

  return (
    <>
      <Observer>
        {() => (
          <>
            <ConfirmationModal
              title="Unsaved Changes"
              description={
                "You have unsaved changes. Are you sure you want to leave?"
              }
              handleOnConfirm={() => {
                setIsConfirmModalOpen(false);
              }}
              useBlocker={!playground.readonly}
              isNavigationBlocked={playground.hasBeenEdited}
              isOpen={isConfirmModalOpen}
              setIsOpen={setIsConfirmModalOpen}
              leaveButtonText="Discard Changes"
              saveButtonText="Cancel"
              headerVariant={ConfirmationModalVariant.warning}
            />
          </>
        )}
      </Observer>
      <div className="relative flex h-full w-full flex-col gap-y-3">
        {playground.readonly ? (
          <Messages
            messages={playground.messages}
            onAddMessage={playground.addEmptyMessage}
          />
        ) : (
          <CompareColumnLayout
            minimal
            gap={0}
            leftComponent={
              <Messages
                messages={playground.messages}
                onAddMessage={playground.addEmptyMessage}
              />
            }
            rightComponent={<Sidebar />}
          />
        )}
      </div>
    </>
  );
};

export default observer(PlaygroundCore);
