import { memo } from "react";
import { observer } from "mobx-react-lite";
import { withPlaygroundState } from "./playground-store";
import PlaygroundCore from "./PlaygroundCore";
import PlaygroundWithSession from "./PlaygroundWithSession";

// Export the core component wrapped with state and memoized
export const Playground = memo(observer(withPlaygroundState(PlaygroundCore)));

// Export the session-aware component with memoization
export const PlaygroundSession = memo(
  observer(withPlaygroundState(PlaygroundWithSession)),
);

// Export default component for backward compatibility
export default memo(observer(withPlaygroundState(PlaygroundCore)));
