import { WEBSOCKET_MESSAGE_NAME } from "@/constants";
import { useUser } from "@/context/user-context";
import { useChannel } from "ably/react";
import { usePlayground } from "../../playground-store";

const useTemplateAppendListener = () => {
  const { user } = useUser();
  const playground = usePlayground();

  const handleTemplateAppend = (payload: any) => {
    const response = JSON.parse(payload.data);
    const { individual_run_request_id } = response;
    if (!playground.isRequestValid(individual_run_request_id)) return;
    const template: string = response?.payload?.template;

    const operation =
      playground.operationStore.getAssociatedOperationByRequestId(
        individual_run_request_id,
      );

    if (!operation?.output_instance_id) {
      const clonedPlayground = playground.clone(operation);

      const output = playground.addOutput(clonedPlayground);

      playground.operationStore.assignOutputId(
        operation!.id,
        output.instanceId,
      );
    }

    playground.appendToTemplate(template);
  };

  useChannel(
    `user:${user.id}`,
    WEBSOCKET_MESSAGE_NAME.APPEND_TO_TEMPLATE,
    handleTemplateAppend,
  );
};

export default useTemplateAppendListener;
