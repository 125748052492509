import { Transition } from "./types";

const isPathMatch = (path1: string, path2: string, matcher: string) => {
  return path1.includes(matcher) && path2.includes(matcher);
};

const isValidPromptId = (path: string) => {
  const id = path.split("prompt/")[1]?.split("/")[0];
  const numId = Number(id);
  return !isNaN(numId) && numId !== 0;
};

export const isConfirmationSkipped = (transition: Transition) => {
  const { currentLocation, nextLocation } = transition;
  const current = currentLocation.pathname;
  const next = nextLocation.pathname;

  return (
    // Chat mode switch - template edit
    isPathMatch(current, next, "create-prompt") ||
    // Chat mode switch - playground
    isPathMatch(current, next, "playground") ||
    // Submit creation - template creation
    (current.includes("create-prompt") &&
      next.includes("prompt/") &&
      isValidPromptId(next)) ||
    // Cancel edition - template edit
    (current.includes("prompt") &&
      next.includes("prompt/") &&
      isValidPromptId(next))
  );
};

export const shouldSkipNavigation = (transition: Transition) =>
  transition.nextLocation.pathname !== transition.currentLocation.pathname;
export const shouldBlockTransition = (
  transition: Transition,
  isBlockerEnabled: boolean,
) => {
  const navigationShouldBeSkipped = shouldSkipNavigation(transition);
  const confirmationIsNotSkipped = !isConfirmationSkipped(transition);

  return (
    isBlockerEnabled && navigationShouldBeSkipped && confirmationIsNotSkipped
  );
};
