import {
  Content,
  FunctionCall,
  Media,
  MediaContent,
  MediaVariable,
  ToolCall,
} from "@/types";
import { action, computed, makeAutoObservable } from "mobx";
import { v4 as uuidv4, v4 } from "uuid";
import {
  DEFAULT_MESSAGE_TYPE,
  Message,
  MessageType,
  ToolCallUIData,
} from "../../types";
import { MESSAGE_PLACEHOLDERS } from "../constants";

export class MessageStore {
  private _id: string;
  private _content: Content[];
  private _role: MessageType;
  private _name: string | undefined;
  private _tool_calls: Map<string, ToolCallUIData> = new Map();
  private _error: string | undefined;

  constructor(defaultMessage: Partial<Message> = {}) {
    const {
      content = [{ type: "text", text: "" }],
      role = DEFAULT_MESSAGE_TYPE,
      id = uuidv4(),
    } = defaultMessage;
    this._id = id;
    this._content = content;
    this._role = role;
    if (role === "placeholder" && "name" in defaultMessage)
      this._content = [
        {
          type: "text",
          text: defaultMessage.name as string,
        },
      ];

    if ("name" in defaultMessage) this._name = defaultMessage.name as string;

    if ("tool_calls" in defaultMessage && defaultMessage.tool_calls) {
      const initialToolCalls = defaultMessage.tool_calls as ToolCall[];
      this._tool_calls = new Map(
        initialToolCalls.map((toolCall) => [
          toolCall.id,
          {
            id: toolCall.id,
            _id: toolCall._id,
            name: toolCall.function.name,
            arguments: toolCall.function.arguments,
          },
        ]),
      );
    }

    if ("tool_call_id" in defaultMessage) {
      this._name = defaultMessage.tool_call_id as string;
    }

    if ("function_call" in defaultMessage && defaultMessage.function_call) {
      const initialFunctionCall = defaultMessage.function_call as FunctionCall;
      this._role = "assistant";
      this._name = initialFunctionCall.name;
      this._content = [
        {
          type: "text",
          text: initialFunctionCall.arguments,
        },
      ];
    }

    makeAutoObservable(this, {}, { autoBind: true });
  }

  get id(): string {
    return this._id;
  }

  get tool_calls(): ToolCall[] {
    return Array.from(this._tool_calls.values()).map((toolCallUIData) => ({
      id: toolCallUIData.id,
      _id: toolCallUIData._id,
      type: "function",
      function: {
        name: toolCallUIData.name,
        arguments: toolCallUIData.arguments,
      },
    }));
  }

  @action addToolCall(toolCall: ToolCallUIData) {
    const id = uuidv4();
    toolCall._id = id;
    this._tool_calls.set(id, toolCall);
  }

  @action updateToolCall(toolCall: ToolCall) {
    if (toolCall._id) {
      this._tool_calls.set(toolCall._id!, {
        id: toolCall.id,
        _id: toolCall._id,
        name: toolCall.function.name,
        arguments: toolCall.function.arguments,
      });
    }
  }

  @action deleteToolCall(_id: string) {
    this._tool_calls.delete(_id);
  }

  @action setName(name: string) {
    this._name = name;
  }

  get name() {
    if (this.role === "placeholder") {
      return this.content;
    }

    return this._name;
  }

  get hasToolCalls(): boolean {
    return this.canAddToolCalls && this._tool_calls.size > 0;
  }

  get canAddToolCalls(): boolean {
    return this._role === "assistant";
  }

  get hasName(): boolean {
    return (
      this._role === "assistant" ||
      this._role === "tool" ||
      this._role === "function"
    );
  }

  get hasMedia(): boolean {
    return this._role === "user";
  }

  get content(): string {
    const textContent =
      this._content.find((content) => content.type === "text")?.text || "";

    return textContent;
  }

  get function_call(): FunctionCall | undefined {
    if (this._role !== "assistant" || !this.name) return undefined;
    return {
      arguments: this.content,
      name: this.name!,
    };
  }

  setMediaVariable(name: string) {
    const existingMediaVariable = this._content.find(
      (content): content is MediaVariable => content.type === "media_variable",
    );
    if (existingMediaVariable) {
      existingMediaVariable.name = name;
    } else {
      this._content.push({
        type: "media_variable",
        name,
      });
    }
  }

  deleteMediaAtIndex(index: number) {
    const mediaOffset = this._content.findIndex(
      (content) => content.type === "media",
    );
    this._content.splice((mediaOffset !== -1 ? mediaOffset : 0) + index, 1);
  }

  setSelectedMedia(media?: Media) {
    if (media) {
      this._content.push({
        type: "media",
        media,
      });
    }
  }

  get selected_media(): Media[] | undefined {
    const existingMedia = this._content
      .filter((content): content is MediaContent => content.type === "media")
      .map((media) => media?.media);
    return existingMedia;
  }

  get media_variable(): string | undefined {
    const existingMediaVariable = this._content.find(
      (content): content is MediaVariable => content.type === "media_variable",
    );
    return existingMediaVariable?.name;
  }

  @computed
  get rawContent(): Content[] {
    if (this.role === "assistant" && this.function_call)
      return [
        {
          type: "text",
          text: "",
        },
      ];
    else if (this.role === "placeholder") return [];

    return this._content;
  }

  get role(): MessageType {
    return this._role;
  }

  get usesMonospaceFont(): boolean {
    return !!(
      ((this._role === "assistant" ||
        this._role === "tool" ||
        this._role === "placeholder") &&
        this._name) ||
      this._role === "function"
    );
  }

  get placeholder() {
    const defaultPlaceholder = MESSAGE_PLACEHOLDERS[this._role];

    if (this._name === "function") return defaultPlaceholder;
    if (this.usesMonospaceFont && this._role !== "placeholder")
      return MESSAGE_PLACEHOLDERS.function;
    return defaultPlaceholder;
  }

  setRole(role: MessageType): void {
    this._role = role;
  }

  @action
  setMessage(message: string): void {
    if (this._content.length && this._content[0].type === "text") {
      this._content[0].text = message;
    } else {
      this._content = [{ type: "text", text: message }];
    }
  }

  replace(
    message: Message & {
      tool_calls?: ToolCall[];
      function_call?: FunctionCall;
    },
  ) {
    this._content = message.content;
    const messageFunctionCall = message.function_call as FunctionCall;

    if (messageFunctionCall) {
      this._role = "assistant";
      this._content = [
        {
          type: "text",
          text: messageFunctionCall.arguments,
        },
      ];
      this._name = messageFunctionCall.name;
      return;
    }

    const initialToolCalls = message.tool_calls as ToolCall[];
    if (initialToolCalls)
      this._tool_calls = new Map(
        initialToolCalls.map((toolCall) => [
          toolCall.id,
          {
            id: toolCall.id,
            _id: toolCall._id,
            name: toolCall.function.name,
            arguments: toolCall.function.arguments,
          },
        ]),
      );
  }

  resetId() {
    this._id = v4();
  }

  toMessage(): Message {
    return {
      content: this._content,
      role: this._role,
      id: this._id,
    };
  }

  get schema() {
    const identifier =
      this.role === "tool" ? { tool_call_id: this.name } : { name: this.name };
    return {
      role: this.role as MessageType,
      content: this.rawContent,
      ...identifier,
      tool_calls: this.tool_calls || [],
      function_call: this.function_call,
    };
  }

  @action setParsingError(error: string) {
    this._error = error;
  }

  get parsingError() {
    return this._error;
  }
}

export type IMessageStore = InstanceType<typeof MessageStore>;
