import { Slot } from "@radix-ui/react-slot";
import { cva, type VariantProps } from "class-variance-authority";
import { ButtonHTMLAttributes, HTMLAttributes, forwardRef } from "react";

import { cn } from "@/lib/utils";
import LoadingSpinner from "../LoadingSpinner";

export type ButtonSize =
  | "default"
  | "xs"
  | "sm"
  | "lg"
  | "xl"
  | "icon"
  | "tinyIcon"
  | "tinyButton";

const buttonVariants = cva(
  "inline-flex items-center justify-center rounded-md text-sm font-medium focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:pointer-events-none disabled:opacity-50 transition-transform",
  {
    variants: {
      variant: {
        default:
          "bg-primary text-primary-foreground shadow hover:bg-primary/90",
        destructive:
          "bg-destructive text-destructive-foreground shadow-sm hover:bg-destructive/90",
        outline:
          "border border-input bg-background shadow-sm hover:bg-accent text-gray-800 hover:text-gray-600",
        destructiveOutline:
          "border border-red-300 bg-background shadow-sm hover:bg-red-50 text-destructive hover:text-red-600",
        secondaryOutline:
          "border border-blue-300 bg-background shadow-sm hover:bg-blue-50 text-gray-800 hover:text-blue-600",
        secondary:
          "bg-gray-50 border border-gray-300 text-secondary-foreground shadow-sm hover:bg-secondary/80",
        secondaryLight:
          "bg-blue-100 text-blue-700 shadow-sm hover:bg-blue-100/80 hover:text-blue-600",
        secondaryLightOutline:
          "border border-blue-300 bg-blue-50 text-blue-600 shadow-sm hover:bg-blue-50/80 hover:border-blue-400",
        ghost:
          "hover:bg-accent hover:text-accent-foreground text-gray-800 hover:text-gray-600",
        ghostGray:
          "bg-gray-200 hover:bg-gray-300 text-gray-800 hover:text-gray-900",
        link: "text-blue-600 hover:text-blue-400 underline-offset-4",
        inlineLink:
          "text-sm font-semibold text-[#1D4ED8] hover:text-[#1D4ED8]/90 !p-0",
        destructiveLink: "text-red-600 hover:text-red-400 underline-offset-4",
      },
      size: {
        default: "px-4 py-2",
        xs: "px-2 py-1.5 text-xs",
        sm: "h-8 rounded-md px-3 text-xs",
        lg: "h-10 rounded-md px-8",
        xl: "h-12 rounded-lg px-10 text-lg",
        icon: "h-9 w-9",
        tinyIcon: "h-7 w-7",
        tinyButton: "h-5 px-2 text-xs",
      },
    },
    defaultVariants: {
      variant: "default",
      size: "default",
    },
  },
);

type ExtendedButtonProps = ButtonHTMLAttributes<HTMLButtonElement> &
  HTMLAttributes<HTMLDivElement> &
  VariantProps<typeof buttonVariants>;

export interface ButtonProps extends ExtendedButtonProps {
  asChild?: boolean;
  isLoading?: boolean;
  size?: ButtonSize;
}

const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      className,
      disabled,
      size,
      variant,
      asChild = false,
      isLoading = false,
      ...props
    },
    ref,
  ) => {
    const Comp = asChild ? Slot : "button";
    return (
      <Comp
        className={cn(buttonVariants({ variant, size, className }))}
        disabled={disabled || isLoading}
        ref={ref}
        {...props}
      >
        {isLoading ? <LoadingSpinner size={5} /> : props.children}
      </Comp>
    );
  },
);
Button.displayName = "Button";

const DivButton = forwardRef<HTMLDivElement, ButtonProps>(
  (
    {
      className,
      disabled,
      size,
      variant,
      asChild = false,
      isLoading = false,
      ...props
    },
    ref,
  ) => {
    const Comp = asChild ? Slot : "div";
    return (
      <Comp
        className={cn(
          "cursor-pointer",
          buttonVariants({ variant, size, className }),
        )}
        aria-disabled={disabled || isLoading}
        ref={ref}
        {...props}
      >
        {isLoading ? <LoadingSpinner size={5} /> : props.children}
      </Comp>
    );
  },
);
DivButton.displayName = "DivButton";

export { Button, DivButton, buttonVariants };
