import { TrashIcon } from "lucide-react";
import { useContext, useMemo, useState } from "react";

import {
  DialogTrigger,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogHeader,
} from "@/components/ui/dialog";
import { DivButton } from "@/components/ui/button";
import { DatasetEditorContext } from "@/components/DatasetEditor";
import ArchiveConfirmationForm from "./ArchiveConfirmationForm";

const ArchiveButton = ({ onDelete }: { onDelete: () => void }) => {
  const { isDraft, datasetGroup, isLoading } = useContext(DatasetEditorContext);

  const isArchived = useMemo(() => !!datasetGroup?.is_deleted, [datasetGroup]);

  const [open, setOpen] = useState(false);

  if (!datasetGroup || datasetGroup.is_deleted) {
    return (
      <div className="flex flex-row items-center">
        <div className="mr-2 h-1 w-1 rounded-full bg-gray-300"></div>
        <span className="self-center text-sm text-gray-400">Archived</span>
      </div>
    );
  }

  const isDisplayed = !isArchived && !isLoading && !isDraft;

  return (
    (isDisplayed && (
      <Dialog open={open} onOpenChange={setOpen}>
        <DialogTrigger>
          <DivButton
            onClick={() => setOpen(true)}
            variant={"destructiveOutline"}
            size={"sm"}
            className="flex h-full flex-1"
          >
            <TrashIcon className="h-4 w-auto pr-1" aria-hidden="true" />
            Archive
          </DivButton>
        </DialogTrigger>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>
              Are you sure you want to delete this dataset group?
            </DialogTitle>
          </DialogHeader>
          <ArchiveConfirmationForm
            onSuccess={onDelete}
            setOpen={setOpen}
            name={datasetGroup?.name || ""}
          />
        </DialogContent>
      </Dialog>
    )) ||
    null
  );
};

export default ArchiveButton;
