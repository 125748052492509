import VersionSelector, {
  VersionSelectorProps,
} from "@/components/VersionSelector";
import { useAuth } from "@/context/auth-context";
import { useUser } from "@/context/user-context";
import {
  useCreatePromptLabel,
  useDynamicReleaseLabelsByPromptRegistry,
  useEvaluationScoresForVersions,
  usePromptLabelsDelete,
  usePromptLabelsPatch,
  usePromptVersions,
  useRequestCountsForVersions,
} from "@/queries";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

const PromptTemplateVersionSelector = ({
  selectedVersionId,
  ...versionSelectorProps
}: {
  selectedVersionId?: number;
} & Partial<VersionSelectorProps>) => {
  const { promptId, versionId } = useParams();
  const workspaceId = useUser()?.activeWorkspaceId!;
  const userToken = useAuth()?.userToken!;
  const navigate = useNavigate();
  const addReleaseLabel = useCreatePromptLabel(userToken);
  const deleteReleaseLabel = usePromptLabelsDelete(userToken);
  const patchReleaseLabel = usePromptLabelsPatch(userToken);
  const promptVersionsQuery = usePromptVersions(userToken, {
    promptRegistryId: parseInt(promptId!),
    workspaceId,
  });

  const { data } = useDynamicReleaseLabelsByPromptRegistry(
    userToken,
    promptId!,
  );
  const dynamicReleaseLabels = data?.dynamic_release_labels;

  const dynamicReleaseLabelRegistry: {
    [version: number]: { name: string; id: number }[];
  } = dynamicReleaseLabels
    ? Object.keys(dynamicReleaseLabels).reduce(
        (acc, versionId) => {
          const intVersionId = parseInt(versionId);
          acc[intVersionId] = dynamicReleaseLabels[Number(versionId)].map(
            (item) => ({
              name: item.prompt_label_name,
              id: item.release_label_group_id,
            }),
          );
          return acc;
        },
        {} as { [version: number]: { name: string; id: number }[] },
      )
    : {};

  const versions = promptVersionsQuery.data?.pages.flatMap(
    (page) => page.items,
  );

  useEffect(() => {
    if (
      versionId &&
      versions &&
      !versions.map((v) => v.number).includes(Number(versionId))
    ) {
      promptVersionsQuery.refetch();
    }
  }, [promptVersionsQuery, versionId, versions]);

  const defaultVersion = promptVersionsQuery.data?.pages?.[0]?.items[0]?.number;

  const { data: requestCounts } = useRequestCountsForVersions(
    promptId!,
    workspaceId.toString(),
    userToken || "",
  );

  useEffect(() => {
    if (!versionId && defaultVersion !== undefined) {
      navigate(
        `/workspace/${workspaceId}/prompt/${promptId}/version/${defaultVersion}`,
        { replace: true },
      );
    }
  }, [defaultVersion, navigate, promptId, versionId, workspaceId]);

  const versionEvaluationScores = useEvaluationScoresForVersions(
    promptId!,
    userToken || "",
  );

  return (
    <VersionSelector
      versions={versions}
      counts={requestCounts}
      versionEvaluationScoresRegistry={versionEvaluationScores.data}
      dynamicReleaseLabelsRegistry={dynamicReleaseLabelRegistry}
      onAddReleaseLabel={async (label, versionId) => {
        const match = versions
          ?.find((v) => v.labels?.some(({ name }) => name === label))
          ?.labels?.find(({ name }) => name === label);
        if (match) {
          return () =>
            patchReleaseLabel.mutate(
              {
                promptId: parseInt(promptId!),
                versionId: versionId!,
                name: label,
                id: match.id,
              },
              {
                onSuccess: () => {
                  promptVersionsQuery.refetch();
                },
              },
            );
        } else {
          addReleaseLabel.mutate(
            {
              promptId: parseInt(promptId!),
              name: label,
              versionId: versionId!,
            },
            {
              onSuccess: (data) => {
                promptVersionsQuery.refetch();
              },
            },
          );
          return false;
        }
      }}
      fetchNextPage={promptVersionsQuery.fetchNextPage}
      onRemoveReleaseLabel={async (label) => {
        deleteReleaseLabel.mutate({
          promptId: parseInt(promptId!),
          versionId: selectedVersionId!,
          id: label,
        });
      }}
      setShowVersionList={() => {}}
      navigateToVersionLink={(version) =>
        `/workspace/${workspaceId}/prompt/${promptId}/version/${version}`
      }
      showVersionList={false}
      {...versionSelectorProps}
    />
  );
};

export default PromptTemplateVersionSelector;
