import Message from "@/components/Playground2/Messages/Message";
import { IMessageStore } from "@/components/Playground2/Messages/Message/message-store";
import { IndividualRun } from "@/types/metadata";
import { observer } from "mobx-react-lite";
import RunInfo from "./RunInfo";

const MessageWithRun = observer(
  ({
    message,
    getMessageRun,
    extraControls,
  }: {
    message: IMessageStore;
    getMessageRun: (id: string) => IndividualRun | null;
    extraControls: (id: string) => JSX.Element[];
  }) => {
    const runData = getMessageRun(message.id);
    return (
      <div className="group h-full w-full">
        <Message
          extraHeader={
            runData && (
              <RunInfo
                runData={runData}
                extraControls={extraControls(message.id)}
              />
            )
          }
          message={message}
          readonly
        />
      </div>
    );
  },
);

export default MessageWithRun;
