import { observer } from "mobx-react-lite";

import { Label } from "@/components/ui/label";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import useProviderBaseURLNameOptions from "@/hooks/useProviderBaseURLNameOptions";

import { useParameters } from "../store";

export const ProviderBaseURL = observer(() => {
  const store = useParameters();
  const providerBaseURL = store.providerBaseURL;
  const providerBaseURLNameOptions = useProviderBaseURLNameOptions();

  if (!providerBaseURLNameOptions.length) return null;

  let value = providerBaseURL ?? "";
  const italicStyling = value === "" ? "italic text-gray-500" : "";

  return (
    <div className="flex-0 mb-4 md:mb-0">
      <div className="mb-4">
        <Label className="col-span-2" htmlFor="providerBaseURL">
          Provider Base URL
        </Label>
        <div className="col-span-2">
          <Select
            onValueChange={(providerName) => {
              store.updateProviderBaseURL(providerName);
            }}
            value={value}
          >
            <SelectTrigger
              className={`flex items-center justify-between break-all rounded-sm border border-gray-300 bg-white py-2 pl-4 pr-2 text-sm font-medium text-gray-800 shadow-sm hover:bg-gray-50 ${italicStyling}`}
              id="providerBaseURL"
            >
              <SelectValue placeholder="Default" />
            </SelectTrigger>
            <SelectContent>
              <SelectGroup>
                {providerBaseURLNameOptions.map((providerName) => (
                  <SelectItem
                    className={
                      providerName === "" ? "italic text-gray-500" : ""
                    }
                    key={providerName}
                    value={providerName}
                  >
                    {providerName || "Default"}
                  </SelectItem>
                ))}
              </SelectGroup>
            </SelectContent>
          </Select>
        </div>
      </div>
    </div>
  );
});
