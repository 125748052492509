import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import SchemaEditor from "@/components/SchemaEditor";
import { useState } from "react";

import { EditJsonSchemaModalProps } from "../types";

const EditJsonSchemaModal = ({
  initialValues,
  handleSubmit,
  readonly = false,
}: EditJsonSchemaModalProps) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  return (
    <Dialog open={isOpen} onOpenChange={(open: boolean) => setIsOpen(open)}>
      <DialogTrigger asChild>
        <Button variant="outline">
          {readonly ? "View" : "Edit"} JSON Schema
        </Button>
      </DialogTrigger>
      <DialogContent className="flex h-[90vh] w-screen max-w-full flex-col overflow-hidden px-12">
        <DialogTitle className="pb-2">
          {readonly ? "View" : "Edit"} JSON Schema
        </DialogTitle>
        <div className="relative flex flex-1 flex-col overflow-hidden">
          <div className="h-full overflow-y-auto pb-20">
            <SchemaEditor
              schema={initialValues.json}
              onSubmit={(e) => {
                setIsOpen(false);
                handleSubmit?.(e);
              }}
              readonly={readonly}
            />
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default EditJsonSchemaModal;
