export const DEFAULT_MODEL_NAMES = {
  openai: "gpt-4o",
  "openai.azure": "gpt-4o",
  anthropic: "claude-3-7-sonnet-latest",
  "anthropic.bedrock": "anthropic.claude-3-7-sonnet-20250219-v1:0",
  google: "gemini-2.0-flash",
  mistral: "mistral-large-latest",
  cohere: "command-r-plus",
};

export const HUGGINGFACE_PROVIDER = "huggingface";
