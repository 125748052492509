import { PostHogProvider as PostHogProviderBase } from "posthog-js/react";

const isProduction = process.env.NODE_ENV === "production";

const options = {
  api_host: process.env.REACT_APP_PUBLIC_POSTHOG_HOST,
  api_key: isProduction
    ? process.env.REACT_APP_PUBLIC_PROD_POSTHOG_KEY
    : process.env.REACT_APP_PUBLIC_DEV_POSTHOG_KEY,
  disable_session_recording: !isProduction,
  capture_pageview: isProduction,
  debug: process.env.REACT_APP_POSTHOG_DEBUG === "true",
};

export const PostHogProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => (
  <PostHogProviderBase apiKey={options.api_key || ""} options={options}>
    {children}
  </PostHogProviderBase>
);
