import { Button, DivButton } from "@/components/ui/button";
import { BasicTooltip } from "@/components/ui/Tooltip";
import { useUser } from "@/context/user-context";
import { ReportCell } from "@/types/evaluate";
import { RefreshIcon } from "@heroicons/react/outline";
import { useEffect, useState } from "react";
import { StatusCell } from "./StatusCell";

export const FailedCell = ({
  cell,
  retryReportCell,
  retryReportCellIsLoading,
}: {
  cell: ReportCell;
  retryReportCell: () => void;
  retryReportCellIsLoading: boolean;
}) => {
  const [url, setUrl] = useState("");
  const { activeWorkspaceId: workspaceId } = useUser();
  useEffect(() => {
    if (cell.value && cell.value.value && cell.value.value.trace_id) {
      setUrl(`/workspace/${workspaceId}/traces/${cell.value.value.trace_id}`);
    }
  }, [cell.value, workspaceId]);
  const plLinkButton =
    url !== "" ? (
      <a
        href={url}
        target="_blank"
        rel="noreferrer"
        className="absolute right-2 top-2 flex opacity-0 group-hover:opacity-100"
      >
        <BasicTooltip content="Go to request" side="top">
          <Button size="tinyIcon" variant="outline" className="-mt-1">
            🍰
          </Button>
        </BasicTooltip>
      </a>
    ) : null;
  return (
    <div className="flex flex-col justify-center text-center">
      <StatusCell status="failed" />
      <div className="group relative mx-auto w-full whitespace-pre-wrap px-3 py-1 text-xs text-red-500">
        Error: {cell.error_message}
        {plLinkButton}
      </div>
      <div>
        <DivButton
          size="sm"
          variant="link"
          className="cursor-pointer"
          onClick={retryReportCell}
          isLoading={retryReportCellIsLoading}
        >
          <RefreshIcon className="mr-1 h-4 w-4" />
          Retry
        </DivButton>
      </div>
    </div>
  );
};
