import { reaction } from "mobx";
import { useRef, useCallback, useEffect, useState } from "react";
import { usePlayground } from "../../playground-store";

const SCROLL_DELAY_MS = 100;
const SCROLL_THRESHOLD = 100; // Pixels from bottom to consider "at bottom"

const useMessageScrolling = (playground: ReturnType<typeof usePlayground>) => {
  const messagesEndRef = useRef<HTMLDivElement>(null);
  const [shouldAutoScroll, setShouldAutoScroll] = useState(true);
  const containerRef = useRef<HTMLDivElement>(null);

  const isNearBottom = useCallback(() => {
    const container = containerRef.current;
    if (!container) return true;
    const { scrollTop, scrollHeight, clientHeight } = container;
    return scrollHeight - (scrollTop + clientHeight) <= SCROLL_THRESHOLD;
  }, []);

  const scrollToBottom = useCallback(() => {
    if (shouldAutoScroll && playground.isChat) {
      messagesEndRef.current?.scrollIntoView({
        behavior: "smooth",
        block: "end",
      });
    }
  }, [shouldAutoScroll, playground.isChat]);

  const handleScroll = useCallback(() => {
    const wasNearBottom = isNearBottom();
    setShouldAutoScroll(wasNearBottom);
  }, [isNearBottom]);

  const handleScrollOnChange = useCallback(
    (newValue: number, prevValue: number) => {
      if (newValue > prevValue && playground.isChat) {
        setTimeout(scrollToBottom, SCROLL_DELAY_MS);
      }
    },
    [scrollToBottom, playground.isChat],
  );

  const handleMessageContentChange = useCallback(
    (newContent: string, oldContent: string) => {
      if (
        newContent.length > oldContent.length &&
        playground.isPendingNewMessage &&
        playground.isChat
      ) {
        setTimeout(scrollToBottom, SCROLL_DELAY_MS);
      }
    },
    [playground, scrollToBottom],
  );

  useEffect(() => {
    const container = containerRef.current;
    if (container) {
      container.addEventListener("scroll", handleScroll);
      return () => container.removeEventListener("scroll", handleScroll);
    }
  }, [handleScroll]);

  useEffect(() => {
    const messageReaction = reaction(
      () => playground.messages.length,
      handleScrollOnChange,
      { fireImmediately: false, delay: SCROLL_DELAY_MS },
    );

    const messageContentReaction = reaction(
      () => playground.messages.map((message) => message.content),
      (newContents, oldContents) => {
        newContents.forEach((content, index) => {
          if (oldContents[index] !== undefined) {
            handleMessageContentChange(content, oldContents[index]);
          }
        });
      },
      { fireImmediately: false, delay: SCROLL_DELAY_MS },
    );

    return () => {
      messageReaction();
      messageContentReaction();
    };
  }, [
    playground.messages,
    playground,
    handleScrollOnChange,
    handleMessageContentChange,
  ]);

  return { messagesEndRef, containerRef };
};

export default useMessageScrolling;
