import ContentArea from "@/components/ContentArea";
import LoadingSpinner from "@/components/LoadingSpinner";
import { Button } from "@/components/ui/button";
import { CopyButton } from "@/components/ui/copy-button";
import { Input } from "@/components/ui/input";
import { Skeleton } from "@/components/ui/skeleton";
import { BasicTooltip } from "@/components/ui/Tooltip";
import { XIcon } from "@heroicons/react/outline";
import { Maximize2 } from "lucide-react";
import { runInAction } from "mobx";
import { observer } from "mobx-react-lite";
import { usePlayground } from "../../playground-store";
import { MESSAGE_DETAILS } from "../constants";
import FullscreenDialog from "./FullscreenDialog";
import useFullscreen from "./hooks/useFullscreen";
import MediaOptions from "./MediaOptions";
import { IMessageStore } from "./message-store";
import RoleSelector from "./RoleSelector";
import ToolCalls from "./ToolCalls";

interface MessageProps {
  message: IMessageStore;
  isCompletion?: boolean;
  isFirst?: boolean;
  readonly?: boolean;
  extraHeader?: React.ReactNode;
  index?: number;
}

export const MessageSkeleton = () => {
  return (
    <div className="group flex h-full w-full flex-col gap-y-1 rounded-lg px-3 py-3">
      <div className="flex items-center justify-between gap-x-2 pb-2">
        <div className="flex w-full items-center gap-x-2">
          <div className="w-[180px]">
            <Skeleton className="h-9 w-full rounded-md" />
          </div>
        </div>
        <div className="flex gap-x-2">
          <Button variant={"ghostGray"} size={"tinyIcon"} disabled>
            <XIcon height={16} width={16} className="text-gray-300" />
          </Button>
        </div>
      </div>
      <div className="relative z-[1] flex h-full w-full flex-col gap-y-1">
        <Skeleton className="min-h-[200px] w-full rounded" />
        <div className="absolute bottom-3 right-2">
          <Button
            variant={"ghost"}
            size={"tinyIcon"}
            disabled
            className="opacity-50"
          >
            <Maximize2 height={16} width={16} className="text-gray-300" />
          </Button>
        </div>
      </div>
    </div>
  );
};

export const MessagePlaceholder = () => {
  return (
    <div className="group my-3 flex w-full flex-col gap-y-1 rounded-lg border border-gray-200 px-3 py-3">
      <div className="flex items-center justify-between gap-x-2 pb-2">
        <div className="flex w-full items-center gap-x-2">
          <div className="h-9 w-[180px] rounded-md border border-gray-200" />
        </div>
        <div className="flex gap-x-2">
          <Button variant={"ghostGray"} size={"tinyIcon"} disabled>
            <XIcon height={16} width={16} className="text-gray-300" />
          </Button>
        </div>
      </div>
      <div className="relative z-[1] flex flex-col gap-y-1">
        <div className="h-[150px] w-full rounded border border-gray-200" />
        <div className="absolute bottom-3 right-2">
          <Button
            variant={"ghost"}
            size={"tinyIcon"}
            disabled
            className="opacity-50"
          >
            <Maximize2 height={16} width={16} className="text-gray-300" />
          </Button>
        </div>
      </div>
    </div>
  );
};

const Message = (props: MessageProps) => {
  const { message, isCompletion, isFirst, readonly, extraHeader, index } =
    props;
  const playground = usePlayground();
  const { isFullscreen, setIsFullscreen, open } = useFullscreen();
  const message_details = MESSAGE_DETAILS[message.role];

  return (
    <div className="group flex h-full w-full flex-col gap-y-1 rounded-lg px-3 py-3 hover:bg-gray-50">
      {message.parsingError && (
        <span className="mb-1 text-sm italic text-red-500/80">
          {message.parsingError}
        </span>
      )}
      {!isCompletion && (
        <div className="flex items-center justify-between gap-x-2 pb-2">
          <div className="flex h-full w-full flex-col">
            {extraHeader}

            <div className="flex w-full items-center gap-x-2">
              <RoleSelector
                key={message.role}
                selected={message.role}
                readonly={readonly}
                onSelect={message.setRole}
              />
              {message.hasName && (!readonly || (readonly && message.name)) && (
                <Input
                  value={message.name}
                  disabled={readonly}
                  onChange={(e) => message.setName(e.target.value)}
                  className={"font-mono"}
                  placeholder={
                    message.role === "tool" ? "Tool ID" : "Function Name"
                  }
                />
              )}
            </div>
          </div>
          {!readonly && (
            <div className="z-10 flex gap-x-2">
              <CopyButton text={message.content} />
              <BasicTooltip content="Delete message">
                <Button
                  onClick={() => {
                    runInAction(() => playground.deleteMessage(message.id));
                  }}
                  variant={"ghost"}
                  size={"tinyIcon"}
                  title="Delete"
                >
                  <XIcon height={16} width={16} />
                </Button>
              </BasicTooltip>
            </div>
          )}
        </div>
      )}
      <div
        className={`relative z-[${
          10 + playground.messages.length - (index || 0) // Offset MediaOptions z-index
        }] flex h-full w-full flex-col gap-y-1`}
      >
        {playground.isPendingNewMessage && isCompletion && (
          <div className="absolute right-2 top-2 z-20">
            <LoadingSpinner size={5} />
          </div>
        )}
        <div className="relative h-full">
          <ContentArea
            readOnly={readonly}
            sourcedSnippets={playground.sourcedSnippets}
            placeholder={message.placeholder || undefined}
            onChange={(e) => message.setMessage(e.target.value)}
            value={message.content}
            className={`z-[1] rounded bg-white text-sm ${
              isFirst && !isCompletion ? "!min-h-[200px]" : ""
            }  ${message.usesMonospaceFont ? "font-mono" : ""} `}
          />
          {!isCompletion && (
            <div className="absolute bottom-3 right-2">
              <BasicTooltip content="Maximize" usePortal={false}>
                <Button
                  onClick={open}
                  variant={"ghost"}
                  size={"tinyIcon"}
                  title="Maximize"
                  className="opacity-50 hover:opacity-100"
                >
                  <Maximize2 height={16} width={16} />
                </Button>
              </BasicTooltip>
            </div>
          )}
        </div>
        {message_details && (
          <span className="text-xs text-gray-500">{message_details}</span>
        )}
      </div>
      {message.hasMedia && !isCompletion && (
        <MediaOptions
          mediaVariable={message.media_variable}
          onDeleteMedia={message.deleteMediaAtIndex}
          setMediaVariable={message.setMediaVariable}
          selectedMedia={message.selected_media}
          setSelectedMedia={message.setSelectedMedia}
          readonly={readonly}
        />
      )}
      {((!readonly && message.canAddToolCalls) ||
        (readonly && message.hasToolCalls)) &&
        !isCompletion && (
          <ToolCalls
            readonly={readonly}
            toolCalls={message.tool_calls}
            onChangeToolCall={(toolCall) => message.updateToolCall(toolCall)}
            onDeleteToolCall={(toolCall) =>
              message.deleteToolCall(toolCall._id!)
            }
            onAddToolCall={() =>
              message.addToolCall({
                id: Math.random().toString(36).substring(7),
                name: "",
                arguments: "",
              })
            }
          />
        )}
      <FullscreenDialog
        isOpen={isFullscreen}
        onOpenChange={setIsFullscreen}
        message={message}
        readonly={readonly}
      />
    </div>
  );
};

export default observer(Message);
