import { Variable } from "../types";

const useVariableManagement = (
  onUpdateInputVariable: (
    id: string,
    key: string,
    value: string | object,
  ) => void,
  onRemoveInputVariable: (id: string) => void,
  onClearInputVariables: () => void,
  inputVariables: Variable[],
) => {
  const addInputVariable = (key?: string, value?: string) => {
    const newVariable: Variable = {
      id: Math.random().toString(36).substring(7),
      key: key || `variable${inputVariables.length + 1}`,
      value: value ?? "",
    };
    onUpdateInputVariable(newVariable.id, newVariable.key, newVariable.value);
  };

  const removeInputVariable = (index: number) =>
    onRemoveInputVariable(inputVariables[index].id);

  const updateInputVariable = (updatedVariable: Variable) =>
    onUpdateInputVariable(
      updatedVariable.id,
      updatedVariable.key,
      updatedVariable.value,
    );
  return {
    addInputVariable,
    removeInputVariable,
    clearAll: onClearInputVariables,
    updateInputVariable,
  };
};

export default useVariableManagement;
