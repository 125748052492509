import { usePlayground } from "@/components/Playground2/playground-store";
import TemplateSelectDropdown from "@/components/TemplateSelectDropdown";
import { Button, ButtonSize } from "@/components/ui/button";
import CommitMessageDialog from "@/components/ui/CommitMessageDialog";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { RadioGroup } from "@/components/ui/radio-group";
import { usePreviousReportId } from "@/routes/prompts/edit/page";
import { SaveIcon } from "lucide-react";
import { observer } from "mobx-react-lite";
import { KeyboardEvent, useState } from "react";
import useSaveTemplate from "./hooks/useSaveTemplate";
import useTemplateForm from "./hooks/useTemplateForm";
import useTemplateSubmit from "./hooks/useTemplateSubmit";
import useTemplateValidation from "./hooks/useTemplateValidation";

const RADIO_ITEMS = [
  {
    label: "Update existing template",
    value: "update",
    id: "update",
  },
  {
    label: "Create new template",
    value: "create",
    id: "create",
  },
];

const SaveTemplateDialog = ({
  disabled = false,
  size = "sm",
}: {
  disabled?: boolean;
  size?: ButtonSize;
}) => {
  const [open, setOpen] = useState(false);
  const [showCommitDialog, setShowCommitDialog] = useState(false);
  const playground = usePlayground();
  const { handleCreate, handleUpdate, isCreating, isUpdating } =
    useSaveTemplate();

  const {
    saveType,
    setSaveType,
    selectedTemplate,
    setSelectedTemplate,
    selectedTemplateId,
    setSelectedTemplateId,
    newTemplateName,
    setNewTemplateName,
  } = useTemplateForm();

  const isValid = useTemplateValidation(
    saveType,
    newTemplateName,
    selectedTemplateId,
    isCreating,
    isUpdating,
  );

  const { previousReportId } = usePreviousReportId({
    promptRegistryId: selectedTemplateId,
  });

  const [submittedVersion, submit] = useTemplateSubmit(
    saveType,
    newTemplateName,
    selectedTemplateId,
    handleCreate,
    handleUpdate,
    isValid,
    setOpen,
  );

  const handleNext = async () => {
    if (saveType === "update") {
      setOpen(false);
      setShowCommitDialog(true);
    } else {
      submit();
    }
  };

  const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter" && isValid()) {
      e.preventDefault();
      handleNext();
    }
  };

  // If playground is in editor mode, skip directly to commit dialog for updates
  const handleButtonClick = () => {
    if (playground.isEditor && saveType === "update") {
      setShowCommitDialog(true);
    } else {
      setOpen(true);
    }
  };

  return (
    <>
      <Dialog open={open} onOpenChange={setOpen}>
        <DialogTrigger asChild>
          <Button
            variant="outline"
            className="whitespace-nowrap"
            size={size}
            disabled={disabled}
            onClick={(e) => {
              e.preventDefault();
              handleButtonClick();
            }}
          >
            <SaveIcon className="mr-2 h-4 w-4" /> Save Template
          </Button>
        </DialogTrigger>
        <DialogContent className="sm:max-w-[425px]">
          <DialogHeader>
            <DialogTitle>Save Prompt Template</DialogTitle>
            <DialogDescription>
              Create a new template or update an existing one
            </DialogDescription>
          </DialogHeader>

          <div className="grid gap-4 py-4">
            <RadioGroup
              value={saveType}
              onValueChange={(value: "update" | "create") => setSaveType(value)}
              items={RADIO_ITEMS}
            />

            <hr />

            {saveType === "update" && (
              <div className="grid gap-2">
                <Label>Select template to update:</Label>
                <TemplateSelectDropdown
                  selectedTemplateName={selectedTemplate}
                  handleTemplateSelection={({ id, name }) => {
                    setSelectedTemplate(name);
                    setSelectedTemplateId(id);
                  }}
                />
              </div>
            )}

            {saveType === "create" && (
              <div className="grid gap-2">
                <Label>New template name:</Label>
                <Input
                  placeholder="Enter name..."
                  value={newTemplateName}
                  onChange={(e) => setNewTemplateName(e.target.value)}
                  onKeyDown={handleKeyDown}
                />
              </div>
            )}
          </div>

          <DialogFooter>
            <Button variant="outline" onClick={() => setOpen(false)}>
              Cancel
            </Button>
            <Button onClick={handleNext} disabled={!isValid() || disabled}>
              {isCreating || isUpdating ? "Saving..." : "Next"}
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
      <CommitMessageDialog
        isLoading={isCreating || isUpdating}
        onConfirm={submit}
        onGoBack={() => {
          setShowCommitDialog(false);
          setOpen(true);
        }}
        submittedVersion={submittedVersion || undefined}
        isOpen={showCommitDialog}
        data={playground.blueprint}
        evaluationId={previousReportId ?? undefined}
        prompt={{
          name: selectedTemplate,
          id: selectedTemplateId,
        }}
        onClose={() => setShowCommitDialog(false)}
      />
    </>
  );
};

export default observer(SaveTemplateDialog);
