import { ReactNode, Suspense, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";

import { ContentNavbar } from "@/components/ContentNavbar";
import LoadingSpinner from "@/components/LoadingSpinner";
import NewFeatureModal from "@/components/NewFeatureModal";
import Header from "@/components/SidebarSearchLayout/components/Header";
import Sidebar from "@/components/SidebarSearchLayout/components/Sidebar";
import { useSwitch } from "../ui/switch";
import Content from "./Content";
import { useRequestLogs } from "@/queries";

export default function SidebarSearchLayout({
  children,
}: {
  children: ReactNode;
}) {
  const [sidebarCollapsed, setSidebarCollapsed] = useState(true);
  const onRequestPage = window.location.pathname.includes("/request");
  const onGroupPage = window.location.pathname.includes("/group");
  const onHistoryPage =
    (onRequestPage || onGroupPage) &&
    !window.location.pathname.includes("playground");

  // Get ID from URL requestId, if exists
  const { groupId } = useParams();

  const requestLogs = useRequestLogs();

  const requests = useMemo(
    () =>
      requestLogs.data?.pages
        ?.map(
          (page) =>
            page.items?.map((item: any) => {
              if (item?.metadata?.[0] === null) {
                item.metadata = null;
              }
              return item;
            }),
        )
        .flat() ?? [],
    [requestLogs.data],
  );

  useEffect(() => {
    if (
      requests.length > 0 &&
      sidebarCollapsed &&
      !sessionStorage.getItem("sidebarCollapsed")
    ) {
      sessionStorage.setItem("sidebarCollapsed", "true");
      setSidebarCollapsed(false);
    }
  }, [requests]); // eslint-disable-line react-hooks/exhaustive-deps

  const [sidebarOpen, setSidebarOpen] = useState(false);
  const { traceId } = useParams();
  const { isSwitchOn: requestsOn, SwitchComponent: Switch } = useSwitch(
    ["Requests", "Traces"],
    !!traceId,
  );

  return (
    <div className={`h-full bg-gray-100`}>
      <NewFeatureModal />
      <div className="h-full">
        <Sidebar
          sidebarCollapsed={sidebarCollapsed}
          sidebarOpen={sidebarOpen}
          requests={requests}
          requestLogs={requestLogs}
          setSidebarOpen={setSidebarOpen}
          requestsOn={requestsOn}
          Switch={Switch}
        />

        {/* Header */}
        <Header
          sidebarCollapsed={sidebarCollapsed}
          setSidebarCollapsed={setSidebarCollapsed}
          requestsOn={requestsOn}
        />
        <div
          className={`h-full transition-all duration-100 ease-in-out ${
            sidebarCollapsed ? "md:pl-20" : "md:pl-80"
          } md:pt-16`}
        >
          <div className="flex h-full w-full flex-grow flex-col rounded-tl-lg border-l border-t border-gray-200 bg-white md:px-8">
            <ContentNavbar
              setSidebarOpen={setSidebarOpen}
              onHistoryPage={onHistoryPage}
              isGroup={!!groupId}
            />

            <main className="w-full flex-1 overflow-y-hidden">
              <div className="h-full overflow-y-auto md:mx-4">
                <Content>
                  <Suspense
                    fallback={
                      <div className="flex h-full items-center justify-center">
                        <LoadingSpinner />
                      </div>
                    }
                  >
                    {children}
                  </Suspense>
                </Content>
              </div>
            </main>
          </div>
        </div>
      </div>
    </div>
  );
}
