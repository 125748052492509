import { DivButton } from "@/components/ui/button";
import { Input } from "@/components/ui/input";

const VariableInput = ({
  value,
  onChange,
  onSave,
  onKeyDown,
}: {
  value: string;
  onChange: (value: string) => void;
  onSave: () => void;
  onKeyDown: (e: React.KeyboardEvent) => void;
}) => {
  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === "Enter") {
      onSave();
    }
    onKeyDown(e);
  };

  return (
    <div className="flex gap-2">
      <Input
        type="text"
        value={value}
        onChange={(e) => onChange(e.target.value)}
        placeholder="Variable name"
        onKeyDown={handleKeyDown}
      />
      <DivButton onClick={onSave} className="shrink-0 text-xs">
        Save
      </DivButton>
    </div>
  );
};

export default VariableInput;
