import { JSONSchema } from "@/types";
import { CopyButton } from "../ui/copy-button";
import { SchemaEditorProvider } from "../SchemaEditor";
import SchemaEditorStore from "../SchemaEditor/schema-editor-store";
import { useLocalObservable } from "mobx-react-lite";
import SchemaParameter from "../SchemaEditor/SchemaParameter";

export const JSONSchemaCell = ({
  jsonSchema,
  componentIsOpen,
}: {
  jsonSchema: JSONSchema;
  componentIsOpen: boolean;
}) => {
  const schemaEditorStore = useLocalObservable(
    () => new SchemaEditorStore(jsonSchema, false, true),
  );

  return (
    <SchemaEditorProvider value={schemaEditorStore}>
      <div className="relative mt-2">
        <div className="text-gray-900">
          <div className="flex items-start justify-between">
            {componentIsOpen ? (
              <div className="flex w-full flex-col">
                {schemaEditorStore.schemaParameters.length > 0 &&
                  schemaEditorStore.schemaParameters.map((parameter, index) => (
                    <SchemaParameter key={index} parameter={parameter} />
                  ))}
                <div className="absolute right-[20px] top-[-45px]">
                  <CopyButton text={JSON.stringify(jsonSchema, null, 2)} />
                </div>
              </div>
            ) : (
              <div className="flex w-full flex-col items-start">
                <span className="text-sm text-gray-500">
                  Name: {schemaEditorStore.name}
                </span>
                <span className="text-sm text-gray-500">
                  Description: {schemaEditorStore.description}
                </span>
              </div>
            )}
          </div>
        </div>
      </div>
    </SchemaEditorProvider>
  );
};
