import {
  Select as SelectComponent,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { SchemaDefinition } from "@/types";

import EditJsonSchemaModal from "../EditJsonSchemaModal";

import { SelectProps } from "./types";
import { isObjectValue, isJsonSchema, getDisplayValue } from "./utils";

export const Select = ({
  options,
  onChange,
  placeholder,
  defaultValue,
  selectedDropdownValue,
  isDisabled,
  readOnly,
}: SelectProps) => {
  const handleChange = (value: string) => {
    const selectedOption = options.find((option) => {
      const optionValue = option.value;
      return isObjectValue(optionValue)
        ? optionValue.type === value
        : String(optionValue) === value;
    });

    if (selectedOption) {
      onChange(selectedOption.value);
    } else {
      onChange(value);
    }
  };

  return (
    <div className="flex flex-col gap-2">
      <SelectComponent
        onValueChange={handleChange}
        defaultValue={defaultValue}
        value={getDisplayValue(selectedDropdownValue) as string}
        disabled={isDisabled || readOnly}
      >
        <SelectTrigger>
          <SelectValue placeholder={placeholder} />
        </SelectTrigger>
        <SelectContent className="max-h-[200px] overflow-y-auto">
          {options.map((option) => (
            <SelectItem
              key={`${option.display_name}-${getDisplayValue(option.value)}`}
              value={getDisplayValue(option.value) as string}
              defaultValue={defaultValue}
            >
              {option.display_name}
            </SelectItem>
          ))}
        </SelectContent>
      </SelectComponent>

      {isJsonSchema(selectedDropdownValue) && (
        <EditJsonSchemaModal
          initialValues={{
            json: selectedDropdownValue.json_schema,
          }}
          readonly={readOnly}
          handleSubmit={(schema: SchemaDefinition) => {
            onChange({
              type: "json_schema",
              json_schema: schema,
            });
          }}
        />
      )}
    </div>
  );
};
