import { ReportColumn } from "@/types/evaluate";
import { useState, useCallback, useMemo } from "react";
import { useBandaid } from "../../../utils/BandaidContext";
import { LanguageType } from "@/components/CodeEditor/types";

export const CodeExamples: Record<LanguageType, string> = {
  python: `message = "These are my column names: "
columns = [column_name for column_name in data.keys()]
return message + str(columns)`,
  javascript: `const message = "These are my column names: ";
const columns = Object.keys(data);
return message + JSON.stringify(columns);`,
};

const useColumnLanguage = (columnData: Partial<ReportColumn>) => {
  const [language, setLanguage] = useState<LanguageType>(
    (columnData.configuration?.language?.toLowerCase() as LanguageType) ||
      "python",
  );

  const isBandaid = useBandaid();

  const parse = useCallback(
    (field: string) =>
      isBandaid ? field?.replaceAll("column", "node") : field,
    [isBandaid],
  );

  const [code, setCode] = useState<string>(
    columnData.configuration?.code || parse(CodeExamples[language]),
  );

  const isCustomCode = useMemo(
    () => !parse(CodeExamples[language])?.includes(code),
    [language, parse, code],
  );

  const handleLanguageChange = useCallback(
    (newLanguage: LanguageType) => {
      setLanguage(newLanguage);
      if (!isCustomCode) setCode(parse(CodeExamples[newLanguage]));
    },
    [isCustomCode, parse],
  );

  const handleExampleChange = useCallback(() => {
    setCode(parse(CodeExamples[language]));
  }, [language, parse]);

  return {
    language,
    code,
    setCode,
    handleLanguageChange,
    handleExampleChange,
    isCustomCode,
  };
};

export default useColumnLanguage;
