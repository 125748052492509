import { AnimatePresence, motion } from "framer-motion";
import InputVariable from "./InputVariable";
import { Variable } from "./types";

const VariableList = ({
  variables,
  updateInputVariable,
  removeInputVariable,
  lastItemRef,
}: {
  variables: Variable[];
  updateInputVariable: (v: Variable, i: number) => void;
  removeInputVariable: (i: number) => void;
  lastItemRef: React.RefObject<HTMLDivElement>;
}) => (
  <AnimatePresence initial={false}>
    <div className="flex w-full flex-col gap-y-2">
      {variables.map((variable, index) => (
        <motion.div
          key={variable.id}
          ref={index === variables.length - 1 ? lastItemRef : null}
          initial={{ opacity: 0, height: 0, scale: 0.98 }}
          animate={{ opacity: 1, height: "auto", scale: 1 }}
          exit={{ opacity: 0, height: 0, scale: 0.98 }}
          transition={{
            duration: 0.15,
            ease: "easeOut",
            scale: {
              duration: 0.1,
            },
          }}
        >
          <InputVariable
            variable={variable}
            onChange={(v) => updateInputVariable(v, index)}
            onDelete={() => removeInputVariable(index)}
            tabIndex={index * 3 + 1}
          />
        </motion.div>
      ))}
    </div>
  </AnimatePresence>
);

export default VariableList;
