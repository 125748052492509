import { PromptBlueprintProvider } from "@/components/Playground/context";
import { Metadata } from "@/types";
import { observer } from "mobx-react-lite";
import { usePlayground } from "../../playground-store";

const LegacyControlProvider = ({ children }: { children: React.ReactNode }) => {
  const playground = usePlayground();

  return (
    <PromptBlueprintProvider
      key={playground.mode}
      promptBlueprint={playground.blueprint}
      onChange={(e: any) => {
        const inference_client_name = e.inference_client_name;
        const provider_base_url_name = e.provider_base_url_name;
        const metadata: Metadata = e.metadata;
        const functions = e.prompt_template.functions;
        const tools = e.prompt_template?.tools || [];

        playground.update({
          model: metadata.model,
          inference_client_name,
          provider_base_url_name,
          functions,
          tools,
          tool_choice:
            e.prompt_template.tool_choice ?? e.prompt_template.function_call,
        });
      }}
      inputVariableRows={[]}
    >
      {children}
    </PromptBlueprintProvider>
  );
};

export default observer(LegacyControlProvider);
