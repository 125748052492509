import { Breadcrumbs } from "@/components/Breadcrumbs";
import DatasetWebSocketListener from "@/components/DatasetEditor/DatasetWebSocketListener";
import OverwriteDraftDatasetModal from "@/components/OverwriteDraftDatasetModal";
import UserChannelProvider from "@/components/UserChannelProvider";
import { useAuth } from "@/context/auth-context";
import { useUser } from "@/context/user-context";
import { useDatasets } from "@/queries";
import { useDatasetRows } from "@/queries/datasets";
import { DatasetGroupRead } from "@/types/dataset-groups";
import { DatasetRead } from "@/types/datasets";
import { createContext, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import DatasetContent from "./DatasetContent";
import Header from "./Header";
import { useActions } from "./hooks/useActions";
import { useData } from "./hooks/useData";
import { usePagination } from "./hooks/usePagination";
import { useSorting } from "./hooks/useSorting";

export const DatasetEditorContext = createContext({
  isDraft: false,
  isDeletingDataset: false,
  isDeletingDatasetGroup: false,
  isSavingDataset: false,
  isSavingCell: false,
  datasetGroup: {
    created_at: "",
    id: 0,
    workspace_id: 0,
    name: "",
  } as DatasetGroupRead | undefined,
  isSettingDraftDatasetStateFromDataset: false,
  setSavingCell: (bool: boolean) => {},
  handleEditClick: () => {},
  handleDeleteClick: () => {},
  handleSaveAsNewVersionClick: (onSuccess?: () => void) => {},
  handleDatasetGroupNameEdit: ({
    onSuccess,
    onError,
    newTitle,
  }: {
    onSuccess?: () => void;
    onError?: () => void;
    newTitle: string;
  }) => {},
  handleDeleteDatasetGroup: () => {},
  rows: [],
  datasets: [] as DatasetRead[],
  isLoading: false,
  activeDataset: {} as DatasetRead | undefined,
  uploadProgress: 0,
  setUploadProgress: (progress: number) => {},
  isUploadLoading: false,
  setIsUploadLoading: (loading: boolean) => {},
});

const DatasetEditor = () => {
  const [isOverwriteDraftDatasetModalOpen, setOverwriteDraftDatasetModalOpen] =
    useState(false);
  const [isUploadLoading, setIsUploadLoading] = useState<boolean>(false);
  const [uploadProgress, setUploadProgress] = useState<number>(0);
  const [page, setPage, pageSize, setPageSize] = usePagination();

  const [sortBy, sortOrder] = useSorting();

  const { datasetId, datasetGroupId, workspaceId } = useParams();

  const userContext = useUser(),
    authContext = useAuth();
  const userToken = authContext!.userToken;

  const datasetsQuery = useDatasets(userToken!, datasetGroupId!),
    datasetRowsQuery = useDatasetRows(
      userToken!,
      parseInt(datasetId!, 10),
      page,
      pageSize,
      sortBy,
      sortOrder,
    );

  const {
    datasetGroup,
    isLoading: datasetGroupLoading,
    datasets,
    isDraft,
    nonDraftVersionsExist,
    rows,
    activeDataset,
  } = useData(
    userToken!,
    parseInt(datasetId!, 10),
    parseInt(datasetGroupId!, 10),
    datasetsQuery,
    datasetRowsQuery,
  );

  const {
    isDeletingDataset,
    isDeletingDatasetGroup,
    isSavingDataset,
    isSettingDraftDatasetStateFromDataset,
    handleEditClick,
    handleDeleteClick,
    handleSaveAsNewVersionClick,
    handleDatasetGroupNameEdit,
    handleDeleteDatasetGroup,
  } = useActions(
    workspaceId,
    datasets,
    datasetId,
    datasetGroupId,
    userToken,
    nonDraftVersionsExist,
    setOverwriteDraftDatasetModalOpen,
  );

  const isLoading = useMemo(
    () =>
      datasetsQuery.isFetching ||
      datasetsQuery.isLoading ||
      datasetRowsQuery.isLoading ||
      datasetRowsQuery.isFetching,
    [
      datasetsQuery.isFetching,
      datasetsQuery.isLoading,
      datasetRowsQuery.isLoading,
      datasetRowsQuery.isFetching,
    ],
  );

  const [isSavingCell, setSavingCell] = useState(false);

  return (
    <DatasetEditorContext.Provider
      value={{
        isDraft,
        isSavingCell,
        setSavingCell,
        isDeletingDataset,
        isDeletingDatasetGroup,
        isSavingDataset,
        datasetGroup,
        isSettingDraftDatasetStateFromDataset,
        handleEditClick,
        handleDeleteClick,
        handleSaveAsNewVersionClick,
        handleDatasetGroupNameEdit,
        handleDeleteDatasetGroup,
        rows,
        datasets,
        isLoading,
        activeDataset,
        isUploadLoading,
        setIsUploadLoading,
        uploadProgress,
        setUploadProgress,
      }}
    >
      <div className="min-h-max bg-white p-1">
        <div className="pb-3">
          <Breadcrumbs
            items={["Datasets", datasetGroup?.name || ""]}
            navigateUrl={`/workspace/${userContext.activeWorkspaceId}/datasets`}
          />
        </div>
        <Header isLoading={datasetGroupLoading} />

        <div className="mb-12 flex">
          <DatasetContent
            datasetRowsQuery={datasetRowsQuery}
            page={page}
            pageSize={pageSize}
            setPage={setPage}
            setPageSize={setPageSize}
          />
        </div>
      </div>
      {isOverwriteDraftDatasetModalOpen && (
        <OverwriteDraftDatasetModal
          datasets={datasets}
          setOpen={setOverwriteDraftDatasetModalOpen}
        />
      )}
      <UserChannelProvider>
        <DatasetWebSocketListener />
      </UserChannelProvider>
    </DatasetEditorContext.Provider>
  );
};

export default DatasetEditor;
