import { BasicTooltip } from "@/components/ui/Tooltip";
import { Media } from "@/types";
import { XIcon } from "@heroicons/react/outline";
import { FileTextIcon, ImageIcon, MusicIcon, VideoIcon } from "lucide-react";
import { MouseEvent } from "react";

interface MediaPreviewProps {
  media: Media;
  onShowFullscreen: () => void;
  onMediaSelect?: (media?: Media) => void;
  onDelete: () => void;
  readonly?: boolean;
}

type MediaIconType = {
  [key: string]: React.ElementType;
};

const mediaIcons: MediaIconType = {
  image: ImageIcon,
  video: VideoIcon,
  audio: MusicIcon,
  pdf: FileTextIcon,
  default: FileTextIcon,
};

const MediaPreview: React.FC<MediaPreviewProps> = ({
  media,
  onShowFullscreen,
  onDelete,
  readonly,
}) => {
  const handleDelete = (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    onDelete();
  };

  const mediaType = media.type.split("/")[0];
  const Icon = mediaIcons[mediaType] || mediaIcons.default;
  const isPdf = media.type.includes("pdf");
  const isImage = mediaType === "image";
  const isVideo = mediaType === "video" || media.type.includes("mp4");
  const isAudio = mediaType === "audio";

  // Tailwind class sets
  const containerClasses = "relative group h-full";
  const previewWrapperClasses =
    "h-full w-full overflow-hidden rounded-lg border-2 border-gray-200 transition-all duration-200 hover:border-blue-400 hover:shadow-md";
  const iconPreviewClasses =
    "flex h-full w-full items-center justify-center bg-gray-50 p-2 aspect-square";

  return (
    <div className={containerClasses} onClick={onShowFullscreen}>
      <BasicTooltip content={`Open ${media.title}`} usePortal={false}>
        <div className={previewWrapperClasses}>
          {isImage && (
            <div className="relative aspect-square">
              <img
                src={media.url}
                alt={media.title}
                className="h-full w-full object-cover"
              />
              <div className="absolute inset-0 rounded-lg bg-black opacity-0 transition-opacity duration-200 hover:!opacity-0 group-hover:opacity-10" />
            </div>
          )}

          {isVideo && (
            <div className="relative aspect-square">
              <video src={media.url} className="h-full w-full object-cover" />
              <div className="absolute inset-0 flex items-center justify-center">
                <div className="flex h-10 w-10 items-center justify-center rounded-full bg-white/80 shadow-md">
                  <div className="border-y-6 ml-1 h-0 w-0 border-l-8 border-y-transparent border-l-gray-800" />
                </div>
              </div>
              <div className="absolute inset-0 rounded-lg bg-black opacity-0 transition-opacity duration-200 hover:!opacity-0 group-hover:opacity-10" />
            </div>
          )}

          {(isAudio || isPdf || (!isImage && !isVideo && !isAudio)) && (
            <div className={iconPreviewClasses}>
              <div className="flex flex-col items-center space-y-1">
                <Icon className="h-6 w-6 text-gray-500" />
                <div className="max-w-full truncate px-1 text-xs font-medium text-gray-500">
                  {media.title.length > 12
                    ? `${media.title.substring(0, 10)}...`
                    : media.title}
                </div>
              </div>
            </div>
          )}
        </div>
      </BasicTooltip>

      {!readonly && (
        <BasicTooltip content="Remove" usePortal={false}>
          <button
            onClick={handleDelete}
            className="absolute -right-1.5 -top-1.5 z-10 flex h-6 w-6 items-center justify-center rounded-full bg-gray-800 text-white shadow-md transition-colors duration-150 hover:bg-red-600"
            aria-label="Remove media"
          >
            <XIcon className="h-3.5 w-3.5" />
          </button>
        </BasicTooltip>
      )}
    </div>
  );
};

export default MediaPreview;
