import { useCallback } from "react";

const useTemplateValidation = (
  saveType: "update" | "create",
  newTemplateName: string,
  selectedTemplateId: number,
  isCreating: boolean,
  isUpdating: boolean,
) => {
  return useCallback(() => {
    if (isCreating || isUpdating) return false;
    if (saveType === "create" && !newTemplateName) return false;
    if (saveType === "update" && selectedTemplateId < 0) return false;
    return true;
  }, [isCreating, isUpdating, saveType, newTemplateName, selectedTemplateId]);
};

export default useTemplateValidation;
