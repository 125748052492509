import { useAuth } from "@/context/auth-context";
import { usePromptRegistry } from "@/queries";

const usePromptNameResolver = (promptId: number) => {
  const userToken = useAuth()?.userToken;

  const promptRegistryQuery = usePromptRegistry(userToken!, Number(promptId));

  const promptRegistry = promptRegistryQuery.data;

  return promptRegistry?.prompt_name;
};

export default usePromptNameResolver;
