import { useAuth } from "@/context/auth-context";
import { useUser } from "@/context/user-context";
import { useRequestsFromFilterPayload } from "@/queries";
import { observer } from "mobx-react-lite";
import { FilterPayload } from "../types";

interface ResultPreviewProps {
  filterPayload: () => FilterPayload;
}

const ResultPreview = ({ filterPayload }: ResultPreviewProps) => {
  const userToken = useAuth()?.userToken;
  const workspaceId = useUser()?.activeWorkspaceId;
  const { data, isLoading } = useRequestsFromFilterPayload(
    userToken!,
    workspaceId!,
    filterPayload(),
  );

  return (
    <div className="flex h-full w-full flex-col gap-4 overflow-hidden">
      <div className="flex items-center justify-between border-b pb-2">
        <h2 className="text-lg font-semibold text-gray-800">Preview</h2>
        {data?.items && (
          <span className="text-sm text-gray-500">
            {data.total} result{data.total !== 1 ? "s" : ""}
          </span>
        )}
      </div>

      <div className="overflow-y-auto">
        {isLoading ? (
          <div className="flex w-full justify-center p-8">
            <div className="animate-pulse text-gray-500">
              Loading results...
            </div>
          </div>
        ) : !data?.items?.length ? (
          <div className="flex w-full justify-center p-8">
            <div className="text-gray-500">
              No results match your filter criteria
            </div>
          </div>
        ) : (
          <div className="flex flex-col gap-4 pb-2">
            {data.items.map((item) => (
              <div
                key={item.id}
                className="rounded-md border p-3 shadow-sm transition-all hover:shadow-md md:p-4"
              >
                <div className="mb-2 flex flex-col sm:flex-row sm:items-start sm:justify-between">
                  <h3 className="max-w-[70%] truncate font-medium">
                    {item.function_name}
                  </h3>
                  <div className="mt-1 flex flex-wrap gap-1 sm:mt-0">
                    {item.tags_array.slice(0, 3).map((tag: string) => (
                      <span
                        key={tag}
                        className="whitespace-nowrap rounded-full bg-gray-100 px-2 py-0.5 text-xs"
                      >
                        {tag}
                      </span>
                    ))}
                    {item.tags_array.length > 3 && (
                      <span className="rounded-full bg-gray-100 px-2 py-0.5 text-xs">
                        +{item.tags_array.length - 3}
                      </span>
                    )}
                  </div>
                </div>
                <div className="mb-2 grid grid-cols-1 gap-2 sm:grid-cols-2 md:gap-4">
                  <div>
                    <p className="text-xs text-gray-500 md:text-sm">Engine</p>
                    <p className="truncate text-sm md:text-base">
                      {item.engine}
                    </p>
                  </div>
                  <div>
                    <p className="text-xs text-gray-500 md:text-sm">Time</p>
                    <p className="truncate text-sm md:text-base">
                      {new Date(item.request_start_time).toLocaleString()}
                    </p>
                  </div>
                </div>
                <div className="mt-2">
                  <p className="text-xs text-gray-500 md:text-sm">Prompt</p>
                  <div className="mt-1 max-h-28 overflow-y-auto rounded bg-gray-50 p-2 text-xs md:text-sm">
                    <pre className="whitespace-pre-wrap break-words">
                      {item.is_chat
                        ? JSON.stringify(item.request_text, null, 2)
                        : item.request_text}
                    </pre>
                  </div>
                </div>
                <div className="mt-2">
                  <p className="text-xs text-gray-500 md:text-sm">Response</p>
                  <div className="mt-1 max-h-40 overflow-y-auto rounded bg-gray-50 p-2 text-xs md:text-sm">
                    <pre className="whitespace-pre-wrap break-words">
                      {typeof item.response_text === "string"
                        ? item.response_text
                        : JSON.stringify(item.response_text, null, 2)}
                    </pre>
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default observer(ResultPreview);
