import { useAuth } from "@/context/auth-context";
import { useUser } from "@/context/user-context";
import {
  usePromptRegistryObjects,
  usePromptVersion,
  usePromptVersions,
} from "@/queries";
import { PromptTemplateConfiguration } from "@/types/evaluate";
import { useMemo, useState } from "react";
import { ModalStep } from "../ModalRouter";
import { PromptVersion } from "@/types";
import { ModelParameters } from "@/components/ModelParameters";
import { PromptData } from "@/components/ModelParameters/types";
import LoadingSpinner from "@/components/LoadingSpinner";
import { ParametersPreview } from "@/components/ModelParameters/AdvancedParameters/ParametersPreview";

const ChooseEngineStep = ({
  navigateAway,
  configuration,
  setConfiguration,
  editable,
}: {
  navigateAway: () => void;
  configuration: Partial<PromptTemplateConfiguration>;
  setConfiguration: (
    data:
      | Partial<PromptTemplateConfiguration>
      | ((
          prevState: Partial<PromptTemplateConfiguration>,
        ) => Partial<PromptTemplateConfiguration>),
  ) => void;
  editable: boolean;
}) => {
  const [data, setData] = useState<PromptData | null>(null);

  const saveAction = () => {
    navigateAway();

    setConfiguration((previousConfig: Partial<PromptTemplateConfiguration>) => {
      const newConfig = { ...previousConfig };

      if (isCustom) {
        if (data?.metadata?.model?.name && data?.metadata?.model?.provider) {
          newConfig.engine = {
            provider_base_url_name: data?.provider_base_url_name || null,
            provider: data?.metadata?.model?.provider,
            model: data?.metadata?.model?.name,
            parameters: data?.metadata?.model?.parameters,
          };
        } else if ("engine" in newConfig) {
          delete newConfig.engine;
        }
      } else if ("engine" in newConfig) {
        delete newConfig.engine;
      }

      return newConfig;
    });
  };

  const [isCustom, setIsCustom] = useState(configuration.engine ? true : false);

  const userContext = useUser();
  const auth = useAuth();
  const userToken = auth?.userToken!;
  const workspaceId = userContext.activeWorkspaceId!;

  const promptRegistryObjects = usePromptRegistryObjects(userToken, {
    workspaceId,
    perPage: Number.MAX_SAFE_INTEGER,
  });

  const availablePromptTemplates =
    promptRegistryObjects.data?.pages.flatMap((page) => page.items) || [];

  const selectedPromptRegistry = availablePromptTemplates.find(
    (template) => template.prompt_name === configuration.template?.name,
  );

  const promptVersionQuery = usePromptVersions(userToken, {
    workspaceId,
    promptRegistryId: selectedPromptRegistry?.id,
    perPage: Number.MAX_SAFE_INTEGER,
  });
  const promptVersions =
    promptVersionQuery.data?.pages.flatMap((page) => page.items) || [];

  const version =
    promptVersions.find(
      (version) => version.number === configuration.template?.version_number,
    ) || promptVersions.at(0);

  const promptTemplateVersionQuery = usePromptVersion(
    userToken,
    // @ts-ignore
    selectedPromptRegistry?.id,
    version?.number,
  );

  const selectedPromptTemplateVersion = promptTemplateVersionQuery.data;

  const isChat = selectedPromptTemplateVersion?.prompt_template.type === "chat";

  const formattedVersion = useMemo(() => {
    return {
      ...selectedPromptTemplateVersion,
      provider_base_url_name: configuration?.engine?.provider_base_url_name,
      metadata: {
        ...selectedPromptTemplateVersion?.metadata,
        model: {
          name: configuration?.engine?.model || null,
          provider: configuration?.engine?.provider || null,
          parameters: configuration?.engine?.parameters || null,
        },
      },
    };
  }, [selectedPromptTemplateVersion, configuration]);

  return (
    <ModalStep
      navigateNext={editable ? saveAction : undefined}
      nextButtonText={editable ? "Save" : undefined}
      navigatePrevious={editable ? undefined : saveAction}
    >
      <div className="pb-4">
        <div className="flex items-center">
          <input
            type="checkbox"
            className="mr-2 cursor-pointer"
            id="customize-engine"
            checked={isCustom}
            onChange={() => setIsCustom(!isCustom)}
            disabled={!editable}
            key={isCustom.toString()}
          />
          <label
            htmlFor="customize-engine"
            className="cursor-pointer text-base font-medium"
          >
            Customize engine & parameters?
          </label>
        </div>
        <div className="ml-6 text-sm font-light text-gray-500">
          By default, the engine and model parameters will be pulled from the
          Prompt Registry.
        </div>
      </div>
      {!isCustom && (
        <div className={`rounded-md border border-gray-200 bg-gray-50 p-3`}>
          The parameters will be pulled at runtime from your Prompt Template :)
        </div>
      )}

      <div className="flex w-full items-center justify-center">
        {selectedPromptTemplateVersion ? (
          isCustom &&
          (editable ? (
            <ModelParameters
              isPromptChat={isChat}
              promptData={formattedVersion as PromptVersion}
              onUpdate={(newData) => setData(newData || null)}
              displayAsPopover={false}
              className="w-full"
              shouldShowClearButton={false}
              showFunctions={false}
            />
          ) : (
            <ParametersPreview
              displayAsPopover={false}
              promptData={formattedVersion as PromptVersion}
            />
          ))
        ) : (
          <LoadingSpinner size={4} />
        )}
      </div>
    </ModalStep>
  );
};

export default ChooseEngineStep;
