import { Edge, Node } from "@xyflow/react";
import { Variable } from "../InputVariableConfigurator/types";

enum WorkflowNodeType {
  START = "start",
  END = "end",
  TERMINAL = "terminal",
  FUNCTION = "function",
  CONDITIONAL = "conditional",
  INPUT = "input",
  DEFAULT = "default",
  PENDING = "PENDING",
  QUEUED = "QUEUED",
  RUNNING = "RUNNING",
  COMPLETED = "COMPLETED",
}

export enum WorkflowNodeStatus {
  QUEUED = "QUEUED",
  RUNNING = "RUNNING",
  COMPLETED = "SUCCESS",
  FAILED = "FAILED",
  SKIPPED = "SKIPPED",
}

export enum EdgeStatusType {
  PASSED = "PASSED",
  FAILED = "FAILED",
}

type EdgeStatus = {
  id: number;
  edge_id: string;
  workflow_version_execution_id: number;
  status: EdgeStatusType;
  error_message: string | null;
};

interface WorkflowNodeConfiguration {
  function_name?: string;
  value?: string;
  first_source?: string;
  second_source?: string;
  sources?: string[];
  source?: string;
  template?: any;
  position?: { x: number; y: number };
  prompt_template_variable_mappings?: Record<string, string>;
}
interface StaticConfig {
  static_value: string;
}

interface ReferenceConfig {
  source: {
    name: string;
    json_path?: string | null;
  };
}

type ValueConfig = StaticConfig | ReferenceConfig;

interface EdgeCondition {
  id: string;
  position: number;
  left_config: ValueConfig;
  operator: "=" | "!=" | "<" | ">" | "<=" | ">=";
  right_config: ValueConfig;
}

interface WorkflowEdge {
  id: string;
  conditionals: EdgeCondition[];
  workflow_version_id: number;
  source_node_name: string;
  target_node_name: string;
  is_and: boolean;
  is_loading?: boolean; // FE only prop
  status?: EdgeStatusType; // FE only prop
  saved?: boolean; // FE only prop
}

interface WorkflowNode {
  id: string;
  name: string;
  node_type: WorkflowNodeType;
  configuration: WorkflowNodeConfiguration;
  dependencies: string[];
  status?: WorkflowNodeStatus;
  workflow_version_id?: number;
  is_output_node: boolean;
  saved?: boolean;
  has_default_name?: boolean;
}

type InputVariables = { [key: string]: string };

interface WorkflowVersion {
  id: number;
  workflow_id: number;
  edges: WorkflowEdge[];
  number: number;
  created_at: string;
  commit_message: string;
  user_id: number;
  required_input_variables: InputVariables;
  user_email: string;
  labels: WorkflowLabel[];
}

export interface WorkflowLabel {
  id: number;
  created_at: string;
  name: string;
  workflow_version_id: number;
  workflow_id: number;
}

interface WorkflowVersionExecution {
  id: string;
  workflow_version_id: number;
  input_variables: object;
  created_at: string;
}

interface WorkflowNodeOutput {
  id: string;
  workflow_node_id: string;
  workflow_version_execution_id: string;
  value: any;
  display_value: string;
  created_at: string;
  status: WorkflowNodeStatus;
}

interface WorkflowInfo {
  id: number;
  name: string;
  workspace_id: number;
}

interface Workflow extends WorkflowInfo {
  workflow_id: number | null;
  commit_message: string;
  nodes: WorkflowNode[];
  input_variables: Variable[];
  created_at?: string;
  version_id?: number | null;
}

interface WorkflowNodesResponse {
  workflow_nodes?: WorkflowNode[];
  message?: string;
  success: boolean;
}

interface WorkflowVersionResponse {
  workflow_versions?: WorkflowVersion[];
  message?: string;
  success: boolean;
}

type WorkflowNodesRequest = [
  key: string,
  workflow_version_id: number | string,
  userToken: string,
];

type WorkflowVersionRequest = [
  key: string,
  workflow_id: string,
  userToken: string,
];

interface WorkflowNodeOutputPayload {
  created_at: string | null;
  display_value: any | null;
  error_message: string | null;
  id: number;
  is_draft: boolean;
  status: string;
  value: any | null;
  workflow_node_id: number;
  workflow_node_name: string;
}

type SetNodes = React.Dispatch<React.SetStateAction<Node[]>>;
type SetEdges = React.Dispatch<React.SetStateAction<Edge[]>>;

interface ExecutionStatus {
  display_value: DisplayValue;
  error_message: string | null;
  id: string;
  is_draft: boolean;
  status: WorkflowNodeStatus;
  value: any | null;
  workflow_node_id: string;
  workflow_node_name: string;
}

type DisplayValue =
  | {
      type: "string";
      value: string;
    }
  | {
      type: "number";
      value: number;
    }
  | {
      type: "boolean";
      value: boolean;
    }
  | {
      type: "object";
      value: object;
    }
  | {
      type: "unknown";
      value: unknown;
    };

interface NodeOutput {
  date: number;
  display_value: DisplayValue;
  request_id?: number;
  trace_id?: string;
}

interface ObservableWorkflowNode extends WorkflowNode {
  errors?: any[];
  outputs?: NodeOutput[];
  triggered?: boolean;
}

interface WorkflowSchema {
  workflow_id: number | null;
  workspace_id?: number;
  name: string;
  commit_message: string;
  nodes: WorkflowNode[];
  edges: Array<{
    source_node_name: string | null;
    target_node_name: string | null;
    is_and: boolean;
    conditionals: Array<{
      position: number;
      operator: EdgeCondition["operator"];
      left_config: {
        type: "source" | "static_value";
      } & ValueConfig;
      right_config: {
        type: "source" | "static_value";
      } & ValueConfig;
    }>;
  }>;
  required_input_variables: Record<string, string | object>;
  is_draft: boolean;
}

export type {
  WorkflowSchema,
  DisplayValue,
  EdgeStatus,
  ExecutionStatus,
  InputVariables,
  NodeOutput,
  ObservableWorkflowNode,
  SetEdges,
  SetNodes,
  Workflow,
  WorkflowInfo,
  WorkflowNode,
  WorkflowNodeOutput,
  WorkflowNodeOutputPayload,
  WorkflowNodesRequest,
  WorkflowNodesResponse,
  WorkflowNodeType,
  WorkflowVersion,
  WorkflowVersionExecution,
  WorkflowVersionRequest,
  WorkflowVersionResponse,
  WorkflowEdge,
  EdgeCondition,
  ValueConfig,
  ReferenceConfig,
};
