import { Variable } from "@/components/InputVariableConfigurator/types";

export const TooltipVariableList = ({
  variables,
}: {
  variables: Variable[];
}) => (
  <div className="max-h-[150px] overflow-y-auto">
    {variables.map(({ key, value }, i) => (
      <div key={`Variable_${i}`}>
        <span className="font-bold">{key}</span>
      </div>
    ))}
  </div>
);

export const ReadOnlyVariableList = ({
  variables,
}: {
  variables: Variable[];
}) => (
  <div className="max-h-[300px] space-y-4 overflow-y-auto">
    {variables.map(({ key, value }, i) => (
      <div
        key={key}
        className="flex items-center space-x-4 rounded-lg border border-gray-200 bg-gray-50 p-4 shadow-sm"
      >
        <div className="w-1/3 font-mono text-sm text-gray-800">{key}</div>
      </div>
    ))}
  </div>
);
