import { Media } from "@/types";
import { useRef } from "react";
import MediaUploader from "./MediaUploader";
import MediaVariable from "./MediaVariable";
import SelectedMediaGrid from "./SelectedMediaGrid";
import SetMediaVariableButton from "./SetMediaVariableButton";

interface MediaOptionsProps {
  mediaVariable?: string;
  setMediaVariable: (mediaVariable: string) => void;
  onDeleteMedia: (index: number) => void;
  selectedMedia?: Media[];
  setSelectedMedia: (selectedMedia?: Media) => void;
  readonly?: boolean;
}

const MediaOptions = ({
  mediaVariable,
  setMediaVariable,
  selectedMedia,
  onDeleteMedia,
  setSelectedMedia,
  readonly = false,
}: MediaOptionsProps) => {
  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleClear = () => {
    if (readonly) return;
    setMediaVariable("");
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };

  return (
    <>
      <div className="z-10 flex max-w-[300px] flex-row gap-x-4">
        <MediaUploader
          selectedMedia={selectedMedia}
          onDeleteMedia={onDeleteMedia}
          onMediaSelect={setSelectedMedia}
          fileInputRef={fileInputRef}
          readonly={readonly}
        />
        {(mediaVariable && (
          <MediaVariable
            mediaVariable={mediaVariable}
            onClear={handleClear}
            readonly={readonly}
          />
        )) ||
          (!readonly && (
            <SetMediaVariableButton
              mediaVariable={mediaVariable || ""}
              onSave={setMediaVariable}
            />
          ))}
      </div>
      <SelectedMediaGrid
        selectedMedia={selectedMedia || []}
        onDeleteMedia={onDeleteMedia}
        readonly={readonly}
        onMediaSelect={setSelectedMedia}
      />
    </>
  );
};

export default MediaOptions;
