import { zodResolver } from "@hookform/resolvers/zod";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { z } from "zod";
import { useForgotPassword, useLogin } from "@/api/application-api";
import AuthSubmitButton from "@/components/AuthSubmitButton";
import { useAuth } from "@/context/auth-context";
import { FormInput } from "@/components/ui/FormInput";
import logo from "@/assets/logo/promptlayer-cake-color.svg";
import { loginSchema } from "./schema";
import { loginFormFields } from "./constants";
import { displayErrorToast } from "@/utils/toast";
import { getWorkspaces } from "@/queries";
import { Button } from "@/components/ui/button";

const Login = () => {
  const auth = useAuth();
  const navigate = useNavigate();
  const { mutate: login, isLoading: isLoginLoading } = useLogin();
  const { mutate: forgotPassword, isLoading: isForgotPasswordLoading } =
    useForgotPassword();

  useEffect(() => {
    if (auth?.userToken) navigate("/");
  }, [auth?.userToken, navigate]);

  const form = useForm<z.infer<typeof loginSchema>>({
    mode: "onChange",
    reValidateMode: "onChange",
    defaultValues: {
      email: "",
      password: "",
    },
    resolver: zodResolver(loginSchema),
  });

  const handleForgotPasswordClick = async () => {
    const email = form.watch("email");
    const emailSchema = z
      .string()
      .email({ message: "Invalid email format" })
      .nonempty({ message: "Email is required" });
    const emailResult = emailSchema.safeParse(email);

    if (!emailResult.success) {
      form.setError("email", { message: "Please provide a valid email." });
      return;
    }

    forgotPassword({ email });
  };

  const onSubmit = (data: z.infer<typeof loginSchema>) =>
    login(data, {
      onSuccess: async (response) => {
        const { access_token, user } = response;
        const { workspaces } = await getWorkspaces(access_token);

        if (workspaces.length === 0) {
          displayErrorToast("You do not have access to any workspaces.");
          return;
        }

        const activeWorkspace =
          workspaces.find(({ is_default }) => is_default) || workspaces[0];

        auth?.login(access_token, user);

        navigate(`/workspace/${activeWorkspace.id}/home`);
      },
    });

  return (
    <>
      <div className="flex flex-col items-center justify-center gap-2">
        <img src={logo} alt="logo" className="h-[52px] w-[52px]" />
        <span className="text-3xl font-bold text-gray-900">Log In</span>
      </div>
      <form
        className="flex flex-col gap-3 py-3"
        onSubmit={form.handleSubmit(onSubmit)}
      >
        {loginFormFields.map((field: any) => {
          return (
            <FormInput
              key={field.name}
              label={field.label}
              placeholder={field.placeholder}
              type={field.type}
              error={
                form.formState.errors[
                  field.name as keyof z.infer<typeof loginSchema>
                ]?.message
              }
              {...form.register(
                field.name as keyof z.infer<typeof loginSchema>,
              )}
            />
          );
        })}
        <div className="flex justify-end">
          <Button
            variant="link"
            className={`!p-0 text-sm ${
              isForgotPasswordLoading
                ? "text-gray-300"
                : "text-[#1D4ED8] hover:text-[#1D4ED8]/90"
            }`}
            onClick={handleForgotPasswordClick}
            isLoading={isForgotPasswordLoading}
            disabled={isForgotPasswordLoading}
            type="button"
          >
            Forgot password?
          </Button>
        </div>
        <AuthSubmitButton loading={isLoginLoading} text="Login" />
      </form>
      <div className="text-center text-sm font-light text-gray-800">
        Don't have an account yet?{" "}
        <Button
          variant="inlineLink"
          onClick={() => navigate("/create-account")}
        >
          Create an account.
        </Button>
      </div>
    </>
  );
};

export default Login;
