import useMatrixPagination from "./hooks/useMatrixPagination";
import useScoreCards from "./hooks/useScoreCards";
import MatrixWithLabel from "./MatrixWithLabel";
import PaginationControls from "./PaginationControls";
import { DiffProps } from "./types";

const ScoreMatrix: React.FC<
  DiffProps & {
    showAll?: boolean;
    showScore?: boolean;
    showBefore?: boolean;
    collapsed?: boolean;
  }
> = ({
  beforeScore,
  afterScore,
  showDiff = true,
  hideTitle = false,
  showAll = false,
  showScore = false,
  showBefore = true,
  collapsed = true,
}) => {
  const { currentPage, maxPages, handlePrevPage, handleNextPage } =
    useMatrixPagination(beforeScore, afterScore);
  const scoreCards = useScoreCards(
    beforeScore,
    afterScore,
    showAll,
    currentPage,
  );

  return (
    <div className="flex flex-col items-center">
      <div className="relative w-full">
        {showAll ? (
          <div className="flex gap-6">
            {["before", "after"].map((type) => {
              if (type === "after" && !showDiff) return null;
              const isBefore = type === "before";
              const scoreMatrix = isBefore
                ? beforeScore?.score_matrix
                : afterScore?.score_matrix;
              const cards = isBefore ? scoreCards.before : scoreCards.after;
              const compareCards = isBefore
                ? scoreCards.after
                : scoreCards.before;
              const score = isBefore ? beforeScore?.score : afterScore?.score;
              const length = cards.length;

              if (!showBefore && type === "before") {
                return null;
              }

              return (
                <div key={type} className="flex flex-col gap-6">
                  {scoreMatrix
                    ? cards.map((scoreCard, index) =>
                        scoreCard.length === 0 ? (
                          <MatrixWithLabel
                            key={`${type}-placeholder-${index}`}
                            label={
                              length > 1
                                ? `${
                                    type.charAt(0).toUpperCase() + type.slice(1)
                                  } (${index + 1})`
                                : type.charAt(0).toUpperCase() + type.slice(1)
                            }
                            type={type as "before" | "after"}
                            showDiff={showDiff}
                            showScore={showScore}
                            collapsed={collapsed}
                          />
                        ) : (
                          <MatrixWithLabel
                            key={`${type}-${index}`}
                            scoreCard={scoreCard}
                            compareCard={compareCards[index]}
                            type={type as "before" | "after"}
                            showDiff={showDiff}
                            label={
                              showDiff
                                ? length > 1
                                  ? `${
                                      type.charAt(0).toUpperCase() +
                                      type.slice(1)
                                    } (${index + 1})`
                                  : type.charAt(0).toUpperCase() + type.slice(1)
                                : undefined
                            }
                            showScore={showScore}
                            score={score}
                            collapsed={collapsed}
                          />
                        ),
                      )
                    : Array.from({ length: compareCards.length }).map(
                        (_, index) => (
                          <MatrixWithLabel
                            key={`${type}-placeholder-${index}`}
                            label={
                              compareCards.length > 1
                                ? `${
                                    type.charAt(0).toUpperCase() + type.slice(1)
                                  } (${index + 1})`
                                : type.charAt(0).toUpperCase() + type.slice(1)
                            }
                            type={type as "before" | "after"}
                            showDiff={showDiff}
                            showScore={showScore}
                            collapsed={collapsed}
                          />
                        ),
                      )}
                </div>
              );
            })}
          </div>
        ) : (
          <div className="flex gap-6">
            {!showAll && maxPages > 1 && (
              <PaginationControls
                currentPage={currentPage}
                maxPages={maxPages}
                onPrevPage={handlePrevPage}
                onNextPage={handleNextPage}
              />
            )}
            <div className="flex flex-col gap-6 sm:flex-row">
              {beforeScore?.score_matrix ? (
                <MatrixWithLabel
                  scoreCard={scoreCards.before[0]}
                  compareCard={scoreCards.after[0]}
                  type="before"
                  showDiff={showDiff}
                  label={showDiff && !hideTitle ? "Before" : undefined}
                  showScore={showScore}
                  score={beforeScore.score}
                  collapsed={collapsed}
                />
              ) : (
                showDiff && (
                  <div className="flex h-full items-center justify-center">
                    <MatrixWithLabel
                      type="before"
                      showDiff={showDiff}
                      label="Before"
                      showScore={showScore}
                      collapsed={collapsed}
                    />
                  </div>
                )
              )}
              {showDiff &&
                !showScore &&
                (afterScore?.score_matrix ? (
                  <MatrixWithLabel
                    scoreCard={scoreCards.after[0]}
                    compareCard={scoreCards.before[0]}
                    type="after"
                    showDiff={showDiff}
                    label="After"
                    showScore={showScore}
                    score={afterScore.score}
                    collapsed={collapsed}
                  />
                ) : (
                  <div
                    className="flex h-full items-center justify-center"
                    style={{
                      width: beforeScore?.score_matrix ? "100%" : "auto",
                    }}
                  >
                    <MatrixWithLabel
                      type="after"
                      showDiff={showDiff}
                      label="After"
                      showScore={showScore}
                      collapsed={collapsed}
                    />
                  </div>
                ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ScoreMatrix;
