import {
  Select,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";

import { SelectContent } from "@/components/ui/select";

import { LanguageType } from "../types";
import { LanguageNameMap } from "../constants";
import { LanguageSelectorSelectProps } from "./types";

export const LanguageSelect = ({
  languages,
  editable,
  handleLanguageChange,
  language,
  label,
}: LanguageSelectorSelectProps) => {
  return (
    <div className="flex w-full items-center justify-between gap-2">
      <div>{label}</div>
      <div>
        <Select
          disabled={!editable}
          onValueChange={(value) => handleLanguageChange(value as LanguageType)}
          value={language}
        >
          <SelectTrigger className="w-full min-w-[157px]">
            <SelectValue placeholder="Select a language..." />
          </SelectTrigger>
          <SelectContent>
            {languages.map((lang) => (
              <SelectItem key={lang} value={lang}>
                {LanguageNameMap[lang]}
              </SelectItem>
            ))}
          </SelectContent>
        </Select>
      </div>
    </div>
  );
};
