import { TagIcon as TagIconOutline } from "@heroicons/react/outline";
import { TagIcon } from "@heroicons/react/solid";
import React, { useEffect, useRef, useState } from "react";
import { Button } from "./ui/button";
import { Input } from "./ui/input";
import { Popover, PopoverContent, PopoverTrigger } from "./ui/popover";

interface TagsEditorProps {
  tags: string[];
  onEdit: (newTags: string[]) => void;
  maxTags?: number;
}

const TagsEditor: React.FC<TagsEditorProps> = ({
  tags,
  onEdit,
  maxTags = 10,
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (isEditing && inputRef.current) {
      inputRef.current.focus();
    }
  }, [isEditing]);

  const handleAddTag = () => {
    if (
      inputValue.trim() &&
      tags.length < maxTags &&
      !tags.includes(inputValue.trim())
    ) {
      const newTags = [...tags, inputValue.trim()];
      onEdit(newTags);
      setInputValue("");
    }
  };

  const handleRemoveTag = (tagToRemove: string) => {
    const newTags = tags.filter((tag) => tag !== tagToRemove);
    onEdit(newTags);
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      e.preventDefault();
      handleAddTag();
    } else if (e.key === "Backspace" && inputValue === "" && tags.length > 0) {
      handleRemoveTag(tags[tags.length - 1]);
    }
  };

  const handleToggleTag = (tag: string) => {
    if (tags.includes(tag)) {
      handleRemoveTag(tag);
    } else {
      onEdit([...tags, tag]);
    }
  };

  return (
    <div className="flex flex-wrap items-center gap-2">
      <Popover open={isEditing} onOpenChange={setIsEditing}>
        <PopoverTrigger asChild>
          <Button
            variant="ghost"
            size="sm"
            onClick={() => setIsEditing(true)}
            className={`gap-x-1 rounded-full bg-gray-50 px-2 py-2.5 text-xs ${
              tags.length > 0
                ? "text-blue-600 hover:text-blue-700"
                : "text-gray-500  hover:text-gray-700"
            }`}
          >
            {tags.length > 0 ? (
              <TagIcon className="h-2.5 w-2.5 flex-shrink-0" />
            ) : (
              <TagIconOutline className="h-2.5 w-2.5 flex-shrink-0" />
            )}
            {tags.length === 0 ? "No Tags" : "Tags"}
            {tags.length > 0 && (
              <span className="ml-1 inline-flex items-center justify-center rounded-full bg-blue-100 px-1 py-0.5 text-xs font-bold leading-none text-blue-600">
                {tags.length}
              </span>
            )}
          </Button>
        </PopoverTrigger>
        <PopoverContent className="p-0">
          <div className="flex flex-col gap-y-2 rounded-md  p-2">
            <div className="flex items-center gap-x-2">
              <Input
                ref={inputRef}
                type="text"
                value={inputValue}
                onChange={(e) => setInputValue(e.target.value)}
                onKeyDown={handleKeyDown}
                placeholder="Add a tag..."
                className="h-9" // Added fixed height
              />
              <Button
                onClick={(e) => {
                  handleAddTag();
                  setIsEditing(false);
                }}
                aria-label="Add tag"
                className="h-9 whitespace-nowrap text-xs" // Added fixed height
              >
                Create Tag
              </Button>
            </div>
            <div className="flex flex-col gap-y-1">
              {Array.from(new Set(tags)).map((tag) => (
                <div
                  key={tag}
                  className="flex cursor-pointer items-center rounded p-1 hover:bg-gray-100"
                  onClick={() => handleToggleTag(tag)}
                >
                  <input
                    id={tag}
                    type="checkbox"
                    checked={tags.includes(tag)}
                    readOnly
                    className="h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-500"
                  />
                  <span className="ml-3 block truncate font-medium">{tag}</span>
                </div>
              ))}
            </div>
          </div>
        </PopoverContent>
      </Popover>
    </div>
  );
};

export default TagsEditor;
