import { Input } from "@/components/ui/input";
import { Parameter } from "../ParameterControl";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "@/components/ui/accordion";

import { ParametersWithTitleProps } from "./types";

export const ParametersWithTitle = ({
  parameters,
}: ParametersWithTitleProps) => {
  const customParameters = parameters.filter((param) => param.isCustom);
  const defaultParameters = parameters.filter((param) => !param.isCustom);

  return (
    <Accordion
      type="multiple"
      defaultValue={["default", "custom"]}
      className="w-full"
    >
      {defaultParameters.length > 0 && (
        <AccordionItem value="default" className="border-b pb-1">
          <AccordionTrigger className="py-1.5 text-sm text-muted-foreground hover:text-gray-400 hover:no-underline">
            Parameters
          </AccordionTrigger>
          <AccordionContent className="pt-1">
            <div className="flex flex-col gap-3">
              {defaultParameters.map((param) => (
                <Parameter key={param.name} parameter={param} readOnly />
              ))}
            </div>
          </AccordionContent>
        </AccordionItem>
      )}
      {customParameters.length > 0 && (
        <AccordionItem value="custom" className="border-b-0">
          <AccordionTrigger className="py-1.5 text-sm text-muted-foreground hover:text-gray-400 hover:no-underline">
            Custom Parameters
          </AccordionTrigger>
          <AccordionContent className="pt-1">
            <div className="flex flex-col gap-3">
              {customParameters.map((param) => (
                <div
                  key={param.name}
                  className="flex items-center justify-between gap-2"
                >
                  <p
                    className="max-w-[120px] truncate text-sm font-medium"
                    title={param.name}
                  >
                    {param.name}
                  </p>
                  <Input
                    type="text"
                    value={String(param.value)}
                    className="w-full max-w-[220px]"
                    readOnly
                  />
                </div>
              ))}
            </div>
          </AccordionContent>
        </AccordionItem>
      )}
    </Accordion>
  );
};
