import { ENDPOINTS } from "@/api/application-api";
import { useAuth } from "@/context/auth-context";
import { CellStatus, Report } from "@/types/evaluate";
import { handleResponse } from "@/utils/errorResponseHandlers";
import { authHeader } from "@/utils/headers";
import { useQuery, useQueryClient } from "@tanstack/react-query";

type StatusCounts = Record<CellStatus, number>;

interface ReportStats {
  status_counts: StatusCounts;
}

export interface GetReportResponse {
  success: boolean;
  report: Report;
  stats: ReportStats;
}

export const useGetReport = (reportId: number | null) => {
  const auth = useAuth();
  const queryClient = useQueryClient();
  const userToken = auth?.userToken!;
  return useQuery(
    [ENDPOINTS.reports, reportId],
    (): Promise<GetReportResponse | null> => {
      if (!reportId) return Promise.resolve(null);
      const url = new URL(`${ENDPOINTS.reports}/${reportId.toString()}`);
      return fetch(url.toString(), {
        headers: authHeader(userToken),
      })
        .then(handleResponse)
        .then((responseJson) => {
          if (responseJson?.success) {
            queryClient.invalidateQueries([ENDPOINTS.report_columns, reportId]);
            return responseJson;
          }
        });
    },
  );
};
