import { observer } from "mobx-react-lite";

import { Label } from "@/components/ui/label";
import { ModelDropdown } from "@/components/ModelDropdown";

import { HUGGINGFACE_PROVIDER } from "../constants";
import { useParameters } from "../store";

export const LLMModel = observer(() => {
  const store = useParameters();
  const model = store.modelName;
  const inference_client_name = store.inferenceClientName;

  return (
    <div className="flex-0 mb-4 md:mb-0">
      <div className="mb-4">
        <Label
          htmlFor="version-select"
          className="mb-2 block text-sm font-medium text-gray-700"
        >
          {store.provider === HUGGINGFACE_PROVIDER
            ? "Inference Client"
            : "Model"}
        </Label>
        <div className="relative">
          <ModelDropdown
            selectedModel={inference_client_name || model || ""}
            handleSelectModel={(modelName) => store.updateModelName(modelName)}
            models={
              store.apiModels?.find((m) => m.provider_name === store.provider)
                ?.model_configs || []
            }
            isChat={store.isPromptChat}
            provider={store.provider || ""}
          />
        </div>
      </div>
    </div>
  );
});
