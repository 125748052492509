import { Media } from "@/types";

interface FullscreenMediaProps {
  media: Media;
  onShowFullscreen: () => void;
}

const FullscreenMedia = ({ media, onShowFullscreen }: FullscreenMediaProps) => {
  const commonFullscreenClasses = "";
  const mediaProps = {
    src: media.url,
    title: media.title,
    onClick: onShowFullscreen,
    className: `${commonFullscreenClasses} max-w-[96%] ${
      media.type !== "audio" && !media.type.includes("pdf")
        ? "max-h-[calc(95vh-4rem)] w-auto object-contain "
        : "w-full max-w-[calc(95vw-2rem)]"
    }`,
  };

  if (media.type.startsWith("image")) {
    return <img {...mediaProps} alt={media.title} />;
  } else if (media.type.startsWith("video") || media.type.includes("mp4")) {
    return <video {...mediaProps} controls />;
  } else if (media.type.startsWith("audio")) {
    return (
      <div className="flex h-full items-center justify-center">
        <audio {...mediaProps} controls className="w-full max-w-md" />
      </div>
    );
  } else if (media.type.includes("pdf")) {
    return (
      <embed
        {...mediaProps}
        type="application/pdf"
        style={{ height: "75vh" }}
      />
    );
  } else {
    return null; // or a default component for unsupported media types
  }
};

export default FullscreenMedia;
