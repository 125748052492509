import DatasetPicker from "@/components/ui/DatasetPicker";

import { useAuth } from "@/context/auth-context";
import { ToastType } from "@/enums";
import {
  useDatasetInputVariables,
  useRequestLogInputVariables,
} from "@/queries";
import { displayErrorToast, displayToast } from "@/utils/toast";
import { DatabaseIcon } from "@heroicons/react/outline";
import { ImportIcon, LinkIcon, Loader2Icon } from "lucide-react";
import { useEffect, useState } from "react";
import OptionInputPopover from "../OptionInputPopover";
import { Input } from "../input";

interface ImportVariablesPopoverProps {
  onVariablesFetched?: (variables: Record<string, string>) => void;
}

const ImportVariablesPopover = ({
  onVariablesFetched,
}: ImportVariablesPopoverProps) => {
  const auth = useAuth();
  const [referenceId, setReferenceId] = useState<number | null>(null);
  const [selectedOption, setSelectedOption] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  const datasetVariablesRequest = useDatasetInputVariables(
    auth?.userToken!,
    selectedOption === "dataset" ? referenceId : null,
  );

  const requestLogVariablesRequest = useRequestLogInputVariables(
    auth?.userToken!,
    selectedOption === "request" ? referenceId : null,
  );

  const {
    data,
    isFetching,
    error: fetchError,
    refetch,
  } = selectedOption === "dataset"
    ? {
        data: datasetVariablesRequest.data,
        isFetching: datasetVariablesRequest.isFetching,
        error: datasetVariablesRequest.error,
        refetch: datasetVariablesRequest.refetch,
      }
    : {
        data: requestLogVariablesRequest.data?.prompt_input_variables,
        isFetching: requestLogVariablesRequest.isFetching,
        error: requestLogVariablesRequest.error,
        refetch: requestLogVariablesRequest.refetch,
      };

  useEffect(() => {
    if (data && !isFetching) {
      setIsLoading(false);
      if (Object.keys(data).length > 0) {
        onVariablesFetched?.(data);
        displayToast("Variables imported successfully!", ToastType.success);
        setReferenceId(null);
      } else {
        displayErrorToast("No input variables found");
        setReferenceId(null);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, isFetching]);

  useEffect(() => {
    if (fetchError && referenceId) {
      setIsLoading(false);
      displayErrorToast(
        typeof fetchError === "string"
          ? fetchError
          : "Failed to fetch variables. Please check the URL and try again.",
      );
    }
  }, [fetchError, referenceId]);

  const handleImport = async (value: number | string, option: string) => {
    setIsLoading(true);
    if (typeof value === "string") {
      if (!value.trim()) {
        setIsLoading(false);
        displayErrorToast("Please enter a URL");
        return;
      }

      if (!value.includes("/")) {
        setIsLoading(false);
        displayErrorToast("Invalid URL format");
        return;
      }

      const id = parseInt(value.split("/").pop() || "0");

      if (!id || isNaN(id)) {
        setIsLoading(false);
        displayErrorToast("Invalid ID in URL");
        return;
      }
      setSelectedOption(option);
      setReferenceId(id);
      return;
    }
    setSelectedOption(option);
    setReferenceId(value as number);
    await refetch();
  };

  return (
    <div className="ml-auto max-w-[250px]">
      <OptionInputPopover<number | string>
        dropdownItems={[
          {
            name: "Import from Dataset",
            value: "dataset",
            icon: DatabaseIcon,
            onClick: () => {},
            disabled: isLoading,
          },
          {
            name: "Import from Request",
            value: "request",
            icon: LinkIcon,
            onClick: () => {},
            disabled: isLoading,
          },
        ]}
        tooltipContent="Import input variables"
        submitButtonText={isLoading ? "Loading..." : "Import"}
        buttonIcon={
          isLoading ? (
            <Loader2Icon className="h-5 w-5 animate-spin text-gray-600" />
          ) : (
            <ImportIcon className="h-5 w-5 text-gray-600" />
          )
        }
        renderSelector={(props, selectedOption) => {
          if (selectedOption === "dataset") {
            return <DatasetPicker onSelect={props.onChange} />;
          } else {
            return (
              <Input
                type="text"
                {...(props as any)}
                onChange={(e) => {
                  props.onChange(e.target.value);
                }}
                disabled={isLoading}
              />
            );
          }
        }}
        onSubmit={(url, selectedOption) => {
          try {
            handleImport(url, selectedOption);
          } catch (error) {
            setIsLoading(false);
            displayErrorToast("An error occurred while importing variables");
          }
        }}
        isLoading={isLoading}
      />
    </div>
  );
};

export default ImportVariablesPopover;
