import { BasicTooltip } from "@/components/ui/Tooltip";
import { Button } from "@/components/ui/button";
import { XIcon } from "@heroicons/react/outline";
import { observer } from "mobx-react-lite";
import moment from "moment";

const OutputHeader = ({
  provider,
  modelName,
  isCollapsed,
  onToggleCollapse,
  createdAt,
  onDelete,
}: {
  provider: string;
  modelName: string;
  isCollapsed: boolean;
  onToggleCollapse: () => void;
  createdAt?: string;
  onDelete?: () => void;
}) => (
  <div
    onClick={onToggleCollapse}
    className={`flex cursor-pointer select-none items-center justify-between ${
      isCollapsed ? "rounded-lg" : "rounded-t-lg"
    } border-b bg-gray-50 p-3 transition-all duration-200 hover:bg-gray-100`}
    role="button"
    tabIndex={0}
    aria-label={isCollapsed ? "Expand content" : "Collapse content"}
  >
    <div className="flex items-center gap-3">
      <div className="flex h-6 w-6 items-center justify-center">
        <svg
          className={`h-4 w-4 transform transition-transform duration-200 ${
            isCollapsed ? "-rotate-30" : "rotate-90"
          }`}
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M9 5l7 7-7 7"
          />
        </svg>
      </div>
      <span className="text-sm font-semibold text-gray-700 transition-colors hover:text-gray-900">
        {provider}
      </span>
      <span className="whitespace-nowrap rounded-full bg-gray-200 px-2 py-1 text-xs font-medium text-gray-500">
        {modelName}
      </span>
    </div>
    <div className="flex items-center gap-3">
      {createdAt && (
        <span className="text-xs text-gray-400">
          {moment(createdAt).fromNow()}
        </span>
      )}
      {onDelete && (
        <BasicTooltip content="Delete output">
          <Button variant="ghostGray" size="tinyIcon" onClick={onDelete}>
            <XIcon className="h-4 w-auto text-gray-400" />
          </Button>
        </BasicTooltip>
      )}
    </div>
  </div>
);

export default observer(OutputHeader);
