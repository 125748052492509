import { FunctionDialog } from "@/components/FunctionsModal";
import {
  ChatFunctionCall,
  Function_,
  FunctionsType,
  JSONSchema,
  Tool,
  ToolChoice,
} from "@/types";
import { getFunctionCall, getFunctionsType } from "@/utils/utils";
import { usePromptBlueprint } from "../hooks";
import { useParameters } from "@/components/ModelParameters/store";

const Functions = () => {
  const form = usePromptBlueprint();
  const store = useParameters();
  const promptTemplate = form.watch("prompt_template");

  if (promptTemplate.type === "completion") return null;

  const functionsType = getFunctionsType(promptTemplate);

  const functions =
    (functionsType === "tools"
      ? promptTemplate.tools?.map((tool) => tool.function)
      : promptTemplate.functions) ?? [];

  const functionCall = getFunctionCall(promptTemplate);

  const onJSONChange = (newJSON: JSONSchema) => {
    const responseFormat = store.parametersMap.get("response_format");
    if (responseFormat) {
      responseFormat.updateValue({
        type: "json_schema",
        json_schema: newJSON,
      });
    }
  };

  const setFunctionsType = (newFunctionsType: FunctionsType) => {
    if (newFunctionsType === "tools") {
      promptTemplate.functions = undefined;
      promptTemplate.function_call = undefined;
      promptTemplate.tools = functions.map((func) => ({
        type: "function",
        function: func,
      }));
      if (functionCall) {
        promptTemplate.tool_choice =
          typeof functionCall === "string"
            ? functionCall
            : {
                type: "function",
                function: functionCall,
              };
      }
    } else {
      promptTemplate.tools = undefined;
      promptTemplate.tool_choice = undefined;
      promptTemplate.functions = functions;
      if (functionCall) {
        promptTemplate.function_call = functionCall;
      }
    }
    form.setValue("prompt_template", promptTemplate);
  };

  const setFunctions = (newFunctions: Array<Function_>) => {
    if (functionsType === "tools") {
      const tools = newFunctions.map<Tool>((func) => ({
        type: "function",
        function: func,
      }));
      form.setValue("prompt_template.tools", tools);
    } else {
      form.setValue("prompt_template.functions", newFunctions);
    }
  };

  const onSubmit = (newFunction: any) => {
    let prev = structuredClone(functions);
    const index = prev.findIndex((func) => func.name === newFunction.name);
    if (index !== -1) {
      prev[index] = newFunction;
    } else {
      prev = prev.concat([newFunction]);
    }
    setFunctions(prev);
  };

  const setFunctionCall = (newFunctionCall: ChatFunctionCall) => {
    if (functionsType === "tools") {
      const tool_choice: ToolChoice =
        typeof newFunctionCall === "string"
          ? newFunctionCall
          : {
              type: "function",
              function: newFunctionCall,
            };
      form.setValue("prompt_template.tool_choice", tool_choice);
    } else {
      form.setValue("prompt_template.function_call", newFunctionCall);
    }
  };

  return (
    <FunctionDialog
      onJSONChange={onJSONChange}
      functions={functions}
      functionCall={functionCall}
      functionsType={functionsType}
      onSubmit={onSubmit}
      setFunctionCall={setFunctionCall}
      setFunctions={setFunctions}
      setFunctionsType={setFunctionsType}
    />
  );
};

export default Functions;
