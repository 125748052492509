import { ToastType } from "@/enums";
import { getDraftDatasetInformation } from "@/queries";
import {
  useSaveDataset,
  useSetDraftDatasetStateFromDataset,
  useEditDatasetGroup,
  useDeleteDatasetGroup,
  useDeleteDataset,
} from "@/queries/datasets";
import { DatasetRead } from "@/types/datasets";
import { displayToast, displayErrorToast } from "@/utils/toast";
import { useCallback } from "react";
import { useNavigate } from "react-router-dom";

export const useActions = (
  workspaceId: string | undefined,
  datasets: DatasetRead[],
  datasetId: string | undefined,
  datasetGroupId: string | undefined,
  userToken: string | null,
  nonDraftVersionsExist: boolean,
  setOverwriteDraftDatasetModalOpen: (value: boolean) => void,
) => {
  const { mutateAsync: deleteDataset, isLoading: isDeletingDataset } =
    useDeleteDataset(userToken!);
  const { mutateAsync: deleteDatasetGroup, isLoading: isDeletingDatasetGroup } =
    useDeleteDatasetGroup(userToken!);
  const { mutateAsync: editDatasetGroup } = useEditDatasetGroup(userToken!);
  const navigate = useNavigate();
  const { mutateAsync: saveDataset, isLoading: isSavingDataset } =
    useSaveDataset(userToken!);
  const {
    mutateAsync: setDraftDatasetStateFromDataset,
    isLoading: isSettingDraftDatasetStateFromDataset,
  } = useSetDraftDatasetStateFromDataset(userToken!);

  const handleDeleteDatasetGroup = useCallback(async () => {
    try {
      const { draft_dataset_exists, draft_dataset_id } =
        await getDraftDatasetInformation(userToken!, datasetGroupId!);

      if (draft_dataset_exists) {
        await deleteDataset(parseInt(draft_dataset_id!, 10));
      }

      deleteDatasetGroup(parseInt(datasetGroupId!, 10), {
        onSuccess: () => {
          displayToast("Dataset deleted successfully", ToastType.success);
          navigate(`/workspace/${workspaceId}/datasets`);
        },
        onError: () => {
          displayToast("Error deleting dataset group", ToastType.error);
        },
      });
    } catch (error) {
      displayToast("Error deleting dataset group", ToastType.error);
    }
  }, [
    deleteDataset,
    deleteDatasetGroup,
    datasetGroupId,
    navigate,
    userToken,
    workspaceId,
  ]);

  const handleDatasetGroupNameEdit = ({
    onSuccess,
    onError,
    newTitle,
  }: {
    onSuccess?: (newTitle: string) => void;
    onError?: () => void;
    newTitle: string;
  }) => {
    editDatasetGroup(
      {
        datasetGroupId: parseInt(datasetGroupId!, 10),
        name: newTitle,
      },
      {
        onSuccess: (data) => {
          if (onSuccess) {
            onSuccess(data.dataset_group.name);
          }
        },
        onError: () => {
          if (onError) {
            onError();
          }
        },
      },
    );
  };

  const handleDeleteClick = useCallback(async () => {
    if (nonDraftVersionsExist) {
      await deleteDataset(parseInt(datasetId!, 10));
      displayToast("Draft version deleted successfully", ToastType.success);
      const latestDatasetId = datasets.find(
        (dataset: DatasetRead) => dataset.version_number !== -1,
      )?.id;
      navigate(
        `/workspace/${workspaceId}/dataset-groups/${datasetGroupId}/dataset/${latestDatasetId}`,
      );
    } else {
      await deleteDatasetGroup(parseInt(datasetGroupId!, 10));
      displayToast("Dataset deleted successfully", ToastType.success);
      navigate(`/workspace/${workspaceId}/datasets`);
    }
  }, [
    datasetGroupId,
    datasetId,
    datasets,
    deleteDataset,
    deleteDatasetGroup,
    navigate,
    nonDraftVersionsExist,
    workspaceId,
  ]);

  const handleEditClick = useCallback(async () => {
    const { draft_dataset_exists, is_draft_dataset_populated } =
      await getDraftDatasetInformation(userToken!, datasetGroupId!);

    if (draft_dataset_exists && is_draft_dataset_populated) {
      setOverwriteDraftDatasetModalOpen(true);
      return;
    }

    const { draft_dataset_id } = await setDraftDatasetStateFromDataset({
      datasetId: parseInt(datasetId!, 10),
    });

    navigate(
      `/workspace/${workspaceId}/dataset-groups/${datasetGroupId}/dataset/${draft_dataset_id}`,
    );
  }, [
    datasetGroupId,
    datasetId,
    navigate,
    setDraftDatasetStateFromDataset,
    setOverwriteDraftDatasetModalOpen,
    userToken,
    workspaceId,
  ]);

  const handleSaveAsNewVersionClick = useCallback(
    async (onSuccess?: () => void) => {
      try {
        const response = await saveDataset({
          dataset_id: datasetId!,
        });
        if (response.success) {
          displayToast("Dataset created", ToastType.success);
          if (onSuccess) {
            onSuccess();
          } else {
            response.dataset &&
              navigate(
                `/workspace/${workspaceId}/dataset-groups/${datasetGroupId}/dataset/${response.dataset.id}`,
              );
          }
        }
      } catch (error) {
        displayErrorToast(error as string);
        console.error(error);
      }
    },
    [datasetGroupId, datasetId, navigate, saveDataset, workspaceId],
  );

  return {
    handleDatasetGroupNameEdit,
    handleDeleteClick,
    handleEditClick,
    handleSaveAsNewVersionClick,
    isDeletingDataset,
    isDeletingDatasetGroup,
    isSavingDataset,
    isSettingDraftDatasetStateFromDataset,
    handleDeleteDatasetGroup,
  };
};
