import { ArrowsExpandIcon } from "@heroicons/react/outline";
import { ChevronsDownUpIcon, ChevronsUpDownIcon } from "lucide-react";
import { useState } from "react";

import { Chat } from "@/chat";
import CodeBlockModal from "@/components/CodeBlockModal";
import { RequestInfoBlock } from "@/components/RequestInfoBlock";
import { TextCopyBlock } from "@/components/TextCopyBlock";
import NotFoundMessage from "@/components/ui/not-found";
import { JSONSchema, PromptBlueprint } from "@/types";
import { Request } from "@/types/requests";
import {
  getRequestMessages,
  getResponseMessage,
  getStringContent,
} from "@/utils/utils";

type ChatRequestProps = {
  functions?: any[];
  tools?: any[];
  requestInfo: Request;
  isOpenStates: Record<string, boolean | undefined>;
  setIsOpenStates: React.Dispatch<
    React.SetStateAction<Record<string, boolean | undefined>>
  >;
  requestId: string;
  jsonSchema?: {
    json_schema: JSONSchema;
    type: "json_schema";
  };
};

const ChatRequest = ({
  requestInfo,
  isOpenStates,
  setIsOpenStates,
  requestId,
  jsonSchema,
}: ChatRequestProps) => {
  const chatTemplate = requestInfo.prompt_version?.prompt_template;
  if (chatTemplate?.type !== "chat")
    return (
      <div className="rounded border border-red-300 p-2">
        Invalid prompt format
      </div>
    );
  const functions = chatTemplate.functions;
  const tools = chatTemplate.tools;
  const functionDefinitionsChatBox = functions && functions.length > 0 && (
    <Chat chatTemplate={chatTemplate} />
  );
  const toolDefinitionsChatBox = tools && tools.length > 0 && (
    <Chat chatTemplate={chatTemplate} />
  );

  return (
    <div className="space-y-1 overflow-y-auto pr-1">
      {jsonSchema && (
        <Chat chatTemplate={{ ...chatTemplate, json_schema: jsonSchema }} />
      )}
      {toolDefinitionsChatBox
        ? toolDefinitionsChatBox
        : functionDefinitionsChatBox}
      {getRequestMessages(chatTemplate).map((message, index) => {
        const key = `${requestId}.${requestInfo?.request_text?.length}.${index}`;
        if (!(key in isOpenStates)) {
          isOpenStates[key] = undefined;
        }
        return (
          <div key={key} className="text-sm">
            <Chat
              inputVariables={requestInfo.prompt_input_variables}
              startOpen={index === requestInfo?.request_text?.length - 1}
              isOpen={isOpenStates[key]}
              onToggle={() => {
                setIsOpenStates({
                  ...isOpenStates,
                  [key]: !isOpenStates[key],
                });
              }}
              message={message}
              chatTemplate={chatTemplate}
            />
          </div>
        );
      })}
    </div>
  );
};

const ChatResponse = ({
  requestResponse,
  requestInfo,
}: {
  requestResponse: any;
  requestInfo: Request;
}) => {
  const chatTemplate = requestInfo.prompt_version?.prompt_template;
  if (chatTemplate?.type !== "chat")
    return (
      <div className="rounded border border-red-300 p-2">
        Invalid response format
      </div>
    );
  const responseMessage =
    chatTemplate.type === "chat" ? getResponseMessage(chatTemplate) : undefined;
  if (responseMessage) {
    return (
      <div className="space-y-1 overflow-y-auto pr-1">
        <div className="text-sm">
          <Chat
            startOpen={true}
            message={responseMessage}
            chatTemplate={chatTemplate}
          />
        </div>
      </div>
    );
  }
  if (requestInfo.request_response) {
    // Display the request_response if the response_json is not available
    return (
      <pre className="rounded border p-2">
        {JSON.stringify(requestResponse, undefined, 2)}
      </pre>
    );
  }
  // Display the error if the response_json is not available
  const error = requestInfo?.response_json?.error;
  return (
    <div className="rounded border border-red-300 p-2">
      {error?.message ?? "Your response is not in the correct format"}
    </div>
  );
};

const ImageResponse = ({ requestInfo }: { requestInfo: any }) => {
  const images = requestInfo?.request_response?.data;
  if (images) {
    return (
      <div className="relative flex h-full flex-row flex-wrap justify-center gap-2 overflow-y-auto rounded-sm border border-gray-300 bg-white p-2">
        {images?.map((image: any) => (
          <img src={image.url} alt="generated" className="max-w-full" />
        ))}
      </div>
    );
  }
  return (
    <div className="rounded border border-red-300 p-2">No images found</div>
  );
};

const RequestDisplay: React.FC<{
  requestId: string;
  requestInfo: any;
}> = ({ requestId, requestInfo }) => {
  const [showRequestModal, setShowRequestModal] = useState(false);
  const [showResponseModal, setShowResponseModal] = useState(false);
  const [showFullRequest, setShowFullRequest] = useState(false);
  const [showFullResponse, setShowFullResponse] = useState(false);
  const [choice, setChoice] = useState(0);

  // Key string begins with `requestId` to keep state constant when flipping through requests
  const [chatBoxOpenStates, setChatBoxOpenStates] = useState<
    Record<string, boolean | undefined>
  >({});

  const handleCollapseAll = () => {
    const newRecord = Object.fromEntries(
      Object.keys(chatBoxOpenStates).map((key) =>
        key.startsWith(requestId)
          ? [key, false]
          : [key, chatBoxOpenStates[key]],
      ),
    );
    setChatBoxOpenStates(newRecord);
  };

  const handleExpandAll = () => {
    const newRecord = Object.fromEntries(
      Object.keys(chatBoxOpenStates).map((key) =>
        key.startsWith(requestId) ? [key, true] : [key, chatBoxOpenStates[key]],
      ),
    );
    setChatBoxOpenStates(newRecord);
  };

  const notFoundPage = (
    <NotFoundMessage
      title="Log not found"
      subtitle="The LLM log you are looking for does not exist or you do not have access to it."
      backLinkMessage="Go Home"
      backLink="/"
    />
  );

  if (!requestInfo) {
    return notFoundPage;
  }

  const promptBlueprint: PromptBlueprint | null = requestInfo.prompt_version;
  const template = promptBlueprint?.prompt_template;
  const engine = requestInfo.engine;
  const inputTokens = requestInfo.input_tokens;
  const outputTokens = requestInfo.output_tokens;
  var shortRequestText = requestInfo.prompt_string;
  if (template?.type === "chat") {
    shortRequestText = getRequestMessages(template).map((message) => {
      return `Role: ${message.role}\nContent: ${getStringContent(message)}\n`;
    });
  }
  const request_json = {
    tags: requestInfo.tags_array,
    function_name: requestInfo.function_name,
    function_args: requestInfo.function_args,
    function_kwargs: requestInfo.function_kwargs,
    request_start_time: requestInfo.request_start_time,
    request_end_time: requestInfo.request_end_time,
    json_schema:
      requestInfo.prompt_version.metadata.model?.parameters?.response_format,
  };

  const requestText = showFullRequest
    ? JSON.stringify(
        request_json || requestInfo.request_json_fallback,
        undefined,
        2,
      )
    : shortRequestText;

  let shortResponseText = requestInfo.response_string;
  if (requestInfo.is_embedding) {
    shortResponseText = "[Generated Embedding]";
  } else if (template?.type === "chat") {
    const responseMessage = getResponseMessage(template);
    if (responseMessage) {
      shortResponseText = `Role: ${
        responseMessage.role
      }\nContent: ${getStringContent(responseMessage)}\n`;
    }
  }

  const responseText = showFullResponse
    ? JSON.stringify(
        requestInfo.response_json ||
          requestInfo.response_json_fallback ||
          requestInfo.request_response,
        undefined,
        2,
      )
    : shortResponseText;

  const tools = request_json?.function_kwargs?.tools;

  const functions = request_json?.function_kwargs?.functions;

  const jsonSchema = request_json?.json_schema;

  const isChat = promptBlueprint?.prompt_template.type === "chat";
  const isImage = requestInfo.is_image;

  const requestResponse = requestInfo.request_response;

  let multipleChoiceSelector = null;
  let choiceResponse = null;
  let choiceResponseText = "";
  // Support for OpenAI returning multiple choices
  // TODO: Support other providers

  if (
    requestInfo &&
    !requestInfo?.response_json?.error &&
    requestInfo?.request_response &&
    requestInfo.request_response?.choices &&
    requestInfo.request_response.choices.length > 1
  ) {
    const choicesCount = requestInfo.request_response.choices.length;

    if (isChat) {
      choiceResponse = requestInfo.request_response.choices[choice].message;
      choiceResponseText = JSON.stringify(choiceResponse, undefined, 2);
    } else {
      choiceResponse = requestInfo.request_response.choices[choice].text;
      choiceResponseText = JSON.stringify(
        requestInfo?.response_json || choiceResponse,
        undefined,
        2,
      );
    }

    multipleChoiceSelector = (
      <span className="flex gap-x-1 pr-1">
        {Array.from({ length: choicesCount }, (_, i) => (
          <div
            className={`mt-1 cursor-pointer rounded-sm border border-gray-300 px-2 py-1 font-mono text-xs text-gray-600 ${
              choice === i
                ? "border-gray-400 bg-gray-100"
                : `hover:border-gray-400 hover:bg-gray-50 hover:text-gray-900`
            }`}
            key={i}
            onClick={() => setChoice(i)}
          >
            {i + 1}
          </div>
        ))}
      </span>
    );
  }

  let chatResponseTextBlock = "";
  if (!!choiceResponse) {
    if (showFullResponse) {
      // Show JSON enabled
      chatResponseTextBlock = choiceResponseText;
    } else {
      if (isChat) {
        chatResponseTextBlock = choiceResponseText;
      } else {
        chatResponseTextBlock = choiceResponse;
      }
    }
  } else {
    chatResponseTextBlock = responseText;
  }

  let fullScreenModalText = "";
  if (showRequestModal) {
    fullScreenModalText = requestText;
  } else if (!!choiceResponse) {
    if (isChat && !showFullResponse) {
      fullScreenModalText = `Role: ${choiceResponse?.role}\nContent: ${choiceResponse?.content}`;
    } else {
      fullScreenModalText = chatResponseTextBlock;
    }
  } else {
    fullScreenModalText = responseText;
  }

  let responseDisplay = null;
  if (isChat && !showFullResponse) {
    responseDisplay = (
      <ChatResponse
        requestResponse={choiceResponse ?? requestResponse}
        requestInfo={requestInfo}
      />
    );
  } else if (isImage && !showFullResponse) {
    responseDisplay = <ImageResponse requestInfo={requestInfo} />;
  } else {
    responseDisplay = (
      <TextCopyBlock
        text={chatResponseTextBlock}
        code={showFullResponse}
        highlight={!showFullResponse}
      />
    );
  }
  return (
    <>
      <div className="flex h-full flex-col gap-4 overflow-hidden pb-4">
        <CodeBlockModal
          showModal={showRequestModal || showResponseModal}
          setShowModal={
            showRequestModal ? setShowRequestModal : setShowResponseModal
          }
          text={fullScreenModalText}
          isCode={showRequestModal ? showFullRequest : showFullResponse}
          highlight={showResponseModal && !showFullResponse}
          forceMonoSpace={true}
          inputVariables={requestInfo.prompt_input_variables}
        />
        <RequestInfoBlock
          key={requestId}
          requestId={requestId}
          requestInfo={requestInfo}
          requestText={requestText}
          inputTokens={inputTokens}
          outputTokens={outputTokens}
          response_format={requestInfo?.response_format?.type}
          engine={engine}
          provider={requestInfo?.provider_type}
        />
        <div className="grid h-full grid-cols-2 gap-x-10 overflow-y-auto">
          <div className="flex h-full flex-col overflow-y-auto">
            <div className="flex flex-row">
              <h1 className="flex-1 pb-1 text-gray-700">
                Prompt:
                <span
                  className="cursor-pointer pl-2 text-xs text-blue-500 hover:text-blue-400"
                  onClick={() => setShowFullRequest(!showFullRequest)}
                >
                  {showFullRequest ? "(Show prompt text)" : "(Show full JSON)"}
                </span>
              </h1>
              {isChat && (
                <>
                  <div
                    className="cursor-pointer text-gray-600 outline-none hover:text-gray-400"
                    onClick={() => handleExpandAll()}
                  >
                    <ChevronsUpDownIcon className="mr-1 inline h-5 w-auto" />
                  </div>
                  <div
                    className="cursor-pointer text-gray-600 outline-none hover:text-gray-400"
                    onClick={() => handleCollapseAll()}
                  >
                    <ChevronsDownUpIcon className="mr-1 inline h-5 w-auto" />
                  </div>
                </>
              )}
              <div
                className="cursor-pointer text-gray-600 outline-none hover:text-gray-400"
                onClick={() => setShowRequestModal(true)}
              >
                <ArrowsExpandIcon className="mr-1 inline h-5 w-auto" />
              </div>
            </div>
            {isChat && !showFullRequest ? (
              <ChatRequest
                functions={functions}
                tools={tools}
                jsonSchema={jsonSchema}
                requestInfo={requestInfo}
                isOpenStates={chatBoxOpenStates}
                setIsOpenStates={setChatBoxOpenStates}
                requestId={requestId}
              />
            ) : (
              <TextCopyBlock
                text={requestText}
                code={showFullRequest}
                inputVariables={requestInfo.prompt_input_variables}
              />
            )}
          </div>
          <div className={`flex flex-col overflow-y-auto`}>
            <div
              className={`flex flex-row ${multipleChoiceSelector && "mb-1"}`}
            >
              <h1 className="flex-1 pb-1 text-gray-700">
                Response:
                <span
                  className="cursor-pointer pl-2 text-xs text-blue-500 hover:text-blue-400"
                  onClick={() => setShowFullResponse(!showFullResponse)}
                >
                  {showFullResponse
                    ? "(Show response text)"
                    : "(Show full JSON)"}
                </span>
              </h1>
              {!showFullResponse || isChat ? multipleChoiceSelector : null}
              <button
                className="text-gray-600 hover:text-gray-500"
                onClick={() => setShowResponseModal(true)}
              >
                <ArrowsExpandIcon className="mr-1 inline h-5 w-auto" />
              </button>
            </div>
            {responseDisplay}
          </div>
        </div>
      </div>
    </>
  );
};

export default RequestDisplay;
