import { Dialog, DialogContent } from "@/components/ui/dialog";
import { Media } from "@/types";
import { useState } from "react";
import FullscreenMedia from "./FullscreenMedia";
import MediaPreview from "./MediaPreview";

interface SelectedMediaProps {
  media: Media;
  onMediaSelect: (media?: Media) => void;
  onDelete: () => void;
  readonly?: boolean;
}

const SelectedMedia = ({
  media,
  onMediaSelect,
  onDelete,
  readonly = false,
}: SelectedMediaProps) => {
  const [showFullscreen, setShowFullscreen] = useState(false);

  return (
    <div className="relative h-full">
      <MediaPreview
        media={media}
        onShowFullscreen={() => setShowFullscreen(true)}
        readonly={readonly}
        onMediaSelect={onMediaSelect}
        onDelete={onDelete}
      />

      <Dialog open={showFullscreen} onOpenChange={setShowFullscreen}>
        <DialogContent>
          <FullscreenMedia
            media={media}
            onShowFullscreen={() => setShowFullscreen(true)}
          />
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default SelectedMedia;
