import { useContext } from "react";
import { observer } from "mobx-react-lite";

import { Button } from "@/components/ui/button";
import { PlaygroundStoreContext } from "@/components/Playground2/playground-store";

import { ProviderBaseURL } from "../GeneralParameters/ProviderBaseURL";
import { LLMProvider } from "../GeneralParameters/LLMProvider";
import { LLMModel } from "../GeneralParameters/LLMModel";
import { useParameters } from "../store";
import { HUGGINGFACE_PROVIDER } from "../constants";

import { Controls } from "./Controls";

export const EditParameters = observer(
  ({ shouldShowClearButton = true }: { shouldShowClearButton?: boolean }) => {
    const store = useParameters();
    const isPlayground = useContext(PlaygroundStoreContext);
    const isHuggingFace = store.provider === HUGGINGFACE_PROVIDER;

    return (
      <div className="flex w-full flex-col gap-4">
        <div className="rounded-md border border-gray-200 bg-gray-50 p-3">
          <LLMProvider />
          <LLMModel />
          {!isHuggingFace && <ProviderBaseURL />}
          <Controls />
        </div>
        {!isPlayground && shouldShowClearButton && (
          <div className="flex items-center justify-center">
            <Button
              type="button"
              onClick={() => {
                store.clearModelData();
              }}
              variant="destructiveOutline"
            >
              Clear Parameters
            </Button>
          </div>
        )}
      </div>
    );
  },
);
