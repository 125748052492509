import { useGetReport } from "@/queries";
import { autorun, runInAction } from "mobx";
import { useEffect } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { useCompareReportsStore } from "../compare-reports-store";

const useDefaultReport = () => {
  const [searchParams] = useSearchParams();
  const reportStore = useCompareReportsStore();
  const reportId = searchParams.get("report_id");
  const { workspaceId } = useParams();
  const { setSelectedBatch, addBatch } = reportStore;
  const reportIdNumber = reportId ? Number(reportId) : null;

  const { isLoading, data } = useGetReport(reportIdNumber);

  useEffect(() => {
    runInAction(() => {
      reportStore.reset();
    });
  }, [workspaceId, reportStore]);

  useEffect(() => {
    autorun(() => {
      if (!isLoading && data) {
        runInAction(() => {
          addBatch(data.report);
          setSelectedBatch(reportIdNumber!, "A");
        });
      }
    });
  }, [addBatch, data, isLoading, reportIdNumber, setSelectedBatch]);
};

export default useDefaultReport;
