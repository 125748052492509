import { useEffect } from "react";

interface SourceNameProps {
  name: string;
  setName: (name: string) => void;
  isNode: boolean;
  isValid?: boolean;
  readonly?: boolean;
  columnCount?: number;
  variant?: "PROMPT_TEMPLATE" | "AGENT" | "REGEX";
}
const SourceName = ({
  name,
  setName,
  isNode,
  isValid,
  readonly,
  columnCount,
  variant,
}: SourceNameProps) => {
  useEffect(() => {
    if (!name && !isNode) setName(`step-${1 + (columnCount || 0)}`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (variant === "REGEX") {
    return (
      <div
        className="flex items-center gap-4"
        style={{ display: isNode ? "none" : "flex" }}
      >
        <label htmlFor="name" className="w-1/3 text-left">
          Column name:
        </label>
        <div className="w-2/3">
          <input
            id="name"
            className={`w-full rounded border ${
              !isValid ? "border-red-500" : "border-gray-300"
            } px-2 py-1 disabled:cursor-not-allowed disabled:bg-gray-50`}
            value={name || ""}
            placeholder={`step-${1 + (columnCount || 0)}`}
            onKeyDown={(e) => {
              if (e.key === " ") {
                // Only prevent default and handle manually for spaces
                e.preventDefault();
                setName(name + " ");
              }
              // Let all other keys (including backspace) work normally
            }}
            onChange={(e) => setName(e.target.value)}
            disabled={readonly}
          />
          {!isValid && (
            <div className="text-left text-xs text-red-500">
              Name is required
            </div>
          )}
        </div>
      </div>
    );
  }

  return (
    <>
      <label
        htmlFor="name"
        className={`self-center ${
          variant !== "PROMPT_TEMPLATE" ? "col-span-1" : ""
        } whitespace-nowrap`}
        style={{ display: isNode ? "none" : "block" }}
      >
        {isNode ? "Node" : "Column"} name:
      </label>
      <input
        id="name"
        className={`${
          variant === "AGENT"
            ? "w-full"
            : variant !== "PROMPT_TEMPLATE"
            ? "col-span-2"
            : ""
        } rounded border ${
          !isValid ? "border-red-500" : "border-gray-300"
        } px-2 py-1 disabled:cursor-not-allowed disabled:bg-gray-50`}
        value={name || ""}
        defaultValue={`step-${1 + (columnCount || 0)}`}
        placeholder={`step-${1 + (columnCount || 0)}`}
        onKeyDown={(e) => {
          if (e.key === " ") {
            // Only prevent default and handle manually for spaces
            e.preventDefault();
            setName(name + " ");
          }
          // Let all other keys (including backspace) work normally
        }}
        onChange={(e) => setName(e.target.value)}
        disabled={readonly}
        style={{ display: isNode ? "none" : "block" }}
      />
      {!isValid && (
        <>
          <div className="col-span-1 -mt-4">&nbsp;</div>
          <div className="col-span-2 -mt-4 text-left text-xs text-red-500">
            Name is required
          </div>
        </>
      )}
    </>
  );
};

export default SourceName;
