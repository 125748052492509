import { EndpointConfiguration, ReportColumn } from "@/types/evaluate";
import { useState } from "react";
import { useBandaid } from "../../utils/BandaidContext";
import { ModalStep } from "../ModalRouter";
import SourceName from "../SourceName";

const EndpointColumnBuilder = ({
  columnData,
  availableColumns,
  navigatePrevious,
  saveColumnAction,
  editable,
}: {
  columnData: Partial<ReportColumn>;
  availableColumns: ReportColumn[];
  navigatePrevious: () => void;
  saveColumnAction: (newColumnDataArg: ReportColumn) => void;
  editable: boolean;
}) => {
  const [url, setUrl] = useState<string | null>(
    columnData.configuration?.url || null,
  );
  const [isUrlValid, setIsUrlValid] = useState<boolean>(true);
  const [name, setName] = useState<string | null>(columnData.name || null);
  const [isNameValid, setIsNameValid] = useState<boolean>(true);

  const saveEndpointAction = () => {
    if (!name) {
      setIsNameValid(false);
      setIsUrlValid(true);
      return;
    } else if (!url) {
      setIsNameValid(true);
      setIsUrlValid(false);
      return;
    }

    setIsNameValid(true);
    setIsUrlValid(true);

    saveColumnAction({
      ...columnData,
      name: name,
      configuration: {
        url: url,
      } as EndpointConfiguration,
    } as ReportColumn);
  };

  const bandaid = useBandaid();
  const examplePayload = {
    data: availableColumns.reduce((acc: any, column: ReportColumn) => {
      acc[column.name] = "_value_";
      return acc;
    }, {}),
  };

  return (
    <ModalStep
      navigatePrevious={navigatePrevious}
      navigateNext={saveEndpointAction}
      nextButtonText={editable ? "Save Step" : "Done"}
    >
      <div className="grid grid-cols-[auto,1fr] gap-4">
        <div className="col-span-2">
          <div className="text-left text-lg font-semibold">
            Configure API Endpoint
          </div>
          <div className="max-w-md text-left text-sm text-gray-500">
            Using the URL provided below, PromptLayer will send a POST request
            to your endpoint with a payload of all {bandaid ? "node" : "column"}{" "}
            data.
          </div>
        </div>
        <SourceName
          name={name!}
          columnCount={availableColumns.length}
          isValid={isNameValid}
          setName={setName}
          isNode={bandaid}
          readonly={!editable}
          variant="PROMPT_TEMPLATE"
        />
        <label htmlFor="url" className="self-center text-left">
          Endpoint URL:
        </label>
        <div>
          <input
            id="url"
            className={`w-full rounded border ${
              !isUrlValid ? "border-red-500" : "border-gray-300"
            } px-2 py-1 disabled:cursor-not-allowed disabled:bg-gray-50`}
            value={url || ""}
            placeholder="https://api.example.com/promptlayer"
            onChange={(e) => setUrl(e.target.value)}
            disabled={!editable}
          />
          {!isUrlValid && (
            <div className="mt-1 text-left text-xs text-red-500">
              URL is required
            </div>
          )}
        </div>
        <div className="col-span-2 m-2 flex flex-col gap-y-2 rounded-md bg-gray-200 p-3">
          <div className="text-left text-sm font-semibold">
            Example Payload (POST)
          </div>
          <pre className="max-w-md text-left font-mono text-xs text-gray-600">
            {JSON.stringify(examplePayload, null, 2).replace(/"/g, "")}
          </pre>
        </div>
      </div>
    </ModalStep>
  );
};

export default EndpointColumnBuilder;
