import { useState } from "react";

export const useXrayMode = (promptVersion: any) => {
  const [isXrayMode, setIsXrayMode] = useState(false);

  const hasDatasetExamples =
    (promptVersion.data?.prompt_template?.dataset_examples?.length ?? 0) > 0;
  const isXrayAvailable =
    (promptVersion.data?.sourced_snippets?.length ?? 0) > 0 ||
    hasDatasetExamples;

  return { isXrayMode, setIsXrayMode, isXrayAvailable };
};
