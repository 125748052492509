import EmptyPlaceholder from "@/components/ui/EmptyPlaceholder";

import { Button } from "@/components/ui/button";
import { AnimatePresence, motion } from "framer-motion";
import { ChevronLeft, ChevronRight, Loader2 } from "lucide-react";
import { Observer, observer } from "mobx-react-lite";
import AnimatedElement from "../../Messages/AnimatedElement";
import { MessageSkeleton } from "../../Messages/Message";
import { usePlayground } from "../../playground-store";
import SidebarWidget from "../SidebarWidget";
import OutputCard from "./OutputCard";
import useInitialOutputs from "./hooks/useInitialOutputs";

const OutputsWidget = () => {
  const {
    outputsPage: page,
    setInitialOutputsPage: setCurrentPage,
    outputs,
    pendingOutputs,
    hasOutputContent,
    instanceId,
  } = usePlayground();

  const { isLoading, total, hasNext, hasPrev } = useInitialOutputs(page);

  const handlePrevPage = () => {
    if (page > 1) {
      setCurrentPage(page - 1);
    }
  };

  const handleNextPage = () => {
    setCurrentPage(page + 1);
  };

  return (
    <SidebarWidget name="Outputs" count={total}>
      <div className="flex flex-col gap-y-1">
        <AnimatePresence initial={true} mode="popLayout">
          <Observer>
            {() => (
              <>
                {/* Empty Placeholder Section */}
                {!hasOutputContent && page === 1 && (
                  <motion.div
                    key="empty-placeholder"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                  >
                    <EmptyPlaceholder />
                  </motion.div>
                )}

                {/* Pending Outputs Section */}
                {page === 1 && pendingOutputs > 0 && (
                  <motion.div
                    key="pending-outputs-container"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                  >
                    {Array.from({ length: pendingOutputs })
                      .map((_, index) => (
                        <AnimatedElement
                          key={`pending-output-${index}-${instanceId}`}
                        >
                          <MessageSkeleton />
                        </AnimatedElement>
                      ))
                      .reverse()}
                  </motion.div>
                )}

                {/* Actual Outputs Section */}
                {outputs.map((output) => {
                  // Ensure we have a unique, non-empty key
                  const outputKey =
                    output.currentOperation?.id ||
                    `instance-${output.instanceId}` ||
                    `output-${Math.random().toString(36).slice(2, 11)}`;

                  return (
                    <AnimatedElement key={outputKey}>
                      <OutputCard output={output} />
                    </AnimatedElement>
                  );
                })}
              </>
            )}
          </Observer>
        </AnimatePresence>
        {!isLoading && (hasPrev || hasNext) && (
          <div className="mt-4 flex items-center justify-center gap-4">
            <Button
              onClick={handlePrevPage}
              disabled={hasPrev || isLoading}
              variant={"ghost"}
              size={"tinyIcon"}
              aria-label="Previous page"
            >
              {isLoading ? (
                <Loader2 className="h-5 w-5 animate-spin text-gray-500" />
              ) : (
                <ChevronLeft className="h-5 w-5" />
              )}
            </Button>
            <span className="text-sm font-medium text-gray-600 dark:text-gray-300">
              Page {page}
            </span>
            <Button
              onClick={handleNextPage}
              disabled={!hasNext || isLoading}
              variant={"ghost"}
              size={"tinyIcon"}
              aria-label="Next page"
            >
              {isLoading ? (
                <Loader2 className="h-5 w-5 animate-spin text-gray-500" />
              ) : (
                <ChevronRight className="h-5 w-5" />
              )}
            </Button>
          </div>
        )}
      </div>
    </SidebarWidget>
  );
};

export default observer(OutputsWidget);
