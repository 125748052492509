import { observer } from "mobx-react-lite";
import { usePlayground } from "../../playground-store";

const DetectedInputVariables = () => {
  const { detectedInputVariables } = usePlayground();

  if (detectedInputVariables.length === 0) return null;

  return (
    <div className="flex max-w-[250px] flex-row gap-2 overflow-x-auto p-2 [mask-image:linear-gradient(to_right,transparent,black_5%,black_95%,transparent)] ">
      {detectedInputVariables.map((variable) => (
        <span
          key={variable}
          className="flex items-center whitespace-nowrap rounded-full border px-3 py-1 text-sm font-medium text-blue-800"
        >
          {variable}
        </span>
      ))}
    </div>
  );
};

export default observer(DetectedInputVariables);
