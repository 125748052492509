import { ReferenceConfig, ValueConfig } from "@/components/Workflows/types";

import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { useWorkflow } from "@/components/Workflows/WorkflowCanvas/workflow-context";
import { observer } from "mobx-react-lite";
import { useLinkedDependencies } from "../../edge-conditions-context";
import SwitchButton from "./SwitchButton";

interface SourceInputProps {
  source: ReferenceConfig["source"];
  onChange?: (value: ValueConfig) => void;
  onStaticSwitch: (e: React.MouseEvent) => void;
  readonly?: boolean;
}

const SourceInput = ({
  source,
  onChange,
  onStaticSwitch,
  readonly,
}: SourceInputProps) => {
  const workflow = useWorkflow();
  const input_variables = workflow.input_variables;
  const linkedDependencies = useLinkedDependencies();

  return (
    <div className="t-2 flex w-full flex-col gap-2">
      {!readonly && (
        <SwitchButton
          toSource={false}
          onSourceSwitch={() => {}}
          onStaticSwitch={onStaticSwitch}
        />
      )}
      <Select
        value={source.name || undefined}
        onValueChange={(val) =>
          onChange?.({
            source: {
              name: val,
              json_path: source.json_path,
            },
          })
        }
        disabled={readonly}
      >
        <SelectTrigger>
          <SelectValue
            defaultValue=""
            placeholder={!readonly ? "Select an option" : ""}
          />
        </SelectTrigger>
        <SelectContent>
          {linkedDependencies.map((dep) => (
            <SelectItem key={dep} value={dep}>
              {dep}
            </SelectItem>
          ))}
          {input_variables.map(({ key }) => {
            return (
              <SelectItem key={key} value={key}>
                {`{${key}}`}
              </SelectItem>
            );
          })}
        </SelectContent>
      </Select>
    </div>
  );
};

export default observer(SourceInput);
