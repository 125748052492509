import { Button } from "@/components/ui/button";
import {
  CheckIcon,
  PlusIcon,
  SaveIcon,
  TrashIcon,
} from "@heroicons/react/outline";
import { motion } from "framer-motion";

interface VariableControlsProps {
  clearAll: () => void;
  addInputVariable: () => void;
  onClose: () => void;
  isLoading?: boolean;
  useSaveButton?: boolean;
  useClearAll?: boolean;
}

const VariableControls = ({
  clearAll,
  addInputVariable,
  onClose,
  isLoading,
  useSaveButton = false,
  useClearAll = false,
}: VariableControlsProps) => {
  return (
    <motion.div
      className="mt-2 flex flex-row justify-between p-2"
      initial={{ opacity: 0, y: 5 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{
        duration: 0.15,
        ease: "easeOut",
      }}
    >
      {useClearAll ? (
        <Button onClick={clearAll} variant={"destructiveOutline"}>
          <TrashIcon className="mr-2 h-4 w-4" />
          Clear All
        </Button>
      ) : (
        <span />
      )}
      <div className="flex flex-row gap-x-2">
        <Button onClick={() => addInputVariable()} variant={"outline"}>
          <PlusIcon className="mr-2 h-4 w-4" />
          Add Variable
        </Button>
        <Button onClick={onClose} disabled={isLoading}>
          {isLoading ? (
            "Loading..."
          ) : useSaveButton ? (
            <>
              <SaveIcon className="mr-2 h-4 w-4" />
              Save
            </>
          ) : (
            <>
              <CheckIcon className="mr-2 h-4 w-4" />
              Done
            </>
          )}
        </Button>
      </div>
    </motion.div>
  );
};

export default VariableControls;
