import { Switch } from "@/components/switch";
import { usePlayground } from "../../playground-store";

const ModeSwitch = ({ readonly }: { readonly?: boolean }) => {
  const playground = usePlayground();
  return (
    <div className="flex select-none items-center">
      <span className="mr-2 text-sm">Chat</span>
      <Switch
        checked={!playground.isChat}
        disabled={readonly}
        onChange={playground.toggleMode}
      />
      <span className="ml-2 text-sm">Completion</span>
    </div>
  );
};

export default ModeSwitch;
