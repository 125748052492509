import {
  TextareaHTMLAttributes,
  forwardRef,
  useCallback,
  useEffect,
  useRef,
} from "react";

import { cn } from "@/lib/utils";

const useAutoSize = (enabled: boolean = false) => {
  const textareaRef = useRef<HTMLTextAreaElement | null>(null);

  const adjustHeight = useCallback(() => {
    if (enabled && textareaRef.current) {
      // Reset height to allow shrinking
      textareaRef.current.style.height = "0px";
      // Set height to scrollHeight to fit content
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
    }
  }, [enabled]);

  useEffect(() => {
    if (enabled) {
      adjustHeight();
      // Adjust on window resize
      window.addEventListener("resize", adjustHeight);
      return () => window.removeEventListener("resize", adjustHeight);
    }
  }, [enabled, adjustHeight]);

  return {
    textareaRef,
    adjustHeight,
  };
};

export interface TextareaProps
  extends TextareaHTMLAttributes<HTMLTextAreaElement> {
  autoSize?: boolean;
}

const Textarea = forwardRef<HTMLTextAreaElement, TextareaProps>(
  ({ className, autoSize = false, ...props }, ref) => {
    const { textareaRef, adjustHeight } = useAutoSize(autoSize);

    const handleRef = (element: HTMLTextAreaElement | null) => {
      if (typeof ref === "function") {
        ref(element);
      } else if (ref) {
        ref.current = element;
      }
      textareaRef.current = element;
      if (element && autoSize) {
        adjustHeight();
      }
    };

    const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
      if (autoSize) {
        adjustHeight();
      }
      props.onChange?.(e);
    };

    const handleInput = (e: React.FormEvent<HTMLTextAreaElement>) => {
      if (autoSize) {
        adjustHeight();
      }
      props.onInput?.(e);
    };

    const baseClassName =
      "z-10 flex min-h-[60px] w-full rounded-md border border-input bg-transparent bg-white px-3 py-2 text-sm shadow-sm placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:cursor-not-allowed disabled:opacity-50";

    return (
      <textarea
        className={cn(baseClassName, className)}
        ref={handleRef}
        onChange={handleChange}
        onInput={handleInput}
        {...props}
      />
    );
  },
);

Textarea.displayName = "Textarea";

export { Textarea };
