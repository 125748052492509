import LoadingSpinner from "@/components/LoadingSpinner";
import { DivButton } from "@/components/ui/button";
import NotFoundMessage from "@/components/ui/not-found";
import { useAuth } from "@/context/auth-context";
import { useUser } from "@/context/user-context";
import { useCreateFinalReport } from "@/queries";
import { ColumnType, ReportCell, ReportColumn } from "@/types/evaluate";
import { displayErrorToast } from "@/utils/toast";
import { CogIcon, PlusIcon } from "@heroicons/react/outline";
import { TooltipProvider } from "@radix-ui/react-tooltip";
import { ArrowRightCircle } from "lucide-react";
import { useCallback, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ConfigureScoreCardModal from "../ConfigureScoreCardModal";
import { ReportHeader } from "../ReportHeader";
import EvalTableSection from "../TableComponents/EvalTableSection";
import ModalRouter from "../TableComponents/NewColumnModal/ModalRouter";
import { ModalRouterProvider, useModalRouter } from "../modal-router-context";
import { ReportProvider, useReportContext } from "../report-context";
import RunReportModal from "./RunReportModal";

const EditBlueprint = () => {
  // Get blueprint metadata
  const { blueprintId } = useParams();
  const userContext = useUser();

  const reportId = blueprintId ? Number(blueprintId) : null;

  if (!reportId) {
    return (
      <NotFoundMessage
        backLink={`/workspace/${userContext?.activeWorkspaceId}/evaluate`}
        title="Blueprint not found"
        subtitle="Evaluation blueprint does not exist on this workspace."
        backLinkMessage="Go back to Evaluate"
      />
    );
  }

  return (
    <ReportProvider reportId={reportId} editable={true}>
      <ModalRouterProvider>
        <EditBlueprintHelper />
      </ModalRouterProvider>
    </ReportProvider>
  );
};

const EditBlueprintHelper = () => {
  const reportContext = useReportContext();
  const { setIsOpen, isOpen, selectedColumn, setSelectedColumn } =
    useModalRouter();
  const reportId = reportContext.reportId;

  const navigate = useNavigate();
  const auth = useAuth();
  const userContext = useUser();
  const userToken = auth!.userToken!;

  const blueprintMetadata = reportContext.reportMetadata?.report;
  const blueprintIsLoading: boolean = reportContext.reportIsLoading;

  const sortedBlueprintColumns: ReportColumn[] | null =
    reportContext.sortedColumns;
  const blueprintColumnsIsLoading: boolean =
    reportContext.reportColumnsIsLoading;

  const blueprintCellsIsLoading: boolean = reportContext.reportCellsIsLoading;

  const blueprintDatasetInfo = reportContext.datasetInfo;

  const {
    mutateAsync: createFinalReport,
    data: createFinalReportData,
    isLoading: createFinalReportIsLoading,
  } = useCreateFinalReport(userToken);

  const handleBuildReport = useCallback(
    async (reportName: string) => {
      if (reportId) {
        const finalReportName = `${reportName}`;

        try {
          const response = await createFinalReport({
            reportId: reportId,
            name: finalReportName,
          });

          if (response.success) {
            const finalReportId = response.report_id;
            if (finalReportId) {
              navigate(
                `/workspace/${userContext?.activeWorkspaceId}/evaluate/reports/${finalReportId}`,
              );
            }
          } else {
            displayErrorToast(
              response.message ||
                "There was an error creating the final report",
            );
          }
        } catch (error) {
          displayErrorToast(
            "An error occurred while creating the final report",
          );
        }
      }
    },
    [reportId, createFinalReport, navigate, userContext],
  );

  useEffect(() => {
    if (createFinalReportData && createFinalReportData?.success) {
      const finalReportId = createFinalReportData.report_id;
      if (finalReportId) {
        navigate(
          `/workspace/${userContext?.activeWorkspaceId}/evaluate/reports/${finalReportId}`,
        );
      }
    }
  }, [createFinalReportData, navigate, userContext]);

  if (blueprintIsLoading) {
    return <LoadingSpinner />;
  }

  if (!blueprintMetadata) {
    return (
      <NotFoundMessage
        backLink={`/workspace/${userContext?.activeWorkspaceId}/evaluate`}
        title="Blueprint not found"
        subtitle="Evaluation blueprint does not exist on this workspace."
        backLinkMessage="Go back to Evaluate"
      />
    );
  }

  const blueprintCells: ReportCell[] | null = reportContext.reportCells;

  const datasetRows = blueprintDatasetInfo ? blueprintDatasetInfo.total : 0;
  const previewCellCount = blueprintCells?.length;
  const previewColumnCount = sortedBlueprintColumns?.length;
  const previewRows =
    previewCellCount && previewColumnCount
      ? previewCellCount / previewColumnCount
      : 0;

  const hasAddedNonDatasetColumns = !!sortedBlueprintColumns?.some(
    (column) => column.column_type !== ColumnType.DATASET,
  );

  const hiddenRows =
    datasetRows - previewRows > 0 ? datasetRows - previewRows : 0;

  const promptId = blueprintMetadata?.prompt_registry_id;
  const promptVersionNumber = blueprintMetadata?.prompt_version_number;

  return (
    <div className="pb-10">
      <div className="flex pb-5">
        <div className="flex-1">
          <ReportHeader
            createdAt={blueprintMetadata.created_at}
            datasetInfo={blueprintDatasetInfo}
            latestDataset={reportContext.latestDataset}
            isBlueprint={true}
            isDeleted={blueprintMetadata.deleted}
            lastUpdated={blueprintMetadata.updated_at}
            promptId={promptId}
            promptVersionNumber={promptVersionNumber}
            reportId={reportId}
            reportName={blueprintMetadata.name}
          />
        </div>
        <div
          className={`flex flex-row gap-x-2 pb-8 ${
            hasAddedNonDatasetColumns ? "" : "hidden"
          }`}
        >
          <div className={`flex flex-col justify-end`}>
            <ConfigureScoreCardModal>
              <DivButton
                variant="outline"
                size={"lg"}
                isLoading={createFinalReportIsLoading}
                className={`gap-x-1`}
              >
                <CogIcon className="inline-block h-4 w-4" />
                Score Card
              </DivButton>
            </ConfigureScoreCardModal>
          </div>
          <div className="flex flex-col justify-end">
            <RunReportModal
              handleBuildReport={handleBuildReport}
              createFinalReportIsLoading={createFinalReportIsLoading}
              blueprintName={blueprintMetadata.name}
            >
              <DivButton
                variant={"secondaryLightOutline"}
                size={"lg"}
                isLoading={createFinalReportIsLoading}
              >
                Run Full Batch{" "}
                <ArrowRightCircle className="ml-1 inline-block h-4 w-4" />
              </DivButton>
            </RunReportModal>
          </div>
        </div>
      </div>
      {blueprintColumnsIsLoading || blueprintCellsIsLoading ? (
        <LoadingSpinner />
      ) : (
        <>
          <div className="flex gap-x-4">
            <div className="flex-1 overflow-x-auto">
              <TooltipProvider>
                <EvalTableSection />
              </TooltipProvider>
            </div>
            <div>
              <ModalRouter
                availableColumns={sortedBlueprintColumns || []}
                reportId={reportId}
                oldColumn={selectedColumn!}
                open={isOpen}
                onOpenChange={setIsOpen}
              />
              <DivButton
                variant={"default"}
                onClick={() => {
                  setIsOpen(true);
                  setSelectedColumn(null);
                }}
              >
                <div className="flex flex-col text-center text-xs">
                  <PlusIcon className="mx-auto inline-block h-12 w-12 font-light" />
                  <span>Add Step</span>
                </div>
              </DivButton>
            </div>
          </div>
          {hiddenRows > 0 && (
            <div className="pt-2 text-center text-xs text-gray-400">
              {`${hiddenRows} ${
                hiddenRows === 1 ? "row" : "rows"
              } hidden from preview. Run full batch to see all results.`}
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default EditBlueprint;
