import { useReportContext } from "@/components/Evaluate/report-context";
import { useAuth } from "@/context/auth-context";

import { usePatchReportCell as usePatchReportCellQuery } from "@/queries";
import { displayErrorToast } from "@/utils/toast";

export const usePatchReportCell = (cellId: number) => {
  const authContext = useAuth();
  const reportContext = useReportContext();
  const { mutate, isLoading } = usePatchReportCellQuery(
    authContext!.userToken!,
    reportContext?.report?.id,
    cellId,
  );

  return {
    patchReportCell: (data: any) =>
      mutate(data, { onError: (e) => displayErrorToast(e) }),
    isLoading,
  };
};
