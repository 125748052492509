import { PhotographIcon } from "@heroicons/react/outline";
import { useState } from "react";

interface DropZoneProps {
  isLoading: boolean;
  onDrop: (file: File) => void;
  onClick: () => void;
  children: React.ReactNode;
}

const DropZone = ({ isLoading, onDrop, onClick, children }: DropZoneProps) => {
  const [isDragging, setIsDragging] = useState(false);

  return (
    <div
      className={`flex h-10 w-full items-center justify-center rounded-lg border border-dashed px-2 transition-colors ${
        isLoading
          ? "cursor-wait border-gray-300"
          : isDragging
          ? "border-blue-500 bg-blue-50"
          : "cursor-pointer border-gray-300 hover:border-gray-400"
      }`}
      onDrop={(e) => {
        e.preventDefault();
        setIsDragging(false);
        if (!isLoading) {
          onDrop(e.dataTransfer.files[0]);
        }
      }}
      onDragOver={(e) => {
        e.preventDefault();
        if (!isLoading && !isDragging) {
          setIsDragging(true);
        }
      }}
      onDragLeave={() => setIsDragging(false)}
      onClick={onClick}
    >
      {children}
      <PhotographIcon
        className={`ml-2 h-5 w-5 ${
          isDragging ? "text-blue-500" : "text-gray-400"
        }`}
      />
    </div>
  );
};

export default DropZone;
