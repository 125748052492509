import { observer } from "mobx-react-lite";
import { useState } from "react";
import { ChevronDownIcon } from "@heroicons/react/outline";

import { Button } from "@/components/ui/button";

import { AdvancedControls } from "../AdvancedParameters";
import { useParameters } from "../store";

export const Controls = observer(() => {
  const store = useParameters();
  const [showAdvancedControls, setShowAdvancedControls] = useState(false);
  const model = store.modelName;
  const hasAdvancedControls = model || store.inferenceClientName;

  return (
    <div>
      {hasAdvancedControls ? (
        <div className="mb-4 mt-2 flex justify-end">
          <Button
            variant="link"
            onClick={() => setShowAdvancedControls(!showAdvancedControls)}
            className="flex items-center text-xs text-blue-500 hover:text-blue-700 focus:outline-none"
          >
            {showAdvancedControls ? "Hide" : "Show"} Advanced Controls
            <ChevronDownIcon
              className={`ml-1 h-4 w-4 transform ${
                showAdvancedControls ? "rotate-180" : ""
              }`}
            />
          </Button>
        </div>
      ) : null}
      {showAdvancedControls && hasAdvancedControls && <AdvancedControls />}
    </div>
  );
});
