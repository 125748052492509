import { useState } from "react";
import Checkbox from "../checkbox";

const DateRangeFilter = ({
  onChange,
  initialStartDate = "",
  initialEndDate = "",
}: {
  onChange: (startDate: string, endDate: string) => void;
  onDateRangeDefaultChange?: (enabled: boolean) => void;
  initialStartDate?: string;
  initialEndDate?: string;
}) => {
  const [type, setType] = useState<"date" | "datetime-local">("date");
  const [startDate, setStartDate] = useState(initialStartDate);
  const [endDate, setEndDate] = useState(initialEndDate);

  const handleDateChange = (type: "start" | "end", value: string) => {
    if (type === "start") {
      setStartDate(value);
    } else {
      setEndDate(value);
    }
    onChange(
      type === "start" ? value : startDate,
      type === "end" ? value : endDate,
    );
  };

  return (
    <div>
      <label className="text-sm font-medium text-gray-700">Date Range</label>
      <div className="mt-2 flex flex-row gap-4">
        <div className="flex min-w-0 flex-1 flex-col">
          <label htmlFor="start-date" className="text-xs text-gray-500">
            Start
          </label>
          <input
            type={type}
            name="start-date"
            id="start-date"
            className="h-8 w-full rounded-md border-gray-300 text-xs shadow-sm focus:border-blue-500 focus:ring-blue-500"
            value={startDate}
            onChange={(e) => handleDateChange("start", e.target.value)}
            pattern="\d{2}/\d{2}/\d{2}"
            placeholder="MM/DD/YY"
          />
        </div>
        <div className="flex min-w-0 flex-1 flex-col">
          <label htmlFor="end-date" className="text-xs text-gray-500">
            End
          </label>
          <input
            type={type}
            name="end-date"
            id="end-date"
            className="h-8 w-full rounded-md border-gray-300 text-xs shadow-sm focus:border-blue-500 focus:ring-blue-500"
            value={endDate}
            onChange={(e) => handleDateChange("end", e.target.value)}
            pattern="\d{2}/\d{2}/\d{2}"
            placeholder="MM/DD/YY"
          />
        </div>
      </div>
      <div className="mt-2 flex flex-row items-end gap-4">
        <Checkbox
          checked={type === "datetime-local"}
          label="Datetime"
          onClick={() =>
            setType(type === "datetime-local" ? "date" : "datetime-local")
          }
        />
      </div>
    </div>
  );
};

export default DateRangeFilter;
