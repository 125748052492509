export enum ComponentType {
  SLIDER = "slider",
  INPUT = "input",
  DROPDOWN = "dropdown",
  GROUP = "group",
}

export interface Provider {
  provider_name: string;
  model_configs: Model[];
}

export interface Model {
  llm_model_name: string;
  display_name?: string;
  is_chat: boolean;
  is_internal: boolean;
  params: Parameter[];
}

export interface Option {
  display_name: string;
  value: any;
}

export interface ParameterBase {
  param_id: string;
  name: string;
  component_type: string;
  default: any;
  placeholder?: string;
  value?: any;
  is_disabled?: boolean;
  is_json?: boolean;
  options?: Option[];
  required?: boolean;
  is_custom?: boolean;
}

export interface DropdownParam extends ParameterBase {
  component_type: ComponentType.DROPDOWN;
  is_json?: boolean;
  options?: Option[];
}

export interface SliderParam extends ParameterBase {
  component_type: ComponentType.SLIDER;
  max?: number;
  min?: number;
}

export interface InputParam extends ParameterBase {
  component_type: ComponentType.INPUT;
  max?: number;
  min?: number;
  is_int?: boolean;
}

export interface GroupParam extends ParameterBase {
  component_type: ComponentType.GROUP;
  parameters: Parameter[];
}

export type Parameter = DropdownParam | SliderParam | InputParam | GroupParam;

export type ModelsResponse = Provider[];
