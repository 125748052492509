import { BasicTooltip } from "@/components/ui/Tooltip";
import { DivButton } from "@/components/ui/button";
import { XIcon } from "lucide-react";

const MediaVariable = ({
  mediaVariable,
  onClear,
  readonly = false,
}: {
  mediaVariable: string;
  onClear: () => void;
  readonly?: boolean;
}) => (
  <div className="z-10 flex max-w-[33%] items-center gap-2 rounded-lg border border-gray-200 bg-gray-50 px-2 py-1.5 transition-colors hover:bg-gray-100">
    <div className="min-w-0 flex-1">
      <BasicTooltip content={`{${mediaVariable}}`}>
        <span className="block cursor-pointer select-all truncate font-mono text-xs">{`{${mediaVariable}}`}</span>
      </BasicTooltip>
    </div>
    {!readonly && (
      <BasicTooltip content="Remove variable">
        <DivButton
          variant="ghost"
          size="sm"
          className="h-4 w-4 shrink-0 p-0 transition-colors hover:bg-red-100 hover:text-red-600"
          onClick={onClear}
        >
          <XIcon className="h-3 w-3" />
        </DivButton>
      </BasicTooltip>
    )}
  </div>
);

export default MediaVariable;
