import { observer } from "mobx-react-lite";
import { useParams } from "react-router-dom";
import LoadingSpinner from "../LoadingSpinner";
import usePlaygroundSession from "./hooks/usePlaygroundSession";
import { usePlayground } from "./playground-store";
import PlaygroundCore from "./PlaygroundCore";

const PlaygroundWithSession = () => {
  const { sessionId } = useParams();
  const playground = usePlayground();
  usePlaygroundSession();
  if (playground.isPendingSessionInitialization || !sessionId) {
    return (
      <div className="flex h-full w-full items-center justify-center">
        <div className="flex flex-col items-center gap-4">
          <LoadingSpinner />
          <div className="text-lg font-medium text-gray-700">
            Initializing playground...
          </div>
        </div>
      </div>
    );
  }
  return <PlaygroundCore />;
};

export default observer(PlaygroundWithSession);
