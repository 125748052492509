import { DatasetSelect } from "@/components/Widgets/types/ExamplesWidget/selectors";
import { Dataset } from "@/components/Widgets/types/ExamplesWidget/types";
import { useAuth } from "@/context/auth-context";
import { useUser } from "@/context/user-context";
import { useDatasetGroups } from "@/queries";
import { useEffect, useState } from "react";

const useDatasetVersionSelection = () => {
  const [selectedDataset, setSelectedDataset] = useState<string>("");

  return {
    selectedDataset,
    setSelectedDataset,
  };
};

interface DatasetPickerProps {
  onSelect?: (datasetId: number) => void;
}

const DatasetPicker = ({ onSelect }: DatasetPickerProps) => {
  const { selectedDataset, setSelectedDataset } = useDatasetVersionSelection();

  const { data, isLoading } = useDatasetGroups(
    useAuth()?.userToken!,
    Number(useUser()?.activeWorkspaceId),
  );
  const dataset_groups = data?.dataset_groups || [];

  const selectedDatasetId = dataset_groups
    .find(({ name }: { name: string }) => name === selectedDataset)
    ?.datasets.sort(
      (a: Dataset, b: Dataset) => b.version_number - a.version_number,
    )[0]?.id;

  useEffect(() => {
    if (selectedDataset && onSelect && selectedDatasetId) {
      onSelect(selectedDatasetId);
    }
  }, [selectedDataset, selectedDatasetId, onSelect]);

  if (isLoading) return <div>Loading...</div>;

  return (
    <div className="flex gap-2">
      <DatasetSelect
        autoFocus
        datasetGroups={dataset_groups}
        selectedDataset={selectedDataset}
        setSelectedDataset={setSelectedDataset}
        datasetId={selectedDatasetId}
      />
    </div>
  );
};

export default DatasetPicker;
