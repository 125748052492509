import { Button } from "@/components/ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import ToggleSwitch from "@/components/ui/toggle-switch";
import { WorkflowInfo } from "@/components/Workflows/types";
import { useAuth } from "@/context/auth-context";
import { useUser } from "@/context/user-context";
import {
  useWorkflowVersions as useBaseWorkflowVersions,
  useWorkflows,
} from "@/queries";
import { ReportColumn, WorkflowConfiguration } from "@/types/evaluate";
import { formatInputVariable } from "@/utils/evaluate";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useBandaid } from "../utils/BandaidContext";
import { ModalStep } from "./ModalRouter";
import SourceName from "./SourceName";

const WorkflowColumnBuilder = ({
  columnData,
  patchColumnData,
  navigatePrevious,
  saveColumnAction,
  editable,
  availableColumns,
}: {
  columnData: Partial<ReportColumn>;
  patchColumnData: (
    data:
      | Partial<ReportColumn>
      | ((prevState: Partial<ReportColumn>) => Partial<ReportColumn>),
  ) => void;
  availableColumns: ReportColumn[];
  navigatePrevious: () => void;
  saveColumnAction: (newColumnDataArg: ReportColumn) => void;
  editable: boolean;
  minimal?: boolean;
}) => {
  const [name, setName] = useState(columnData.name ?? "");
  const [workflowName, setWorkflowName] = useState(
    columnData.configuration?.name ?? "",
  );
  const [version, setVersion] = useState(
    columnData.configuration?.workflow_version_number,
  );
  const [label, setLabel] = useState(
    columnData.configuration?.workflow_label_name,
  );
  const [returnAllOutputs, setReturnAllOutputs] = useState(
    columnData.configuration?.return_all_outputs ?? false,
  );

  useEffect(() => {
    if (!label) return;
    setVersion(undefined);
  }, [label, setVersion]);

  useEffect(() => {
    if (!version) return;
    setLabel(undefined);
  }, [version, setLabel]);

  const [inputVariables, setInputVariables] = useState(
    columnData.configuration?.input_variables ?? {},
  );
  const configuration: WorkflowConfiguration = useMemo(() => {
    return columnData.configuration || {};
  }, [columnData]);

  const saveAction = () => {
    saveColumnAction({
      ...columnData,
      name,
      configuration: {
        name: workflowName,
        workflow_version_number: version,
        workflow_label_name: label,
        input_variables: inputVariables,
        return_all_outputs: returnAllOutputs,
      } as WorkflowConfiguration,
    } as ReportColumn);
  };

  const setConfiguration = (configuration: WorkflowConfiguration) => {
    patchColumnData({ configuration });
  };

  const bandaid = useBandaid();
  const userContext = useUser();
  const auth = useAuth();
  const userToken = auth?.userToken!;
  const workspaceId = userContext.activeWorkspaceId!;

  const workflowSet = useWorkflows(workspaceId.toString(), userToken);

  const availableWorkflows =
    workflowSet.data?.pages.flatMap((page) => page.workflows) ?? [];

  const [usingLabel, setUsingLabel] = useState<boolean>(
    !!getSelectedLabel(configuration),
  );

  const selectedWorkflow = availableWorkflows.find(
    (workflow) => workflow.name === workflowName,
  );

  const handleWorkflowNameSelection = (name: string) => {
    setWorkflowName(name);
  };

  const handleVersionLabelToggle = (value: string) => {
    const newUsingLabel = value === "Label";
    setUsingLabel(newUsingLabel);
  };
  return (
    <ModalStep
      navigatePrevious={navigatePrevious}
      navigateNext={saveAction}
      nextButtonText={editable ? "Save Step" : "Done"}
    >
      <div className="text-lg font-semibold">Run Agent</div>
      <div className="max-w-md text-sm text-gray-500">
        This step will run an agent and return the output.
      </div>
      <div className="my-2 flex w-full flex-row items-center justify-between gap-x-6">
        <SourceName
          name={name!}
          columnCount={availableColumns.length}
          isValid={true}
          setName={setName}
          isNode={bandaid}
          readonly={!editable}
          variant="AGENT"
        />
      </div>
      <div className="mb-4 ml-0">
        <div className="flex items-center gap-2">
          <input
            type="checkbox"
            id="return-all-outputs"
            checked={returnAllOutputs}
            onChange={(e) => setReturnAllOutputs(e.target.checked)}
            disabled={!editable}
            className="h-4 w-4 cursor-pointer rounded border-gray-300 text-blue-600 focus:ring-2 focus:ring-blue-500 disabled:cursor-not-allowed disabled:opacity-50"
          />
          <label
            htmlFor="return-all-outputs"
            className={`cursor-pointer pb-0.5 text-sm font-medium text-gray-900 ${
              !editable ? "opacity-50" : ""
            }`}
          >
            Return All Nodes
          </label>
        </div>
        <div className="pl-6 text-xs text-gray-500">
          Returns the output of all nodes, including failed ones. Commonly used
          for debugging execution issues.
        </div>
      </div>

      <div className="rounded-md border border-gray-200 bg-gray-50 p-3">
        <div className="flex flex-col">
          <div className={`mb-4 md:mb-0`}>
            <div className="mb-4">
              <label
                htmlFor="prompt-select"
                className="mb-2 block text-sm font-medium text-gray-700"
              >
                Agent
              </label>
              <div className="relative">
                <SelectWorkflow
                  selectedWorkflowName={workflowName}
                  handleWorkflowSelection={({ name }) =>
                    handleWorkflowNameSelection(name)
                  }
                  disabled={!editable}
                />
              </div>
            </div>
          </div>
          {selectedWorkflow ? (
            <div className="flex justify-center">
              <ToggleSwitch
                left="Version"
                right="Label"
                onChange={handleVersionLabelToggle}
                value={usingLabel ? "Label" : "Version"}
                disabled={!editable}
              />
            </div>
          ) : null}
          {usingLabel && !!selectedWorkflow ? (
            <SelectLabel
              selectedWorkflow={selectedWorkflow}
              editable={editable}
              label={label}
              setLabel={setLabel}
            />
          ) : null}
          {!usingLabel && !!selectedWorkflow ? (
            <SelectVersion
              selectedWorkflow={selectedWorkflow}
              editable={editable}
              version={version}
              setVersion={setVersion}
            />
          ) : null}
          {selectedWorkflow ? (
            <SetInputVariables
              availableColumns={availableColumns}
              configuration={configuration}
              editable={editable}
              selectedWorkflow={selectedWorkflow}
              setInputVariables={setInputVariables}
              setConfiguration={setConfiguration}
              label={label}
              version={version}
              inputVariables={inputVariables}
            />
          ) : null}
        </div>
      </div>
    </ModalStep>
  );
};

const getSelectedLabel = (configuration: WorkflowConfiguration) => {
  return configuration.workflow_label_name ?? "";
};

const SelectWorkflow = ({
  selectedWorkflowName,
  handleWorkflowSelection,
  disabled = false,
}: {
  selectedWorkflowName: string | undefined;
  handleWorkflowSelection: ({ id, name }: { id: number; name: string }) => void;
  disabled?: boolean;
}) => {
  const userContext = useUser();
  const auth = useAuth();
  const userToken = auth?.userToken!;
  const workspaceId = userContext.activeWorkspaceId!;
  const workflowSet = useWorkflows(workspaceId.toString(), userToken);

  const availableWorkflows =
    workflowSet.data?.pages.flatMap((page) => page.workflows) ?? [];
  return (
    <DropdownMenu>
      <DropdownMenuTrigger disabled={disabled} className="w-full">
        {selectedWorkflowName || (
          <span className="font-normal text-gray-500">
            {availableWorkflows?.length > 0
              ? "Select an agent..."
              : "No agents found"}
          </span>
        )}
      </DropdownMenuTrigger>
      <DropdownMenuContent>
        {availableWorkflows.map((workflow) => (
          <DropdownMenuItem
            key={workflow.id}
            onSelect={() =>
              handleWorkflowSelection({
                id: workflow.id,
                name: workflow.name,
              })
            }
          >
            {workflow.name}
          </DropdownMenuItem>
        ))}
      </DropdownMenuContent>
    </DropdownMenu>
  );
};

interface SelectLabelProps {
  selectedWorkflow: WorkflowInfo;
  editable: boolean;
  label?: string;
  setLabel: (label?: string) => void;
}

const SelectLabel = ({
  selectedWorkflow,
  editable,
  label,
  setLabel,
}: SelectLabelProps) => {
  const workflowLabels = useWorkflowLabels(selectedWorkflow);
  const handleLabelSelection = (label: string) => {
    setLabel(label);
  };

  if (workflowLabels.length === 0) return <div>No labels found</div>;
  return (
    <div className={`mb-4 md:mb-0`}>
      <div className="mb-4">
        <label
          htmlFor="label-select"
          className="mb-2 block text-sm font-medium text-gray-700"
        >
          Label
        </label>
        <div className="relative">
          <DropdownMenu>
            <DropdownMenuTrigger disabled={!editable} className="w-full">
              {label || (
                <span className="font-normal text-gray-500">
                  Select a label...
                </span>
              )}
            </DropdownMenuTrigger>
            <DropdownMenuContent>
              {workflowLabels.map((label) => (
                <DropdownMenuItem
                  key={label.id}
                  onSelect={() => handleLabelSelection(label.name)}
                >
                  {label.name}
                </DropdownMenuItem>
              ))}
            </DropdownMenuContent>
          </DropdownMenu>
        </div>
      </div>
    </div>
  );
};

interface SelectVersionProps {
  selectedWorkflow: WorkflowInfo;
  version?: number;
  setVersion: (version?: number) => void;
  editable: boolean;
}

const SelectVersion = ({
  selectedWorkflow,
  editable,
  version,
  setVersion,
}: SelectVersionProps) => {
  const workflowVersions = useWorkflowVersions(selectedWorkflow);

  const handleVersionSelection = (newVersion: string | number) => {
    let newVersionNumber: number | undefined = undefined;
    if (newVersion === "latest") {
      newVersionNumber = undefined;
    } else {
      newVersionNumber = Number(newVersion);
    }
    setVersion(newVersionNumber ?? undefined);
  };

  return (
    <div className={`mb-4 md:mb-0`}>
      <div className="mb-4">
        <label
          htmlFor="version-select"
          className="mb-2 block text-sm font-medium text-gray-700"
        >
          Version
        </label>
        <div className="relative">
          <DropdownMenu>
            <DropdownMenuTrigger disabled={!editable} className="w-full">
              {version ?? "default"}
            </DropdownMenuTrigger>
            <DropdownMenuContent>
              <DropdownMenuItem
                key={"latest"}
                onSelect={() => handleVersionSelection("latest")}
              >
                default
              </DropdownMenuItem>
              {workflowVersions.map((version) => (
                <DropdownMenuItem
                  key={version.number}
                  onSelect={() => handleVersionSelection(version.number)}
                >
                  {version.number}
                </DropdownMenuItem>
              ))}
            </DropdownMenuContent>
          </DropdownMenu>
        </div>
      </div>
    </div>
  );
};

interface SetInputVariablesProps {
  selectedWorkflow: WorkflowInfo;
  inputVariables: Record<string, any>;
  setInputVariables: (inputVariables: Record<string, unknown>) => void;
  editable: boolean;
  configuration: WorkflowConfiguration;
  availableColumns: ReportColumn[];
  setConfiguration: (configuration: WorkflowConfiguration) => void;
  label?: string;
  version?: number;
}

const SetInputVariables = ({
  availableColumns,
  editable,
  setConfiguration,
  selectedWorkflow,
  label,
  version,
  inputVariables,
  setInputVariables,
}: SetInputVariablesProps) => {
  const bandaid = useBandaid();
  const selectedWorkflowVersion = useSelectedWorkflowVersion(
    selectedWorkflow,
    label,
    version,
  );

  const onUpdateInputVariableMapping = useCallback(
    (inputVariable: string, columnName: string) => {
      const newInputVariableMapping = {
        ...(inputVariables || {}),
        [inputVariable]: columnName,
      };
      setInputVariables(newInputVariableMapping);
    },
    [inputVariables, setInputVariables],
  );

  if (!selectedWorkflowVersion) return null;

  const fillInputVariables = () => {
    const newMappings = {
      ...(inputVariables || {}),
    };
    Object.keys(selectedWorkflowVersion.required_input_variables).forEach(
      (variable) => {
        const matchingColumn = availableColumns.find(
          (column) =>
            column.name === variable ||
            column.name === `variable.${variable}` ||
            column.name === `metadata.${variable}`,
        );
        if (matchingColumn) {
          newMappings[variable] = matchingColumn.name;
        }
      },
    );
    setInputVariables(newMappings);
  };

  if (
    Object.keys(selectedWorkflowVersion.required_input_variables).length === 0
  )
    return (
      <div className="col-span-2 rounded-md border border-gray-200 bg-gray-50 p-3">
        <div className="text-sm text-gray-500">
          {`This template does not have any input variables. Please add {variables} using f-string or jinja.`}
        </div>
      </div>
    );

  return (
    <div className="col-span-2 mx-3 rounded-md border border-gray-200 bg-gray-50">
      <div className="text-right">
        <Button
          onClick={fillInputVariables}
          variant="link"
          size="sm"
          className="inline-flex items-center"
        >
          Click to auto-fill ✨
        </Button>
      </div>
      <div className="max-h-96 overflow-y-auto px-5 py-2">
        {Object.keys(selectedWorkflowVersion.required_input_variables).map(
          (variable) => (
            <div
              className="grid grid-cols-3 items-center space-x-2 space-y-2"
              key={`var-${variable}`}
            >
              <div className="col-span-1 mr-2 mt-2 break-all text-right font-mono text-sm text-gray-600">
                {`{${variable}}`}
              </div>
              <div className="col-span-2 w-full">
                <DropdownMenu>
                  <DropdownMenuTrigger disabled={!editable} className="w-full">
                    {inputVariables[variable] || (
                      <span className="font-normal text-gray-500">
                        Select a {bandaid ? "node" : "column"}...
                      </span>
                    )}
                  </DropdownMenuTrigger>
                  <DropdownMenuContent>
                    {availableColumns.map((column) => (
                      <DropdownMenuItem
                        key={column.name}
                        onSelect={() =>
                          onUpdateInputVariableMapping(variable, column.name)
                        }
                      >
                        {formatInputVariable(column.column_type, column.name)}
                      </DropdownMenuItem>
                    ))}
                  </DropdownMenuContent>
                </DropdownMenu>
              </div>
            </div>
          ),
        )}
      </div>
    </div>
  );
};

const useWorkflowVersions = (workflow: WorkflowInfo) => {
  const auth = useAuth();
  const userToken = auth?.userToken!;
  const workflowVersionsQuery = useBaseWorkflowVersions(
    workflow.id.toString(),
    userToken,
  );
  return (
    workflowVersionsQuery.data?.workflow_versions?.flatMap((page) => page) ?? []
  );
};

const useWorkflowLabels = (workflow: WorkflowInfo) => {
  const workflowVersions = useWorkflowVersions(workflow);
  return workflowVersions.flatMap((version) => version.labels);
};

const useSelectedWorkflowVersion = (
  workflow: WorkflowInfo,
  label?: string,
  version?: number,
) => {
  const workflowVersions = useWorkflowVersions(workflow);
  const latestVersion = workflowVersions
    .sort((a, b) => b.number - a.number)
    .at(0);
  if (label)
    return (
      workflowVersions.find((version) =>
        version.labels.some((l) => l.name === label),
      ) ?? latestVersion
    );
  return workflowVersions.find((v) => v.number === version) ?? latestVersion;
};

export default WorkflowColumnBuilder;
