import { Provider } from "@/queries/models";
import { PromptVersion } from "@/types";
import { ParameterStore } from "@/components/ModelParameters/store/parameter-store";
import { Parameter } from "@/queries/models";

import { HUGGINGFACE_PROVIDER } from "../../constants";
import {
  getHuggingFaceModel,
  mapInitialDataWithExistingParams,
} from "../../utils";

export const getParameters = (
  promptData: Partial<PromptVersion> | null,
  models: Provider[],
  isPromptChat?: boolean,
): ParameterStore[] => {
  const provider = promptData?.metadata?.model?.provider;

  const providerExists =
    provider && models && models.some((p) => p.provider_name === provider);

  if (!providerExists) {
    return [];
  }

  let model = models
    .find((p) => p.provider_name === provider)
    ?.model_configs.find(
      (m) => m.llm_model_name === promptData?.metadata?.model?.name,
    );

  if (provider === HUGGINGFACE_PROVIDER) {
    model = getHuggingFaceModel(models, isPromptChat);
  }

  const promptDataWithParams = mapInitialDataWithExistingParams(
    promptData,
    model?.params || [],
  );

  return promptDataWithParams?.metadata?.model?.parameters
    .filter((param: Parameter) => !param.is_disabled)
    .map((param: Parameter) => {
      const defaultParamsKeys = model?.params?.map((p) => p.param_id) || [];

      return new ParameterStore({
        ...param,
        is_custom: !defaultParamsKeys.includes(param.param_id),
      });
    });
};
