export const PROVIDERS = [
  {
    apiName: "openai",
    displayName: "OpenAI",
  },
  {
    apiName: "openai.azure",
    displayName: "OpenAI Azure",
  },
  {
    apiName: "anthropic",
    displayName: "Anthropic",
  },
  {
    apiName: "anthropic.bedrock",
    displayName: "Anthropic Bedrock",
  },
  {
    apiName: "google",
    displayName: "Google",
  },
  {
    apiName: "huggingface",
    displayName: "Hugging Face",
  },
  {
    apiName: "cohere",
    displayName: "Cohere",
  },
  {
    apiName: "mistral",
    displayName: "Mistral",
  },
];
