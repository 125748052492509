import { ENDPOINTS } from "@/api/application-api";
import { MobxMutation } from "@/queries/mobx/mobx-mutation";
import { handleResponse } from "@/utils/errorResponseHandlers";
import { jsonAuthHeaders } from "@/utils/headers";
import { QueryClient } from "@tanstack/react-query";
import { EdgeCondition, WorkflowNode, WorkflowSchema } from "../types";

export type WorkflowMutations = {
  save: MobxMutation<
    SaveWorkflowMutationResponse,
    unknown,
    { is_existing: boolean; workflowSchema: WorkflowSchema }
  >;
  execute: MobxMutation<
    ExecuteWorkflowResponse,
    unknown,
    ExecuteWorkflowRequest
  >;
  saveNode: MobxMutation<SaveSingleNodeResponse, unknown, WorkflowNode>;
  delete: MobxMutation<DeleteWorkflowResponse, unknown, DeleteWorkflowRequest>;
  playFromHere: MobxMutation<
    PlayFromHereResponse,
    unknown,
    PlayFromHereRequest
  >;
  addReleaseLabel: MobxMutation<
    AddWorkflowLabelResponse,
    unknown,
    AddWorkflowLabelRequest
  >;
  removeReleaseLabel: MobxMutation<
    DeleteWorkflowLabelResponse,
    unknown,
    DeleteWorkflowLabelRequest
  >;
  updateName: MobxMutation<
    UpdateWorkflowNameResponse,
    unknown,
    UpdateWorkflowNameRequest
  >;
  updateLabel: MobxMutation<
    UpdateWorkflowLabelResponse,
    unknown,
    UpdateWorkflowLabelRequest
  >;
  createEdge: MobxMutation<WorkflowEdgeResponse, unknown, WorkflowEdgeRequest>;
  deleteEdge: MobxMutation<
    DeleteWorkflowEdgeResponse,
    unknown,
    DeleteWorkflowEdgeRequest
  >;
};

export interface SaveWorkflowMutationResponse {
  success: boolean;
  error?: string;
  message: { loc: string[]; msg: string; type: string }[] | string;
  workflow_id?: number;
  workflow_version_id?: number;
}

export type WorkflowEdgeResponse = {
  success: boolean;
  edge: {
    id: string;
    workflow_version_id: number;
    source_node_name: string;
    target_node_name: string;
    is_and: boolean;
  };
  conditionals: {
    id: string;
    edge_id: string;
    position: number;
    left_config: {
      type: string;
      source: {
        name: string;
        json_path: string;
      };
    };
    operator: string;
    right_config: {
      type: string;
      static_value: string;
    };
  }[];
};

export type WorkflowEdgeRequest = {
  id: string;
  workflow_version_id: number;
  source_node_name: string;
  target_node_name: string;
  is_and: boolean;
  conditionals: Omit<EdgeCondition, "id">[];
};

export const createSaveMutation = (
  userToken: string,
  queryClient: QueryClient,
) =>
  new MobxMutation<
    SaveWorkflowMutationResponse,
    unknown,
    { is_existing: boolean; workflowSchema: WorkflowSchema }
  >(
    {
      mutationFn: async (variables) => {
        const response = await fetch(`${ENDPOINTS.workflow_versions}`, {
          method: "POST",
          headers: jsonAuthHeaders(userToken),
          body: JSON.stringify({
            ...variables.workflowSchema,
            ...(variables.is_existing ? { name: undefined } : {}),
            is_existing: variables.is_existing,
          }),
        });
        return handleResponse(response);
      },
    },
    queryClient,
  );

export const createWorkflowEdgeMutation = (
  userToken: string,
  workspace_id: number,
  queryClient: QueryClient,
) =>
  new MobxMutation<WorkflowEdgeResponse, unknown, WorkflowEdgeRequest>(
    {
      mutationFn: async (variables) => {
        const response = await fetch(
          `${ENDPOINTS.get_workflow_edges(workspace_id)}`,
          {
            method: "PUT",
            headers: jsonAuthHeaders(userToken),
            body: JSON.stringify(variables),
          },
        );
        return handleResponse(response);
      },
      onSuccess: (res) => {
        if (!res.success) {
          console.error("Error creating workflow edge");
        }
        queryClient.invalidateQueries([
          ENDPOINTS.get_workflow_edges(workspace_id),
        ]);
      },
      onError: (error) => {
        console.log("Error creating workflow edge", error);
      },
    },
    queryClient,
  );

export type DeleteWorkflowEdgeResponse = {
  success: boolean;
  message: string;
};

export type DeleteWorkflowEdgeRequest = {
  edge_id: string;
};

export const createDeleteWorkflowEdgeMutation = (
  userToken: string,
  workspace_id: number,
  queryClient: QueryClient,
) =>
  new MobxMutation<
    DeleteWorkflowEdgeResponse,
    unknown,
    DeleteWorkflowEdgeRequest
  >(
    {
      mutationFn: async (variables) => {
        const response = await fetch(
          `${ENDPOINTS.get_workflow_edges(workspace_id)}/${variables.edge_id}`,
          {
            method: "DELETE",
            headers: jsonAuthHeaders(userToken),
          },
        );
        return handleResponse(response);
      },
      onSuccess: (res) => {
        if (!res.success) {
          console.error("Error deleting workflow edge");
        }
        queryClient.invalidateQueries([
          ENDPOINTS.get_workflow_edges(workspace_id),
        ]);
      },
      onError: (error) => {
        console.log("Error deleting workflow edge", error);
      },
    },
    queryClient,
  );

export type UpdateWorkflowNameResponse = {
  success: boolean;
  workflow: {
    id: number;
    is_deleted: boolean;
    is_draft: boolean;
    name: string;
    workspace_id: number;
  };
};

export type UpdateWorkflowNameRequest = {
  id: number;
  name: string;
};

export const createUpdateWorkflowNameMutation = (
  userToken: string,
  queryClient: QueryClient,
) =>
  new MobxMutation<
    UpdateWorkflowNameResponse,
    unknown,
    UpdateWorkflowNameRequest
  >(
    {
      mutationFn: async ({ id, name }) => {
        const response = await fetch(`${ENDPOINTS.workflows}/${id}`, {
          method: "PATCH",
          headers: jsonAuthHeaders(userToken),
          body: JSON.stringify({ name }),
        });
        return handleResponse(response);
      },
      onSuccess: (res) => {
        if (!res.success) {
          console.error("Error updating workflow");
        }
        queryClient.invalidateQueries([ENDPOINTS.workflows]);
      },
      onError: (error) => {
        console.log("Error updating workflow", error);
      },
    },
    queryClient,
  );

export type ExecuteWorkflowResponse = unknown;

export type ExecuteWorkflowRequest = {
  workflow_id: number;
  workflow_version_id: number;
  input_variables: Record<string, string | object>;
};

export const createExecuteWorkflowMutation = (
  userToken: string,
  queryClient: QueryClient,
) =>
  new MobxMutation<ExecuteWorkflowResponse, unknown, ExecuteWorkflowRequest>(
    {
      mutationFn: async ({
        workflow_id,
        workflow_version_id,
        input_variables,
      }) => {
        const response = await fetch(
          `${ENDPOINTS.workflow_versions}/${workflow_version_id}/run`,
          {
            method: "POST",
            headers: jsonAuthHeaders(userToken),
            body: JSON.stringify({
              input_variables,
              base_workflow_id: workflow_id,
            }),
          },
        );
        return handleResponse(response);
      },
    },
    queryClient,
  );

export type SaveSingleNodeResponse = {
  success: boolean;
  message?: string;
  error?: string;
};

export const createSaveSingleNodeMutation = (
  userToken: string,
  queryClient: QueryClient,
) =>
  new MobxMutation<SaveSingleNodeResponse, unknown, WorkflowNode>(
    {
      mutationFn: async (body: WorkflowNode) => {
        const response = await fetch(ENDPOINTS.workflow_nodes, {
          method: "POST",
          headers: jsonAuthHeaders(userToken),
          body: JSON.stringify(body),
        });
        return handleResponse(response);
      },
      onSuccess: (res) => {
        if (!res.success) {
          console.error(res.message || res.error || "");
        }
        queryClient.invalidateQueries([ENDPOINTS.workflow_nodes]);
        return res;
      },
      onError: (error) => {
        return error;
      },
    },
    queryClient,
  );

export type DeleteWorkflowResponse = {
  success: boolean;
  message?: string;
  error?: string;
};

export type DeleteWorkflowRequest = {
  workflow_id: number;
};

export const createDeleteWorkflowMutation = (
  userToken: string,
  queryClient: QueryClient,
) =>
  new MobxMutation<DeleteWorkflowResponse, unknown, DeleteWorkflowRequest>(
    {
      mutationFn: async ({ workflow_id }) => {
        console.log("Deleting workflow", workflow_id);
        const response = await fetch(`${ENDPOINTS.workflows}/${workflow_id}`, {
          method: "DELETE",
          headers: jsonAuthHeaders(userToken),
        });
        return handleResponse(response);
      },
      onSuccess: (res) => {
        if (!res.success) {
          console.error(res.message || res.error || "");
        }
        queryClient.invalidateQueries([ENDPOINTS.workflows]);
        queryClient.invalidateQueries([ENDPOINTS.workflow_versions]);
      },
      onError: (error) => {
        console.log("Error deleting workflow", error);
      },
    },
    queryClient,
  );

export type PlayFromHereResponse = {
  success: boolean;
  message?: string;
  error?: string;
};

export type PlayFromHereRequest = {
  workflow_node_id: string;
  workflow_id: number;
};

export const createPlayFromHereMutation = (
  userToken: string,
  queryClient: QueryClient,
) =>
  new MobxMutation<PlayFromHereResponse, unknown, PlayFromHereRequest>(
    {
      mutationFn: async ({ workflow_node_id, workflow_id }) => {
        const response = await fetch(`${ENDPOINTS.workflow_play_from_here}`, {
          method: "POST",
          headers: jsonAuthHeaders(userToken),
          body: JSON.stringify({
            workflow_node_id,
            base_workflow_id: workflow_id,
          }),
        });
        return handleResponse(response);
      },
      onSuccess: (res) => {
        if (!res.success) {
          console.error(res.message || res.error || "");
        }
      },
      onError: (error) => {
        console.log("Error playing workflow from node", error);
      },
    },
    queryClient,
  );

export type AddWorkflowLabelResponse = {
  success: boolean;
  workflow_label: {
    created_at: string;
    id: number;
    name: string;
    workflow_id: number;
    workflow_version_id: number;
  };
};

export type AddWorkflowLabelRequest = {
  name: string;
  workflow_id: number;
  workflow_version_id: number;
};

export const createAddWorkflowLabelMutation = (
  userToken: string,
  queryClient: QueryClient,
) =>
  new MobxMutation<AddWorkflowLabelResponse, unknown, AddWorkflowLabelRequest>(
    {
      mutationFn: async (variables) => {
        const response = await fetch(`${ENDPOINTS.workflow_labels}`, {
          method: "POST",
          headers: jsonAuthHeaders(userToken),
          body: JSON.stringify(variables),
        });

        return await response.json();
      },
      onSuccess: (res) => {
        if (!res.success) {
          return res;
        }

        queryClient.invalidateQueries([ENDPOINTS.workflow_labels]);
      },
      onError: (error) => {
        return error;
      },
    },
    queryClient,
  );

export type UpdateWorkflowLabelResponse = {
  success: boolean;
  workflow_label: {
    created_at: string;
    id: number;
    name: string;
    workflow_id: number;
    workflow_version_id: number;
  };
};

export type UpdateWorkflowLabelRequest = {
  id: number;
  workflow_version_id: number;
};

export const createUpdateWorkflowLabelMutation = (
  userToken: string,
  queryClient: QueryClient,
) =>
  new MobxMutation<
    UpdateWorkflowLabelResponse,
    unknown,
    UpdateWorkflowLabelRequest
  >(
    {
      mutationFn: async ({ id, workflow_version_id }) => {
        const response = await fetch(`${ENDPOINTS.workflow_labels}/${id}`, {
          method: "PATCH",
          headers: jsonAuthHeaders(userToken),
          body: JSON.stringify({ workflow_version_id }),
        });
        return handleResponse(response);
      },
      onSuccess: (res) => {
        if (!res.success) {
          console.error("Error updating workflow label");
        }
        queryClient.invalidateQueries([ENDPOINTS.workflow_labels]);
      },
      onError: (error) => {
        console.log("Error updating workflow label", error);
      },
    },
    queryClient,
  );

export type DeleteWorkflowLabelResponse = {
  success: boolean;
  message: string;
};

export type DeleteWorkflowLabelRequest = {
  id: number;
};

export const createDeleteWorkflowLabelMutation = (
  userToken: string,
  queryClient: QueryClient,
) =>
  new MobxMutation<
    DeleteWorkflowLabelResponse,
    unknown,
    DeleteWorkflowLabelRequest
  >(
    {
      mutationFn: async ({ id }) => {
        const response = await fetch(`${ENDPOINTS.workflow_labels}/${id}`, {
          method: "DELETE",
          headers: jsonAuthHeaders(userToken),
        });
        return handleResponse(response);
      },
      onSuccess: (res) => {
        if (!res.success) {
          console.error("Error deleting workflow label");
        }
        queryClient.invalidateQueries([ENDPOINTS.workflow_labels]);
      },
      onError: (error) => {
        console.log("Error deleting workflow label", error);
      },
    },
    queryClient,
  );
